import languageService from '../../../services/LanguageService'
import { FeatureChoice } from '../../game-features/types/types'
import { MarketExplorerSegmentDataFeature } from '../../market-explorer/types/MarketExplorerSegmentData'
import { NewsEntryFeature, NewsEntryScreenshot, NewsEntryScreenshotFeature } from '../../news/types/UnifiedNewsEntry'
import { TopFeature } from '../types/top-feature'

class FeatureService {
  inAppAdvertisingFeatureCategoryId = '5e9d6c603ef43b00be56f344'
  defaultTagsSelectionForFeatureIdeas = [
    '57bed2f5bc85fa005b70baae',
    '57bed2f5bc85fa005b70baad',
    '57bed2f5bc85fa005b70bab1',
    '57bed2f5bc85fa005b70bab2',
    '57bed2f5bc85fa005b70bab0',
    '57bed2f5bc85fa005b70bab3',
    '58d23407049648f910dc002f',
    '57bed2f5bc85fa005b70baaf',
    '57bed2f5bc85fa005b70bab6',
    '57bed2f5bc85fa005b70bab7',
    '57bed2f5bc85fa005b70bab8',
    '57bed2f5bc85fa005b70babb',
    '5e9d6c603ef43b00be56f344',
  ]

  getChoiceTypeForFeatureOriginalChoice(choice: string) {
    const originalChoice = choice.replace(' ', '').toLowerCase()

    let choiceType: string
    switch (originalChoice) {
      case 'yes':
        choiceType = 'yes'
        break

      case 'no':
      case 'none':
      case 'n/a':
      case 'na':
      case '0':
        choiceType = 'no'
        break

      default:
        choiceType = 'text'
        break
    }

    return choiceType
  }

  getTopFeatureIsDifferentiating(topFeature: TopFeature) {
    if (
      topFeature.avgEffect > 0.3 &&
      topFeature.popularity.Overall > 5 &&
      topFeature.popularity['20Percent'] / topFeature.popularity.Overall > 1.1 &&
      topFeature.popularity['50Percent'] / topFeature.popularity.Overall > 1.1 &&
      topFeature.featureLegacyId !== 174 // Publisher effect
    ) {
      return true
    } else {
      return false
    }
  }

  fullFeaturePopularityDataAvailableForMarketIsoAndCategoryId(marketIso: string, categoryId: string) {
    return (marketIso === 'cn' || marketIso === 'jp') && categoryId === this.inAppAdvertisingFeatureCategoryId ? false : true
  }

  getFilteredFeaturesForNewsEntryFeatures(features: Array<NewsEntryFeature> = []) {
    return features
      .filter(
        (feature) =>
          feature.choice1 &&
          feature.choice2 &&
          !(this.isFeatureChoiceNameNonActiveType(feature.choice1) && this.isFeatureChoiceNameNonActiveType(feature.choice2))
      )
      .sort((a, b) => (a.featureName < b.featureName ? -1 : 1))
  }

  isFeatureChoiceNameNonActiveType(choiceName: string) {
    return this.getChoiceTypeForFeatureOriginalChoice(choiceName) === 'no'
  }

  getUpdatedFeaturesFromNewsEntryScreenshots(filteredFeatures: Array<NewsEntryFeature>, screenshots: Array<NewsEntryScreenshot> = []) {
    const FILTERED_IDS = new Set(filteredFeatures.map((feature) => feature.featureLegacyId))

    return [
      ...screenshots
        .reduce((acc, val) => {
          val.features
            ?.filter((feature) => !acc.has(feature.featureLegacyId + '') && !FILTERED_IDS.has(feature.featureLegacyId))
            .forEach((feature) => {
              acc.set(feature.featureLegacyId + '', feature)
            })
          return acc
        }, new Map<string, NewsEntryScreenshotFeature>())
        .values(),
    ].sort((a, b) => (a.featureName_translation < b.featureName_translation ? -1 : 1))
  }

  localizeFeature(feature: TopFeature | FeatureChoice | MarketExplorerSegmentDataFeature) {
    return {
      ...feature,
      originalChoiceLabel: feature.choiceLabel,
      featureLabel: languageService.getTranslation('features', feature.featureLegacyId.toString()),
      choiceLabel: languageService.getTranslation('choices', feature.choiceLegacyId.toString()),
    }
  }
}

const featureService = new FeatureService()

export default featureService
