import { DateRangeValue } from '../../../components/DateRangePicker/DateRangePicker'

export enum DeviceTypeValue {
  AllIOSDevices = 'AllIOSDevices',
  IPhone = 'IPhone',
  IPad = 'IPad',
}

export enum GranularityValue {
  Day = 'Day',
  Week = 'Week',
  Month = 'Month',
}

export enum DataTypeValue {
  Revenue = 'Revenue',
  Downloads = 'Downloads',
  RevenueDownloadsRatioAllTime = 'RevenueDownloadsRatioAllTime',
  RevenueDownloadsRatio7Days = 'RevenueDownloadsRatio7Days',
  RevenueDownloadsRatio30Days = 'RevenueDownloadsRatio30Days',
  RevenueDownloadsRatio90Days = 'RevenueDownloadsRatio90Days',
}

export type GameRevenueAndDownloadsFiltersValue = {
  dateRange?: DateRangeValue
  deviceType: DeviceTypeValue
  granularity: GranularityValue
  dataType: DataTypeValue
  selectedMarketIsos: string[]
}
