import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Box, Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, MenuItem, Select, Typography } from '@mui/material'

import { GRTooltip } from '../../../../components/GRTooltip/GRTooltip'
import marketExplorerService from '../../../../services/MarketExplorerService'
import { MarketExplorerGamesChartAxisId } from '../../types/MarketExplorerGamesChartAxisId'
import { MarketExplorerGamesChartAxisScale, MarketExplorerGamesChartAxisType } from '../../types/MarketExplorerGamesChartAxisType'
import { MarketExplorerGamesChartNode } from '../../types/MarketExplorerGamesChartNode'
import MarketExplorerGamesChartAxis from '../MarketExplorerGamesChartAxis/MarketExplorerGamesChartAxis'
import { MarketExplorerGamesChartColorChip } from '../MarketExplorerGamesChartColorChip/MarketExplorerGamesChartColorChip'
import MarketExplorerGamesChartNodeItem from '../MarketExplorerGamesChartNodeItem/MarketExplorerGamesChartNodeItem'
import { SegmentQueryDataType } from '../MarketExplorerSegments/MarketExplorerSegment/MarketExplorerSegment'
import './MarketExplorerGamesChart.scss'

const maxNodeCount = 1000

interface MarketExplorerGameChartProps {
  segments: SegmentQueryDataType[]
  yAxis: MarketExplorerGamesChartAxisType
  yAxisMax: MarketExplorerGamesChartAxisScale
  yAxisMin: MarketExplorerGamesChartAxisScale
  xAxis: MarketExplorerGamesChartAxisType
  xAxisMax: MarketExplorerGamesChartAxisScale
  xAxisMin: MarketExplorerGamesChartAxisScale
  onAxisSelectChange: (id: MarketExplorerGamesChartAxisId, axisType: MarketExplorerGamesChartAxisType) => void
  onAxisMaxValueSelectChange: (
    id: MarketExplorerGamesChartAxisId,
    axisType: MarketExplorerGamesChartAxisType,
    axisScale: MarketExplorerGamesChartAxisScale,
    minAxisScale: MarketExplorerGamesChartAxisScale
  ) => void
  onAxisMinValueSelectChange: (
    id: MarketExplorerGamesChartAxisId,
    axisType: MarketExplorerGamesChartAxisType,
    axisScale: MarketExplorerGamesChartAxisScale,
    maxAxisScale: MarketExplorerGamesChartAxisScale
  ) => void
  onNodesChange: (chartNodes: MarketExplorerGamesChartNode[]) => void
}

const MarketExplorerGamesChart: React.FC<MarketExplorerGameChartProps> = ({
  segments,
  yAxis,
  yAxisMax,
  yAxisMin,
  xAxis,
  xAxisMax,
  xAxisMin,
  onAxisSelectChange,
  onAxisMinValueSelectChange,
  onAxisMaxValueSelectChange,
  onNodesChange,
}) => {
  const { t } = useTranslation()

  const [showSegmentColor, setShowSegmentColor] = useState<{ [segmentId: string]: boolean }>({
    segment1: true,
    segment2: true,
    segment3: true,
    segment4: true,
    segment5: true,
    segment6: true,
  })

  const handleShowSegmentColorChange = (name: number | string, selected: boolean) => {
    setShowSegmentColor({
      ...showSegmentColor,
      [name]: selected,
    })
  }

  const [showTopGames, setShowTopGames] = useState(true)
  const [showNodeIcons, setShowNodeIcons] = useState(true)
  const [showTopGamesPercent, setShowTopGamesPercent] = useState(10)

  const chartNodes = useMemo(() => {
    let nodes: MarketExplorerGamesChartNode[] = []
    let nodeIndex = 0

    segments.forEach((segment, index) => {
      const segmentId = index + 1
      const marketIso = segment?.segmentConfiguration.marketIso

      if (marketIso) {
        const segmentNodes: MarketExplorerGamesChartNode[] = []

        segment?.data?.games.forEach((game) => {
          const { value: valueX, showOnChart: showXNode } = marketExplorerService.getGamePositionOnChart(game, xAxis, xAxisMax, xAxisMin, marketIso)
          const { value: valueY, showOnChart: showYNode } = marketExplorerService.getGamePositionOnChart(game, yAxis, yAxisMax, yAxisMin, marketIso)
          if (showXNode && showYNode) {
            segmentNodes.push({
              id: `MarketExplorerGamesChartNode-${segmentId}-${nodeIndex}`,
              segmentId: segmentId,
              marketIso,
              x: valueX,
              y: valueY,
              game,
              showNodeColor: showSegmentColor[`segment${segmentId}`] ? true : false,
              showIcon: showNodeIcons,
              topGame: false,
            })
            nodeIndex++
          }
        })

        const SGRRanks: number[] = segmentNodes.map((node) => {
          const gameSGRRank = node.game.sranks[marketIso]
          if (gameSGRRank > 0 && gameSGRRank < marketExplorerService.maxSustainedRank) {
            return gameSGRRank
          } else {
            return marketExplorerService.maxSustainedRank
          }
        })
        SGRRanks.sort((a, b) => a - b)
        const maxTopGameSGRRank = SGRRanks.length >= 0 ? SGRRanks[Math.round((SGRRanks.length - 1) * (showTopGamesPercent / 100))] : 1

        segmentNodes.forEach((node) => {
          const gameSGRRank = node.game.sranks[marketIso] || marketExplorerService.maxSustainedRank
          if (showTopGames && gameSGRRank < marketExplorerService.maxSustainedRank && gameSGRRank <= maxTopGameSGRRank) {
            node.topGame = true
          }
        })

        nodes = [...nodes, ...segmentNodes]
      }
    })

    if (nodes.length > maxNodeCount) {
      nodes.forEach((node) => (node.showIcon = false))
    }

    return nodes.sort((a, b) => (a.x + a.y - (b.x + b.y) ? -1 : 1))
  }, [segments, yAxis, yAxisMax, yAxisMin, xAxis, xAxisMax, xAxisMin, showSegmentColor, showNodeIcons, showTopGames, showTopGamesPercent])

  useEffect(() => {
    onNodesChange(chartNodes)
  }, [chartNodes, onNodesChange])

  return (
    <div className="MarketExplorerGamesChart">
      <div className="MarketExplorerGamesChart__Header">
        <Box sx={{ textAlign: 'center' }}>
          <div className="MarketExplorerGamesChart__Header__Title">
            <GRTooltip content={t(yAxis.description)}>
              <Typography className="MarketExplorerGamesChart__text">{t(yAxis.name)}</Typography>
            </GRTooltip>
            <Typography sx={{ color: (theme) => theme.palette.grey[600] }} className="MarketExplorerGamesChart__text">
              {t('common:vs_text')}
            </Typography>
            <GRTooltip content={t(xAxis.description)}>
              <Typography className="MarketExplorerGamesChart__text">{t(xAxis.name)}</Typography>
            </GRTooltip>
          </div>
        </Box>
        <FormControl component="fieldset" variant="standard">
          <FormGroup row>
            <FormLabel component="legend" style={{ alignSelf: 'center', margin: '0px 10px 10px 0px' }}>
              {t('market-explorer:show_colors_icons')}
            </FormLabel>
            {segments.map((segment, index) => {
              const segmentId = index + 1
              return (
                segment?.segmentConfiguration.visible && (
                  <MarketExplorerGamesChartColorChip
                    key={`segment${segmentId}`}
                    label={t('segment:segment')}
                    segmentId={segmentId}
                    selected={showSegmentColor[`segment${segmentId}`]}
                    onClick={(segmentId) => {
                      handleShowSegmentColorChange(`segment${segmentId}`, !showSegmentColor[`segment${segmentId}`])
                    }}
                  />
                )
              )
            })}

            <MarketExplorerGamesChartColorChip
              label={
                <>
                  <GRTooltip content={t('market-explorer:based_on_sgr')}>{t('market-explorer:top_games')} </GRTooltip>

                  <FormControl sx={{ minWidth: 60, marginLeft: '5px' }}>
                    <Select
                      value={showTopGamesPercent}
                      size="small"
                      onClick={(event) => {
                        event.stopPropagation()
                      }}
                      onChange={(event) => {
                        event.stopPropagation()
                        setShowTopGamesPercent(event.target.value as number)
                      }}
                      style={{ height: '16px', margin: '-1px 0px' }}
                    >
                      <MenuItem value={10}>10%</MenuItem>
                      <MenuItem value={20}>20%</MenuItem>
                    </Select>
                  </FormControl>
                </>
              }
              segmentId={'top'}
              selected={showTopGames}
              onClick={() => {
                setShowTopGames(!showTopGames)
              }}
            />

            <FormControlLabel
              control={<Checkbox size="small" checked={showNodeIcons} onClick={() => setShowNodeIcons((value) => !value)} />}
              label={t<string>('market-explorer:show_game_icons_check', { count: maxNodeCount })}
              style={{ alignSelf: 'center', margin: '0px 10px 10px 0px' }}
            />
          </FormGroup>
        </FormControl>
      </div>

      <div className="MarketExplorerGamesChart__ChartWrapper">
        <div className="MarketExplorerGamesChart__YAxisHelper">
          <div className="MarketExplorerGamesChart__Axis MarketExplorerGamesChart__Axis--Y">
            <MarketExplorerGamesChartAxis
              id={MarketExplorerGamesChartAxisId.Y}
              axis={yAxis}
              axisMax={yAxisMax}
              axisMin={yAxisMin}
              onAxisSelectChange={onAxisSelectChange}
              onMaxValueSelectChange={onAxisMaxValueSelectChange}
              onMinValueSelectChange={onAxisMinValueSelectChange}
            ></MarketExplorerGamesChartAxis>
          </div>
        </div>

        <div className="MarketExplorerGamesChart__Axis MarketExplorerGamesChart__Axis--X">
          <MarketExplorerGamesChartAxis
            id={MarketExplorerGamesChartAxisId.X}
            axis={xAxis}
            axisMax={xAxisMax}
            axisMin={xAxisMin}
            onAxisSelectChange={onAxisSelectChange}
            onMaxValueSelectChange={onAxisMaxValueSelectChange}
            onMinValueSelectChange={onAxisMinValueSelectChange}
          ></MarketExplorerGamesChartAxis>
        </div>

        <div className="MarketExplorerGamesChart__Chart">
          <div className="MarketExplorerGamesChart__Chart__YDivider"></div>
          <div className="MarketExplorerGamesChart__Chart__XDivider"></div>

          <div className="MarketExplorerGamesChart__Chart__Inner">
            {chartNodes.map((node) => {
              return <MarketExplorerGamesChartNodeItem key={node.id} node={node} yAxisType={yAxis} xAxisType={xAxis} />
            })}
            {chartNodes.length === 0 && <div className="MarketExplorerGamesChart__Chart__Message">{t('common:no_games_on_chart')}</div>}
          </div>
        </div>
      </div>
    </div>
  )
}

export default MarketExplorerGamesChart
