import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Star } from '@mui/icons-material'
import { Grid } from '@mui/material'

import { GRTooltip } from '../../../../components/GRTooltip/GRTooltip'
import featureService from '../../../../features/feature/services/FeatureService'
import FeatureChoiceSimple from '../../../feature/components/FeatureChoiceSimple/FeatureChoiceSimple'
import { TabOption } from '../../types/feature-modal-tabs'
import { TopFeature } from '../../types/top-feature'
import { ModalFeature } from '../FeatureModal/FeatureModal'
import './FeatureLabelLink.scss'
import { FeatureLink } from './FeatureLink'

type Choice = {
  choiceLegacyId: number
  name: string
  originalChoiceName?: string
}

interface FeatureLabelLinkProps {
  choice?: Choice
  feature: ModalFeature
  initialTab?: TabOption
  topFeature?: TopFeature
  forceShowFeatureInList?: boolean //force to show features in list despite showInlists props
}

/**
 * Warning: Having multiple FeatureLabelLinks on the page with same feature AND initialTab
 * will open multiple modals when the matching share URL is opened
 */
export const FeatureLabelLink: React.FC<FeatureLabelLinkProps> = ({ choice, feature, initialTab, topFeature, forceShowFeatureInList }) => {
  const { t } = useTranslation()
  const isDifferentiatingFeature = useMemo(() => (!topFeature ? false : featureService.getTopFeatureIsDifferentiating(topFeature)), [topFeature])
  return (
    <FeatureLink choice={choice} feature={feature} initialTab={initialTab} forceShowFeatureInList={forceShowFeatureInList}>
      <Grid container spacing={2} className="FeatureLabelLink">
        <Grid item xs>
          {feature.featureName}
          {choice?.originalChoiceName && <FeatureChoiceSimple choiceLocalized={choice.name} originalChoice={choice.originalChoiceName} />}
        </Grid>
        {isDifferentiatingFeature && (
          <Grid item>
            <GRTooltip content={t('overview:differentiating_feature_in_genre_tooltip')}>
              <Star className="FeatureLabelLink__Star" />
            </GRTooltip>
          </Grid>
        )}
      </Grid>
    </FeatureLink>
  )
}
