import { differenceInDays } from 'date-fns'
import { FC, memo, ReactNode, useCallback, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Card, Chip, TableContainer } from '@mui/material'
import { Box } from '@mui/system'

import { Filters } from '../..'
import { GRTable, GRTableColumn, SortOrder } from '../../../../components/GRTable/GRTable'
import { LockedDataIndicator } from '../../../../components/LockedDataIndicator/LockedDataIndicator'
import { PowerScoreIndicator } from '../../../../components/PowerScoreIndicator/PowerScoreIndicator'
import { RoleEnum } from '../../../account'
import { useRoleCheck } from '../../../account/hooks/roleHooks'
import { RevenueAndDownloadEstimates } from '../../../estimates/services/RevenueAndDownloadEstimates'
import { Game } from '../../../game'
import { GameCardContent } from '../../../game/components/GameCard/GameCard'
import { useCurrentMarket } from '../../../markets'
import { tier1MarketIso } from '../../constants/constants'
import { useSoftLaunchGameFilters } from '../../hooks/useSoftLaunchGameFilters'
import { CellValue } from '../CellValue/CellValue'
import { CurrencyIndicator } from '../CurrencyIndicator/CurrencyIndicator'
import SoftLaunchMarketList from '../SoftLaunchMarketList/SoftLaunchMarketList'
import { TrendIndicator } from '../TrendIndicator/TrendIndicator'
import styles from './SoftLaunchTable.module.scss'

type SoftLaunchTableProps = {
  filters: Filters
  games: Game[]
  isLoading?: boolean
}

/**
 * Component for displaying Soft Launch Games table
 */
export const SoftLaunchTable: FC<SoftLaunchTableProps> = memo(({ filters, games, isLoading }) => {
  const { t } = useTranslation()
  const containerRef = useRef(null)

  const estimatesUnlocked = useRoleCheck(RoleEnum.revenue_estimates)
  const { currentMarketIso: mainMarketIso } = useCurrentMarket()

  const softLaunchTableColumns: GRTableColumn<Game, typeof customTableProps>[] = [
    {
      labelAccessor: t('sidebar:soft_launch'),
      columns: [
        {
          labelAccessor: t('common:markets'),
          accessor: ({ row }) => <SoftLaunchMarketList softLaunchMarkets={row.softLaunchMarkets} size="small" />,
          cellProps: { align: 'left' },
          headerCellProps: { sx: { minWidth: 120 } },
        },
      ],
    },

    {
      labelAccessor: t('common:game'),
      columns: [
        {
          labelAccessor: t('common:name'),
          accessor: ({ row, rowIndex }) => (
            <Box>
              <GameCardContent game={row} rank={rowIndex + 1} variant="table" />
            </Box>
          ),
          sortable: true,
          sortAccessor: 'name',
          cellProps: { align: 'left' },
          headerCellProps: { sx: { minWidth: 200 } },
        },
        {
          labelAccessor: t('common:game_power_score'),
          accessor: ({ row: game, customTableProps }) => {
            return (
              <div className={styles.powerScoreContainer}>
                {differenceInDays(new Date(), game.released) <= 30 && (
                  <Chip className={styles.ribbon} label={t('common:new')} variant="outlined" size="small"></Chip>
                )}
                <PowerScoreIndicator value={customTableProps?.mainMarketIso ? game.gpsPerMarket[customTableProps.mainMarketIso] : 0} />
              </div>
            )
          },
          sortable: true,
          sortAccessor: ({ row: game, customTableProps }) => (customTableProps?.mainMarketIso ? game.gpsPerMarket[customTableProps.mainMarketIso] || 0 : 0),
          cellProps: { align: 'center' },
        },
      ],
    },

    {
      labelAccessor: `${t('soft-launch-games:tier_1_markets')} ${t('common:30_days_revenue')}`,
      columns: [
        {
          labelAccessor: t('common:value_text'),
          accessor: ({ row, customTableProps }) => (
            <RevenueEstimateGuard estimatesUnlocked={customTableProps?.estimatesUnlocked}>
              <CurrencyIndicator estimate={getTier1MarketValues(row).revenue30Day} />
            </RevenueEstimateGuard>
          ),
          sortable: true,
          sortAccessor: estimateSortAccessor('revenue30Day'),
          sortOrder: SortOrder.DESC,
        },
        {
          labelAccessor: t('common:trend'),
          accessor: ({ row, customTableProps }) => (
            <RevenueEstimateGuard estimatesUnlocked={customTableProps?.estimatesUnlocked}>
              <TrendIndicator estimate={getTier1MarketValues(row).revenueTrend30Day} />
            </RevenueEstimateGuard>
          ),
          sortable: true,
          sortAccessor: estimateSortAccessor('revenueTrend30Day'),
        },
      ],
    },
    {
      labelAccessor: `${t('soft-launch-games:tier_1_markets')} ${t('common:30_days_downloads')}`,
      columns: [
        {
          labelAccessor: t('common:value_text'),
          accessor: ({ row, customTableProps }) => (
            <RevenueEstimateGuard estimatesUnlocked={customTableProps?.estimatesUnlocked}>
              <CellValue value={getTier1MarketValues(row).downloads30Day.value} emphasized>
                {getTier1MarketValues(row).downloads30Day.format()}
              </CellValue>
            </RevenueEstimateGuard>
          ),
          sortable: true,
          sortAccessor: estimateSortAccessor('downloads30Day'),
        },
        {
          labelAccessor: t('common:trend'),
          accessor: ({ row, customTableProps }) => (
            <RevenueEstimateGuard estimatesUnlocked={customTableProps?.estimatesUnlocked}>
              <TrendIndicator estimate={getTier1MarketValues(row).downloadsTrend30Day} />
            </RevenueEstimateGuard>
          ),
          sortable: true,
          sortAccessor: estimateSortAccessor('downloadsTrend30Day'),
        },
      ],
    },
    {
      labelAccessor: `${t('soft-launch-games:tier_1_markets')} ${t('common:30_days_revenue_download_ratio')}`,
      columns: [
        {
          labelAccessor: t('common:value_text'),
          accessor: ({ row, customTableProps }) => (
            <RevenueEstimateGuard estimatesUnlocked={customTableProps?.estimatesUnlocked}>
              <CurrencyIndicator estimate={getTier1MarketValues(row).revenueAndDownloadsRatio30Day} maxFractionDigits={3} threshold={0} />
            </RevenueEstimateGuard>
          ),
          sortable: true,
          sortAccessor: estimateSortAccessor('revenueAndDownloadsRatio30Day'),
        },
        {
          labelAccessor: t('common:trend'),
          accessor: ({ row, customTableProps }) => (
            <RevenueEstimateGuard estimatesUnlocked={customTableProps?.estimatesUnlocked}>
              <TrendIndicator estimate={getTier1MarketValues(row).revenueAndDownloadsRatioTrend30Day} />
            </RevenueEstimateGuard>
          ),
          sortable: true,
          sortAccessor: estimateSortAccessor('revenueAndDownloadsRatioTrend30Day'),
        },
      ],
    },
  ]

  const customTableProps = useMemo(
    () => ({
      mainMarketIso,
      estimatesUnlocked,
    }),
    [mainMarketIso, estimatesUnlocked]
  )

  const { filteredGames } = useSoftLaunchGameFilters(games, filters)
  const [columns, setColumns] = useState<GRTableColumn<Game, typeof customTableProps>[]>(softLaunchTableColumns)
  const handleColumnsUpdate = useCallback((updatedColumns: GRTableColumn<Game, typeof customTableProps>[]) => {
    setColumns(updatedColumns)
  }, [])

  return (
    <TableContainer component={Card} ref={containerRef} className={styles.container}>
      <GRTable
        rows={filteredGames}
        columns={columns}
        striped
        hoverable
        onColumnsUpdated={handleColumnsUpdate}
        rowIdKey="id"
        noRowsLabel={t('market-explorer:no_games_available')}
        isLoading={isLoading}
        scroller={containerRef}
        customProps={customTableProps}
      />
    </TableContainer>
  )
})

const estimateSortAccessor =
  (
    estimateFieldName:
      | 'revenueAndDownloadsRatioTrend30Day'
      | 'revenueAndDownloadsRatio30Day'
      | 'downloadsTrend30Day'
      | 'downloads30Day'
      | 'revenueTrend30Day'
      | 'revenue30Day'
  ) =>
  ({ row: game, customTableProps: options }: { row: Game; customTableProps?: { mainMarketIso: string; estimatesUnlocked: boolean } }) => {
    if (options?.estimatesUnlocked) {
      return getTier1MarketValues(game)[estimateFieldName].value ? getTier1MarketValues(game)[estimateFieldName].value : 0
    } else {
      return options?.mainMarketIso ? game.gpsPerMarket[options.mainMarketIso] || 0 : 0
    }
  }

const getTier1MarketValues = (game: Game) => {
  return new RevenueAndDownloadEstimates(game, tier1MarketIso)
}

const RevenueEstimateGuard: FC<{ estimatesUnlocked?: boolean; children: ReactNode }> = ({ estimatesUnlocked, children }) => {
  return estimatesUnlocked ? <>{children}</> : <LockedDataIndicator />
}
