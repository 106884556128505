import { FC, ReactNode, useCallback, useEffect, useMemo, useRef } from 'react'
import { SidebarHeader } from 'react-calendar-timeline'

import { Box } from '@mui/material'

import { useAppDispatch, useAppSelector } from '../../../../../hooks/storeHooks'
import { useCalendarEventsByGame } from '../../../hooks/liveEventsCalendarEvents'
import { gameCalendarVisibilityToggled, selectVisibleGameCalendars } from '../../../slices/liveEventsCalendarsSlice'
import { CalendarTimeRangeChangeCallback, CalendarTimeRanges } from '../../../types/Calendar'
import { GameTopIAPsByDate } from '../../../types/GameTopIAPsByDate'
import { GameVersion } from '../../../types/GameVersion'
import { TrackingEventTimelineItem, TrackingEventTimelineGroup } from '../../../types/LiveEvents'
import { PerformanceEffect } from '../../../types/PerformanceEffect'
import { TrackedGame } from '../../../types/TrackedGame'
import { LiveEventReview, TrackingEvent } from '../../../types/TrackingEvents'
import LiveEventsCalendar from '../LiveEventsCalendar'
import { LiveEventsCalendarCustomSidebarHeader } from '../LiveEventsCalendarCustomSidebarHeader/LiveEventsCalendarCustomSidebarHeader'

/**
 * Container component that prepares event data to be shown for a specific game only
 */
type LiveEventsCalendarByGameContainerProps = {
  trackedGame: TrackedGame
  trackedEvents: TrackingEvent[]
  performanceEffects: PerformanceEffect[]
  gameVersions: GameVersion[]
  gameTopIAPs: GameTopIAPsByDate[]
  analystReviews: LiveEventReview[]
  calendarTimeRanges: CalendarTimeRanges
  timeStart?: number
  timeEnd?: number
  minZoom?: number
  maxZoom?: number
  highlightedEventId?: string
  selectedCalendarAdditionalDataIdsMap: { [dataId: string]: boolean }
  onItemClick: (clickedItem: TrackingEventTimelineItem) => void
  onGroupClick: (clickedGroup: TrackingEventTimelineGroup) => void
  onDeselectGame?: (trackedGame: TrackedGame) => void
  onOverviewTrackedGameChanged: (trackedGame: TrackedGame) => void
  onCalendarTimeRangeChange: CalendarTimeRangeChangeCallback
  onGamePerformanceDialogOpen: (trackedGame: TrackedGame) => void
  onCalendarChange?: () => void
  isLoading?: boolean
  children?: ReactNode
}

export const LiveEventsCalendarByGameContainer: FC<LiveEventsCalendarByGameContainerProps> = ({
  calendarTimeRanges,
  gameVersions,
  performanceEffects,
  gameTopIAPs,
  analystReviews,
  trackedEvents,
  trackedGame,
  timeStart,
  timeEnd,
  minZoom,
  maxZoom,
  highlightedEventId,
  selectedCalendarAdditionalDataIdsMap,
  onItemClick,
  onGroupClick,
  onDeselectGame,
  onOverviewTrackedGameChanged,
  onCalendarTimeRangeChange,
  onGamePerformanceDialogOpen,
  onCalendarChange,
  isLoading,
}) => {
  const { items, groups } = useCalendarEventsByGame({
    selectedCalendarAdditionalDataIdsMap,
    trackedGame,
    trackedEvents,
    performanceEffects,
    gameVersions,
    gameTopIAPs,
    analystReviews,
    highlightedEventId,
    calendarTimeStart: calendarTimeRanges[trackedGame.game.id]?.timeStart || timeStart,
    calendarTimeEnd: calendarTimeRanges[trackedGame.game.id]?.timeEnd || timeEnd,
  })

  const dispatch = useAppDispatch()
  const containerRef = useRef<HTMLDivElement>(null)
  const visibleGameCalendars = useAppSelector(selectVisibleGameCalendars)
  const calendarHidden = !visibleGameCalendars?.includes(trackedGame.game.id)

  const sidebarHeader = useMemo(() => {
    return (
      <SidebarHeader>
        {({ getRootProps }) => (
          <LiveEventsCalendarCustomSidebarHeader
            trackedGame={trackedGame}
            onDeselectGame={onDeselectGame}
            onToggleVisibility={() => dispatch(gameCalendarVisibilityToggled(trackedGame.game.id))}
            onOverviewTrackedGameChanged={onOverviewTrackedGameChanged}
            onGamePerformanceDialogOpen={onGamePerformanceDialogOpen}
            {...getRootProps()}
            calendarContainerRef={containerRef}
          />
        )}
      </SidebarHeader>
    )
  }, [trackedGame, onDeselectGame, onOverviewTrackedGameChanged, onGamePerformanceDialogOpen, dispatch])

  const handleCalendarTimeRangeChange = useCallback(
    (timeStart: number, timeEnd: number, updateScrollCanvas: (start: number, end: number) => void) => {
      updateScrollCanvas(timeStart, timeEnd)
      onCalendarTimeRangeChange(trackedGame.game.id, timeStart, timeEnd)
    },
    [onCalendarTimeRangeChange, trackedGame.game.id]
  )

  useEffect(() => {
    onCalendarChange?.()
  }, [onCalendarChange, groups, items, calendarHidden])

  return (
    <Box ref={containerRef}>
      <LiveEventsCalendar
        groups={groups}
        items={items}
        onItemClick={onItemClick}
        onGroupClick={onGroupClick}
        sidebarHeader={sidebarHeader}
        timeStart={calendarTimeRanges[trackedGame.game.id]?.timeStart || timeStart}
        timeEnd={calendarTimeRanges[trackedGame.game.id]?.timeEnd || timeEnd}
        minZoom={minZoom}
        maxZoom={maxZoom}
        isLoading={items.length === 0 && isLoading}
        calendarHidden={calendarHidden}
        lineHeight={34}
        onCalendarTimeRangeChange={handleCalendarTimeRangeChange}
      />
    </Box>
  )
}
