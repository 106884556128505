import classNames from 'classnames'
import { FC, ReactNode, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { CSSTransition } from 'react-transition-group'

import { GridView, ViewCarousel } from '@mui/icons-material'
import { Box, Typography, Card, CardContent, Divider, Grid, ButtonGroup, Button, useMediaQuery, useTheme } from '@mui/material'

import { useGetTrackedEventQuery } from '../../../../../api/core'
import { Analyst } from '../../../../../components/Analyst/Analyst'
import { FullscreenToggler } from '../../../../../components/FullscreenToggler/FullscreenToggler'
import { GRCarousel } from '../../../../../components/GRCarousel/GRCarousel'
import GRCircularProgress from '../../../../../components/GRCircularProgress/GRCircularProgress'
import { HtmlContentParser } from '../../../../../components/HtmlContentParser/HtmlContentParser'
import { ModalMode, liveEventReviewModalParserOptions } from '../../../../../components/LiveEventModalLink/LiveEventModalLink'
import { useError } from '../../../../../hooks/useError'
import { useCurrentUserLanguage } from '../../../../account/hooks/userHooks'
import { useLiveEventDialogDescriptionTabAnalyticsEvents } from '../../../hooks/useLiveEventsTrackerAnalyticsEvents'
import { TrackedGame } from '../../../types/TrackedGame'
import { Duration } from '../../../types/TrackingEvents'
import LiveEventsSecondaryTaxonomyTagList from '../../LiveEventsSecondaryTaxonomyTagList/LiveEventsSecondaryTaxonomyTagList'
import { EventHeader } from '../EventHeader/EventHeader'
import './DescriptionTab.scss'

export type DescriptionTabImageListingType = 'carousel' | 'thumbnails'
/**
 * Component representing event dialog Description tab
 */
type DescriptionTabProps = {
  currentTrackedGame?: TrackedGame
  eventId?: string
  eventTypeId?: string
  eventTypeName?: string
  children?: ReactNode
  screenshotIndex?: number
  duration?: Duration
  onScreenshotIndexChange: (screenshotIndex: number) => void
  onScrollParentTop: () => void
  onEventHighlighted?: (eventId: string) => void
}

export const DescriptionTab: FC<DescriptionTabProps> = ({
  currentTrackedGame,
  eventId,
  eventTypeId,
  eventTypeName,
  screenshotIndex = 0,
  duration,
  onScreenshotIndexChange,
  onScrollParentTop,
  onEventHighlighted,
}) => {
  const eventQuery = useGetTrackedEventQuery(eventId as string, { skip: !eventId })
  useError({ error: eventQuery.error })

  const { t } = useTranslation()
  const theme = useTheme()
  const { handleTrackImageListingTypeChangeEvent } = useLiveEventDialogDescriptionTabAnalyticsEvents()
  const userLanguage = useCurrentUserLanguage()
  const [loadingImage, setLoadingImage] = useState(true)
  const isFullscreenAvailable = useMediaQuery(theme.breakpoints.up('md'), { noSsr: true })
  const [imageListingType, setImageListingType] = useState<DescriptionTabImageListingType>('carousel')
  const [imagePreviewMode, setImagePreviewMode] = useState<'default' | 'fullscreen'>('default')
  const [flexDirection, setFlexDirection] = useState<'row' | 'column-reverse' | 'column'>(isFullscreenAvailable ? 'row' : 'column')
  const isLoading = eventQuery.isLoading || eventQuery.isFetching
  const isImagePreviewFullscreenMode = imagePreviewMode === 'fullscreen'

  const screenshots = useMemo(() => {
    if (!eventQuery.data?.event.screenshotUrls) return []

    return eventQuery.data?.event.screenshotUrls?.filter((url) => url)
  }, [eventQuery.data?.event.screenshotUrls])
  const descriptionRawHtml = eventQuery.data?.event.comment?.content?.comment?.[userLanguage]
    ? eventQuery.data?.event.comment?.content?.comment?.[userLanguage]
    : eventQuery.data?.event.comment?.content?.comment?.['en']
  const eventName = eventQuery.data?.event.name
  const inactive = eventQuery.data?.event.active === false
  const analyst = eventQuery.data?.analyst

  const gotoScreenshot = (delta: number) => {
    setLoadingImage(true)
    onScreenshotIndexChange((screenshotIndex + delta) % screenshots.length)
  }

  const handleImageListingTypeChange = (newType: DescriptionTabImageListingType) => {
    setImageListingType(newType)
    if (newType === 'thumbnails') {
      onScrollParentTop()
    }

    handleTrackImageListingTypeChangeEvent({
      imageListingType,
      trackedGame: currentTrackedGame,
      eventId,
      eventName,
      eventTypeId,
      eventTypeName,
    })
  }

  const thumbnailGridSize = useMemo(() => {
    const count = screenshots.length
    if (count === 1) return 6
    if (count <= 4) return isImagePreviewFullscreenMode ? 4 : 6
    if (count <= 9) return isImagePreviewFullscreenMode ? 3 : 4
    return 3
  }, [isImagePreviewFullscreenMode, screenshots.length])

  const descriptionItemClasses = classNames('DescriptionTab__description', {
    'DescriptionTab__description--fullscreen': isImagePreviewFullscreenMode,
  })

  return isLoading ? (
    <GRCircularProgress />
  ) : (
    <>
      <Grid className="DescriptionTab" container columnSpacing={2} flexDirection={flexDirection}>
        <Grid item flexShrink={1} className={descriptionItemClasses} mb={2}>
          <Card>
            <CardContent>
              <Box sx={{ textAlign: 'center' }}>
                <EventHeader
                  eventName={eventName}
                  inactive={inactive}
                  eventId={eventId}
                  eventTypeId={eventTypeId}
                  trackedGameId={currentTrackedGame?.game.id}
                  duration={duration}
                  onEventHighlighted={onEventHighlighted}
                />
              </Box>
              {eventQuery?.data?.event.tags && eventQuery.data.event.tags.length > 0 && (
                <>
                  <Divider sx={{ my: 2 }} style={{ marginTop: '0' }}>
                    {t('live-events:live_events_taxonomy')}
                  </Divider>

                  <Box mb={2} display="flex" justifyContent="center" flexWrap="wrap">
                    <LiveEventsSecondaryTaxonomyTagList tags={eventQuery.data.event.tags || []} />
                  </Box>
                </>
              )}
              <Divider sx={{ my: 2 }} style={{ marginTop: '0' }}>
                <Trans i18nKey="common:description" />
              </Divider>
              <Analyst mb={2} name={analyst?.name} title={analyst?.title} picture={analyst?.picture} />
              {descriptionRawHtml && descriptionRawHtml.length > 0 && (
                <Typography variant="body1" component="div">
                  <HtmlContentParser
                    rawHtml={descriptionRawHtml}
                    parserOptions={liveEventReviewModalParserOptions(currentTrackedGame?.game.id || '', { liveEventModalMode: ModalMode.Replace })}
                  />
                </Typography>
              )}
            </CardContent>
          </Card>
        </Grid>

        <CSSTransition
          in={imagePreviewMode === 'default'}
          timeout={300}
          classNames="animate"
          onEnter={() => setFlexDirection('row')}
          onExited={() => setFlexDirection('column-reverse')}
          appear
        >
          <Grid item className="DescriptionTab__gridItemImage">
            <div className={imageListingType === 'carousel' && imagePreviewMode === 'default' ? 'DescriptionLeftSticky' : ''}>
              <Grid container alignItems="center" justifyContent="center" mb={2} columnSpacing={1}>
                {isFullscreenAvailable && (
                  <>
                    <Grid item>
                      <FullscreenToggler
                        active={imagePreviewMode === 'fullscreen'}
                        onClick={() => setImagePreviewMode((current) => (current === 'default' ? 'fullscreen' : 'default'))}
                        enterTooltip={<Trans i18nKey="live-events:event_dialog_screenshot_maximize" />}
                        exitTooltip={<Trans i18nKey="live-events:event_dialog_screenshot_minimize" />}
                      />
                    </Grid>
                    <Grid item alignSelf="stretch">
                      <Divider orientation="vertical" />
                    </Grid>
                  </>
                )}
                <Grid item>
                  <ButtonGroup className="ButtonGroup" variant="outlined" size="small">
                    {['carousel', 'thumbnails'].map((type) => {
                      return (
                        <Button
                          key={`image-list-button__${type}`}
                          variant={imageListingType === type ? 'contained' : 'outlined'}
                          color={imageListingType === type ? 'secondary' : 'info'}
                          onClick={() => handleImageListingTypeChange(type as DescriptionTabImageListingType)}
                          title={type === 'carousel' ? t('common:gallery_view') : t('common:thumbnails_view')}
                        >
                          {type === 'carousel' ? <ViewCarousel /> : <GridView />}
                        </Button>
                      )
                    })}
                  </ButtonGroup>
                </Grid>
              </Grid>

              {imageListingType === 'carousel' && screenshots.length > 0 && (
                <Box mb={3} position={'relative'}>
                  <GRCarousel
                    previousButtonProps={{
                      disabled: screenshotIndex <= 0,
                      onClick: () => gotoScreenshot(-1),
                    }}
                    nextButtonProps={{
                      disabled: screenshotIndex >= screenshots.length - 1,
                      onClick: () => gotoScreenshot(1),
                    }}
                  >
                    <img alt="Screenshot" src={screenshots[screenshotIndex]} onLoad={() => setLoadingImage(false)} className="Screenshot" />
                    {loadingImage && <GRCircularProgress className="Progress" />}
                    <Typography variant="body1" sx={{ mt: 2 }}>
                      {screenshotIndex + 1}/{screenshots.length}
                    </Typography>
                  </GRCarousel>
                </Box>
              )}

              {imageListingType === 'thumbnails' && screenshots.length > 0 && (
                <Box mb={10} position={'relative'}>
                  <Grid container wrap="wrap" justifyContent={'center'} spacing={2}>
                    {screenshots.map((screenshot, index) => {
                      return (
                        <Grid key={`thumbnail-${index}`} item xs={thumbnailGridSize}>
                          <img
                            className="Thumbnail"
                            alt="Thumbnail"
                            src={screenshot}
                            onClick={() => {
                              onScreenshotIndexChange(index)
                              setImageListingType('carousel')
                              isImagePreviewFullscreenMode && onScrollParentTop()
                            }}
                          />
                        </Grid>
                      )
                    })}
                  </Grid>
                </Box>
              )}
            </div>
          </Grid>
        </CSSTransition>
      </Grid>
    </>
  )
}
