import { Tag } from '../../../api/core'
import { BooleanMap } from '../../../types/BooleanMap'
import { LiveEventCalendarAdditionalData } from './LiveEventAdditionalCalendarData'
import { LiveEventAppearance } from './LiveEventAppearance'
import { LiveEventDuration } from './LiveEventDuration'
import { LiveEventsTag } from './LiveEventsTag'

export enum LiveEventsCommonFilterKeys {
  LiveEventTags = 'liveEventTags',
  LiveEventSecondaryTags = 'liveEventSecondaryTags',
  LiveEventDurations = 'liveEventDurations',
  LiveEventAppearances = 'liveEventAppearances',
  AdditionalDatas = 'additionalDatas',
}

export type TLiveEventsCommonFilters = {
  [LiveEventsCommonFilterKeys.LiveEventTags]: Tag[]
  [LiveEventsCommonFilterKeys.LiveEventSecondaryTags]: Tag[]
  [LiveEventsCommonFilterKeys.LiveEventDurations]: BooleanMap
  [LiveEventsCommonFilterKeys.LiveEventAppearances]: BooleanMap
  [LiveEventsCommonFilterKeys.AdditionalDatas]: BooleanMap
}

export type LiveEventsCommonFiltersData = {
  [LiveEventsCommonFilterKeys.LiveEventTags]: LiveEventsTag[]
  [LiveEventsCommonFilterKeys.LiveEventSecondaryTags]: LiveEventsTag[]
  [LiveEventsCommonFilterKeys.LiveEventDurations]: LiveEventDuration[]
  [LiveEventsCommonFilterKeys.LiveEventAppearances]: LiveEventAppearance[]
  [LiveEventsCommonFilterKeys.AdditionalDatas]: LiveEventCalendarAdditionalData[]
}

export enum LiveEventsCommonFilterGroup {
  GameData = 'gameData',
  EventType = 'eventType',
  EventSecondaryTags = 'eventSecondaryTags',
  EventTypeGroup = 'eventTypeGroup',
  Duration = 'duration',
  Appearance = 'appearance',
}

export type LiveEventsCommonFiltersSelection = {
  id: string
  name: string
  type: LiveEventsCommonFilterGroup
}
