import React from 'react'
import { useTranslation } from 'react-i18next'

import { Typography } from '@mui/material'

import { MarketTrendEffectProps } from '../../features/market-trends/components/MarketTrendGraph/MarketTrendGraph'
import { GRTooltip } from '../GRTooltip/GRTooltip'
import './GREffectDots.scss'

export interface GREffectDotsProps {
  effect: number
  size?: string
  showDetailEffect?: boolean
  detailEffect?: MarketTrendEffectProps
}

const getEffectValues = (avgEffect: number) => {
  const numberFormat = Intl.NumberFormat('en-us', { maximumFractionDigits: 2 })
  const displayValue = numberFormat.format(avgEffect)
  const effect = Math.round(avgEffect * 100) / 100

  if (effect > 1.5) {
    return { count: 6, impact: 'high', displayValue }
  } else if (effect > 0.9 && effect <= 1.5) {
    return { count: 5, impact: 'high', displayValue }
  } else if (effect > 0.599 && effect <= 0.9) {
    return { count: 4, impact: 'moderate', displayValue }
  } else if (effect > 0.399 && effect <= 0.599) {
    return { count: 3, impact: 'moderate', displayValue }
  } else if (effect > 0.15 && effect <= 0.399) {
    return { count: 2, impact: 'low', displayValue }
  } else if (effect >= 0.001 && effect <= 0.15) {
    return { count: 1, impact: 'low', displayValue }
  }
  return { count: 0, impact: null, displayValue }
}

/**
 * Static amount of dots is six.
 *
 * @param effect decimal, 1.5 and above is the highest effect
 * @param size 'medium' | 'small'
 */

export const GREffectDots: React.FC<GREffectDotsProps> = ({ effect, size = 'medium', showDetailEffect, detailEffect }) => {
  const { t } = useTranslation()
  const { count, impact, displayValue } = getEffectValues(effect)
  const min = detailEffect && Intl.NumberFormat('en-IN', { maximumSignificantDigits: 2 }).format(detailEffect.minEffect)
  const max = detailEffect && Intl.NumberFormat('en-IN', { maximumSignificantDigits: 2 }).format(detailEffect.maxEffect)

  if (showDetailEffect)
    return (
      <GRTooltip
        content={
          <>
            <Typography>{`${t('feature:effect_on_powerscore')}`}</Typography>
            <Typography>{`${t('common:avg')} (${displayValue})`}</Typography>
            <Typography>{`${t('common:min')} (${min})`}</Typography>
            <Typography>{`${t('common:max')} (${max})`}</Typography>
          </>
        }
      >
        <span className={`GREffectDots GREffectDots__${size}`}>
          {[...Array(count)].map((e, i) => (
            <span key={i} className={`impact_${count}`} />
          ))}
          {[...Array(6 - count)].map((e, i) => (
            <span key={i} />
          ))}
        </span>
      </GRTooltip>
    )
  return (
    <GRTooltip content={`${t('feature:effect_on_powerscore')} ${impact ? t(`feature:impact_${impact}`) : ''} (${displayValue})`}>
      <span className={`GREffectDots GREffectDots__${size}`}>
        {[...Array(count)].map((e, i) => (
          <span key={i} className={`impact_${count}`} />
        ))}
        {[...Array(6 - count)].map((e, i) => (
          <span key={i} />
        ))}
      </span>
    </GRTooltip>
  )
}
