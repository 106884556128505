import { memo, useCallback } from 'react'

import { SxProps, TableCellProps, TableRow } from '@mui/material'

import { GRTableColumn, SortOrder } from '../GRTable'
import { HeaderCell } from '../HeaderCell/HeaderCell'
import { findParentColumn, isLastVisibleColumnOfParent, getChildColumns, stickyColumnProps } from '../helpers/tableHelpers'
import styles from './HeaderRow.module.scss'

/**
 * Component representing header rows of a table
 */
type RecursiveRowProps<RowType, CustomPropsType, ColumnIdType = void> = {
  columns: GRTableColumn<RowType, CustomPropsType, ColumnIdType>[]
  parentColumns?: GRTableColumn<RowType, CustomPropsType, ColumnIdType>[]
  onHeaderClick: (col: GRTableColumn<RowType, CustomPropsType, ColumnIdType>) => void
  onMeasure: (column: GRTableColumn<RowType, CustomPropsType, ColumnIdType>, colWidth: number) => void
  getColumnHiddenValue: (col: GRTableColumn<RowType, CustomPropsType, ColumnIdType>) => boolean
  defaultSortOrder?: SortOrder
  customTableProps?: CustomPropsType
}

const RecursiveRow = <RowType extends object, CustomPropsType extends object | undefined, ColumnIdType extends string | void>({
  columns,
  parentColumns = [],
  defaultSortOrder,
  onHeaderClick,
  onMeasure,
  getColumnHiddenValue,
  customTableProps,
}: RecursiveRowProps<RowType, CustomPropsType, ColumnIdType>) => {
  const getHeaderValue = useCallback(
    (col: GRTableColumn<RowType, CustomPropsType, ColumnIdType>) => {
      if (typeof col.labelAccessor === 'function') {
        return col.labelAccessor({ col, handleSort: onHeaderClick, customTableProps, columns })
      } else {
        return col.labelAccessor
      }
    },
    [onHeaderClick, customTableProps, columns]
  )

  const handleMeasure = useCallback(
    (width: number, columnIndex: number) => {
      onMeasure(columns[columnIndex], width)
    },
    [onMeasure, columns]
  )

  const handleHeaderClick = useCallback(
    (columnIndex: number) => {
      onHeaderClick(columns[columnIndex])
    },
    [onHeaderClick, columns]
  )

  const childColumns = getChildColumns(columns)

  return (
    <>
      <TableRow className={styles.row}>
        {columns.map((column, columnIndex) => {
          const parentColumn = findParentColumn(parentColumns, column)
          const isHidden = (parentColumn && getColumnHiddenValue(parentColumn)) || getColumnHiddenValue(column)
          let cellProps: TableCellProps = {
            ...column.headerCellProps,
            colSpan: column.columns?.reduce((acc, col) => (getColumnHiddenValue(col) ? acc : acc + 1), 0) || 1,
            sx: column.sticky || parentColumn?.sticky ? ({ ...column.headerCellProps?.sx, ...stickyColumnProps.sx } as SxProps) : column.headerCellProps?.sx,
          }

          const isGrouping = isLastVisibleColumnOfParent(parentColumns, column, getColumnHiddenValue) || childColumns.length > 0
          return isHidden ? null : (
            <HeaderCell
              className={styles.cell}
              column={column}
              sortable={column.sortable}
              sortOrder={column.sortOrder}
              defaultSortOrder={column.defaultSortOrder || defaultSortOrder}
              onHeaderClick={handleHeaderClick}
              onMeasure={handleMeasure}
              cellProps={cellProps}
              key={columnIndex}
              columnIndex={columnIndex}
              grouping={isGrouping}
            >
              {getHeaderValue(column)}
            </HeaderCell>
          )
        })}
      </TableRow>
      {childColumns.length > 0 && (
        <RecursiveRow
          columns={childColumns}
          parentColumns={columns}
          onHeaderClick={onHeaderClick}
          onMeasure={onMeasure}
          defaultSortOrder={defaultSortOrder}
          customTableProps={customTableProps}
          getColumnHiddenValue={getColumnHiddenValue}
        />
      )}
    </>
  )
}

export const HeaderRow = memo(RecursiveRow) as typeof RecursiveRow
