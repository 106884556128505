import { FC, ReactNode } from 'react'
import { Routes, Route, Outlet, Navigate } from 'react-router-dom'

import ScreenshotsModalContainer from '../../features/news/components/ScreenshotsModalContainer/ScreenshotsModalContainer'
import { UpdateModalContainer } from '../../features/update-modal/components/UpdateModalContainer/UpdateModalContainer'
import PageService from '../../services/PageService'
import { RegisterPage } from '../RegisterPage/RegisterPage'
import { UnsubscribeDailyDigestPage } from '../UnsubscribeDailyDigestPage/UnsubscribeDailyDigestPage'
import { AuthenticatedRoute } from './AuthenticatedRoute/AuthenticatedRoute'
import { LiveEventsEventDialogContainerDeprecated, LiveEventsEventStatisticsDialogContainerDeprecated } from './DeprecatedModalRoutes/DeprecatedModalRoutes'
import { UnauthenticatedRoute } from './UnauthenticatedRoute/UnauthenticatedRoute'

type AppRoutesProps = {
  children?: ReactNode
}

export const AppRoutes: FC<AppRoutesProps> = ({ children }) => {
  return (
    <Routes>
      <Route path="/unsubscribe-daily-digest" element={<UnsubscribeDailyDigestPage />} />
      <Route element={<UnauthenticatedRoute />}>
        <Route path="/register" element={<RegisterPage />} />
      </Route>
      <Route element={<AuthenticatedRoute />}>
        {PageService.pages
          .filter((page) => page.component)
          .map((page) => {
            const Page = page.component
            return (
              <Route
                key={page.id}
                path={page.href}
                element={
                  <ModalOutlet>
                    <Page />
                  </ModalOutlet>
                }
              >
                {/* TODO: game update modal and screenshot modal need to be refactored to use url search params instead of path params */}
                <Route path="gameupdate/:gameId/:gameVersion/:marketIso/*" element={<UpdateModalContainer />} />
                <Route path="screenshots/:screenshots/:shot?/*" element={<ScreenshotsModalContainer />} />

                {/* Routes for depracated path based modals for redirecting to new url search params based modals */}
                <Route
                  path="liveevent/:trackedGameId/:liveEventTypeId/liveEventId?/:liveEventId?/tab?/:tab?/screenshotIndex?/:screenshotIndex?/*"
                  element={<LiveEventsEventDialogContainerDeprecated />}
                />
                <Route path="liveeventstatistics/:gameIds/:liveEventTypeId/:tab/*" element={<LiveEventsEventStatisticsDialogContainerDeprecated />}></Route>
              </Route>
            )
          })}
        <Route index element={<Navigate replace to="/daily-insights" />} />
        <Route path={'*'} element={<Navigate replace to="/daily-insights" />} />
      </Route>
    </Routes>
  )
}

const ModalOutlet: FC<{ children?: ReactNode }> = ({ children }) => {
  return (
    <>
      <Outlet />
      {children}
    </>
  )
}
