import classNames from 'classnames'
import { memo, ReactNode, useCallback, useLayoutEffect, useRef } from 'react'

import { TableCell, TableCellProps } from '@mui/material'

import { GRTableColumn, SortOrder } from '../GRTable'
import styles from './HeaderCell.module.scss'
import { SortLabel } from './SortLabel/SortLabel'

/**
 * Component representing header cells of a table
 */

type HeaderCellProps<RowType, CustomPropsType, ColumnIdType = void> = {
  column?: GRTableColumn<RowType, CustomPropsType, ColumnIdType>
  onHeaderClick?: (columnIndex: number) => void
  onMeasure?: (colWidth: number, columnIndex: number) => void
  cellProps?: TableCellProps
  sortable?: boolean | 'manual'
  sortOrder?: SortOrder
  defaultSortOrder?: SortOrder
  grouping?: boolean
  columnIndex: number
  className?: string
  children?: ReactNode
}

const Component = <RowType extends object, CustomPropsType extends object | undefined, ColumnIdType extends string | void>({
  column,
  onHeaderClick,
  onMeasure,
  cellProps,
  sortable,
  sortOrder,
  defaultSortOrder,
  grouping,
  columnIndex,
  className,
  children,
}: HeaderCellProps<RowType, CustomPropsType, ColumnIdType>) => {
  const cellRef = useRef<HTMLTableCellElement>(null)

  const isColumnSortingActive = !!sortOrder
  const cellClasses = classNames(styles.root, className, { [styles.grouping]: grouping })

  const handleMeasure = useCallback(() => {
    onMeasure && onMeasure(cellRef?.current?.getBoundingClientRect().width || 0, columnIndex)
  }, [onMeasure, cellRef, columnIndex])

  useLayoutEffect(() => {
    window.addEventListener('resize', handleMeasure)
    return () => window.removeEventListener('resize', handleMeasure)
  }, [handleMeasure])

  useLayoutEffect(() => {
    handleMeasure()
  }, [handleMeasure])

  const handleHeaderClick = useCallback(() => {
    onHeaderClick && onHeaderClick(columnIndex)
  }, [onHeaderClick, columnIndex])

  return (
    <TableCell variant="head" align="center" sx={{ px: 0, py: 2 }} {...cellProps} className={cellClasses} ref={cellRef}>
      {sortable && sortable !== 'manual' ? (
        <SortLabel active={isColumnSortingActive} direction={sortOrder || defaultSortOrder} onClick={handleHeaderClick}>
          {children}
        </SortLabel>
      ) : (
        <>{children}</>
      )}
    </TableCell>
  )
}

export const HeaderCell = memo(Component) as typeof Component
