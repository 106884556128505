import classNames from 'classnames'
import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { CSSTransition } from 'react-transition-group'
import { IntercomProps, useIntercom } from 'react-use-intercom'

import { Close, Menu } from '@mui/icons-material'
import { Fab } from '@mui/material'

import { useGetUserProfileQuery } from '../../api/combined'
import AppHeader from '../../components/AppHeader/AppHeader'
import { FeatureModalContainer } from '../../components/FeatureModalContainer/FeatureModalContainer'
import SideNavigation from '../../components/SideNavigation/SideNavigation'
import ApiFetchingIndicator from '../../features/api-fetching-indicator/components/ApiFetchingIndicator'
import { useMobileSize } from '../../features/responsiveness/hooks/responsivenessHooks'
import { useClientSize } from '../../hooks/useClientSize'
import { fullscreenBreakpoint, useFullscreenMode } from '../../hooks/useFullscreenMode'
import intercomService from '../../services/IntercomService'
import './AppWrapper.scss'

interface Props {
  children?: ReactNode
}

const AppWrapper: React.FC<Props> = ({ children }) => {
  const mobileSize = useMobileSize()
  const [clientWidth] = useClientSize()
  const [fullscreenMode] = useFullscreenMode()
  const [sidebarOpen, setSidebarOpen] = useState<boolean>(mobileSize ? false : true)
  const location = useLocation()
  const { data: currentUser } = useGetUserProfileQuery()
  const { boot: bootIntercom } = useIntercom()

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen)
  }

  // on mobile devices close the sidebar whenever location changes
  useEffect(() => {
    mobileSize && setSidebarOpen(false)
  }, [location, mobileSize])

  useEffect(() => {
    // Boot Intercom
    if (currentUser && intercomService.enabled) {
      const intercomProps: IntercomProps = { email: currentUser?.email, name: `${currentUser?.firstName} ${currentUser?.lastName}` }
      if (currentUser?.hmacHash) {
        intercomProps.userHash = currentUser?.hmacHash
      }
      bootIntercom(intercomProps)
    }
  }, [currentUser, bootIntercom])

  // we need to trigger resize event to force certain components to redraw themselves
  const triggerResize = useCallback(() => window.dispatchEvent(new Event('resize')), [])

  const mobileWrapperClasses = classNames({
    'AppWrapper__wrapper--mobile': mobileSize,
  })

  const wrapperContentClasses = classNames('AppWrapper__content', {
    'AppWrapper__content__sidebar--closed': !sidebarOpen,
  })

  const sidebarClasses = classNames('AppWrapper__sidebar', {
    'AppWrapper__sidebar--open': sidebarOpen,
  })

  const sidebarStyles = useMemo(() => {
    const mobileScreenGap = 20
    const desktopScreenGap = clientWidth > fullscreenBreakpoint && !fullscreenMode ? (clientWidth - fullscreenBreakpoint) / 2 : 0
    return {
      '--sidebarGap': `${mobileSize ? mobileScreenGap : desktopScreenGap}px`,
    } as React.CSSProperties
  }, [fullscreenMode, mobileSize, clientWidth])

  return (
    <div className="AppWrapper">
      <ApiFetchingIndicator />
      <div className="AppWrapper__background"></div>

      <CSSTransition in={fullscreenMode} appear={fullscreenMode} classNames="fullscreen" timeout={400} onEntered={triggerResize} onExited={triggerResize}>
        <div className="AppWrapper__wrapper">
          <div className={mobileWrapperClasses}>
            <CSSTransition in={!sidebarOpen} timeout={300} classNames="Sidebar" onEntered={triggerResize} onExited={triggerResize}>
              <div className={sidebarClasses} style={sidebarStyles}>
                <SideNavigation open={sidebarOpen} onOpenChange={toggleSidebar}></SideNavigation>
              </div>
            </CSSTransition>

            <div className={wrapperContentClasses}>
              <AppHeader></AppHeader>
              <div className="AppWrapper__content__inner">
                {children}
                <FeatureModalContainer />
              </div>
            </div>

            {mobileSize && (
              <>
                <div
                  className={!sidebarOpen ? 'AppWrapper__content-fader' : 'AppWrapper__content-fader AppWrapper__content-fader--active'}
                  onClick={toggleSidebar}
                ></div>

                <div className="AppWrapper__menu-toggle">
                  <Fab onClick={toggleSidebar} color="secondary" size="small">
                    {!sidebarOpen && <Menu />}
                    {sidebarOpen && <Close />}
                  </Fab>
                </div>
              </>
            )}
          </div>
        </div>
      </CSSTransition>
    </div>
  )
}

export default AppWrapper
