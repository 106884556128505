import { t } from 'i18next'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { BookType } from 'xlsx'

import InfoIcon from '@mui/icons-material/Info'
import { Card, CardContent, Checkbox, Divider, FormControl, FormControlLabel, Grid } from '@mui/material'

import { Tag, useGetAnalysisFeatureChoicesQuery } from '../../../../api/core'
import { ExportDataButton } from '../../../../components/ExportDataButton/ExportDataButton'
import { GRTooltip } from '../../../../components/GRTooltip/GRTooltip'
import GameNotAnalyzedCard from '../../../../components/GameNotAnalyzedCard/GameNotAnalyzedCard'
import { intersection } from '../../../../helpers/intersection'
import { uniq } from '../../../../helpers/uniq'
import { useExportDataGameFeatures } from '../../../../hooks/exportDataHooks'
import analyticsService from '../../../../services/AnalyticsService'
import { RoleEnum } from '../../../account/types/RoleEnum'
import { Analysis } from '../../../analysis/types/Analysis'
import { generateExport } from '../../../export-data/util/workbook'
import { defaultTagSelection } from '../../../feature'
import { AllFeatureCategoriesValue, FeatureCategorySelector } from '../../../feature/components/FeatureCategorySelector/FeatureCategorySelector'
import { FeaturesAndKeywordsAutocomplete } from '../../../feature/components/FeaturesAndKeywordsAutocomplete/FeaturesAndKeywordsAutocomplete'
import { TagList } from '../../../feature/components/TagList/TagList'
import { useFeatureTags } from '../../../feature/hooks/useFeatureTags'
import { FeatureAndKeywordSearchResult } from '../../../feature/types/types'
import { UnlockGame } from '../../../game/components/UnlockGame/UnlockGame'
import { useOwnGameCheck } from '../../../game/hooks/gameHooks'
import { Game } from '../../../game/types/Game'
import { useCurrentMarket } from '../../../markets'
import { useCurrentStore } from '../../../store'
import { useFilteredGameFeatureRows, useGameFeatureKeywords, useGameFeatureRows, usePowerscoreBreakdown } from '../../hooks/gameFeaturesHooks'
import { GameFeaturesTable } from '../GameFeaturesTable/GameFeaturesTable'
import { PowerscoreBreakdown } from '../PowerscoreBreakdown/PowerscoreBreakdown'

type FeaturesProps = {
  game: Game
  analysis?: Analysis
  isGameUnlocked?: boolean
  isGameAnalyzed?: boolean
}

/**
 * Main container component for Game Features tab
 */
export const GameFeatures: FC<FeaturesProps> = ({ game, analysis, isGameUnlocked, isGameAnalyzed }) => {
  useEffect(() => {
    analyticsService.trackEvent('Visited Game Overview: Features', {
      data: {
        gameId: game.id,
        gameName: game.resolvedName,
        analysisId: analysis?.id,
      },
    })
  }, [analysis?.id, game.id, game.resolvedName])

  const { currentMarketIso } = useCurrentMarket()
  const { currentStoreId } = useCurrentStore()
  const [selectedFeatureTags, setSelectedFeatureTags] = useState<Tag[]>([])
  const [isGameFeaturesOnlyChecked, setIsGameFeaturesOnlyChecked] = useState<boolean>(true)
  const [categoryFilter, setCategoryFilter] = useState<string>(AllFeatureCategoriesValue)
  const [featureSearchValue, setFeatureSearchValue] = useState<FeatureAndKeywordSearchResult>(null)
  const analysisId = analysis?.id || ''
  const powerscoreBreakdown = usePowerscoreBreakdown({ analysisId })
  const isOwnGame = useOwnGameCheck(game)

  const {
    data: featureChoices,
    isLoading: isFeatureChoicesLoading,
    isFetching: isFeatureChoicesFetching,
  } = useGetAnalysisFeatureChoicesQuery(
    { analysisId, marketIso: currentMarketIso, storeId: currentStoreId as string },
    { skip: !analysisId || !currentStoreId }
  )

  const { data: featureTagGroups } = useFeatureTags()
  const allFeatureTags = useMemo(() => featureTagGroups?.flatMap((featureTag) => featureTag.tags) || [], [featureTagGroups])
  const keywords = useGameFeatureKeywords()

  // prepare table rows by mapping and filtering
  const { rows, isLoading: isGameFeatureRowsLoading } = useGameFeatureRows({ marketIso: currentMarketIso, storeId: currentStoreId, analysisId, game })
  const filteredRows = useFilteredGameFeatureRows({
    rows: rows,
    includeGameFeaturesOnly: isGameFeaturesOnlyChecked,
    categoryId: categoryFilter,
    featureSearchValue,
    featureTags: selectedFeatureTags,
    keywords,
  })

  // initially select all feature tags
  useEffect(() => {
    const tagSelection = allFeatureTags.filter((featureTag) => defaultTagSelection.includes(featureTag.id))
    setSelectedFeatureTags(tagSelection)
  }, [allFeatureTags])

  const handleSelectedFeatureTagsChange = useCallback((tags: Tag[]) => {
    setSelectedFeatureTags(tags)
  }, [])

  const handleClearSelectedFeatureTags = useCallback(() => {
    setSelectedFeatureTags([])
  }, [])

  const handleSelectAllFeatureTags = useCallback(() => {
    setSelectedFeatureTags(allFeatureTags)
  }, [allFeatureTags])

  const handleSelectTagGroup = useCallback(
    (tags: Tag[]) => {
      const currentlySelectedInGroup = intersection(selectedFeatureTags, tags)
      const isWholeTagGroupSelected = currentlySelectedInGroup.length === tags.length

      if (isWholeTagGroupSelected) {
        setSelectedFeatureTags(selectedFeatureTags.filter((selectedFeatureTag) => !tags.includes(selectedFeatureTag)))
      } else {
        setSelectedFeatureTags(uniq([...selectedFeatureTags, ...tags]))
      }
    },
    [selectedFeatureTags]
  )

  const [isExporting, setIsExporting] = useState(false)
  const [exportFormat, setExportFormat] = useState('csv' as BookType)

  const { exportRows } = useExportDataGameFeatures(rows, isExporting)

  useEffect(() => {
    if (!isExporting || !exportRows.length) return
    setIsExporting(false)
    generateExport(exportFormat, exportRows, 'Game Features', `game-analysis-features-${game.appId}`)
  }, [exportFormat, exportRows, game, isExporting])

  const handleDataExport = (format: BookType) => {
    setExportFormat(format)
    setIsExporting(true)
  }

  const filteredSelectedTags = useMemo(() => {
    const selectedTargets = selectedFeatureTags.map((selectedTag) => selectedTag.targets).flat()
    return [...new Set(selectedTargets)]
  }, [selectedFeatureTags])

  const filteredGameFeatureRows = useMemo(() => {
    return filteredRows.filter((feature) => {
      if (!filteredSelectedTags || !filteredSelectedTags.length || !feature.featureLegacyId) {
        return true
      }
      return filteredSelectedTags.includes(feature.featureLegacyId)
    })
  }, [filteredRows, filteredSelectedTags])

  return (
    <>
      {!isGameAnalyzed && <GameNotAnalyzedCard game={game} />}
      {isGameAnalyzed && !isGameUnlocked && <UnlockGame.Card game={game} analysis={analysis} />}
      {isGameAnalyzed && isGameUnlocked && (
        <Grid container gap={2} direction="column">
          <Card>
            <CardContent>
              <PowerscoreBreakdown
                breakdown={powerscoreBreakdown}
                onItemClick={(value) => {
                  const selectedKeyword = keywords.find((keyword) => keyword.name === value)
                  selectedKeyword && setFeatureSearchValue(selectedKeyword)
                }}
              />
            </CardContent>
          </Card>
          <Divider light sx={{ my: 1 }} />
          <TagList
            tagGroups={featureTagGroups}
            onChange={handleSelectedFeatureTagsChange}
            selectedTags={selectedFeatureTags}
            onClear={handleClearSelectedFeatureTags}
            onSelectAll={handleSelectAllFeatureTags}
            onSelectTagGroup={handleSelectTagGroup}
          />
          <Card>
            <CardContent>
              <Grid container wrap="wrap" gap={2}>
                <Grid item xs={12}>
                  <FeaturesAndKeywordsAutocomplete
                    value={featureSearchValue}
                    features={featureChoices}
                    keywords={keywords}
                    onChange={(value) => {
                      setFeatureSearchValue(value)
                    }}
                  />
                </Grid>
                <Grid item container justifyContent="space-between">
                  <FeatureCategorySelector value={categoryFilter} onChange={(event) => setCategoryFilter(event.target.value)} />
                  <FormControl component="fieldset" sx={{ flexDirection: 'row', alignItems: 'baseline', columnGap: 2 }}>
                    <FormControlLabel
                      control={<Checkbox checked={isGameFeaturesOnlyChecked} onClick={() => setIsGameFeaturesOnlyChecked((value) => !value)} />}
                      label={t<string>('overview:show_only_game_features')}
                    />
                    <GRTooltip content={t('overview:show_only_game_features_tooltip')}>
                      <InfoIcon color="primary" />
                    </GRTooltip>
                    <ExportDataButton
                      analyticsEventOrigin="Game Features"
                      accessRoles={[RoleEnum.csv_export_features, isOwnGame ? RoleEnum.csv_game_private : RoleEnum.csv_game_public]}
                      loading={isFeatureChoicesLoading || isFeatureChoicesFetching || isGameFeatureRowsLoading}
                      onChooseFormat={handleDataExport}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <GameFeaturesTable
              gameId={game.id}
              rows={filteredGameFeatureRows}
              isLoading={isFeatureChoicesLoading || isFeatureChoicesFetching || isGameFeatureRowsLoading}
              isOwnGame={isOwnGame}
            />
          </Card>
        </Grid>
      )}
    </>
  )
}
