import React from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink, useLocation } from 'react-router-dom'

import { Chip, Stack } from '@mui/material'

import { useGetUserProfileQuery } from '../../api/combined'
import { useIsOnFreePlan } from '../../features/account/hooks/userHooks'
import { useLastSeenNewsCount } from '../../features/news/hooks/newsHooks'
import { useGetReportsUnreadCount } from '../../features/reports/hooks/reportsHooks'
import { useAppSelector } from '../../hooks/storeHooks'
import { Page } from '../../types/Page'
import { SideNavigationGroupData } from '../../types/SideNavigationGroupData'
import './SideNavigationGroup.scss'

interface Props {
  group: SideNavigationGroupData
}

const SideNavigationGroup: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation()
  const location = useLocation()
  const isOnFreePlan = useIsOnFreePlan()

  const currentPage: Page | undefined = useAppSelector((state) => {
    return state.currentPage
  })

  const { data: currentUser } = useGetUserProfileQuery()
  const { data: lastSeenNewsCount } = useLastSeenNewsCount()
  const lastSeenReportsCount = useGetReportsUnreadCount()

  var i = 0
  const pagesHtml = props.group.pages
    .filter((page: Page) => {
      if (currentUser === undefined) return false
      if (page.hideWithoutRole === undefined) return true
      return currentUser.roles.includes(page.hideWithoutRole)
    })
    .map((page: Page) => {
      i++

      const isCurrentRoute = (page.startUrl && location.pathname.startsWith(page.startUrl)) || location.pathname.startsWith(page.href)
      const pathname = page.startUrl ? page.startUrl : page.href
      return (
        <Stack direction="row" display="flex" justifyContent="space-between" alignContent="center" key={i} maxHeight="28px">
          <NavLink
            to={isCurrentRoute ? { ...location, pathname } : pathname}
            className={({ isActive }) => (isActive || isCurrentRoute ? 'side-navi-item side-navi-item--active' : 'side-navi-item')}
            aria-label={t(page.localizationKey)}
          >
            <>
              <img className="side-navi-item__icon" src={page.iconUrl} alt="" />
              {t(page.localizationKey)}
            </>
          </NavLink>

          {page.id === 'daily-insights' && lastSeenNewsCount !== undefined && lastSeenNewsCount > 0 && (
            <Chip color="primary" label={lastSeenNewsCount} className="badge-item" />
          )}

          {!isOnFreePlan && page.id === 'reports' && lastSeenReportsCount !== undefined && lastSeenReportsCount > 0 && (
            <Chip color="primary" label={lastSeenReportsCount} className="badge-item" />
          )}
        </Stack>
      )
    })

  return (
    <div className="SideNavigationGroup side-navi-group">
      <NavLink
        to={props.group.pages.length ? props.group.pages[0].href : ''}
        className={currentPage?.groupId === props.group.id ? 'side-navi-header side-navi-header--active' : 'side-navi-header'}
      >
        {t(props.group.localizationKey)}
      </NavLink>
      {pagesHtml}
    </div>
  )
}

export default SideNavigationGroup
