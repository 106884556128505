import { FC, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@mui/material'

import { RegionMarketSelector } from '../..'

type RegionMarketSelectorDialogProps = {
  open: boolean
  onClose: (selectedMarketIsos: string[]) => void
  marketIsos: string[]
  selectedMarketIsos: string[]
}

export const RegionMarketSelectorDialog: FC<RegionMarketSelectorDialogProps> = ({ open, onClose, marketIsos, selectedMarketIsos = [] }) => {
  const { t } = useTranslation()
  const [selected, setSelected] = useState<string[]>(selectedMarketIsos)

  const handleChange = useCallback((value: string[]) => {
    setSelected(value)
  }, [])

  return (
    <Dialog open={open} fullWidth maxWidth="md">
      <DialogTitle>{t('common:select_markets')}</DialogTitle>
      <DialogContent dividers>
        <RegionMarketSelector marketIsos={marketIsos} selectedMarketIsos={selected} onChange={handleChange} />
      </DialogContent>
      <DialogActions>
        <Grid container alignItems="center" justifyContent="flex-end" gap={2}>
          <Grid item>
            <Typography variant="body1">
              {t('common:selected')} {selected.length} / {marketIsos.length}
            </Typography>
          </Grid>
          <Grid item>
            <Button variant="contained" color="success" onClick={() => onClose(selected)}>
              {t('common:close')}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}
