import { t } from 'i18next'
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BookType } from 'xlsx'

import { Box, Card, CardContent, CardHeader, Divider, FormControl, SelectChangeEvent } from '@mui/material'

import DateRangePicker, { DateRangeValue } from '../../../../components/DateRangePicker/DateRangePicker'
import { ExportDataButton } from '../../../../components/ExportDataButton/ExportDataButton'
import { GRTooltip } from '../../../../components/GRTooltip/GRTooltip'
import { SelectMenu } from '../../../../components/SelectMenu/SelectMenu'
import { TimelineChart } from '../../../../components/TimelineChart/TimelineChart'
import { valueIsBetween } from '../../../../helpers/valueIsBetween'
import { useExportDataComparePerformanceChart } from '../../../../hooks/exportDataHooks'
import utilsService from '../../../../services/UtilsService'
import { RoleEnum } from '../../../account/types/RoleEnum'
import { GameEstimates, iosEstimate, MarketsPerformanceEstimates, useGameEstimatesRollingData } from '../../../estimates'
import { generateExport } from '../../../export-data/util/workbook'
import { ChartDataFormat } from '../../../update-history/types/types'
import { TableRowUpdateImpact } from '../../../update-impacts/types/TableRowUpdateImpact'

export enum ComparePerformanceDataType {
  Revenue,
  Download,
  RevenueDownloadsRatioAllTime,
  RevenueDownloadsRatio7Days,
  RevenueDownloadsRatio30Days,
  RevenueDownloadsRatio90Days,
}
const rollingDaysByDataType = {
  [ComparePerformanceDataType.RevenueDownloadsRatioAllTime]: 0,
  [ComparePerformanceDataType.Revenue]: 1,
  [ComparePerformanceDataType.Download]: 1,
  [ComparePerformanceDataType.RevenueDownloadsRatio7Days]: 7,
  [ComparePerformanceDataType.RevenueDownloadsRatio30Days]: 30,
  [ComparePerformanceDataType.RevenueDownloadsRatio90Days]: 90,
}

const datasetColors = utilsService.getRGBChartColorList()
/**
 * Data type selector used with prefromance chart
 */
type DataTypeSelectorProps = {
  onChange: (event: SelectChangeEvent<ComparePerformanceDataType>) => void
  value: ComparePerformanceDataType | ''
}
export const DataTypeSelector: FC<DataTypeSelectorProps> = ({ onChange, value }) => {
  const { t } = useTranslation()
  const options = useMemo(() => {
    return [
      {
        name: `${t('common:revenue_text')} (${t('common:apple_ios')})`,
        value: ComparePerformanceDataType.Revenue,
      },
      {
        name: `${t('common:downloads_text')} (${t('common:apple_ios')})`,
        value: ComparePerformanceDataType.Download,
      },
      {
        name: `${t('common:all_time_revenue_downloads_ratio')} (${t('common:apple_ios')})`,
        value: ComparePerformanceDataType.RevenueDownloadsRatioAllTime,
      },
      {
        name: `${t('common:revenue_downloads_ratio_days', { days: 7 })} (${t('common:apple_ios')})`,
        value: ComparePerformanceDataType.RevenueDownloadsRatio7Days,
      },
      {
        name: `${t('common:revenue_downloads_ratio_days', { days: 30 })} (${t('common:apple_ios')})`,
        value: ComparePerformanceDataType.RevenueDownloadsRatio30Days,
      },
      {
        name: `${t('common:revenue_downloads_ratio_days', { days: 90 })} (${t('common:apple_ios')})`,
        value: ComparePerformanceDataType.RevenueDownloadsRatio90Days,
      },
    ]
  }, [t])

  return (
    <FormControl size="small" variant="outlined" sx={{ minWidth: 310 }}>
      <SelectMenu label={t('common:data')} options={options} onChange={onChange} value={value} />
    </FormControl>
  )
}

/**
 * MotivationPerformanceChart
 */
interface MotivationPerformanceChartProps {
  estimates?: MarketsPerformanceEstimates
  gameEstimates?: GameEstimates[]
  allGameEstimates?: GameEstimates[]
  dateRange?: DateRangeValue
  onDateRangeChange?: (dateRange: DateRangeValue | undefined) => void
  updateImpactsMappedForTable?: TableRowUpdateImpact[]
  handleOpenUpdateClicked: (update: TableRowUpdateImpact) => void
  dataType: ComparePerformanceDataType
  onDataTypeSelect: (type: ComparePerformanceDataType) => void
  dateRangePickerDisabled: boolean
  minDate?: Date
}

const ComparePerformanceChart: React.FC<MotivationPerformanceChartProps> = ({
  gameEstimates,
  allGameEstimates,
  dateRange,
  onDateRangeChange,
  updateImpactsMappedForTable = [],
  handleOpenUpdateClicked,
  dataType = ComparePerformanceDataType.Revenue,
  onDataTypeSelect,
  dateRangePickerDisabled,
  minDate,
}) => {
  const rollingGameEstimates = useGameEstimatesRollingData(gameEstimates, rollingDaysByDataType[dataType])

  const [isExporting, setIsExporting] = useState(false)
  const [exportFormat, setExportFormat] = useState('csv' as BookType)

  const versionMarks = useMemo(() => {
    const highlightedVersionMarks: { position: number; data: TableRowUpdateImpact }[] = []
    const normalVersionMarks: { position: number; data: TableRowUpdateImpact }[] = []

    updateImpactsMappedForTable
      .map((update) => ({ position: update.releaseDate, data: update }))
      .filter((mark) => (dateRangePickerDisabled ? true : valueIsBetween(mark.position, dateRange?.fromDate?.getTime(), dateRange?.toDate?.getTime())))
      .forEach((mark) => {
        if (!!mark.data.changedFeatures.length) {
          highlightedVersionMarks.push(mark)
        } else {
          normalVersionMarks.push(mark)
        }
      })

    return { highlightedVersionMarks, normalVersionMarks }
  }, [dateRange?.fromDate, dateRange?.toDate, dateRangePickerDisabled, updateImpactsMappedForTable])

  const generateData = useCallback(
    (estimate: iosEstimate) => {
      switch (dataType) {
        case ComparePerformanceDataType.Revenue:
          return { y: estimate.data.r || 0, x: new Date(estimate.date).getTime() }
        case ComparePerformanceDataType.Download:
          return { y: estimate.data.d || 0, x: new Date(estimate.date).getTime() }
        default:
          return { y: estimate.data.d && estimate.data.r ? estimate.data.r / estimate.data.d : estimate.data.r || 0, x: new Date(estimate.date).getTime() }
      }
    },
    [dataType]
  )

  const datasets = useMemo(() => {
    if (!rollingGameEstimates) return []
    return rollingGameEstimates.map((gameEstimate, index) => {
      return {
        yAxisID: 'y1',
        spanGaps: false,
        tension: 0.1,
        label: gameEstimate.game.resolvedName,
        marketIso: gameEstimate.marketIso,
        data: gameEstimate.iosEstimates.map((estimate) => generateData(estimate)),
        borderColor: datasetColors[index],
        backgroundColor: datasetColors[index],
      }
    })
  }, [rollingGameEstimates, generateData])

  const { exportRows, headerRows, filename } = useExportDataComparePerformanceChart(allGameEstimates, isExporting)

  useEffect(() => {
    if (!isExporting || !exportRows.length || !filename) return
    generateExport(exportFormat, exportRows, 'Compare Games Perfomance', filename, headerRows)
    setIsExporting(false)
  }, [isExporting, exportRows, headerRows, exportFormat, filename])

  const handleDataExport = (format: BookType) => {
    setExportFormat(format)
    setIsExporting(true)
  }

  const scaleConfig = useMemo(() => {
    const formatByDataType = () => {
      switch (dataType) {
        case ComparePerformanceDataType.Revenue:
          return {
            dataFormat: ChartDataFormat.Currency,
            title: `${t('common:revenue_text')} (${t('common:apple_ios')})`,
          }
        case ComparePerformanceDataType.Download:
          return {
            dataFormat: ChartDataFormat.Number,
            title: `${t('common:downloads_text')} (${t('common:apple_ios')})`,
          }
        case ComparePerformanceDataType.RevenueDownloadsRatioAllTime:
          return {
            dataFormat: ChartDataFormat.Number,
            title: `${t('common:all_time_revenue_downloads_ratio')} (${t('common:apple_ios')})`,
          }
        default:
          return {
            dataFormat: ChartDataFormat.Currency,
            title: `${t('common:revenue_downloads_ratio_days', { days: rollingDaysByDataType[dataType] })} (${t('common:apple_ios')})`,
          }
      }
    }

    return {
      y1: {
        ...formatByDataType(),
        shorten: true,
      },
    }
  }, [dataType])

  return (
    <Card>
      <CardHeader title={t('common:performance')} />
      <Divider />
      <CardContent sx={{ pt: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
          <Box>
            {dateRangePickerDisabled ? (
              <GRTooltip content={t('overview:date_picker_disabled_rd_type')}>
                <DateRangePicker minDate={minDate} onChangeValue={onDateRangeChange} value={dateRange} disabled={dateRangePickerDisabled} />
              </GRTooltip>
            ) : (
              <DateRangePicker minDate={minDate} onChangeValue={onDateRangeChange} value={dateRange} disabled={dateRangePickerDisabled} />
            )}
          </Box>

          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Box sx={{ mr: 1 }}>
              <DataTypeSelector
                value={dataType}
                onChange={(e) => {
                  onDataTypeSelect(e.target.value as ComparePerformanceDataType)
                }}
              />
            </Box>
            <ExportDataButton
              accessRoles={[RoleEnum.csv_export_games, RoleEnum.csv_update_history]}
              onChooseFormat={handleDataExport}
              analyticsEventOrigin="Compare Performance Chart"
            />
          </Box>
        </Box>
        <TimelineChart
          data={{ datasets }}
          scaleConfig={scaleConfig}
          verticalMarks={versionMarks.normalVersionMarks}
          onVerticalMarkClicked={handleOpenUpdateClicked}
          highlightedVerticalMarks={versionMarks.highlightedVersionMarks}
        />
      </CardContent>
    </Card>
  )
}

export default ComparePerformanceChart
