import '@mui/lab/themeAugmentation'
import { createTheme } from '@mui/material'

const borderRadiusDefault = '20px'
export const lightColorHex = '#777'

export const theme = createTheme({
  typography: {
    fontFamily: ['Walsheim-Regular', 'sans-serif'].join(','),
    h1: {
      fontSize: '1.5rem',
    },
    h2: {
      fontSize: '1.375rem',
    },
    h3: {
      fontSize: '1.1rem',
      fontWeight: 700,
    },
    h4: {
      fontSize: '0.8125rem',
    },
    h5: {
      fontSize: '0.78125rem',
    },
  },
  palette: {
    primary: {
      main: '#bd65cf',
      contrastText: '#fff',
    },
    secondary: {
      main: '#273143',
    },
    warning: {
      main: '#b13d37',
    },
    success: {
      main: '#38a73e',
    },
    text: {
      primary: '#273143',
    },
    background: {
      default: '#eaeefd',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontWeight: 'bold',
          borderRadius: borderRadiusDefault,
        },
        contained: {
          color: 'white',
        },
      },
    },
    MuiButtonGroup: {
      styleOverrides: {
        root: {
          borderRadius: borderRadiusDefault,
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            backgroundColor: 'rgba(0, 0, 0, 0.1)',
          },
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          display: 'inline-block',
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        underlineNone: {
          color: '#bd65cf',
          cursor: 'pointer',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: borderRadiusDefault,
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          // override default title (h5) style only but allow altering through typography props
          '& span.MuiTypography-h5': {
            fontSize: 18,
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          marginRight: '5px',
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          backgroundColor: '#f2f6ff',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          margin: '0px 8px 8px 0px',
          fontWeight: 'bold',
          height: 'auto',
        },
        sizeSmall: {
          height: 'auto',
        },
        labelSmall: {
          fontSize: '11px',
          padding: '3px 8px !important',
        },
        label: {
          fontSize: '12px',
          padding: '5px 10px !important',
        },
        colorPrimary: {
          backgroundColor: '#bd65cf',
          color: '#FFF',
        },
        colorSecondary: {
          backgroundColor: '#38a73e',
          color: '#FFF',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          color: '#273143',
        },
      },
    },
    MuiGrid: {
      styleOverrides: {
        container: {
          minWidth: '0',
        },
        item: {
          flexShrink: 0,
        },
      },
      defaultProps: {
        wrap: 'nowrap',
        zeroMinWidth: true,
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          lineHeight: 'inherit',
          fontSize: '0.75rem',
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          marginRight: '1.5rem',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          borderRadius: borderRadiusDefault,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: borderRadiusDefault,
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          borderRadius: borderRadiusDefault,
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          borderRadius: borderRadiusDefault,
        },
      },
    },
    MuiCardActions: {
      styleOverrides: {
        spacing: {
          margin: 8,
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        spacing: {
          margin: 8,
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '& .MuiInputLabel-root': {
            lineHeight: '1em',
          },
          '& .MuiInputLabel-shrink': {},
          '& .MuiOutlinedInput-root': {
            borderRadius: 32,
          },
        },
        popper: {
          '& > div': {
            borderRadius: borderRadiusDefault,
          },
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          textTransform: 'uppercase',
          color: '#777',
          fontSize: '0.8125rem',
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          height: '0.5rem',
          borderRadius: '0.5rem',
          backgroundColor: 'rgba(0, 0, 0, 0.08)',
        },
        bar: {
          borderRadius: '0.5rem',
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          borderRadius: 32,
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          borderRadius: borderRadiusDefault,
        },
        root: {
          borderRadius: borderRadiusDefault,
        },
      },
    },
    MuiTabPanel: {
      styleOverrides: {
        root: {
          padding: '24px 0',
        },
      },
    },
  },
})
