import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { BookType } from 'xlsx'

import { Grid } from '@mui/material'

import { useGetRevenueAndDownloadAggregatesQuery } from '../../api/core'
import { ExportDataButton } from '../../components/ExportDataButton/ExportDataButton'
import { RoleEnum } from '../../features/account'
import { useCurrentUserLanguage } from '../../features/account/hooks/userHooks'
import { generateExport } from '../../features/export-data/util/workbook'
import { RevenueAndDownloadAggregatesMap } from '../../features/game/types/Game'
import { Market, selectPublishedMarkets, useCurrentMarket } from '../../features/markets'
import { TopGrossingTable } from '../../features/top-game/components/TopGrossingTable/TopGrossingTable'
import { useTopGrossingGames } from '../../features/top-game/hooks/topGrossingGameHooks'
import { TopGame } from '../../features/top-game/types/TopGame'
import { useExportDataTopGrossingPage } from '../../hooks/exportDataHooks'
import { useDocumentTitle } from '../../hooks/useDocumentTitle'
import usePage from '../../hooks/usePage'
import PageService from '../../services/PageService'
import './TopGrossingPage.scss'

const TopGrossingPage: React.FC = () => {
  const { t } = useTranslation()
  usePage(PageService.getPageWithId('top-grossing'), 'Visited Top 200')
  useDocumentTitle(t('sidebar:topgrossing'))

  const queryLimit = 200
  const userLanguage = useCurrentUserLanguage()
  const { currentMarketIso } = useCurrentMarket()
  const markets: Array<Market> = useSelector(selectPublishedMarkets(userLanguage))
  const { data: topGames, isLoading: isLoadingTopGrossingGames } = useTopGrossingGames(currentMarketIso, [], queryLimit)

  const [isExporting, setIsExporting] = useState(false)
  const [exportFormat, setExportFormat] = useState('csv' as BookType)

  const appIds = useMemo(() => {
    return (
      topGames?.map((topGame) => {
        return topGame.appId
      }) || []
    )
  }, [topGames])

  const { data: revenueAndDownloadByApp, isFetching: isLoadingRevenueAndDownloadByApp } = useGetRevenueAndDownloadAggregatesQuery(
    { appIds, marketIsos: [currentMarketIso] },
    { skip: appIds.length === 0 }
  )

  const gamesRevenueAndDownloadAggregates = useMemo(() => {
    return topGames?.map((topGame) => {
      const appAggregates = revenueAndDownloadByApp && revenueAndDownloadByApp[topGame.appId]
      topGame.revenueAndDownloadAggregatesMap = appAggregates?.countryAggregates as RevenueAndDownloadAggregatesMap
      return topGame
    })
  }, [topGames, revenueAndDownloadByApp])

  const gamesWithConventionalSubgenreId = useMemo(() => {
    if (!gamesRevenueAndDownloadAggregates) return null

    return gamesRevenueAndDownloadAggregates?.filter((game) => {
      return game.conventionalSubgenreId
    })
  }, [gamesRevenueAndDownloadAggregates])

  const { exportRows, headerRows } = useExportDataTopGrossingPage(gamesWithConventionalSubgenreId as TopGame[], queryLimit, isExporting)

  const handleExportData = (format: BookType) => {
    setExportFormat(format)
    setIsExporting(true)
  }

  useEffect(() => {
    if (!isExporting || !exportRows.length) return
    setIsExporting(false)
    generateExport(
      exportFormat,
      exportRows,
      'Top Grossing',
      `GameRefinery_Top_Grossing_${t('common:apple_ios')}_${currentMarketIso.toUpperCase()}_${new Date().toDateString()}`,
      headerRows
    )
  }, [isExporting, exportRows, headerRows, exportFormat, t, currentMarketIso])

  return (
    <div className="TopGrossingPage">
      <Grid container spacing={2}>
        <Grid item xs>
          <h2>
            {t('top-games:top_grossing_iphone_games_title', {
              query_limit: queryLimit,
              market_name: markets.find((market) => market.iso === currentMarketIso)?.name,
            })}
          </h2>
        </Grid>
        <Grid item>
          <ExportDataButton
            accessRoles={[RoleEnum.csv_export_games, RoleEnum.csv_top_grossing]}
            onChooseFormat={handleExportData}
            disabled={isLoadingTopGrossingGames || isLoadingRevenueAndDownloadByApp}
            analyticsEventOrigin="Top Grossing Page"
          />
        </Grid>
      </Grid>

      <TopGrossingTable
        topGames={gamesRevenueAndDownloadAggregates || []}
        marketIso={currentMarketIso}
        isLoading={isLoadingTopGrossingGames || isLoadingRevenueAndDownloadByApp}
      />
    </div>
  )
}

export default TopGrossingPage
