import { t } from 'i18next'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate, useNavigate, useParams } from 'react-router-dom'

import { Delete, Share, SportsEsports, Warning } from '@mui/icons-material'
import { Box, Button, Card, CircularProgress, Grid, ListItemIcon, ListItemText, MenuItem, MenuList, Typography } from '@mui/material'

import { useGetUserSettingQuery, useUpdateUserSettingMutation } from '../../api/combined'
import { GetGameQueryParams, useGetMultipleGamesAndAnalysisQuery } from '../../api/core'
import AddArea from '../../components/AddArea/AddArea'
import { GRBanner } from '../../components/GRBanner/GRBanner'
import GRTabNavigation from '../../components/GRTabNavigation/GRTabNavigation'
import { LockedFeature } from '../../components/LockedFeature/LockedFeature'
import { RoleEnum } from '../../features/account'
import { useCompareGamesAccessCheck, useRoleCheck, useSoftLaunchAccessCheck } from '../../features/account/hooks/roleHooks'
import { useCurrentUserLanguage } from '../../features/account/hooks/userHooks'
import { UserSettingKeys } from '../../features/account/types/UserSettings'
import FeatureTabView from '../../features/compare-games/components/FeatureTabView/FeatureTabView'
import PerformanceTabView from '../../features/compare-games/components/PerformanceTabView/PerformanceTabView'
import { isJSON } from '../../features/compare-games/utils/isJSON'
import { paramObj, shallowCompare } from '../../features/compare-games/utils/shallowCompare'
import { Game, GameLink } from '../../features/game'
import GameSearchDialog, { GameWithMarketIso } from '../../features/game-search/components/GameSearchDialog/GameSearchDialog'
import { GameSearchDialogConfig, GameSearchDialogTab, GameSearchDialogType } from '../../features/game-search/types/GameSearchDialogConfig'
import GameCard from '../../features/game/components/GameCard/GameCard'
import { useFollowedGamesPageGameIds } from '../../features/game/hooks/gameHooks'
import { GameAndAnalysis } from '../../features/game/types/GameAndAnalysis'
import MotivationsTabView from '../../features/motivations/components/MotivationsTabView/MotivationsTabView'
import { useCopyToClipboard } from '../../hooks/useCopyToClipboard'
import { useDocumentTitle } from '../../hooks/useDocumentTitle'
import usePage from '../../hooks/usePage'
import { useSearchParams } from '../../hooks/useSearchParams'
import analyticsService from '../../services/AnalyticsService'
import PageService from '../../services/PageService'
import { AnalyticsProviders } from '../../types/IAnalyticsProvider'
import { LockedFeatureId } from '../../types/LockedFeature'

const maxGameCompare = 6
const minGameCompare = 2

/**
 * GameCards wrapper to show game cards when select
 */
interface GameCardsProps {
  gamesAndAnalysis?: GameAndAnalysis[]
  addMoreGameClick: () => void
  onGameRemoveClick: (game: Game, marketIso?: string) => void
  onClearAllGameClick: () => void
}
const GameCards: React.FC<GameCardsProps> = ({ gamesAndAnalysis = [], addMoreGameClick, onGameRemoveClick, onClearAllGameClick }) => {
  const copyToClipboard = useCopyToClipboard()

  return (
    <div className="FollowedGamesPage__quick-links">
      <Grid container wrap="wrap" justifyContent="space-between" alignItems="center" sx={{ mb: 2 }} rowSpacing={1}>
        <Grid item xs={12} lg={6}>
          <Typography margin={0} variant="h3">
            {t('compare:compare_multiple_games_desc', { min: minGameCompare, max: maxGameCompare })}
          </Typography>
        </Grid>
        <Grid item>
          <Box>
            {gamesAndAnalysis.length > 1 && (
              <Button sx={{ mr: 1 }} variant="contained" onClick={() => copyToClipboard()}>
                <Share sx={{ mr: 1 }} />
                {t('common:share_text')}
              </Button>
            )}
            {gamesAndAnalysis.length > 0 && (
              <Button variant="contained" onClick={onClearAllGameClick}>
                {t('select-game:clear_selected_games')}
              </Button>
            )}
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={2} wrap="wrap">
        {gamesAndAnalysis.map((gameAndAnalysis) => {
          const { game, analysis } = gameAndAnalysis
          return (
            <Grid item key={`${game.id}-${analysis?.marketIso}`} xs={12} md={4} sm={6}>
              <GameCard
                game={game}
                marketIso={analysis?.marketIso}
                showCountryFlag
                onCardClick={() => {
                  addMoreGameClick()
                }}
              >
                <GameCard.Actions>
                  <MenuList>
                    <GameLink style={{ color: 'inherit' }} game={game}>
                      {/* Game View action */}
                      <MenuItem>
                        <ListItemIcon>
                          <SportsEsports />
                        </ListItemIcon>
                        <ListItemText>{t('overview:game_overview')}</ListItemText>
                      </MenuItem>
                    </GameLink>

                    {/* Remove game action */}
                    <MenuItem
                      onClick={() => {
                        onGameRemoveClick(game, analysis?.marketIso)
                      }}
                    >
                      <ListItemIcon>
                        <Delete />
                      </ListItemIcon>
                      <ListItemText sx={{ textTransform: 'capitalize' }}>{t('common:remove')}</ListItemText>
                    </MenuItem>
                  </MenuList>
                </GameCard.Actions>
              </GameCard>
            </Grid>
          )
        })}

        {gamesAndAnalysis.length < maxGameCompare && (
          <Grid item xs={12} md={4} sm={6}>
            <AddArea
              label={t('common:add_new_game') as string}
              onClick={() => {
                addMoreGameClick()
              }}
            />
          </Grid>
        )}
      </Grid>
    </div>
  )
}

type CompareGameParams = {
  gamesWithMarketIso?: string
  force?: string
}

export enum CompareGameTabSelection {
  FEATURES = 'features',
  MOTIVATION = 'motivation',
  PERFORMANCE = 'performance',
}

type compareGameTab = {
  id: CompareGameTabSelection
  name: string
}

/**
 * CompareGamesPage Compage Game View
 */
interface CompareGamesPageProps {}
const CompareGamesPage: React.FC<CompareGamesPageProps> = () => {
  const { t } = useTranslation()
  const userLanguage = useCurrentUserLanguage()
  const hasCompareGamesAccess = useCompareGamesAccessCheck()
  const hasAccessToSoftLaunch = useSoftLaunchAccessCheck()

  usePage(PageService.getPageWithId('compare-games'), 'Visited Game Comparison')
  useDocumentTitle(t('sidebar:game_comparison'))

  const navigate = useNavigate()
  const [gameSearchModalOpen, setGameSearchModalOpen] = useState(false)
  const { subpage } = useParams<{ subpage: CompareGameTabSelection }>()

  const { parsedParams, setSearchParams } = useSearchParams<CompareGameParams, CompareGameParams>()

  const [parsedGamesWithMarketIso, setParsedGamesWithMarketIso] = useState<GameWithMarketIso>({})
  useEffect(() => {
    if (!!parsedParams.gamesWithMarketIso && isJSON(parsedParams.gamesWithMarketIso)) {
      setParsedGamesWithMarketIso(JSON.parse(parsedParams.gamesWithMarketIso))
    }
  }, [parsedParams.gamesWithMarketIso])

  const queryParams = useMemo(() => {
    return Object.keys(parsedGamesWithMarketIso).reduce<GetGameQueryParams[]>((result, gameId) => {
      const queryParams = parsedGamesWithMarketIso[gameId].map((marketIso) => ({ id: gameId, marketIso, tags: true, userLanguage }))
      return [...result, ...queryParams]
    }, [])
  }, [parsedGamesWithMarketIso, userLanguage])

  const { data: gameAndAnalysisData, isLoading: gameAndAnalysisDataLoading } = useGetMultipleGamesAndAnalysisQuery({ queryParams })
  const allGamesUnlocked = useRoleCheck(RoleEnum.all_games_unlocked)
  const followedGameLists = useFollowedGamesPageGameIds()
  const filteredGameAndAnalysisData = useMemo(() => {
    if (allGamesUnlocked) return gameAndAnalysisData
    return gameAndAnalysisData?.filter(({ game }) => followedGameLists.followedGamesIds.includes(game.id))
  }, [allGamesUnlocked, followedGameLists.followedGamesIds, gameAndAnalysisData])

  const [saveUserSetting] = useUpdateUserSettingMutation()

  const { data: gameListSetting, isLoading: gameListSettingLoading } = useGetUserSettingQuery(UserSettingKeys.compareGamesList)

  const [isCleaningSelectedGames, setIsCleaningSelectedGames] = useState(false)
  const onClearAllGameClick = async () => {
    try {
      setIsCleaningSelectedGames(true)
      setSearchParams((currentParams) => ({ ...currentParams, gamesWithMarketIso: undefined }))
      navigate({ search: undefined }, { replace: true })
      setParsedGamesWithMarketIso({})
      await saveUserSetting({ settingKey: UserSettingKeys.compareGamesList, value: {} })

      setIsCleaningSelectedGames(false)
    } catch (err) {
      setIsCleaningSelectedGames(false)
    }
  }

  useEffect(() => {
    if (gameListSettingLoading || isCleaningSelectedGames || parsedParams.force === 'true') return
    const userSettingCompareGameList = gameListSetting as paramObj | undefined

    if (!shallowCompare(parsedGamesWithMarketIso, userSettingCompareGameList)) {
      saveUserSetting({ settingKey: UserSettingKeys.compareGamesList, value: parsedGamesWithMarketIso })
    }

    if (!!userSettingCompareGameList && !!Object.keys(userSettingCompareGameList).length && !Object.keys(parsedGamesWithMarketIso).length) {
      setSearchParams((currentParams) => ({ ...currentParams, gamesWithMarketIso: JSON.stringify(userSettingCompareGameList) }))
      return
    }
  }, [gameListSetting, gameListSettingLoading, isCleaningSelectedGames, parsedGamesWithMarketIso, parsedParams, saveUserSetting, setSearchParams])

  const defaultGames = useMemo(() => {
    if (!filteredGameAndAnalysisData) return []
    return filteredGameAndAnalysisData.map((data) => data.game)
  }, [filteredGameAndAnalysisData])

  const gameSearchDialogConfig = useMemo<GameSearchDialogConfig>((): GameSearchDialogConfig => {
    const tabs = [GameSearchDialogTab.Search]

    if (hasAccessToSoftLaunch) {
      tabs.push(GameSearchDialogTab.SoftLaunch)
    }

    tabs.push(GameSearchDialogTab.MyPortfolio)

    return {
      type: GameSearchDialogType.GameSelector,
      tabs: tabs,
      gameSelectorCounts: { max: maxGameCompare, min: minGameCompare },
    }
  }, [hasAccessToSoftLaunch])

  const onGameSearchSelected = (selectedGames?: Game[], selectedGamesWithMarketIso?: GameWithMarketIso) => {
    if (selectedGamesWithMarketIso) {
      setSearchParams((currentParams) => ({ ...currentParams, gamesWithMarketIso: JSON.stringify(selectedGamesWithMarketIso), force: undefined }))
    }

    if (selectedGames && selectedGames.length) {
      analyticsService.trackEvent('Compared games', {
        data: {
          gameIds: selectedGames.map((game) => game.id).join(', '),
          gameNames: selectedGames.map((game) => game.resolvedName).join(', '),
        },
        serviceToExclude: [AnalyticsProviders.hubspot, AnalyticsProviders.intercom],
      })
    }

    setGameSearchModalOpen(false)
  }

  const onGameRemoveClick = (removeGame: Game, marketIso?: string) => {
    const newParsedGamesWithMarketIso = { ...parsedGamesWithMarketIso }
    if (newParsedGamesWithMarketIso[removeGame.id].length > 1) {
      newParsedGamesWithMarketIso[removeGame.id] = newParsedGamesWithMarketIso[removeGame.id].filter((marketList) => marketList !== marketIso)
    } else {
      delete newParsedGamesWithMarketIso[removeGame.id]
    }
    if (!Object.keys(newParsedGamesWithMarketIso).length) {
      onClearAllGameClick()
      return
    }
    setSearchParams((currentParams) => ({ ...currentParams, gamesWithMarketIso: JSON.stringify(newParsedGamesWithMarketIso) }))
  }

  const tabs: compareGameTab[] = useMemo(
    () => [
      {
        id: CompareGameTabSelection.FEATURES,
        name: t('common:features'),
      },
      {
        id: CompareGameTabSelection.MOTIVATION,
        name: t('motivations:motivations'),
      },
      {
        id: CompareGameTabSelection.PERFORMANCE,
        name: t('common:performance'),
      },
    ],
    [t]
  )

  const defaultTabView = useCallback(() => {
    const subPageTab = subpage ? tabs.map((tab) => tab.id).indexOf(subpage) : 0
    return subPageTab !== -1 ? subPageTab : 0
  }, [subpage, tabs])

  const [tabView, setTabView] = useState<number>(defaultTabView)
  const renderTabView = () => {
    if (gameAndAnalysisDataLoading) {
      return (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      )
    }

    if (!filteredGameAndAnalysisData || !filteredGameAndAnalysisData.length || filteredGameAndAnalysisData.length < minGameCompare) {
      return (
        <GRBanner severity="guidance" icon={<Warning />}>
          <Typography ml={1}>{t('motivations:please_select_at_least_two_games')}</Typography>
        </GRBanner>
      )
    }

    switch (tabs[tabView].id) {
      case CompareGameTabSelection.FEATURES:
        return <FeatureTabView gamesAndAnalysis={filteredGameAndAnalysisData} gamesWithMarketIso={parsedGamesWithMarketIso} />
      case CompareGameTabSelection.MOTIVATION:
        return <MotivationsTabView gamesWithMarketIso={parsedGamesWithMarketIso} gamesAndAnalysis={filteredGameAndAnalysisData} />
      case CompareGameTabSelection.PERFORMANCE:
        return <PerformanceTabView gamesWithMarketIso={parsedGamesWithMarketIso} gamesAndAnalysis={filteredGameAndAnalysisData} />
      default:
        return null
    }
  }

  useEffect(() => {
    setTabView(defaultTabView)
  }, [defaultTabView, subpage])

  if (!subpage) {
    return <Navigate replace to={`/compare-games/${tabs[0].id}`} />
  }

  return hasCompareGamesAccess ? (
    <>
      <GameSearchDialog
        enableMarketSelector
        gameSearchDialogConfig={gameSearchDialogConfig}
        modalOpen={gameSearchModalOpen}
        onClose={onGameSearchSelected}
        defaultSelectedGames={defaultGames}
        defaultGamesWithMarketIso={parsedGamesWithMarketIso}
        onClearGameList={() => {
          setSearchParams({})
        }}
        onlyGamesWithPowerscore
      />

      <div className="CompareGamesPage">
        <Card sx={{ p: 2, borderBottomLeftRadius: 0, borderBottomRightRadius: 0, borderBottom: '1px solid rgba(0,0,0,0.1)' }}>
          <GameCards
            gamesAndAnalysis={filteredGameAndAnalysisData}
            addMoreGameClick={() => {
              setGameSearchModalOpen(true)
            }}
            onGameRemoveClick={onGameRemoveClick}
            onClearAllGameClick={onClearAllGameClick}
          />
        </Card>
        <Box sx={{ mt: 0 }}>
          <GRTabNavigation tabs={tabs} onChange={(tabIndex) => setTabView(tabIndex)} selectedTab={tabView} pagePrefix={'/compare-games'} sticky={false} />
        </Box>
        {renderTabView()}
      </div>
    </>
  ) : (
    <LockedFeature.Card lockedFeatureId={LockedFeatureId.CompareGames} />
  )
}

export default CompareGamesPage
