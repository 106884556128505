import { Business, Group, Mail, Person } from '@mui/icons-material'

import NavigationIconCompareGames from '../assets/images/navigation-icon-compare-games.svg'
import NavigationIconDailyInsights from '../assets/images/navigation-icon-daily-insights.svg'
import NavigationIconFollowedGames from '../assets/images/navigation-icon-followed-games.svg'
import NavigationIconFTUEVideos from '../assets/images/navigation-icon-ftue-videos.svg'
import NavigationIconGameAnalyzer from '../assets/images/navigation-icon-game-analyzer.svg'
import NavigationIconGameUpdateImpacts from '../assets/images/navigation-icon-game-update-impacts.svg'
import NavigationIconGenreEssentials from '../assets/images/navigation-icon-genre-essentials.svg'
import NavigationIconImplementationExamples from '../assets/images/navigation-icon-implementation-examples.svg'
import NavigationIconLiveEventsTracker from '../assets/images/navigation-icon-live-events-tracker.svg'
import NavigationIconMarketExplorer from '../assets/images/navigation-icon-market-explorer.svg'
import NavigationIconMarketShare from '../assets/images/navigation-icon-market-share.svg'
import NavigationIconMarketTrends from '../assets/images/navigation-icon-market-trends.svg'
import NavigationIconReports from '../assets/images/navigation-icon-reports.svg'
import NavigationIconSoftLaunch from '../assets/images/navigation-icon-soft-launch.svg'
import NavigationIconTopGrossing from '../assets/images/navigation-icon-top-grossing.svg'
import NavigationIconVisualExplorer from '../assets/images/navigation-icon-visual-explorer.svg'
import SlackIcon from '../assets/images/slack-icon.svg'
import { RoleEnum } from '../features/account'
import ReportCardPreview from '../features/reports/components/ReportCardPreview/ReportCardPreview'
import AIAnalystPage from '../pages/AIAnalyst/AIAnalystPage'
import CompareGamesPage from '../pages/CompareGamesPage/CompareGamesPage'
import DailyInsightsPage from '../pages/DailyInsightsPage/DailyInsightsPage'
import DataGlossaryPage from '../pages/DataGlossaryPage/DataGlossaryPage'
import FollowedGamesPage from '../pages/FollowedGamesPage/FollowedGamesPage'
import FtueVideosPage from '../pages/FtueVideosPage/FtueVideosPage'
import GameAnalyzerDetailPage from '../pages/GameAnalyzerDetailPage/GameAnalyzerDetailPage'
import GameAnalyzerPage from '../pages/GameAnalyzerPage/GameAnalyzerPage'
import GamePage from '../pages/GamePage/GamePage'
import GamePageWrapper from '../pages/GamePage/GamePageWrapper'
import GenreEssentialsPage from '../pages/GenreEssentialsPage/GenreEssentialsPage'
import ImplementationExamplesPage from '../pages/ImplementationExamplesPage/ImplementationExamplesPage'
import InvitesPage from '../pages/InvitesPage/InvitesPage'
import LiveEventsTrackerPage from '../pages/LiveEventsTrackerPage/LiveEventsTrackerPage'
import MarketExplorerPage from '../pages/MarketExplorerPage/MarketExplorerPage'
import MarketSharePage from '../pages/MarketSharePage/MarketSharePage'
import MarketTrendsPage from '../pages/MarketTrendsPage/MarketTrendsPage'
import MyProfilePage from '../pages/MyProfilePage/MyProfilePage'
import OrganizationPage from '../pages/OrganizationPage/OrganizationPage'
import OrganizationUsersPage from '../pages/OrganizationUsersPage/OrganizationUsersPage'
import ReportsPage from '../pages/ReportsPage/ReportsPage'
import SingleUnifiedNewsEntryPage from '../pages/SingleUnifiedNewsEntryPage/SingleUnifiedNewsEntryPage'
import SlackIntegrationPage from '../pages/SlackIntegrationPage/SlackIntegrationPage'
import SlackPreferencesPage from '../pages/SlackPreferencesPage/SlackPreferencesPage'
import { SoftLaunchPage } from '../pages/SoftLaunchPage/SoftLaunchPage'
import TopGrossingPage from '../pages/TopGrossingPage/TopGrossingPage'
import { UpdateImpactsPage } from '../pages/UpdateImpactsPage/UpdateImpactsPage'
import VisualExplorerPage from '../pages/VisualExplorerPage/VisualExplorerPage'
import { Page } from '../types/Page'
import { SideNavigationGroupData } from '../types/SideNavigationGroupData'
import NavigationIconAIAnalyst from './../assets/images/navigation-icon-ai-analyst.svg'

class PageService {
  pages: Array<Page>

  constructor() {
    this.pages = [
      new Page(DataGlossaryPage, 'data-glossary-detail', '/data-glossary/*', 'sidebar:data_glossary', 'sidebar', ''),
      new Page(DataGlossaryPage, 'data-glossary', '/data-glossary', 'sidebar:data_glossary', 'sidebar', ''),
      new Page(DailyInsightsPage, 'daily-insights', '/daily-insights', 'sidebar:daily_insights', 'sidebar', 'daily-data', NavigationIconDailyInsights),
      new Page(
        GenreEssentialsPage,
        'genre-essentials',
        '/genre-essentials/*',
        'sidebar:genre_essentials',
        'sidebar',
        'daily-data',
        NavigationIconGenreEssentials,
        undefined,
        'genre-essentials'
      ),
      new Page(TopGrossingPage, 'top-grossing', '/top-grossing', 'sidebar:topgrossing', 'sidebar', 'daily-data', NavigationIconTopGrossing),
      new Page(SoftLaunchPage, 'soft-launch', '/soft-launch', 'sidebar:soft_launch', 'sidebar', 'daily-data', NavigationIconSoftLaunch),

      new Page(MarketExplorerPage, 'market-explorer', '/market-explorer/:subpage/*', 'sidebar:market_explorer', '', ''),
      new Page(
        MarketExplorerPage,
        'market-explorer',
        '/market-explorer',
        'sidebar:market_explorer',
        'sidebar',
        'market-research',
        NavigationIconMarketExplorer
      ),
      new Page(MarketSharePage, 'market-share', '/market-share/:subpage', 'sidebar:market_share', '', ''),
      new Page(MarketSharePage, 'market-share', '/market-share', 'sidebar:market_share', 'sidebar', 'market-research', NavigationIconMarketShare),
      new Page(
        VisualExplorerPage,
        'visual-explorer',
        '/visual-explorer/*',
        'sidebar:visuals_explorer',
        'sidebar',
        'market-research',
        NavigationIconVisualExplorer,
        undefined,
        '/visual-explorer'
      ),
      new Page(ReportCardPreview, 'report-detail', '/reports/:reportId/*', 'sidebar:reports', '', ''),
      new Page(ReportsPage, 'reports', '/reports/*', 'sidebar:reports', 'sidebar', 'market-research', NavigationIconReports, undefined, '/reports'),

      new Page(MarketTrendsPage, 'market-trends', '/market-trends', 'sidebar:market-trends', 'sidebar', 'market-research', NavigationIconMarketTrends),
      new Page(MarketTrendsPage, 'market-trends', '/market-trends/*', 'sidebar:market-trends', '', ''),
      new Page(LiveEventsTrackerPage, 'live-events-tracker', '/live-events-tracker/:subpage', 'sidebar:live_events_tracker', ''),
      new Page(
        LiveEventsTrackerPage,
        'live-events-tracker',
        '/live-events-tracker',
        'sidebar:live_events_tracker',
        'sidebar',
        'market-research',
        NavigationIconLiveEventsTracker,
        undefined,
        '/live-events-tracker/feed'
      ),
      new Page(
        AIAnalystPage,
        'ai-analyst',
        '/ai-analyst',
        'sidebar:ai_analyst',
        'sidebar',
        'market-research',
        NavigationIconAIAnalyst,
        undefined,
        undefined,
        RoleEnum.ai_analyst,
        true
      ),

      new Page(FollowedGamesPage, 'followed-games', '/followed-games', 'sidebar:followed_games', 'sidebar', 'game-analysis', NavigationIconFollowedGames),
      new Page(CompareGamesPage, 'compare-games', '/compare-games/:subpage/*', 'sidebar:game_comparison', '', ''),
      new Page(CompareGamesPage, 'compare-games', '/compare-games', 'sidebar:game_comparison', 'sidebar', 'game-analysis', NavigationIconCompareGames),
      new Page(
        UpdateImpactsPage,
        'game-update-impacts',
        '/game-update-impacts/*',
        'sidebar:game-update-impacts',
        'sidebar',
        'game-analysis',
        NavigationIconGameUpdateImpacts,
        undefined,
        '/game-update-impacts'
      ),

      new Page(ImplementationExamplesPage, 'implementation-examples', '/implementation-examples/*', 'sidebar:impl_examples', '', ''),
      new Page(
        ImplementationExamplesPage,
        'implementation-examples',
        '/implementation-examples',
        'sidebar:impl_examples',
        'sidebar',
        'game-analysis',
        NavigationIconImplementationExamples
      ),

      new Page(FtueVideosPage, 'ftue-videos', '/ftue-videos', 'sidebar:ftue-videos', 'sidebar', 'game-analysis', NavigationIconFTUEVideos),
      new Page(FtueVideosPage, 'ftue-videos', '/ftue-videos/*', 'sidebar:ftue-videos', '', ''),

      new Page(GameAnalyzerDetailPage, 'game-analyzer-detail', '/game-analyzer/:id/*', 'sidebar:game_analyzer', '', ''),
      new Page(
        GameAnalyzerPage,
        'game-analyzer',
        '/game-analyzer/*',
        'sidebar:game_analyzer',
        'sidebar',
        'game-analysis',
        NavigationIconGameAnalyzer,
        undefined,
        '/game-analyzer'
      ),

      new Page(SingleUnifiedNewsEntryPage, 'news-card', '/news-card/:id', 'newsfeed:newscard', '', ''),

      new Page(GamePageWrapper, 'game', '/game/:id/:subpage/*', 'common:game', '', ''),
      new Page(GamePage, 'game', '/game/:id', 'common:game', '', ''),

      new Page(MyProfilePage, 'my-profile', '/my-profile', 'profile:my_profile', 'account', '', '', Person),
      new Page(OrganizationPage, 'organization', '/organization', 'organization:organization', 'account', '', '', Business),
      new Page(OrganizationUsersPage, 'organization-users', '/users', 'organization:users', 'account', '', '', Group),
      new Page(SlackIntegrationPage, 'slack', '/slack', 'newsfeed:slack', 'account', '', SlackIcon),
      new Page(SlackPreferencesPage, 'slack-preferences', '/slack/preferences/:teamName/:teamId/:channel/*', 'newsfeed:slack_preferences', '', ''),
      new Page(InvitesPage, 'invites', '/invites', 'profile:invites', 'account', '', '', Mail),
    ]
  }

  getSideNavigationGroups() {
    return [
      new SideNavigationGroupData(
        'daily-data',
        'sidebar:daily_data',
        this.pages.filter((page) => page.groupId === 'daily-data')
      ),
      new SideNavigationGroupData(
        'market-research',
        'sidebar:market_research',
        this.pages.filter((page) => page.groupId === 'market-research')
      ),
      new SideNavigationGroupData(
        'game-analysis',
        'sidebar:game_analysis',
        this.pages.filter((page) => page.groupId === 'game-analysis')
      ),
    ]
  }

  getPageWithId(pageId: string) {
    return this.pages.find((page) => page.id === pageId)
  }
}

const pageService = new PageService()

export default pageService
