import React, { memo, ReactNode } from 'react'
import { Link } from 'react-router-dom'

import { Game } from '../..'

type GameLinkProps = {
  children?: ReactNode
  game: Pick<Game, 'id'>
  style?: React.CSSProperties
  disabled?: boolean
  subpage?:
    | 'overview'
    | 'updatehistory'
    | 'revenue-and-downloads'
    | 'competitors'
    | 'features'
    | 'feature-ideas'
    | 'implementations'
    | 'visuals'
    | 'motivations'
    | 'live-events'
}

/**
 * Component for creating a link to game page
 */
export const GameLink: React.FC<GameLinkProps> = memo(({ game, disabled, children, style, subpage }) => {
  if (disabled) {
    return <>{children}</>
  }

  const topGameLink = subpage ? `/game/${game.id}/${subpage}` : `/game/${game.id}`

  return (
    <Link style={style} to={topGameLink}>
      {children}
    </Link>
  )
})
