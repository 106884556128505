import { t } from 'i18next'
import { FC, ReactNode, useMemo } from 'react'
import { Trans } from 'react-i18next'

import { Box, CardContent, Chip, Dialog, DialogContent, Grid, Typography } from '@mui/material'

import { useGetExplorerGamesWithChoiceQuery } from '../../../../../api/core'
import GRCircularProgress from '../../../../../components/GRCircularProgress/GRCircularProgress'
import GRDialogTitle from '../../../../../components/GRDialogTitle/GRDialogTitle'
import { average } from '../../../../../helpers/average'
import { median } from '../../../../../helpers/median'
import FeatureChoiceSimple from '../../../../feature/components/FeatureChoiceSimple/FeatureChoiceSimple'
import { Game } from '../../../../game'
import { FeatureDataRow } from '../../../hooks/featureDataHooks'
import { GamesDataTableColumnType } from '../../../types/MarketExplorerGamesDataTableColumns'
import { MarketExplorerSegmentConfiguration } from '../../../types/MarketExplorerSegmentConfiguration'
import { Popularity } from '../../../types/MarketExplorerSegmentData'
import { MarketExplorerGamesDataTable } from '../../MarketExplorerGamesData/MarketExplorerGamesDataTable/MarketExplorerGamesDataTable'
import { SegmentQueryDataType } from '../../MarketExplorerSegments/MarketExplorerSegment/MarketExplorerSegment'
import MarketExplorerSegments, { SegmentsQueryReturnType } from '../../MarketExplorerSegments/MarketExplorerSegments'

/**
 * Displays a dialog with a table of top X% popularity games
 */
type TopPercentOfGamesDialogProps = {
  onClose: () => void
  percentValue: number
  popularity: keyof Popularity
  featureData: FeatureDataRow
  segment: SegmentQueryDataType
  segments: SegmentQueryDataType[]
  selectedColumns: GamesDataTableColumnType[]
  onSelectedColumnsChange: (columnIds: GamesDataTableColumnType[]) => void
  children?: ReactNode
  onSegmentsChange: (segments: MarketExplorerSegmentConfiguration[]) => void
  onSegmentsQueryDataChange: (segmentsData: SegmentsQueryReturnType) => void
  onSegmentDelete?: () => void
}

export const TopPercentOfGamesDialog: FC<TopPercentOfGamesDialogProps> = ({
  popularity,
  percentValue,
  onClose,
  featureData,
  segment,
  segments,
  selectedColumns,
  onSelectedColumnsChange,
  onSegmentsChange,
  onSegmentsQueryDataChange,
  onSegmentDelete,
}) => {
  const { choiceLabel, choiceLegacyId, featureLabel, originalChoiceLabel } = featureData
  const marketIso = segment?.segmentConfiguration.marketIso as string
  const {
    data: gameIds,
    isLoading: isGamesWithChoiceLoading,
    isFetching: isGamesWithChoiceFetching,
  } = useGetExplorerGamesWithChoiceQuery(
    { marketIso: marketIso as string, gameIds: segment?.data?.games.map((game) => game.id) || [], choiceLegacyId },
    { skip: !marketIso }
  )
  const isLoading = isGamesWithChoiceLoading || isGamesWithChoiceFetching

  const popularityPercentValue = useMemo(() => popularityToPercentMap[popularity] || 100, [popularity])
  const filteredGames: Game[] = useMemo(() => {
    const analyzedGames = segment?.data?.games.filter((game) => marketIso && game.getPowerscoreForMarket(marketIso) > 0) || []
    // sort games by grossing rank (highest rank first)
    analyzedGames.sort((game1, game2) =>
      marketIso ? (game1.getSustainedGrossingRankForMarket(marketIso) || 2000) - (game2.getSustainedGrossingRankForMarket(marketIso) || 2000) : 0
    )
    const numberOfGamesForPercent = analyzedGames.length ? Math.round(analyzedGames.length * (popularityPercentValue / 100)) : 0

    // take only the top X% of the games
    return analyzedGames.slice(0, numberOfGamesForPercent)
  }, [marketIso, popularityPercentValue, segment])

  const games = filteredGames.filter((game) => gameIds?.includes(game.id))
  const tableRows = games.map((game) => ({
    game,
    segmentIndex: featureData.segmentGroup - 1,
    segmentConfiguration: segment!.segmentConfiguration,
    rowId: `${game.id}_${featureData.segmentGroup - 1}`,
    segmentData: segment,
  }))

  const translationValues = {
    gamesCount: games.length,
    gamesCountAll: filteredGames.length,
    percent: popularityPercentValue,
    gamesPercent: Math.round(percentValue),
  }

  const segmentConfigurations = useMemo(() => {
    return segments.reduce((result, segment) => {
      if (segment && segment.segmentConfiguration) {
        result.push(segment.segmentConfiguration)
      }
      return result
    }, [] as MarketExplorerSegmentConfiguration[])
  }, [segments])

  return (
    <Dialog open={true} onClose={onClose} maxWidth="lg" fullWidth className="TopPercentOfGamesDialog">
      <GRDialogTitle onClose={onClose}>{t('common:list_of_games_title')}</GRDialogTitle>
      <DialogContent dividers>
        {isLoading ? (
          <GRCircularProgress />
        ) : (
          <>
            <Grid container mb={2} justifyContent="space-between" wrap="wrap">
              <Grid item xs={12} sm={4} xl={4} alignSelf="center">
                <Box mb={2} color={(theme) => theme.palette.text.secondary} sx={{ strong: { color: (theme) => theme.palette.secondary.main } }}>
                  <Typography display="inline">
                    <Trans i18nKey="market-explorer:top_percent_games_feature_in_segment" values={translationValues} />
                  </Typography>
                  <Typography display="inline" component="strong">
                    {featureLabel}
                    <FeatureChoiceSimple choiceLocalized={choiceLabel} originalChoice={originalChoiceLabel} />
                  </Typography>
                  <Box sx={{ strong: { color: (theme) => theme.palette.secondary.main } }}>
                    <SustainedGrossingRankData games={games} marketIso={marketIso} />
                  </Box>
                </Box>
              </Grid>
              {segment && (
                <Grid item xs={12} sm={8} xl={8}>
                  <CardContent>
                    <MarketExplorerSegments
                      onSegmentsQueryDataChange={onSegmentsQueryDataChange}
                      onSegmentsChange={onSegmentsChange}
                      segments={segmentConfigurations}
                      displaySegments={[segment.segmentConfiguration]}
                      overrideColorIndex={featureData.segmentGroup - 1}
                      segmentIndex={tableRows[0].segmentIndex}
                      showToggleVisibility
                      showAddNewSegment
                      preventInit
                      onSegmentDelete={onSegmentDelete}
                    ></MarketExplorerSegments>
                  </CardContent>
                </Grid>
              )}
            </Grid>
            <MarketExplorerGamesDataTable
              rows={tableRows}
              selectedColumns={selectedColumns}
              onSelectedColumnsChange={onSelectedColumnsChange}
              tableConfig={{ showSegment: true }}
              isLoading={isLoading}
            />
          </>
        )}
      </DialogContent>
    </Dialog>
  )
}

const popularityToPercentMap: { [key in keyof Popularity]?: number } = {
  '20Percent': 20,
  '50Percent': 50,
  Overall: 100,
}

const SustainedGrossingRankData: FC<{ games: Game[]; marketIso: string }> = ({ games, marketIso }) => {
  const sgrs = games
    .map((game) => {
      return game.getSustainedGrossingRankForMarket(marketIso)
    })
    .filter((sgr) => sgr > 0 && sgr <= 1500)

  const sgrAverage = Math.round(average(sgrs)) || '-'
  const sgrMedian = Math.round(median(sgrs)) || '-'

  return (
    <Box>
      <Box sx={{ mt: 1 }}>
        <Typography variant="body1" sx={{ display: 'inline-flex' }} component="strong">
          <Trans i18nKey="common:average_sgr" />
        </Typography>
        <Chip label={sgrAverage} color="primary" sx={{ ml: 1, verticalAlign: 'baseline' }}></Chip>
      </Box>
      <Box>
        <Typography variant="body1" sx={{ display: 'inline-flex' }} component="strong">
          <Trans i18nKey="common:median_sgr" />
        </Typography>
        <Chip label={sgrMedian} color="primary" sx={{ ml: 1, verticalAlign: 'baseline' }}></Chip>
      </Box>
    </Box>
  )
}
