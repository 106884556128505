import { TrackingEvent } from './TrackingEvents'

export type LiveEventCounts = { [day: number]: number }

export type EventTypeStatValue<ValueType> = {
  value: ValueType
  change: number
  percentChange: number
}

export type EventTypeStat = {
  eventTypeId: string
  popularity: EventTypeStatValue<number>
  duration: {
    max: EventTypeStatValue<Interval>
    avg: EventTypeStatValue<Interval>
  }
  revenue: EventTypeStatValue<number>
  colorHex?: string
}

export type EventTypeStats = {
  [eventTypeId: string]: EventTypeStat
}

export type PerformanceByEventType = { [eventTypeId: string]: { revenue: number[]; downloads: number[] } }
export type PerformanceByGameAndEventType = Partial<{ [gameId: string]: Partial<{ [date: string]: { revenue: number; downloads: number } }> }>

export type RollingPerformance = { ts: number; revenue: number; downloads: number }

export enum LiveEventTypeDurationCategory {
  Days0To1 = 'Days0To1',
  Days2To3 = 'Days2To3',
  Days4To5 = 'Days4To5',
  Days6To7 = 'Days6To7',
  Days8To22 = 'Days8To22',
  Days23To30 = 'Days23To30',
  Days31OrMore = 'Days31OrMore',
}

export type TrackingEventsByDurationCategory = { [key in LiveEventTypeDurationCategory]: TrackingEvent[] }
export type IntervalsByDurationCategory = { [key in LiveEventTypeDurationCategory]: Interval }
