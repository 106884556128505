import { combineReducers } from 'redux'

import { accountApi, unauthenticatedAccountApi } from '../api/account'
import { aiAnalystApi } from '../api/ai-analyst'
import { combinedApi } from '../api/combined'
import { connectServerApi } from '../api/connect'
import { coreApi, unauthenticatedCoreApi } from '../api/core'
import { feedApi } from '../api/feed'
import { keycloakApi } from '../api/keycloak'
import { reportsApi } from '../api/reports'
import { topGrossingServerApi } from '../api/top-grossing'
import GRPopoverReducers from '../components/GRPopover/reducers/GRPopoverReducer'
import liveEventModalReducer from '../features/live-events/slices/liveEventModalSlice'
import liveEventsTrackerCalendarsReducer from '../features/live-events/slices/liveEventsCalendarsSlice'
import snackBarMessageReducer from '../features/snackbar/reducers/snackBarMessageReducer'
import { mainStoreIdReducer } from '../features/store'
import currentPageReducer from './currentPageReducer'

const allReducers = combineReducers({
  currentPage: currentPageReducer,
  mainStoreId: mainStoreIdReducer,
  snackBarMessage: snackBarMessageReducer,
  grPopover: GRPopoverReducers,
  liveEventModal: liveEventModalReducer,
  liveEventsTrackerCalendars: liveEventsTrackerCalendarsReducer,
  [accountApi.reducerPath]: accountApi.reducer,
  [unauthenticatedAccountApi.reducerPath]: unauthenticatedAccountApi.reducer,
  [combinedApi.reducerPath]: combinedApi.reducer,
  [coreApi.reducerPath]: coreApi.reducer,
  [unauthenticatedCoreApi.reducerPath]: unauthenticatedCoreApi.reducer,
  [connectServerApi.reducerPath]: connectServerApi.reducer,
  [topGrossingServerApi.reducerPath]: topGrossingServerApi.reducer,
  [feedApi.reducerPath]: feedApi.reducer,
  [aiAnalystApi.reducerPath]: aiAnalystApi.reducer,
  [reportsApi.reducerPath]: reportsApi.reducer,
  [keycloakApi.reducerPath]: keycloakApi.reducer,
})

export default allReducers
