import numbro from 'numbro'

import { Game } from '../../game'
import { Estimates } from '../../game/types/Game'
import { TopGame } from '../../top-game/types/TopGame'

/**
 * Wrapper class for game revenue and downloads estimates. Provides access to raw value
 * and possibility to format it for display.
 */

type FormatArgs = {
  maxFractionDigits?: number
  shorten?: boolean
  threshold?: number
}

type GameRevenueAndDownloadAggregatesMap = Pick<Game | TopGame, 'revenueAndDownloadAggregatesMap'>

export const DEFAULT_REVENUE_VALUE = 100
export const DEFAULT_DOWNLOADS_VALUE = 100
const LANGUAGE_CODE = 'en-US'

export class RevenueAndDownloadEstimates {
  _estimates: Estimates
  _value: number
  _minRevenueValue: number
  _minDownloadsValue: number

  constructor(
    game: GameRevenueAndDownloadAggregatesMap,
    marketIso: string,
    minRevenueValue: number = DEFAULT_REVENUE_VALUE,
    minDownloadsValue: number = DEFAULT_DOWNLOADS_VALUE
  ) {
    this._estimates = getEstimatesForMarket(game, marketIso)
    this._value = 0
    this._minRevenueValue = minRevenueValue
    this._minDownloadsValue = minDownloadsValue
  }

  get downloads30Day() {
    this._value = this._estimates.downloads30d || 0
    return this
  }

  get downloads60Day() {
    this._value = this._estimates.downloads60d || 0
    return this
  }

  get downloads90Day() {
    this._value = this._estimates.downloads90d || 0
    return this
  }

  get downloads180Day() {
    this._value = this._estimates.downloads180d || 0
    return this
  }

  get downloads360Day() {
    this._value = this._estimates.downloads360d || 0
    return this
  }

  get downloadsLatest() {
    this._value = this._estimates.downloadsLatest || 0
    return this
  }

  get revenue30Day() {
    this._value = this._estimates.revenue30d || 0
    return this
  }

  get revenue60Day() {
    this._value = this._estimates.revenue60d || 0
    return this
  }

  get revenue90Day() {
    this._value = this._estimates.revenue90d || 0
    return this
  }

  get revenue180Day() {
    this._value = this._estimates.revenue180d || 0
    return this
  }

  get revenue360Day() {
    this._value = this._estimates.revenue360d || 0
    return this
  }

  get revenueLatest() {
    this._value = this._estimates.revenueLatest || 0
    return this
  }

  get downloadsTrend30Day() {
    const previous30DayDownloads = this._getPrevious30DayDownloads()
    this._value = this._hasValid30DayDownloadsForEstimates() ? normalizeValues(getTrendValue(this.downloads30Day._value / previous30DayDownloads)) : 0
    return this
  }

  get revenueTrend30Day() {
    const previous30DayRevenue = this._getPrevious30DayRevenue()
    this._value = this._hasValid30DayRevenueForEstimates() ? normalizeValues(getTrendValue(this.revenue30Day._value / previous30DayRevenue)) : 0
    return this
  }

  get revenueAndDownloadsRatio30Day() {
    this._value = this._isValidFor30DayEstimates() ? normalizeValues(this.revenue30Day._value / this.downloads30Day._value) : 0
    return this
  }

  get revenueAndDownloadsRatio180Day() {
    this._value = this._isValidFor180DayEstimates() ? normalizeValues(this.revenue180Day._value / this.downloads180Day._value) : 0
    return this
  }

  get revenueAndDownloadsRatio360Day() {
    this._value = this._isValidFor360DayEstimates() ? normalizeValues(this.revenue360Day._value / this.downloads360Day._value) : 0
    return this
  }

  get revenueAndDownloadsRatioTrend30Day() {
    const previous30DayDownloads = this._getPrevious30DayDownloads()
    const previous30DayRevenue = this._getPrevious30DayRevenue()

    this._value = normalizeValues(getTrendValue(this.revenueAndDownloadsRatio30Day._value / (previous30DayRevenue / previous30DayDownloads)))
    return this
  }

  get value() {
    return this._value
  }

  format({ maxFractionDigits = 2, shorten = false, threshold = this._minDownloadsValue }: FormatArgs = {}) {
    numbro.setLanguage(LANGUAGE_CODE)
    const normalizedValue = this._value <= threshold ? threshold : this._value
    const formattedValue = numbro(normalizedValue).format({
      average: (!!shorten && normalizedValue >= 1000) || normalizedValue <= -1000,
      mantissa: maxFractionDigits,
      thousandSeparated: true,
      trimMantissa: true,
      optionalMantissa: true,
    })

    return `${this._value <= threshold ? '< ' : ''}${formattedValue}`
  }

  formatCurrency({ maxFractionDigits = 2, shorten = false, threshold = this._minRevenueValue }: FormatArgs = {}) {
    numbro.setLanguage(LANGUAGE_CODE)
    const normalizedValue = this._value <= threshold ? threshold : this._value
    const formattedValue = numbro(normalizedValue).formatCurrency({
      average: (!!shorten && normalizedValue >= 1000) || normalizedValue <= -1000,
      mantissa: maxFractionDigits,
      thousandSeparated: true,
      trimMantissa: true,
      optionalMantissa: true,
    })

    return `${this._value <= threshold ? '< ' : ''}${formattedValue}`
  }

  compareThreshold(type: 'downloads' | 'revenue' = 'revenue', threshold?: number) {
    const selectedThreshold = threshold !== undefined ? threshold : type === 'downloads' ? this._minDownloadsValue : this._minRevenueValue
    return this._value <= selectedThreshold
  }

  _getPrevious30DayDownloads() {
    return this.downloads60Day._value - this.downloads30Day._value
  }

  _getPrevious30DayRevenue() {
    return this.revenue60Day._value - this.revenue30Day._value
  }

  _hasValid30DayDownloadsForEstimates() {
    return this.downloads30Day._value > this._minDownloadsValue
  }

  _hasValid30DayRevenueForEstimates() {
    return this.revenue30Day._value > this._minRevenueValue
  }

  _isValidFor30DayEstimates() {
    return this.revenue30Day._value > this._minRevenueValue && this.downloads30Day._value > this._minDownloadsValue
  }

  _isValidFor180DayEstimates() {
    return this.revenue180Day._value > this._minRevenueValue && this.downloads180Day._value > this._minDownloadsValue
  }

  _isValidFor360DayEstimates() {
    return this.revenue360Day._value > this._minRevenueValue && this.downloads360Day._value > this._minDownloadsValue
  }
}

const getEstimatesForMarket = (game: GameRevenueAndDownloadAggregatesMap, marketIso: string) => {
  return game.revenueAndDownloadAggregatesMap?.[marketIso] || {}
}

const normalizeValues = (value: number) => {
  if (isNaN(value) || !isFinite(value)) {
    return 0
  } else {
    return value
  }
}

const getTrendValue = (value: number) => {
  return value !== 0 ? (1 - value) * -1 : value
}
