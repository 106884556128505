import { useMemo } from 'react'

import { Tag, useGetTagGroupsListQuery, useGetTagListQuery } from '../../../api/core'
import { useCurrentUserLanguage } from '../../account/hooks/userHooks'

export const useLiveEventsSecondaryTags = () => {
  const userLanguage = useCurrentUserLanguage()
  const tagListResult = useGetTagListQuery({ type: 'event-secondary', userLanguage })

  return useMemo(() => {
    if (tagListResult.data) {
      return {
        ...tagListResult,
        data: tagListResult.data.filter((tag) => tag.active),
      }
    } else {
      return tagListResult
    }
  }, [tagListResult])
}

export const useLiveEventsSecondaryTagGroups = () => {
  const userLanguage = useCurrentUserLanguage()
  const tagGroupsListResult = useGetTagGroupsListQuery({ type: 'event-secondary', userLanguage })
  return tagGroupsListResult
}

// hook that takes an array of tags as strings and returns them as array of { groupId: string, groupName:string, tags: { id: string, name: string }[] }[]
export const useResolvedLiveEventsSecondaryTagsWithGroups = (tags: string[]) => {
  const liveEventSecondaryTags = useLiveEventsSecondaryTags()
  const liveEventSecondaryTagGroups = useLiveEventsSecondaryTagGroups()

  return useMemo(() => {
    if (liveEventSecondaryTags.data && liveEventSecondaryTagGroups.data && tags.length > 0) {
      // find tag objects from ids
      const resolvedTags = tags.map((id) => liveEventSecondaryTags.data?.find((t) => t.id === id)).filter((t): t is Tag => t !== undefined && t.active)
      // filter out tags that are not in any group
      const resolvedGroups = liveEventSecondaryTagGroups.data.filter((group) => group.tags.some((tagId) => resolvedTags.some((tag) => tag.id === tagId)))

      // map groups to { groupId, groupName, tags } & fill tags only with tags that are in the resolvedTags array
      return resolvedGroups.map((group) => ({
        groupId: group.id,
        groupName: group.name,
        tags: group.tags.map((tagId) => resolvedTags.find((tag) => tag.id === tagId)).filter((t): t is Tag => t !== undefined),
      }))
    } else {
      return []
    }
  }, [liveEventSecondaryTags, liveEventSecondaryTagGroups, tags])
}

// hook that takes an array of tags as strings and returns them as { id:string, name: string, group: string }[]
export const useResolvedLiveEventSecondaryTags = (tags: string[]) => {
  const liveEventSecondaryTags = useLiveEventsSecondaryTags()
  const liveEventSecondaryTagGroups = useLiveEventsSecondaryTagGroups()

  return useMemo(() => {
    if (liveEventSecondaryTags.data && liveEventSecondaryTagGroups.data) {
      return tags
        .map((id) => liveEventSecondaryTags.data?.find((t) => t.id === id))
        .filter((t): t is Tag => t !== undefined && t.active)
        .map((tag) => ({
          ...tag,
          group: liveEventSecondaryTagGroups.data?.find((g) => g.tags.includes(tag.id))?.name || '',
        }))
    } else {
      return []
    }
  }, [liveEventSecondaryTags, liveEventSecondaryTagGroups, tags])
}

export const useLiveEventSecondaryTagGroupsWithTags = () => {
  const liveEventSecondaryTags = useLiveEventsSecondaryTags()
  const liveEventSecondaryTagGroups = useLiveEventsSecondaryTagGroups()

  return useMemo(() => {
    const result = {
      isSuccess: liveEventSecondaryTags.isSuccess && liveEventSecondaryTagGroups.isSuccess,
      isError: liveEventSecondaryTags.isError || liveEventSecondaryTagGroups.isError,
      isFetching: liveEventSecondaryTags.isFetching || liveEventSecondaryTagGroups.isFetching,
      isLoading: liveEventSecondaryTags.isLoading || liveEventSecondaryTagGroups.isLoading,
    }
    if (liveEventSecondaryTags.data && liveEventSecondaryTagGroups.data) {
      const data = [...liveEventSecondaryTagGroups.data]
        .sort((a, b) => a.order - b.order)
        .map((group) => ({
          ...group,
          name: group.name,
          tags: group.tags.map((id) => liveEventSecondaryTags.data?.find((t) => t.id === id))?.filter((t): t is Tag => t !== undefined && t.active),
        }))
        .filter((group) => group.tags.length > 0)

      return {
        ...result,
        data,
      }
    } else {
      return {
        data: undefined,
        ...result,
      }
    }
  }, [liveEventSecondaryTags, liveEventSecondaryTagGroups])
}
