import { FC, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Grid } from '@mui/material'

import { CounterButton } from '../../../../components/CounterButton/CounterButton'
import { FeatureSelectDialog, FeatureSelectDialogResult, FeatureSelectionMode } from '../../../feature/components/FeatureSelectDialog/FeatureSelectDialog'
import { useCurrentMarket } from '../../../markets'
import { FeaturesColumnFilterResult, FeaturesColumnFilterValue, FeaturesFilterValue, SelectOption } from '../../types/ColumnFilter'
import { ColumnFilterSelect } from '../ColumnFilterSelect/ColumnFilterSelect'

/**
 * Component for rendering Features column filter popover content
 */
type FeaturesColumnFilterProps = {
  name: string
  options: SelectOption[]
  onChange?: (value: FeaturesColumnFilterResult) => void
  value: FeaturesColumnFilterValue
}

export const FeaturesColumnFilter: FC<FeaturesColumnFilterProps> = ({ value, name, options, onChange }) => {
  const { t } = useTranslation()
  const { currentMarketIso } = useCurrentMarket()
  const [featureSelectDialogOpen, setFeatureSelectDialogOpen] = useState<boolean>(false)

  const handleChange = useCallback(
    (featureType: string) => {
      onChange && onChange({ name, value: { ...value, featureType: featureType as FeaturesFilterValue } })
    },
    [onChange, name, value]
  )

  const handleSelectedFeaturesChange = useCallback(
    (selectedFeatures: FeatureSelectDialogResult) => {
      onChange && onChange({ name, value: { ...value, selectedFeatures: selectedFeatures.featureChoices } })
    },
    [value, name, onChange]
  )

  const featureCount = Object.keys(value.selectedFeatures || {}).length

  return (
    <>
      <Grid container wrap="wrap" spacing={2} sx={{ width: 200 }}>
        <Grid item xs={12}>
          <ColumnFilterSelect value={value.featureType || FeaturesFilterValue.Any} label={t('common:filter')} options={options} onChange={handleChange} />
        </Grid>
        <Grid item xs={12}>
          <CounterButton
            label={t('feature-select-modal:select_features')}
            count={featureCount}
            variant="contained"
            onClick={() => setFeatureSelectDialogOpen(true)}
            fullWidth
          />
        </Grid>
      </Grid>
      <FeatureSelectDialog
        open={featureSelectDialogOpen}
        onClose={() => setFeatureSelectDialogOpen(false)}
        selectedFeatures={value.selectedFeatures || {}}
        concepts={false}
        onConfirm={handleSelectedFeaturesChange}
        selectionMode={FeatureSelectionMode.Features}
        marketIso={currentMarketIso}
        includeHiddenScreenshots
      />
    </>
  )
}
