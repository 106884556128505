import { PayloadAction } from '@reduxjs/toolkit'

import PageService from '../services/PageService'
import { Page } from '../types/Page'

const currentPageReducer = (state: Page | undefined = PageService.getPageWithId('daily-insights'), action: PayloadAction<Page>) => {
  switch (action.type) {
    case 'CHANGE_PAGE':
      return action.payload

    default:
      return state
  }
}
export default currentPageReducer
