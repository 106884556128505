import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Download } from '@mui/icons-material'
import DownloadingIcon from '@mui/icons-material/Downloading'
import LockIcon from '@mui/icons-material/Lock'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import ShareIcon from '@mui/icons-material/Share'
import { Avatar, Button, Card, CardContent, CardMedia, Grid, Skeleton, SpeedDial, SpeedDialAction, Stack, Typography } from '@mui/material'

import { useGetReportFileMutation } from '../../../../api/reports'
import { GRTooltip } from '../../../../components/GRTooltip/GRTooltip'
import { useAppDispatch } from '../../../../hooks/storeHooks'
import { useCurrentUserLanguage } from '../../../account/hooks/userHooks'
import { useDateTimeFormatter } from '../../../live-events/hooks/useDateTimeFormatter'
import { NewsCardRibbon } from '../../../news/components/NewsCard/NewsCardRibbon/NewsCardRibbon'
import { displaySnackBar } from '../../../snackbar/actions/displaySnackBar'
import { useUpdateUserUnreadReports } from '../../hooks/reportsHooks'
import { Report, ReportVisibility } from '../../types/Report'
import './ReportCard.scss'

/**
 * ReportCard
 */
interface ReportCardProps {
  report?: Report
  loading?: boolean
  useSideBySideLayout?: boolean
}

const reportsPagePath = '/reports'

const ReportCard: React.FC<ReportCardProps> = ({ report, loading = false, useSideBySideLayout = false }) => {
  const { t } = useTranslation()
  const [getReportFile] = useGetReportFileMutation()
  const [fileDownloading, setFileDownloading] = useState(false)
  const userLanguage = useCurrentUserLanguage()
  const dateFormatter = useDateTimeFormatter()
  const publishedAt = dateFormatter.format(report?.publishedAt)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const updateUnreadReports = useUpdateUserUnreadReports(report?.id)

  const handleDownload = async (event: any) => {
    event?.stopPropagation()
    setFileDownloading(true)

    try {
      await getReportFile({ id: report?.id })
      updateUnreadReports()
      setFileDownloading(false)
    } catch (error) {
      setFileDownloading(false)
    }
  }

  const handleShare = (event: any) => {
    event?.stopPropagation()

    // copy url to clipboard
    if (navigator.clipboard) {
      navigator.clipboard.writeText(`${window.location.origin}${reportsPagePath}/${report?.id}`)
      const NOTIFY_TEXT = t('common:notify_url_copied_clipboard')
      dispatch(displaySnackBar({ message: NOTIFY_TEXT, severity: 'success', open: true }))
    } else {
      dispatch(displaySnackBar({ message: t('common:notify_url_not_copied_clipboard'), severity: 'error', open: true }))
    }
  }

  const handleCardClick = () => {
    if (report) navigate(`${reportsPagePath}/${report.id}`)
  }

  return (
    <div className="ReportCard">
      {!report && loading && (
        // CARD SKELETON
        <Card>
          <Grid container wrap="wrap">
            <Grid item xs={useSideBySideLayout ? 4 : 12}>
              <Skeleton sx={{ height: 190 }} animation="wave" variant="rectangular" />
            </Grid>
            <Grid item xs={useSideBySideLayout ? 8 : 12}>
              <CardContent className="ReportCard__content">
                <Grid container flexDirection="row" justifyContent="space-between" sx={{ flexGrow: 1 }}>
                  <Grid
                    item
                    flexDirection="column"
                    xs={10}
                    sx={{
                      minHeight: 60,
                      maxHeight: 60,
                    }}
                  >
                    <Stack>
                      <Skeleton animation="wave" height={10} width="50%" style={{ marginBottom: 6 }} />
                      <Skeleton animation="wave" height={10} width="70%" />
                    </Stack>
                  </Grid>
                  <Grid item flex-direction="column" alignItems="center" sx={{ position: 'relative' }}>
                    <Skeleton animation="wave" variant="circular" width={40} height={40} />
                  </Grid>
                </Grid>
              </CardContent>
            </Grid>
          </Grid>
        </Card>
      )}

      {report && !loading && (
        // CARD WITH REPORT
        <Card onClick={handleCardClick}>
          {report.visibility === ReportVisibility.private && (
            <NewsCardRibbon sx={{ fontSize: 11 }} className="NewsCardRibbon ReportCard__visibility-restricted">
              <Trans i18nKey={'reports:visibility_private'} />
            </NewsCardRibbon>
          )}

          <Grid container wrap="wrap">
            <Grid item xs={useSideBySideLayout ? 4 : 12}>
              {report?.coverUrls && report.coverUrls.length && <CardMedia component="img" image={report?.coverUrls[0]} />}
            </Grid>
            <Grid item xs={useSideBySideLayout ? 8 : 12} alignSelf={useSideBySideLayout ? 'center' : 'start'}>
              <CardContent className="ReportCard__content">
                <Grid container flexDirection="row" wrap="wrap" justifyContent="space-between" alignContent={'middle'} sx={{ flexGrow: 1 }}>
                  <Grid
                    item
                    flexDirection="column"
                    xs={10}
                    sx={{
                      minHeight: 60,
                      maxHeight: 60,
                    }}
                  >
                    <Stack mr={2}>
                      <Typography variant="h4">{publishedAt}</Typography>
                      <GRTooltip content={report?.title[userLanguage] !== '' ? report?.title[userLanguage] : report?.title['en']}>
                        <Typography variant="h3" className="two-line-truncate">
                          {report?.title[userLanguage] !== '' ? report?.title[userLanguage] : report?.title['en']}
                        </Typography>
                      </GRTooltip>
                    </Stack>
                  </Grid>
                  <Grid item flex-direction="column" alignItems="center" xs={useSideBySideLayout ? 12 : 2} sx={{ position: 'relative' }}>
                    {!report?.downloadable && (
                      <Avatar sx={useSideBySideLayout ? {} : { position: 'absolute', bottom: 10, right: 8 }}>
                        <GRTooltip content={t('feature:upgrade_your_plan_to_unlock_this_feature')}>
                          <LockIcon />
                        </GRTooltip>
                      </Avatar>
                    )}

                    {!useSideBySideLayout && report?.downloadable && (
                      <SpeedDial
                        className="speeddial"
                        ariaLabel="Report actions"
                        sx={{ position: 'absolute', bottom: 10, right: 0, zIndex: 99 }}
                        icon={<MoreVertIcon />}
                        onClick={(event: any) => {
                          event?.stopPropagation()
                        }}
                      >
                        {report?.downloadable && (
                          <SpeedDialAction
                            key="download"
                            icon={fileDownloading ? <DownloadingIcon /> : <Download />}
                            tooltipTitle={t('common:download')}
                            tooltipOpen
                            onClick={handleDownload}
                          />
                        )}
                        <SpeedDialAction key="share" icon={<ShareIcon />} tooltipTitle={t('common:share_text')} tooltipOpen onClick={handleShare} />
                      </SpeedDial>
                    )}

                    {useSideBySideLayout && report?.downloadable && (
                      <Grid container spacing={2}>
                        <Grid item>
                          <Button
                            variant="contained"
                            onClick={handleDownload}
                            startIcon={fileDownloading ? <DownloadingIcon /> : <Download />}
                            disabled={fileDownloading}
                          >
                            {t('common:download')}
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button variant="contained" onClick={handleShare} startIcon={<ShareIcon />}>
                            {t('common:share_text')}
                          </Button>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </CardContent>
            </Grid>
          </Grid>
        </Card>
      )}
    </div>
  )
}

export default ReportCard
