import classNames from 'classnames'
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { Check, Info, KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import { Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Paper } from '@mui/material'

import { useGetUserSettingQuery, useUpdateUserSettingMutation } from '../../api/combined'
import { useGetGenreTaxonomyQuery } from '../../api/core'
import { SubgenreMap } from '../../features/account'
import { useCurrentUserLanguage } from '../../features/account/hooks/userHooks'
import { UserSettingKeys } from '../../features/account/types/UserSettings'
import { ConventionalCategory, ConventionalGenre, ConventionalSubgenre } from '../../features/genre-taxonomy'
import SelectedSubgenreChips from '../../features/news/components/SelectedSubgenresChips/SelectedSubgenreChips'
import './GameSubgenrePicker.scss'

export type SingleSubgenreSelection = {
  conventionalCategoryId?: string
  conventionalGenreId?: string
  conventionalSubgenreId?: string
}

interface GameSubgenrePickerProps {
  hideSetAsDefault?: boolean
  showSubgenreDefinitionBtn?: boolean
  selectedSubgenres: SubgenreMap
  selectedSubgenre?: SingleSubgenreSelection
  selectionChanged?: (newSelectedSubgenres: SubgenreMap) => void
  singleSelectionChanged?: (selection: SingleSubgenreSelection) => void
  disabled?: boolean
  defaultExpand?: boolean
  hideSubGenreSelectDropdown?: boolean
  disableCategorySelect?: boolean
  toggleGenreSelectionMode?: boolean
  onClose?: (event: {}, reason: string) => void
}

/**
 * There are two ways of using the component. Please choose one of the following
 *
 *  - Multiple subgenre selection: use selectionChanged callback that uses SubgenreMap
 *  - Single genre / subgenre selection: use singleSelectionChanged callback, @see SingleSubgenreSelection
 *
 * @param props
 */
const GameSubgenrePicker: React.FC<GameSubgenrePickerProps> = (props) => {
  const { t } = useTranslation()
  const userLanguage = useCurrentUserLanguage()
  const { data: genreTaxonomy } = useGetGenreTaxonomyQuery({ userLanguage })
  const [selectedSubgenres, setSelectedSubgenres] = useState(props.selectedSubgenres)
  const [currentDefaultState] = useState(props.selectedSubgenres)
  const [singleSelected, setSingleSelected] = useState<SingleSubgenreSelection>(props.selectedSubgenre || {})
  const [genreTaxonomyExpanded, setGenreTaxonomyExpanded] = useState(props.defaultExpand || false)
  const [saveUserSetting] = useUpdateUserSettingMutation()
  const { data: savedSubgenresMap = {} } = useGetUserSettingQuery<{ data: SubgenreMap }>(UserSettingKeys.defaultConventionalSubgenresKey)
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)

  const savedSubgenresSynced = useMemo(() => {
    if (!savedSubgenresMap) return false

    if (Object.keys(savedSubgenresMap).length !== Object.keys(selectedSubgenres).length) return false

    const diffSubgenres = Object.keys(savedSubgenresMap).filter((savedSubgenre) => !Object.keys(selectedSubgenres).includes(savedSubgenre))
    return !diffSubgenres.length
  }, [savedSubgenresMap, selectedSubgenres])
  const [subgenreSelectionHasChanged, setSubgenreSelectionHasChanged] = useState(false)

  useEffect(() => {
    setSubgenreSelectionHasChanged(!savedSubgenresSynced)
  }, [savedSubgenresSynced])

  useEffect(() => {
    setSelectedSubgenres(props.selectedSubgenres)
  }, [props.selectedSubgenres, selectedSubgenres])

  useEffect(() => {
    if (!!props.selectedSubgenre) {
      setSingleSelected(props.selectedSubgenre)
    }
  }, [props.selectedSubgenre])

  const setSubgenreSelected = ({ id }: ConventionalSubgenre) => {
    if (props.singleSelectionChanged) {
      return setSingle({ conventionalSubgenreId: id })
    }
    setSelected({ ...selectedSubgenres, [id]: !selectedSubgenres[id] })
  }

  const setSingle = useCallback(
    (value: SingleSubgenreSelection) => {
      const id = value?.conventionalSubgenreId
      const subgenres: SubgenreMap = id ? { [id]: !selectedSubgenres[id] } : {}
      setSelectedSubgenres(subgenres)
      setSingleSelected(value)
      props.singleSelectionChanged && props.singleSelectionChanged(value)
    },
    [props, selectedSubgenres]
  )

  const setGenreSelected = (genre: ConventionalGenre, subgenresSelectedFromGenre: number) => {
    if (props.singleSelectionChanged) {
      return setSingle({ conventionalGenreId: genre.id })
    }
    const selected = subgenresSelectedFromGenre <= 0
    const selectedSubgenresMap = Object.fromEntries(genre.subgenres.map((subgenre) => [subgenre.id, selected]))

    if (props.toggleGenreSelectionMode) {
      setSelected(selectedSubgenresMap)
      return
    }

    setSelected({ ...selectedSubgenres, ...selectedSubgenresMap })
  }

  const setCategorySelected = (category: ConventionalCategory, subgenresSelectedFromCategory: number) => {
    if (props.disabled) return
    if (props.singleSelectionChanged) {
      return setSingle({ conventionalCategoryId: category.id })
    }
    const selected = subgenresSelectedFromCategory <= 0
    const selectedSubgenresMap = Object.fromEntries(category.genres.map((genre) => genre.subgenres.map((subgenre) => [subgenre.id, selected])).flat())
    setSelected({ ...selectedSubgenres, ...selectedSubgenresMap })
  }

  const setSelected = useCallback(
    (selectedSubgenresMap: SubgenreMap) => {
      const newSelectedSubgenres = Object.fromEntries(Object.entries(selectedSubgenresMap).filter(([key, value]) => value))
      const isSame = JSON.stringify(currentDefaultState) === JSON.stringify(newSelectedSubgenres)
      setSelectedSubgenres(newSelectedSubgenres)
      props.selectionChanged && props.selectionChanged(newSelectedSubgenres)
      isSame ? setSubgenreSelectionHasChanged(false) : setSubgenreSelectionHasChanged(true)
    },
    [props, currentDefaultState]
  )

  const saveUserDefaultConventionalSubgenres = () => {
    saveUserSetting({ settingKey: UserSettingKeys.defaultConventionalSubgenresKey, value: selectedSubgenres })
    setSubgenreSelectionHasChanged(false)
  }

  const handleClearSelection = useCallback(() => {
    setSingle({})
    setSelected({})
    setSubgenreSelectionHasChanged(true)
    saveUserSetting({ settingKey: UserSettingKeys.defaultConventionalSubgenresKey, value: {} })
  }, [saveUserSetting, setSelected, setSingle])

  const categoriesHtml = genreTaxonomy?.map((category) => {
    let categorySubgenreSelectionCount = 0
    let categorySubgenreTotalCount = 0

    const genresHtml = category.genres.map((genre) => {
      categorySubgenreTotalCount += genre.subgenres.length
      const genreSubgenreSelectionCount = genre.subgenres.filter((subgenre) => selectedSubgenres[subgenre.id]).length
      categorySubgenreSelectionCount += genreSubgenreSelectionCount

      const subgenresHtml = genre.subgenres.map((subgenre) => {
        const subgenreSelected = props.singleSelectionChanged ? singleSelected?.conventionalSubgenreId === subgenre.id : !!selectedSubgenres[subgenre.id]
        return (
          <Box
            key={subgenre.id}
            className={classNames('subgenre', 'picker-element', { 'picker-element--selected': subgenreSelected })}
            onClick={() => setSubgenreSelected(subgenre)}
          >
            <Grid container alignItems="center" spacing={1}>
              <Grid item xs>
                {subgenre.name}
              </Grid>
              {props.singleSelectionChanged ? (
                <Grid item className="subgenre__selected-icon">
                  {singleSelected?.conventionalSubgenreId === subgenre.id && <Check fontSize="small" />}
                </Grid>
              ) : (
                <Grid item className="subgenre__selected-icon">
                  {selectedSubgenres[subgenre.id] && <Check fontSize="small"></Check>}
                </Grid>
              )}
            </Grid>
          </Box>
        )
      })

      const genreSelected = props.singleSelectionChanged ? singleSelected?.conventionalGenreId === genre.id : genreSubgenreSelectionCount > 0
      return (
        <Box className="genre" key={genre.id}>
          <Box
            className={classNames('genre__name', 'picker-element', { 'picker-element--selected': genreSelected })}
            onClick={() => setGenreSelected(genre, genreSubgenreSelectionCount)}
          >
            <Grid container alignItems="center" spacing={1}>
              <Grid item xs>
                {genre.name}
              </Grid>
              {props.singleSelectionChanged ? (
                <Grid item className="subgenre__selected-icon">
                  {singleSelected?.conventionalGenreId === genre.id && <Check fontSize="small" />}
                </Grid>
              ) : (
                <Grid item>
                  <Chip
                    className="Chip"
                    label={`${genreSubgenreSelectionCount}/${genre.subgenres.length}`}
                    color={genreSelected ? 'secondary' : 'default'}
                    size="small"
                  />
                </Grid>
              )}
            </Grid>
          </Box>
          {!props.hideSubGenreSelectDropdown && <Box className="genre__subgenres">{subgenresHtml}</Box>}
        </Box>
      )
    })

    const categorySelected = props.singleSelectionChanged ? singleSelected?.conventionalCategoryId === category.id : categorySubgenreSelectionCount > 0
    return (
      <Grid key={category.id} item xs={12} sm={6} md={6} lg={3}>
        <Paper className="category" sx={{ width: '100%' }}>
          <Grid
            container
            className={classNames(
              'category__name',
              'picker-element',
              { 'picker-element--selected': categorySelected && !props.disabled },
              { 'picker-element--disabled': props.disabled }
            )}
            onClick={() => {
              if (!props.disableCategorySelect) {
                setCategorySelected(category, categorySubgenreSelectionCount)
              }
            }}
            item
          >
            <Grid container item alignItems="center" spacing={1} xs={12}>
              <Grid item xs>
                {category.name}
              </Grid>
              {props.singleSelectionChanged ? (
                <Grid item className="subgenre__selected-icon">
                  {singleSelected?.conventionalCategoryId === category.id && <Check fontSize="small" />}
                </Grid>
              ) : (
                <Grid item>
                  <Chip
                    className="Chip"
                    label={`${categorySubgenreSelectionCount}/${categorySubgenreTotalCount}`}
                    color={categorySubgenreSelectionCount > 0 ? 'secondary' : 'default'}
                    size="small"
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
          {!props.disabled && (
            <Paper
              className={classNames('category__subCategoryContainer', {
                category__subCategoryContainer__collapsed: !genreTaxonomyExpanded,
              })}
            >
              {genresHtml}
            </Paper>
          )}
        </Paper>
      </Grid>
    )
  })

  return (
    <div className="GameSubgenrePicker">
      <div className="GameSubgenrePicker__header">
        <Grid container alignItems="center" spacing={1}>
          <Grid item xs>
            <Button
              size="small"
              onClick={() => setGenreTaxonomyExpanded(!genreTaxonomyExpanded)}
              variant="contained"
              disabled={props.disabled}
              startIcon={genreTaxonomyExpanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            >
              {genreTaxonomyExpanded ? t('common:collapse') : t('common:expand')}
            </Button>
          </Grid>
          <Grid item>
            {props.showSubgenreDefinitionBtn && (
              <Link to={'/data-glossary/genres'} onClick={() => props.onClose && props.onClose({}, 'escapeKeyDown')}>
                <Button sx={{ mr: 1 }} size="small" color="secondary" variant="text" startIcon={<Info />}>
                  {t('common:conventional_category_genre_definitions_button')}
                </Button>
              </Link>
            )}
            {!props.hideSetAsDefault && !props.singleSelectionChanged && (
              <>
                <Button
                  size="small"
                  onClick={() => setConfirmDialogOpen(true)}
                  disabled={props.disabled || !subgenreSelectionHasChanged}
                  color="primary"
                  variant="contained"
                >
                  {t('common:set_as_default_button')}
                </Button>

                <Dialog open={confirmDialogOpen}>
                  <DialogTitle>{t('common:default_taxonomy_confirm_title')}</DialogTitle>
                  <DialogContent>
                    <Box sx={{ mt: 2 }}>
                      <Trans i18nKey="common:taxonomy_default_setting_confirm_message" />
                    </Box>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      variant="contained"
                      onClick={() => {
                        saveUserDefaultConventionalSubgenres()
                        setConfirmDialogOpen(false)
                      }}
                    >
                      {t('common:yes')}
                    </Button>
                    <Button onClick={() => setConfirmDialogOpen(false)}>{t('common:no')}</Button>
                  </DialogActions>
                </Dialog>
              </>
            )}
          </Grid>
        </Grid>
      </div>
      <div className={genreTaxonomyExpanded ? 'GameSubgenrePicker__picker' : 'GameSubgenrePicker__picker GameSubgenrePicker__picker--collapsed'}>
        <Grid container spacing={1} sx={{ flexWrap: 'wrap' }}>
          {categoriesHtml}
        </Grid>
      </div>
      {(Object.keys(selectedSubgenres).length > 0 || (singleSelected && Object.keys(singleSelected).length > 0)) && (
        <div className="GameSubgenrePicker__selected-subgenres">
          <SelectedSubgenreChips
            selectedSubgenres={selectedSubgenres}
            singleSelected={singleSelected && Object.keys(singleSelected).length > 0 ? singleSelected : undefined}
            onClearSelection={handleClearSelection}
          />
        </div>
      )}
    </div>
  )
}

export default memo(GameSubgenrePicker)
