import { TLiveEventsCommonFilters, LiveEventsCommonFiltersData } from '../types/LiveEventsCommonFilters'

export const useLiveEventCommonFilterCounts = ({ filters, data }: { filters: TLiveEventsCommonFilters; data: LiveEventsCommonFiltersData }) => {
  const activeCount =
    filters.liveEventTags.length +
    filters.liveEventSecondaryTags.length +
    Object.keys(filters.additionalDatas).length +
    Object.keys(filters.liveEventDurations).length +
    Object.keys(filters.liveEventAppearances).length
  const maxCount =
    data.liveEventTags.length +
    data.liveEventSecondaryTags.length +
    data.additionalDatas.length +
    data.liveEventAppearances.length +
    data.liveEventDurations.length

  return {
    activeCount,
    maxCount,
  }
}
