import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Box, Card, FormControl, Grid, MenuItem, Select, Typography } from '@mui/material'

import { useGetGameVisualAnalysisQuery, useGetGenreAnalysisQuery } from '../../../api/connect'
import { LockedFeature } from '../../../components/LockedFeature/LockedFeature'
import analyticsService from '../../../services/AnalyticsService'
import { LockedFeatureId } from '../../../types/LockedFeature'
import { useRoleCheck } from '../../account/hooks/roleHooks'
import { RoleEnum } from '../../account/types/RoleEnum'
import { Game } from '../../game'
import { useCurrentMarket } from '../../markets'
import { useIsGameOpenForEveryone } from '../../settings'
import { RankType } from '../types'
import { AppStoreGenreMap, combineStatistics } from '../util/visualStats'
import './GameVisualsModal.scss'
import GameVisualsTable from './GameVisualsTable'

interface GameVisualsProps {
  game: Game
}

const GameVisuals: React.FC<GameVisualsProps> = ({ game }) => {
  useEffect(() => {
    analyticsService.trackEvent('Visited Game Overview: Visuals', {
      data: {
        gameId: game.id,
        gameName: game.resolvedName,
      },
    })
  }, [game.id, game.resolvedName])

  const { t } = useTranslation()

  const isGameOpenForEveryone = useIsGameOpenForEveryone(game)
  const isGameImplementationsFeatureUnlocked = useRoleCheck(RoleEnum.game_visuals)
  const displayVisuals = isGameOpenForEveryone || isGameImplementationsFeatureUnlocked

  const { currentMarketIso: marketIso } = useCurrentMarket()
  const [top, setTop] = useState(200)
  const [rank, setRank] = useState<RankType>('free')
  const [genreId, setGenreId] = useState<string>('6014')
  const { data: visualsData } = useGetGameVisualAnalysisQuery(game.id)
  const { data: genreData } = useGetGenreAnalysisQuery({ genreIds: game.appGenres || [], marketIso }, { skip: !visualsData })
  const statsTables = useMemo(() => combineStatistics(genreId, top, rank, visualsData, genreData), [top, rank, genreData, genreId, visualsData])

  return (
    <Box className="GamePageVisuals">
      {!displayVisuals ? (
        <LockedFeature.Card lockedFeatureId={LockedFeatureId.GameVisuals} />
      ) : (
        <>
          <Grid container mt={4} mb={1} alignItems="center" justifyContent="space-between" wrap="wrap">
            <Grid item xs={4}>
              <Typography variant="h3">{t('visuals-explorer:overview_tab')}</Typography>
            </Grid>
            {genreData ? (
              <Grid item xs={8} textAlign="right">
                <Typography variant="body2" display="inline">
                  {t('visuals-explorer:game_visuals_filters_text')}
                </Typography>
                <FormControl size="small" variant="outlined" sx={{ verticalAlign: 'middle', ml: 1 }}>
                  <Select onChange={({ target }) => setGenreId(target.value)} value={genreId} autoWidth>
                    {genreData.map(
                      (data) =>
                        data && (
                          <MenuItem value={data.appGenre} key={data.appGenre}>
                            {AppStoreGenreMap[data.appGenre]}
                          </MenuItem>
                        )
                    )}
                  </Select>
                </FormControl>
                <FormControl size="small" variant="outlined" sx={{ verticalAlign: 'middle', ml: 1 }}>
                  <Select onChange={({ target }) => setTop(target.value as number)} value={top} autoWidth>
                    {[50, 100, 200, 500].map((value) => (
                      <MenuItem value={value} key={'top-select-' + value}>
                        {t('common:top_' + value)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl size="small" variant="outlined" sx={{ verticalAlign: 'middle', ml: 1 }}>
                  <Select onChange={({ target }) => setRank(target.value as RankType)} value={rank} autoWidth>
                    <MenuItem value="free">{t('common:sustained_download_rank')}</MenuItem>
                    <MenuItem value="grossing">{t('common:sustained_grossing_rank')}</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            ) : (
              visualsData &&
              visualsData[0] === null && (
                <Grid item xs={12}>
                  <Typography variant="subtitle2" mt={1}>
                    {t('common:no_data_available')}
                  </Typography>
                </Grid>
              )
            )}
          </Grid>
          <Card>
            {statsTables?.map((data, index) => (
              <GameVisualsTable data={data} key={`stats-${index}`} topCount={top} genreId={genreId} rankType={rank} />
            ))}
          </Card>
        </>
      )}
    </Box>
  )
}

export default GameVisuals
