import { MarketExplorerSegmentConfiguration } from '../../market-explorer/types/MarketExplorerSegmentConfiguration'
import { MarketExplorerSegmentsDTO } from '../../market-explorer/types/MarketExplorerSegmentsDTO'
import { MarketExplorerUserSegment } from '../../market-explorer/types/MarketExplorerUserSegment'

export enum UserSettingKeys {
  defaultConventionalSubgenresKey = 'userDefaultConventionalSubgenres',
  userMarket = 'userMarket',
  dailyDigest = 'daily_digest',
  newsfeedTypes = 'newsfeedTypes',
  followedGamesSettings = 'followedGamesSettings',
  lastSeenGameVersionsMap = 'lastSeenGameVersionsMap',
  analysisRequests = 'analysisRequests',
  notShowCreateNewGameIntro = 'notShowCreateNewGameIntro',
  recentlySearchedGameIds = 'recentlySearchedGameIds',
  compareGamesList = 'compareGamesList',
  marketExplorerDefaultSegment = 'marketExplorerDefaultSegment',
  legacyUserSegments = 'userSegments',
  userSegments = 'marketExplorerUserSegments',
  acceptedTerms = 'acceptedTerms',
  fullscreenMode = 'fullscreenMode',
  liveEventsTrackerSelectedGameIds = 'liveEventsTrackerSelectedGameIds',
  lastSeenNews = 'lastSeenNews',
  unreadReports = 'unreadReports',
}

export interface UserSettings {
  [UserSettingKeys.userMarket]?: { iso: string }
  [UserSettingKeys.dailyDigest]?: boolean
  [UserSettingKeys.newsfeedTypes]?: Array<string>
  [UserSettingKeys.defaultConventionalSubgenresKey]?: SubgenreMap
  [UserSettingKeys.followedGamesSettings]?: {
    games: Array<string>
    privateMode: boolean
  }
  [UserSettingKeys.lastSeenGameVersionsMap]?: {
    [key: string]: string
  }
  [UserSettingKeys.analysisRequests]?: {
    [appId: number]: string | boolean
  }
  [UserSettingKeys.notShowCreateNewGameIntro]?: boolean | undefined | string
  [UserSettingKeys.recentlySearchedGameIds]?: string[]
  [UserSettingKeys.compareGamesList]?: {
    [gameId: string]: string[]
  }
  [UserSettingKeys.marketExplorerDefaultSegment]?: MarketExplorerSegmentConfiguration
  [UserSettingKeys.legacyUserSegments]?: MarketExplorerSegmentsDTO[]
  [UserSettingKeys.userSegments]?: MarketExplorerUserSegment[]
  [UserSettingKeys.fullscreenMode]?: string | boolean

  [key: string]: any
  [UserSettingKeys.acceptedTerms]?: boolean
  [UserSettingKeys.liveEventsTrackerSelectedGameIds]?: string[]
  [UserSettingKeys.lastSeenNews]?: number
}

export interface SubgenreMap {
  [key: string]: boolean
}
