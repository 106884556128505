import { t } from 'i18next'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { CloseRounded, ErrorOutline } from '@mui/icons-material'
import { Box, Button, Dialog, DialogContent, DialogTitle, Divider, Grid, IconButton, TableContainer, Typography } from '@mui/material'

import { useGetExplorerFeaturesForConventionalSubgenresQuery, useGetFeatureEffectsQuery } from '../../../../api/core'
import GRDotRating from '../../../../components/GRDotRating/GRDotRating'
import { GRTable, GRTableColumn, SortOrder } from '../../../../components/GRTable/GRTable'
import { GRTooltip } from '../../../../components/GRTooltip/GRTooltip'
import NotAvailableIcon from '../../../../components/NotAvailableIcon/NotAvailableIcon'
import analyticsService from '../../../../services/AnalyticsService'
import languageService from '../../../../services/LanguageService'
import motivationService from '../../../../services/MotivationService'
import { AnalyticsProviders } from '../../../../types/IAnalyticsProvider'
import FeatureChoiceSimple from '../../../feature/components/FeatureChoiceSimple/FeatureChoiceSimple'
import { FeatureLink } from '../../../feature/components/FeatureLink'
import { TopFeature } from '../../../feature/types/top-feature'
import { Game, GameIcon } from '../../../game'
import { GameAndAnalysis } from '../../../game/types/GameAndAnalysis'
import { MotivationStatsWithGameScore } from '../../../market-explorer/types/MarketExplorerSegmentData'
import { MotivationType } from '../../../market-explorer/types/MotivationType'
import { useCurrentMarket } from '../../../markets'
import useGetSegmentMotivationsType from '../../hooks/useGetSegmentMotivationsType'
import { isWesternMarket } from '../../hooks/useMarketMotivationsValidation'
import { MotivationIcon } from '../../utils/svgs'
import { motivationParentTranslationKey } from '../MotivationArcheTypeCard/MotivationArcheTypeCard'
import MotivationTableIconWrapper from '../MotivationTableIconWrapper/MotivationTableIconWrapper'
import './MotivationTable.scss'

// todo rename this later
export type InputDataRow = {
  [key in MotivationType]: MotivationDataRow
}

export type MotivationDataRow = {
  [key: string]: number
}

export type MotivationTableRow = {
  motivationName: string
  motivationUrl?: string
  dataRow: MotivationDataRow
  motivationType: MotivationType
  motivationDescription?: string
}

type FeatureEffectTableRow = {
  key: string
  segmentEffectScore: number
  feature: TopFeature
  games: GameAndAnalysis[]
}

type featureEffect = {
  [key: string]: FeatureEffectTableRow[]
}

/**
 * MotivationTable show motivation table
 */
export interface MotivationTableProps {
  motivationProfileVersions?: string[]
  motivationStats?: MotivationStatsWithGameScore[]
  gameLists?: Game[]
  gamesAndAnalysis?: GameAndAnalysis[]
  dataColumns?: GRTableColumn<MotivationTableRow, MotivationTableRow>[]
  dataRow?: InputDataRow
  sortBy?: string
  sortByOrder?: SortOrder
  gameNotAnalyzed?: boolean
  showDescriptionColumn?: boolean
  selectedVersion?: string
  gamePowerScore?: number
  gamesWithMarketIso?: { [key: string]: string[] }
}
const MotivationTable: React.FC<MotivationTableProps> = ({
  motivationProfileVersions,
  motivationStats,
  gameLists,
  gamesAndAnalysis,
  dataColumns,
  dataRow,
  sortBy,
  sortByOrder = SortOrder.DESC,
  gameNotAnalyzed,
  showDescriptionColumn,
  selectedVersion,
  gamePowerScore,
  gamesWithMarketIso,
}) => {
  const { currentMarketIso: marketIso } = useCurrentMarket()
  const containerRef = useRef(null)

  const { data: featureEffects } = useGetFeatureEffectsQuery()
  const { data: topFeatures } = useGetExplorerFeaturesForConventionalSubgenresQuery({ marketIso })
  const { segmentMotivationsType } = useGetSegmentMotivationsType()

  // if selectedVersion is last version on the motivationProfileVersions, then it is the first version
  const isFirstVersion = selectedVersion === motivationProfileVersions?.[motivationProfileVersions.length - 1]

  const [selectedMotivation, setSelectedMotivation] = useState<MotivationType>()

  const handleCloseFeatureEffectsModal = () => {
    setSelectedMotivation(undefined)
  }

  const handleShowFeatureEffectsModal = (row: MotivationTableRow) => {
    setSelectedMotivation(row.motivationType)

    analyticsService.trackEvent('Motivations: See Feature Effects', {
      data: row,
      serviceToExclude: [AnalyticsProviders.hubspot, AnalyticsProviders.intercom],
    })
  }

  const normalizedEffectScore = (score: number): number => {
    if (score >= 0.5) {
      return 5
    } else if (score >= 0.35) {
      return 4
    } else if (score >= 0.2) {
      return 3
    } else if (score >= 0.15) {
      return 2
    } else {
      return 1
    }
  }

  const combinedFeatureEffects = useMemo<featureEffect>(() => {
    const combinedData: { [key: string]: any[] } = {}
    if (!topFeatures || !featureEffects) {
      return {}
    }

    Object.keys(segmentMotivationsType).forEach((segmentMotivation) => {
      if (!combinedData[segmentMotivation]) {
        combinedData[segmentMotivation] = []
      }

      topFeatures.forEach((topFeature) => {
        const segmentEffect =
          featureEffects[topFeature.featureLegacyId] &&
          featureEffects[topFeature.featureLegacyId][topFeature.choiceLegacyId] &&
          featureEffects[topFeature.featureLegacyId][topFeature.choiceLegacyId][segmentMotivation]
            ? featureEffects[topFeature.featureLegacyId][topFeature.choiceLegacyId][segmentMotivation]
            : 0

        if (segmentEffect !== 0) {
          const gamesIncludeFeature = gamesAndAnalysis
            ? gamesAndAnalysis.filter(({ analysis }) => {
                if (!analysis) {
                  return false
                }

                if (!analysis.answers[topFeature.categoryId] && !analysis.answers[topFeature.categoryId][topFeature.featureId]) {
                  return false
                }

                return analysis.answers[topFeature.categoryId][topFeature.featureId] === topFeature.choiceLegacyId
              })
            : []

          combinedData[segmentMotivation].push({
            key: topFeature.featureId + '-' + topFeature.featureLegacyId,
            segmentEffectScore: normalizedEffectScore(segmentEffect),
            feature: topFeature,
            games: gamesIncludeFeature,
          })
        }
      })
    })
    return combinedData
  }, [featureEffects, gamesAndAnalysis, segmentMotivationsType, topFeatures])

  const initialColumns: GRTableColumn<MotivationTableRow, MotivationTableRow>[] = useMemo(() => {
    const compareVersionColumn =
      !!gameLists && !!selectedVersion && !!gamePowerScore
        ? [
            {
              labelAccessor: ({ col }: { col: { sortOrder: boolean } }) => (
                <MotivationTableIconWrapper
                  game={gameLists[0]}
                  selected={!!col.sortOrder}
                  label={
                    isFirstVersion
                      ? `${t('motivations:importance')} (${t('motivations:first_version', { version: selectedVersion })})`
                      : `${t('motivations:importance')} (${selectedVersion})`
                  }
                />
              ),
              accessor: ({ row }: { row: MotivationTableRow }) => {
                const value = row.dataRow.compareGameVersion || 0
                return (
                  <Box>
                    <GRDotRating showZeroRating dotValue={value} maxDots={5} />
                    <Typography variant="subtitle2" sx={{ display: 'flex', justifyContent: 'center' }}>
                      {value?.toFixed(1)}
                    </Typography>
                  </Box>
                )
              },
              sortable: true,
              SortOrder: SortOrder.DESC,
              sortAccessor: ({ row }: { row: MotivationTableRow }) => row.dataRow.compareGameVersion,
              defaultSortOrder: SortOrder.DESC,
            },
          ]
        : []

    const gameColumns = !!gameLists
      ? gameLists.map((game) => ({
          labelAccessor: ({ col }: { col: { sortOrder: boolean } }) => (
            <MotivationTableIconWrapper
              game={game}
              selected={!!col.sortOrder}
              label={`${t('motivations:importance')} ${game.version ? ` (${t('motivations:current_version', { version: game.version })})` : ''}`}
            />
          ),
          accessor: ({ row }: { row: MotivationTableRow }) => {
            if (gameNotAnalyzed) {
              return (
                <Box>
                  <GRTooltip content={t('motivations:motivation_data_na')}>
                    <ErrorOutline sx={{ cursor: 'help' }} color="primary" />
                  </GRTooltip>
                </Box>
              )
            }

            const value = row.dataRow[game.id] || 0
            const displayColumn = game?.id && gamesWithMarketIso?.[game.id]?.[0] ? isWesternMarket(gamesWithMarketIso[game.id][0]) : true

            return value && displayColumn ? (
              <Box>
                <GRDotRating showZeroRating dotValue={value} maxDots={5} />
                <Typography variant="subtitle2" sx={{ display: 'flex', justifyContent: 'center' }}>
                  {value?.toFixed(1)}
                </Typography>
              </Box>
            ) : (
              <NotAvailableIcon />
            )
          },
          sortable: true,
          sortAnalyticsEvent: {
            eventName: 'Game Motivations Importance Clicked',
            eventData: {
              game,
            },
          },
          sortAccessor: ({ row }: { row: MotivationTableRow }) => row.dataRow[game.id],
          SortOrder: SortOrder.DESC,
          defaultSortOrder: SortOrder.DESC,
        }))
      : []

    const descriptionColumn = [
      {
        labelAccessor: t('common:description'),
        accessor: ({ row }: { row: MotivationTableRow }) => <Typography>{row.motivationDescription}</Typography>,
        headerCellProps: { sx: { minWidth: 300 } },
        cellProps: { align: 'left' },
        hidden: !showDescriptionColumn,
      },
    ]

    const columns = [
      {
        labelAccessor: () => <Box>{t('motivations:motivation')}</Box>,
        accessor: ({ row }) => (
          <Grid className="MotivationLabel" container>
            <Grid item xs={8} display="flex" alignItems="center">
              <MotivationIcon motivation={row.motivationType} />

              <Typography display="inline">{row.motivationName}</Typography>
              {!showDescriptionColumn && (
                <GRTooltip
                  content={
                    <>
                      <Typography variant="body1" gutterBottom>
                        {row.motivationName}
                      </Typography>
                      <Typography variant="body2">
                        {languageService.getTranslation(motivationParentTranslationKey, row.motivationType + '_description')}
                      </Typography>
                    </>
                  }
                >
                  <Typography color="primary" display="inline" ml={0.5} mr={1} sx={{ cursor: 'pointer' }}>
                    ?
                  </Typography>
                </GRTooltip>
              )}
            </Grid>

            <Grid item xs={4} display="flex" alignItems="center" justifyContent="flex-end">
              <Button size="small" variant="outlined" onClick={() => handleShowFeatureEffectsModal(row)}>
                {t('motivations:see_feature_effects')}
              </Button>
            </Grid>
          </Grid>
        ),
        sortable: true,
        sortAccessor: 'motivationName',
        defaultSortOrder: SortOrder.ASC,
        headerCellProps: { sx: { minWidth: 350, textAlign: 'center' } },
        cellProps: { align: 'left' },
      },
      ...descriptionColumn,
      ...compareVersionColumn,
      ...gameColumns,
    ] as GRTableColumn<MotivationTableRow, MotivationTableRow>[]

    if (dataColumns) {
      return columns.concat(dataColumns)
    }

    return columns
  }, [dataColumns, gameLists, gameNotAnalyzed, gamePowerScore, gamesWithMarketIso, isFirstVersion, selectedVersion, showDescriptionColumn])

  const rows = useMemo<MotivationTableRow[]>(() => {
    return Object.keys(segmentMotivationsType).map((typeKey): MotivationTableRow => {
      const motivationType = typeKey as MotivationType
      const motivationStat = motivationStats
        ? motivationStats
            .filter((stat, index) => !!gameLists && !!gameLists[index])
            .reduce<{ [key: string]: number }>((result, stat, index) => {
              if (!!gameLists && stat[motivationType]) {
                result[gameLists[index].id] = Math.round((stat[motivationType]?.game || 0) * 10) / 10
              }
              return result
            }, {})
        : {}

      return {
        motivationType,
        motivationName: languageService.getTranslation('motivations', motivationType),
        motivationDescription: languageService.getTranslation('motivations', `${motivationType}_description`),
        dataRow: dataRow ? { ...dataRow[motivationType], ...motivationStat } : motivationStat,
      }
    })
  }, [dataRow, gameLists, motivationStats, segmentMotivationsType])

  const sortedRows = useMemo(() => {
    if (!sortBy) return rows

    return rows.sort((a, b) => {
      if (sortByOrder === SortOrder.ASC) {
        return a.dataRow[sortBy] - b.dataRow[sortBy]
      }

      return b.dataRow[sortBy] - a.dataRow[sortBy]
    })
  }, [rows, sortBy, sortByOrder])

  const [columns, setColumns] = useState<GRTableColumn<MotivationTableRow, MotivationTableRow>[]>(initialColumns)
  const handleColumnsUpdate = useCallback((updatedColumns: GRTableColumn<MotivationTableRow, MotivationTableRow>[]) => {
    setColumns(updatedColumns)
  }, [])

  useEffect(() => {
    handleColumnsUpdate(initialColumns)
  }, [handleColumnsUpdate, initialColumns])

  return (
    <Box className="MotivationTable">
      <Divider />
      <TableContainer ref={containerRef}>
        <GRTable
          columns={columns}
          rows={sortedRows}
          scroller={containerRef}
          onColumnsUpdated={handleColumnsUpdate}
          rowIdKey="motivationName"
          hoverable
          striped
        />
      </TableContainer>
      {selectedMotivation && (
        <Dialog open={true} onClose={handleCloseFeatureEffectsModal} maxWidth="md" className="FeatureEffectModal">
          <DialogTitle>
            <Grid container justifyContent="space-between" alignItems="center">
              <Typography variant="h3">
                {t('motivations:motivation_feature_effects_modal_title') +
                  ': ' +
                  languageService.getTranslation(motivationParentTranslationKey, selectedMotivation)}
              </Typography>
              <IconButton className="closeButton" onClick={handleCloseFeatureEffectsModal}>
                <CloseRounded />
              </IconButton>
            </Grid>
          </DialogTitle>
          <DialogContent>
            <Box my={2} className="MotivationSelection">
              {motivationService
                .getMotivationTypes()
                .sort()
                .map((motivation) => (
                  <Button
                    key={motivation}
                    variant={selectedMotivation === motivation ? 'contained' : 'outlined'}
                    size="small"
                    onClick={() => setSelectedMotivation(motivation)}
                  >
                    {languageService.getTranslation('motivations', motivation).includes(' - ')
                      ? languageService.getTranslation('motivations', motivation).split(' - ')[1]
                      : languageService.getTranslation('motivations', motivation).split('：')[1]}
                  </Button>
                ))}
            </Box>
            <Divider />
            <Grid container my={2} direction="row" columnSpacing={2} alignItems="center">
              <Grid item>
                <MotivationIcon motivation={selectedMotivation} />
              </Grid>
              <Grid item xs={11}>
                <Typography display="inline">
                  <q>{languageService.getTranslation(motivationParentTranslationKey, selectedMotivation + '_description')}</q>
                </Typography>
              </Grid>
            </Grid>
            <Divider />
            <FeatureEffectTable data={combinedFeatureEffects[selectedMotivation]} />
          </DialogContent>
        </Dialog>
      )}
    </Box>
  )
}

/**
 * FeatureEffectTable show feature effect table
 */
interface FeatureEffectTableProps {
  data: FeatureEffectTableRow[]
  sortBy?: string
}
const FeatureEffectTable: React.FC<FeatureEffectTableProps> = ({ data, sortBy }) => {
  const containerRef = useRef(null)

  const dotTooltipText = (score: number): string => {
    switch (score) {
      case 1:
        return t('feature:impact_low')
      case 2:
      case 3:
        return t('feature:impact_moderate')
      case 4:
      case 5:
        return t('feature:impact_high')
      default:
        return ''
    }
  }

  const initialColumns: GRTableColumn<FeatureEffectTableRow, FeatureEffectTableRow>[] = useMemo(() => {
    return [
      {
        labelAccessor: t('motivations:genre_features_info'),
        accessor: ({ row }) => (
          <Box
            className="FeatureEffectTable_effectColumn"
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <FeatureLink
              feature={{
                featureLegacyId: row.feature.featureLegacyId,
                featureName: languageService.getTranslation('features', row.feature.featureLegacyId.toString()),
              }}
              forceShowFeatureInList
              choice={{ name: row.feature.choiceLabel, choiceLegacyId: row.feature.choiceLegacyId }}
            >
              <Typography sx={{ fontSize: '16px' }}>
                {languageService.getTranslation('features', row.feature.featureLegacyId.toString())}
                {row?.feature.originalChoiceLabel && (
                  <FeatureChoiceSimple choiceLocalized={row.feature.choiceLabel} originalChoice={row.feature.originalChoiceLabel}></FeatureChoiceSimple>
                )}
              </Typography>
            </FeatureLink>
            <Box>
              {row.games.map((game) => (
                <GRTooltip key={game.game.id} content={t('common:game_has_this_feature', { gameName: game.game.resolvedName })}>
                  <GameIcon gameName={game.game.resolvedName} src={game.game.icons || game.game.icon} size="tiny" />
                </GRTooltip>
              ))}
            </Box>
          </Box>
        ),
        headerCellProps: { sx: { minWidth: 350, textAlign: 'left' } },
      },
      {
        labelAccessor: <GRTooltip content={t('motivations:effect_on_the_motivation_tooltip')}>{t('motivations:effect_on_the_motivation')}</GRTooltip>,
        accessor: ({ row }) => (
          <GRTooltip content={t('motivations:effect_on_the_motivation') + ': ' + dotTooltipText(row.segmentEffectScore)}>
            <GRDotRating maxDots={5} dotValue={row.segmentEffectScore} />
          </GRTooltip>
        ),
        sortable: true,
        sortAccessor: ({ row }: { row: FeatureEffectTableRow }) => row.segmentEffectScore,
        defaultSortOrder: SortOrder.DESC,
      },
    ] as GRTableColumn<FeatureEffectTableRow, FeatureEffectTableRow>[]
  }, [])

  const [columns, setColumns] = useState<GRTableColumn<FeatureEffectTableRow, FeatureEffectTableRow>[]>(initialColumns)
  const handleColumnsUpdate = useCallback((updatedColumns: GRTableColumn<FeatureEffectTableRow, FeatureEffectTableRow>[]) => {
    setColumns(updatedColumns)
  }, [])

  const sortedData = useMemo(() => {
    return data.sort((a, b) => b.segmentEffectScore - a.segmentEffectScore)
  }, [data])

  return (
    <TableContainer ref={containerRef}>
      <GRTable
        defaultSortOrder={SortOrder.DESC}
        columns={columns}
        rows={sortedData}
        scroller={containerRef}
        onColumnsUpdated={handleColumnsUpdate}
        rowIdKey="key"
        hoverable
        striped
      />
    </TableContainer>
  )
}

export default MotivationTable
