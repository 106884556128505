import React from 'react'

import { ArrowDropDown, ArrowDropUp, ArrowLeft, ArrowRight } from '@mui/icons-material'

import './RankChange.scss'

interface Props {
  rankNow: number
  rankPreviously: number
}

const RankChange: React.FC<Props> = (props: Props) => {
  const maxDisplayedRank = 200
  const rankChange = props.rankPreviously - props.rankNow

  const getChangeArrow = () => {
    if (rankChange === 0) {
      return (
        <>
          <ArrowLeft />
          <ArrowRight />
        </>
      )
    } else if (rankChange > 0) {
      return <ArrowDropUp color="success" />
    } else {
      return <ArrowDropDown color="warning" />
    }
  }

  return (
    <div className="RankChange">
      {getChangeArrow()}{' '}
      {rankChange !== 0 && <span className="RankChange__previous">{props.rankPreviously > maxDisplayedRank ? '200+' : props.rankPreviously}</span>}
    </div>
  )
}

export default RankChange
