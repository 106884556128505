import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Close } from '@mui/icons-material'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Tab, Tabs, Typography } from '@mui/material'

import { useAppDispatch } from '../../../../hooks/storeHooks'
import analyticsService from '../../../../services/AnalyticsService'
import { SubgenreMap } from '../../../account'
import { Game } from '../../../game'
import { MarketSelector, useCurrentMarket } from '../../../markets'
import { displaySnackBar } from '../../../snackbar'
import { GameSearchDialogConfig, GameSearchDialogType } from '../../types/GameSearchDialogConfig'
import GameSearchDialogMyPortfolioTab from '../GameSearchDialogMyPortfolioTab/GameSearchDialogMyPortfolioTab'
import GameSearchDialogSearchTab from '../GameSearchDialogSearchTab/GameSearchDialogSearchTab'
import GameSearchDialogSoftLaunchTab from '../GameSearchDialogSoftLaunchTab/GameSearchDialogSoftLaunchTab'
import './GameSearchDialog.scss'

export interface GameWithMarketIso {
  [gameId: string]: string[]
}

interface Props {
  modalOpen: boolean
  gameSearchDialogConfig: GameSearchDialogConfig
  defaultSelectedGames?: Game[]
  onClose: (selectedGames?: Game[], gamesWithMarketIso?: GameWithMarketIso, reason?: string) => void
  onClearGameList?: () => void
  defaultGamesWithMarketIso?: GameWithMarketIso
  enableMarketSelector?: boolean
  defaultSelectedSubgenres?: SubgenreMap
  searchResultFilter?: (games: Game[]) => Game[]
  marketIso?: string
  cacheGamesOnClose?: boolean
  useRawResults?: boolean
  hideSetAsDefault?: boolean
  showTopGames?: boolean
  onlyGamesWithPowerscore?: boolean
  onlyGamesWithPowerscoreOrRank?: boolean
}

const defaultSubgenres = {}

const GameSearchDialog: React.FC<Props> = ({
  modalOpen,
  gameSearchDialogConfig,
  defaultSelectedGames,
  onClose,
  onClearGameList,
  enableMarketSelector,
  defaultGamesWithMarketIso,
  defaultSelectedSubgenres = defaultSubgenres,
  searchResultFilter,
  marketIso,
  cacheGamesOnClose,
  useRawResults,
  hideSetAsDefault,
  showTopGames,
  onlyGamesWithPowerscore,
  onlyGamesWithPowerscoreOrRank,
}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { currentMarketIso: mainMarketIso } = useCurrentMarket()
  const [selectedMarketIso, setSelectedMarketIso] = useState(marketIso || mainMarketIso)

  const [selectedGames, setSelectedGames] = useState<{ [gameId: string]: Game }>({})
  const [tempGameList, setTempGameList] = useState<{ [gameId: string]: Game }>({})
  const [selectedTab, setSelectedTab] = useState(0)
  const [gamesWithMarketIso, setGamesWithMarketIso] = useState<GameWithMarketIso>({})

  useEffect(() => {
    if (defaultSelectedGames) {
      setSelectedGames(
        defaultSelectedGames.reduce((map: { [gameId: string]: Game }, game) => {
          map[game.id] = game
          return map
        }, {})
      )
    } else {
      setSelectedGames({})
    }
  }, [defaultSelectedGames])

  useEffect(() => {
    setSelectedMarketIso(marketIso || mainMarketIso)
  }, [mainMarketIso, marketIso])

  const handleClose = useCallback(() => {
    if (onClose) {
      onClose(undefined, undefined, 'closeButtonClick')
    }

    if (defaultGamesWithMarketIso) {
      setGamesWithMarketIso(defaultGamesWithMarketIso)
    }

    if (!cacheGamesOnClose) {
      setSelectedGames({})
    }
    setTempGameList({})
  }, [cacheGamesOnClose, defaultGamesWithMarketIso, onClose])

  const handleCancel = useCallback(() => {
    onClose(undefined, undefined, 'cancel')
  }, [onClose])

  const dialogClose = useCallback(
    (event: {}, reason: string) => {
      // Handle cancel when clicking outside of dialog or pressing escape
      if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
        handleCancel()
      }
    },
    [handleCancel]
  )

  useEffect(() => {
    if (defaultGamesWithMarketIso) {
      setGamesWithMarketIso(defaultGamesWithMarketIso)
    }
  }, [defaultGamesWithMarketIso])

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue)
  }

  const onDoneSelectingGames = useCallback(() => {
    const uniqueSelectedGames = [...new Set(Object.values(selectedGames))]

    const newGamesAdded = Object.keys(gamesWithMarketIso)
      .map((key) => (tempGameList[key] ? tempGameList[key].resolvedName : undefined))
      .filter((name) => !!name)

    const message = (
      <Typography component="span">
        <Trans i18nKey={'select-game:selected_games'} />
        <ul style={{ paddingInlineStart: '20px' }}>
          {newGamesAdded.map((resolvedName, i) => (
            <li key={i}>{resolvedName}</li>
          ))}
        </ul>
      </Typography>
    )

    newGamesAdded.length && dispatch(displaySnackBar({ message, severity: 'success', open: true }))

    onClose(uniqueSelectedGames, gamesWithMarketIso)
    setSelectedGames({})
    setGamesWithMarketIso({})
  }, [selectedGames, gamesWithMarketIso, dispatch, onClose, tempGameList])

  const onClearSelectedGames = useCallback(() => {
    onClearGameList && onClearGameList()

    setSelectedGames({})
    setGamesWithMarketIso({})
    dispatch(displaySnackBar({ message: t('select-game:success_cleared_game_selection'), severity: 'success', open: true }))
  }, [dispatch, onClearGameList, t])

  const onGameDeselect = useCallback(
    (game: Game) => {
      if (gameSearchDialogConfig.type === GameSearchDialogType.GameBenchmark) {
        setSelectedGames({})
        setGamesWithMarketIso({})
        return
      }
    },
    [gameSearchDialogConfig.type]
  )

  const onGameSelect = useCallback(
    (game: Game) => {
      setTempGameList({ ...tempGameList, [game.id]: game })
      if (gameSearchDialogConfig.type === GameSearchDialogType.GameBenchmark) {
        onClose([game], { [game.id]: [selectedMarketIso] })
        return
      }

      if (gameSearchDialogConfig.type === GameSearchDialogType.GameSearch || gameSearchDialogConfig.type === GameSearchDialogType.GameFollow) {
        if (gameSearchDialogConfig.type === GameSearchDialogType.GameFollow) {
          analyticsService.trackEvent('Followed game', { data: { gameId: game.id, gameName: game.resolvedName } })
        }

        handleClose()
      } else if (gameSearchDialogConfig.type === GameSearchDialogType.GameSelector) {
        const newSelectedGames = Object.assign({}, selectedGames)
        if (!newSelectedGames[game.id]) {
          newSelectedGames[game.id] = game
        } else {
          delete newSelectedGames[game.id]
        }

        if (gameSearchDialogConfig.gameSelectorCounts?.max === 1) {
          onClose(Object.values(newSelectedGames), { [game.id]: [selectedMarketIso] })
          setSelectedGames({})
          setGamesWithMarketIso({})
        } else {
          setSelectedGames(newSelectedGames)
        }

        const newGamesWithMarketIso = { ...gamesWithMarketIso }

        if (!newGamesWithMarketIso[game.id]) {
          newGamesWithMarketIso[game.id] = [selectedMarketIso]
        } else if (newGamesWithMarketIso[game.id].includes(selectedMarketIso)) {
          // delete market iso if exist
          newGamesWithMarketIso[game.id] = newGamesWithMarketIso[game.id].filter((marketIso) => marketIso !== selectedMarketIso)
        } else {
          newGamesWithMarketIso[game.id] = [...new Set([...newGamesWithMarketIso[game.id], selectedMarketIso])]
        }
        if (!newGamesWithMarketIso[game.id].length) {
          delete newGamesWithMarketIso[game.id]
        }
        setGamesWithMarketIso(newGamesWithMarketIso)
      }
    },
    [
      tempGameList,
      gameSearchDialogConfig.type,
      gameSearchDialogConfig.gameSelectorCounts?.max,
      onClose,
      selectedMarketIso,
      handleClose,
      selectedGames,
      gamesWithMarketIso,
    ]
  )

  const getLocalizedTabName = (tab: string) => {
    switch (tab) {
      case 'Search':
        return t('common:search')

      case 'SoftLaunch':
        return t('sidebar:soft_launch')

      case 'MyPortfolio':
        return t('sidebar:my_portfolio')
    }
  }

  const dialogTitle = () => {
    switch (gameSearchDialogConfig.type) {
      case GameSearchDialogType.GameSearch:
        return t('common:advanced_search')
      default:
        return t('select-game:select_game')
    }
  }
  const onMarketSelectorChange = (marketIso: string) => {
    setSelectedMarketIso(marketIso)
  }

  const selectedGamesCount = useMemo(() => {
    if (!enableMarketSelector) return Object.keys(selectedGames).length
    return Object.values(gamesWithMarketIso).flat().length
  }, [enableMarketSelector, gamesWithMarketIso, selectedGames])

  return (
    <div>
      <Dialog className="GameSearchDialog" onClose={dialogClose} fullWidth={true} maxWidth="md" open={modalOpen}>
        <DialogTitle>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>{dialogTitle()}</Grid>
            <Grid
              item
              sx={{
                display: 'inline-flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              {enableMarketSelector && (
                <Box sx={{ mr: 2 }}>
                  <MarketSelector onChange={onMarketSelectorChange} value={selectedMarketIso} />
                </Box>
              )}
              <Box>
                <IconButton aria-label="close" onClick={handleClose} style={{ margin: '-10px' }}>
                  <Close />
                </IconButton>
              </Box>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent className="DialogContent" dividers>
          {gameSearchDialogConfig.tabs.length > 1 && (
            <Tabs className="GameSearchDialog__tabs" value={selectedTab} onChange={handleTabChange} variant="scrollable" scrollButtons="auto">
              {gameSearchDialogConfig.tabs.map((tabName, index) => (
                <Tab key={index} label={getLocalizedTabName(tabName)} />
              ))}
            </Tabs>
          )}
          <div className="GameSearchDialog__content">
            {gameSearchDialogConfig.tabs[selectedTab] === 'Search' && (
              <GameSearchDialogSearchTab
                marketIso={selectedMarketIso}
                selectedGames={selectedGames}
                selectedSubgenres={defaultSelectedSubgenres}
                gameSearchDialogConfig={gameSearchDialogConfig}
                onGameSelect={onGameSelect}
                onGameDeselect={onGameDeselect}
                enableMarketSelector={enableMarketSelector}
                gamesWithMarketIso={gamesWithMarketIso}
                selectedGamesCount={selectedGamesCount}
                searchResultFilter={searchResultFilter}
                useRawResults={useRawResults}
                hideSetAsDefault={hideSetAsDefault}
                showTopGames={showTopGames}
                onClose={dialogClose}
                onlyGamesWithPowerscore={onlyGamesWithPowerscore}
                onlyGamesWithPowerscoreOrRank={onlyGamesWithPowerscoreOrRank}
              />
            )}

            {gameSearchDialogConfig.tabs[selectedTab] === 'SoftLaunch' && (
              <GameSearchDialogSoftLaunchTab
                marketIso={selectedMarketIso}
                selectedGames={selectedGames}
                gameSearchDialogConfig={gameSearchDialogConfig}
                selectedGamesCount={selectedGamesCount}
                onGameSelect={onGameSelect}
                onGameDeselect={onGameDeselect}
              />
            )}

            {gameSearchDialogConfig.tabs[selectedTab] === 'MyPortfolio' && (
              <GameSearchDialogMyPortfolioTab
                marketIso={selectedMarketIso}
                selectedGames={selectedGames}
                gameSearchDialogConfig={gameSearchDialogConfig}
                onGameSelect={onGameSelect}
                onGameDeselect={onGameDeselect}
              />
            )}
          </div>
        </DialogContent>

        {/* Benchmark game */}
        {gameSearchDialogConfig.type === GameSearchDialogType.GameBenchmark && (
          <DialogActions>
            <Grid container spacing={2} justifyContent="space-between" alignItems="center">
              <Grid item xs={3}>
                <Button size="small" variant="contained" color="warning" disabled={selectedGamesCount === 0} onClick={onClearSelectedGames}>
                  {t('select-game:clear_selected_games')}
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        )}

        {/* GameSelector */}
        {gameSearchDialogConfig.type === GameSearchDialogType.GameSelector && (gameSearchDialogConfig.gameSelectorCounts?.max || 1) > 1 && (
          <DialogActions className="DialogActions">
            <Grid container spacing={2} justifyContent="space-between" alignItems="center">
              <Grid item xs={3}>
                <Button size="small" variant="contained" color="warning" disabled={selectedGamesCount === 0} onClick={onClearSelectedGames}>
                  {t('select-game:clear_selected_games')}
                </Button>
              </Grid>
              <Grid item xs={6} style={{ textAlign: 'center' }}>
                <Trans i18nKey="select-game:games_selected" values={{ count: selectedGamesCount, max: gameSearchDialogConfig.gameSelectorCounts?.max }}></Trans>
              </Grid>
              <Grid item xs={3} style={{ textAlign: 'right' }}>
                <Button
                  size="small"
                  variant="contained"
                  color="success"
                  disabled={selectedGamesCount < (gameSearchDialogConfig.gameSelectorCounts?.min || 1)}
                  onClick={onDoneSelectingGames}
                >
                  {t('common:done')}
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        )}
      </Dialog>
    </div>
  )
}

export default GameSearchDialog
