import { FC, memo } from 'react'
import { useTranslation } from 'react-i18next'

import { Features } from '../..'
import { LockedFeatureIndicator } from '../../../../components/LockedFeatureIndicator/LockedFeatureIndicator'
import { NewsEntryFeature, NewsEntryScreenshotFeature } from '../../../news/types/UnifiedNewsEntry'
import { FeaturesWithTitle } from '../FeaturesWithTitle/FeaturesWithTitle'

/**
 * Wrapper for all features related to an update
 */
type FeaturesContainerProps = {
  changedFeatures?: NewsEntryFeature[]
  updatedFeatures?: NewsEntryScreenshotFeature[]
  isUnlocked?: boolean
}

export const FeaturesContainer: FC<FeaturesContainerProps> = memo(({ changedFeatures = [], updatedFeatures = [], isUnlocked }) => {
  const { t } = useTranslation()

  if (!isUnlocked) {
    return <LockedFeatureIndicator />
  }

  return (
    <>
      <FeaturesWithTitle title={t('common:feature_changes')} itemCount={changedFeatures.length}>
        <Features.Changed.List features={changedFeatures} variant="table" />
      </FeaturesWithTitle>
      <FeaturesWithTitle title={t('common:updated_features')} itemCount={updatedFeatures.length}>
        <Features.Updated.List features={updatedFeatures} variant="table" />
      </FeaturesWithTitle>
    </>
  )
})
