import { addWeeks, getISOWeek, getMonth, startOfDay, startOfWeek } from 'date-fns'

import { useGetActiveUsersQuery } from '../../../api/core'
import { average } from '../../../helpers/average'
import { getTimestampByGranularity } from '../../revenue-and-downloads/helpers/helpers'
import { GranularityValue } from '../../revenue-and-downloads/types/Filters'
import { MonthlyActiveUser } from '../types/ActiveUsersData'

export const usePerformanceChartActiveUserData = ({ appId, granularity }: { appId: number; granularity: GranularityValue }) => {
  const { data: activeUsers, error, isLoading } = useGetActiveUsersQuery({ appId })

  const dauGroupedByGranularity = activeUsers?.dailyActiveUsers?.reduce((acc, dau) => {
    const granularityParsedDate = new Date(dau.date)
    const parsedDate = getTimestampByGranularity(granularity, granularityParsedDate).getTime()
    const dateFormattedByGranularity = parsedDate
    const current = acc[dateFormattedByGranularity]

    if (!current) {
      acc[dateFormattedByGranularity] = [{ ...dau, date: dateFormattedByGranularity }]
    } else {
      acc[dateFormattedByGranularity] = [...current, { ...dau, date: dateFormattedByGranularity }]
    }

    return acc
  }, {} as { [granularityGroup: number]: { activeUsers: number; date: number }[] })

  const dauAverages = Object.entries(dauGroupedByGranularity || {}).map(([date, dau]) => {
    const averageValue = average(dau.map((dau) => dau.activeUsers))
    return { activeUsers: averageValue, date: +date }
  })

  const monthlyActiveUsers =
    activeUsers?.monthlyActiveUsers?.map((mau) => ({
      activeUsers: mau.activeUsers,
      date: getMauDate(mau, granularity).getTime(),
    })) || []

  const sortedDauAverages = dauAverages.sort((a, b) => a.date - b.date)
  const sortedMonthlyActiveUsers = monthlyActiveUsers.sort((a, b) => a.date - b.date)

  return {
    dailyActiveUsers: sortedDauAverages,
    monthlyActiveUsers: sortedMonthlyActiveUsers,
    error,
    isLoading,
  }
}

const getMauDate = (mau: MonthlyActiveUser, granularity: GranularityValue) => {
  const mauDate = startOfDay(new Date(mau.year, mau.month - 1))
  // If the granularity is week and the month is January and the week is greater than 1, we need to add a week to push the value to the year's first week
  if (granularity === GranularityValue.Week) {
    if (getMonth(mauDate) === 0 && getISOWeek(mauDate) > 1) {
      return startOfWeek(addWeeks(mauDate, 1))
    } else {
      return startOfWeek(mauDate)
    }
  }

  return mauDate
}
