import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Grid, GridProps } from '@mui/material'

import { LockedData } from '../LockedData/LockedData'

/**
 * Component for a simple text indicator telling that a feature is locked
 */

type LockedFeatureIndicatorProps = GridProps & {}

export const LockedFeatureIndicator: FC<LockedFeatureIndicatorProps> = ({ ...gridProps }) => {
  const { t } = useTranslation()

  return (
    <Grid container alignItems="center" columnGap={1} {...gridProps}>
      <Grid item>
        <LockedData />
      </Grid>
      <Grid item>{t('feature:upgrade_your_plan_to_unlock_this_feature')}</Grid>
    </Grid>
  )
}
