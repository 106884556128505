import { t } from 'i18next'
import React, { useState } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'

import { Box, Card, CardActions, CardMedia, Grid, Typography } from '@mui/material'

import { FtueVideo } from '../../../../api/core'
import GRCircularProgress from '../../../../components/GRCircularProgress/GRCircularProgress'
import { formatDate } from '../../../../helpers/date'
import { useCurrentUserLanguage } from '../../../account/hooks/userHooks'
import { GameIcon } from '../../../game'
import { useGames } from '../../../game/hooks/gameHooks'
import FtueVideoFullscreenModal from '../FtueVideoFullscreenModal/FtueVideoFullscreenModal'
import { SelectedChapterTag } from '../GameChapterList/GameChapterList'
import './FtueVideoStaticPreviewCard.scss'

/**
 * FtueVideoStaticPreviewCard display video preview card as static image
 */
interface FtueVideoStaticPreviewCardProps {
  isLoading?: boolean
  video?: FtueVideo
}
const FtueVideoStaticPreviewCard: React.FC<FtueVideoStaticPreviewCardProps> = ({ isLoading, video }) => {
  const userLanguage = useCurrentUserLanguage()
  const [openFTUEVideo, setOpenFTUEVideo] = useState(false)
  const {
    games: { 0: game },
    isLoading: isGameLoading,
  } = useGames(video?.gameId ? [video?.gameId] : [], video?.marketIso || '', false, true)
  const [selectedChapterTag, setSelectedChapterTag] = useState<SelectedChapterTag>({ chapterId: 0, tagId: '' })

  const thumbnail = video?.thumbnails[3] // 720p resolution
  const gameVersion = video?.gameVersion
  const versionReleaseDate = video?.gameVersionReleaseDate

  const loading = isLoading || isGameLoading

  if (!video) {
    return null
  }

  return (
    <Box className="FtueVideoStaticPreviewCard">
      <Card
        className="FtueVideoStaticPreviewCard__cardContainer"
        onClick={() => {
          if (!loading) {
            setOpenFTUEVideo(true)
          }
        }}
      >
        <CardMedia>
          {loading ? <GRCircularProgress /> : <LazyLoadImage className="FtueVideoStaticPreviewCard__lazyImage" src={thumbnail?.linkWithPlayButton} alt="" />}
        </CardMedia>
        <CardActions disableSpacing>
          <Grid container spacing={2}>
            <Grid item xs={2} mr={2}>
              <GameIcon gameName={game?.resolvedName || ''} size="normal" src={game?.icons[0]} />
            </Grid>
            <Grid item xs={9}>
              <Typography variant="h3" color="primary">
                {game?.resolvedName}
              </Typography>
              <Typography variant="body1" className="artist" sx={{ fontSize: '12px' }}>
                {game?.artist}
              </Typography>
              <Typography variant="body2" className="version">
                {gameVersion?.length && `${t('common:version')} ${gameVersion}`}
                {!!versionReleaseDate && ` - ${formatDate(versionReleaseDate, { language: userLanguage })}`}
              </Typography>
            </Grid>
          </Grid>
        </CardActions>
      </Card>
      {openFTUEVideo && !!video && game && (
        <FtueVideoFullscreenModal
          game={game}
          position={0}
          video={video}
          onClose={() => {
            setOpenFTUEVideo(false)
          }}
          selectedChapterTag={selectedChapterTag}
          onChapterTagClick={setSelectedChapterTag}
        />
      )}
    </Box>
  )
}

export default FtueVideoStaticPreviewCard
