import { FC } from 'react'

import { Button } from '@mui/material'

import { HtmlContentParser } from '../../../../../../components/HtmlContentParser/HtmlContentParser'
import languageService from '../../../../../../services/LanguageService'
import { useCurrentUserLanguage } from '../../../../../account/hooks/userHooks'
import { NewsCardContentSectionProps } from '../NewsCardContentSections'
import './NewsCardContentEditorialSection.scss'

type NewsCardContentEditorialSectionProps = NewsCardContentSectionProps

export const NewsCardContentEditorialSection: FC<NewsCardContentEditorialSectionProps> = ({ newsEntry }) => {
  const language = useCurrentUserLanguage()
  const showActionButton =
    newsEntry.feedEntry.entryData.action && newsEntry.feedEntry.entryData.action.type && newsEntry.feedEntry.entryData.action.type !== 'none'

  return (
    <div className="NewsCardContentEditorialSection">
      <div className="NewsCardContentEditorialSection__editorial-content">
        {newsEntry.feedEntry.entryData.content && (
          <HtmlContentParser
            rawHtml={newsEntry.feedEntry.entryData.content[language] || newsEntry.feedEntry.entryData.content[languageService.defaultLanguage]}
          />
        )}
      </div>
      {showActionButton && (
        <Button
          variant="contained"
          color="primary"
          href={newsEntry.feedEntry.entryData.action.target}
          target={newsEntry.feedEntry.entryData.action.target.includes('saas') ? '_self' : '_blank'}
        >
          {newsEntry.feedEntry.entryData.action.label[language] || newsEntry.feedEntry.entryData.action.label[languageService.defaultLanguage]}
        </Button>
      )}
    </div>
  )
}
