import { formatDuration, intervalToDuration } from 'date-fns'
import { FC, useRef, useMemo, useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { Link, TableContainer } from '@mui/material'

import { GRTableColumn, SortOrder, GRTable } from '../../../../../components/GRTable/GRTable'
import { GRTooltip } from '../../../../../components/GRTooltip/GRTooltip'
import { LinearProgressBar } from '../../../../../components/LinearProgressBar/LinearProgressBar'
import { TrendIndicator } from '../../../../../components/TrendIndicator/TrendIndicator'
import { intervalToRoundedDuration } from '../../../../../helpers/date'
import languageService from '../../../../../services/LanguageService'
import { TableSortValues } from '../../../../../types/TableSortValues'
import { EventStatisticsDialogTab } from '../../../hooks/useEventStatisticsDialogTabs'
import { useLiveEventEventStatisticsModal } from '../../../hooks/useLiveEventEventStatisticsModal'
import { EventTypeStat } from '../../../types/LiveEventStatistics'
import { TrackedGame } from '../../../types/TrackedGame'
import { EventTagGroupIndicator } from '../../EventTagGroupIndicator/EventTagGroupIndicator'

type LiveEventStatisticsTableProps = {
  trackedGames: TrackedGame[]
  rows: EventTypeStat[]
  isLoading?: boolean
}

const durationFormat = ['years', 'months', 'days']

export const LiveEventStatisticsTable: FC<LiveEventStatisticsTableProps> = ({ trackedGames, rows, isLoading }) => {
  const { t } = useTranslation()
  const containerRef = useRef(null)
  const { showModal } = useLiveEventEventStatisticsModal()
  const customProps = useMemo(
    () => ({
      showModal,
      trackedGameIds: trackedGames.map((trackedGame) => trackedGame.game.id),
    }),
    [showModal, trackedGames]
  )

  const liveEventStatisticsColumns: GRTableColumn<EventTypeStat, typeof customProps>[] = useMemo(
    () => [
      {
        labelAccessor: '',
        columns: [
          {
            labelAccessor: t('live-events:event_statistics_table_column_event_type'),
            accessor: ({ row, customTableProps }) => (
              <>
                <EventTagGroupIndicator colorHex={row.colorHex} />
                <Link
                  underline="none"
                  sx={{ color: (theme) => theme.palette.text.primary, '&:hover': { color: (theme) => theme.palette.primary.main, cursor: 'pointer' } }}
                  onClick={() => customTableProps?.showModal({ trackedGameIds: customTableProps.trackedGameIds, eventTypeId: row.eventTypeId })}
                >
                  {languageService.getTranslation('tags', row.eventTypeId)}
                </Link>
              </>
            ),
            cellProps: { align: 'left' },
            headerCellProps: { sx: { minWidth: 120 } },
            sortable: true,
            sortAccessor: ({ row }) => languageService.getTranslation('tags', row.eventTypeId),
          },
        ],
      },
      {
        labelAccessor: t('live-events:event_statistics_table_column_popularity'),
        columns: [
          {
            labelAccessor: t('common:value_text'),
            accessor: ({ row }) => (
              <LinearProgressBar
                variant="determinate"
                color="success"
                value={row.popularity.value * 100}
                labelAfter={<TrendIndicator value={row.popularity.value} sign={false} />}
              />
            ),
            cellProps: { align: 'center' },
            headerCellProps: { sx: { minWidth: 120 } },
            sortable: true,
            sortOrder: SortOrder.DESC,
            sortAccessor: ({ row }) => row.popularity.value,
          },
          {
            labelAccessor: () => <GRTooltip content={t('live-events:event_statistics_change_tooltip')}>{t('common:change_text')}</GRTooltip>,
            accessor: ({ row }) => <TrendIndicator value={row.popularity.percentChange} direction allowInfinity />,
            cellProps: { align: 'center' },
            headerCellProps: { sx: { minWidth: 120 } },
            sortable: true,
            sortAccessor: ({ row }) => resolvePercentChangeSortValue(row.popularity.percentChange),
          },
        ],
      },
      {
        labelAccessor: t('live-events:event_statistics_table_column_revenue'),
        columns: [
          {
            labelAccessor: () => <GRTooltip content={t('live-events:event_statistics_revenue_value_tooltip')}>{t('common:value_text')}</GRTooltip>,
            accessor: ({ row, customTableProps }) => (
              <Link
                underline="none"
                sx={{ color: (theme) => theme.palette.text.primary, '&:hover': { color: (theme) => theme.palette.primary.main, cursor: 'pointer' } }}
                onClick={() =>
                  customTableProps?.showModal({
                    trackedGameIds: customTableProps.trackedGameIds,
                    eventTypeId: row.eventTypeId,
                    tab: EventStatisticsDialogTab.Performance,
                  })
                }
              >
                <TrendIndicator value={row.revenue.value} sign={false} allowInfinity />
              </Link>
            ),
            cellProps: { align: 'center' },
            headerCellProps: { sx: { minWidth: 120 } },
            sortable: true,
            sortAccessor: ({ row }) => row.revenue.value || 0,
          },
          {
            labelAccessor: () => <GRTooltip content={t('live-events:event_statistics_change_tooltip')}>{t('common:change_text')}</GRTooltip>,
            accessor: ({ row }) => <TrendIndicator value={row.revenue.percentChange} direction allowInfinity />,
            cellProps: { align: 'center' },
            headerCellProps: { sx: { minWidth: 120 } },
            sortable: true,
            sortAccessor: ({ row }) => resolvePercentChangeSortValue(parseFloat((row.revenue.percentChange * 100).toFixed(0)) / 100),
          },
        ],
      },
      {
        labelAccessor: t('live-events:event_statistics_table_column_duration_avg'),
        columns: [
          {
            labelAccessor: t('common:value_text'),
            accessor: ({ row, customTableProps }) => (
              <Link
                underline="none"
                sx={{ color: (theme) => theme.palette.text.primary, '&:hover': { color: (theme) => theme.palette.primary.main, cursor: 'pointer' } }}
                onClick={() =>
                  customTableProps?.showModal({
                    trackedGameIds: customTableProps.trackedGameIds,
                    eventTypeId: row.eventTypeId,
                    tab: EventStatisticsDialogTab.Durations,
                  })
                }
              >
                {formatDuration(intervalToRoundedDuration(row.duration.avg.value), { format: durationFormat })}
              </Link>
            ),
            cellProps: { align: 'center' },
            headerCellProps: { sx: { minWidth: 120 } },
            sortable: true,
            sortAccessor: ({ row }) => resolveIntervalSortValue(row, 'avg'),
          },
          {
            labelAccessor: () => <GRTooltip content={t('live-events:event_statistics_change_tooltip')}>{t('common:change_text')}</GRTooltip>,
            accessor: ({ row }) => <TrendIndicator value={row.duration.avg.percentChange} direction allowInfinity />,
            cellProps: { align: 'center' },
            headerCellProps: { sx: { minWidth: 120 } },
            sortable: true,
            sortAccessor: ({ row }) => resolvePercentChangeSortValue(row.duration.avg.percentChange),
          },
        ],
      },
      {
        labelAccessor: t('live-events:event_statistics_table_column_duration_max'),
        columns: [
          {
            labelAccessor: t('common:value_text'),
            accessor: ({ row, customTableProps }) => (
              <Link
                underline="none"
                sx={{ color: (theme) => theme.palette.text.primary, '&:hover': { color: (theme) => theme.palette.primary.main, cursor: 'pointer' } }}
                onClick={() =>
                  customTableProps?.showModal({
                    trackedGameIds: customTableProps.trackedGameIds,
                    eventTypeId: row.eventTypeId,
                    tab: EventStatisticsDialogTab.Durations,
                  })
                }
              >
                {formatDuration(intervalToDuration(row.duration.max.value), { format: durationFormat })}
              </Link>
            ),
            cellProps: { align: 'center' },
            headerCellProps: { sx: { minWidth: 120 } },
            sortable: true,
            sortAccessor: ({ row }) => resolveIntervalSortValue(row, 'max'),
          },
          {
            labelAccessor: () => <GRTooltip content={t('live-events:event_statistics_change_tooltip')}>{t('common:change_text')}</GRTooltip>,
            accessor: ({ row }) => <TrendIndicator value={row.duration.max.percentChange} direction allowInfinity />,
            cellProps: { align: 'center' },
            headerCellProps: { sx: { minWidth: 120 } },
            sortable: true,
            sortAccessor: ({ row }) => resolvePercentChangeSortValue(row.duration.max.percentChange),
          },
        ],
      },
    ],
    [t]
  )

  const [columns, setColumns] = useState<GRTableColumn<EventTypeStat, typeof customProps>[]>(liveEventStatisticsColumns)
  const handleColumnsUpdate = useCallback((updatedColumns: GRTableColumn<EventTypeStat, typeof customProps>[]) => {
    setColumns(updatedColumns)
  }, [])

  return (
    <TableContainer ref={containerRef}>
      <GRTable
        rows={rows}
        columns={columns}
        onColumnsUpdated={handleColumnsUpdate}
        rowIdKey="eventTypeId"
        scroller={containerRef}
        customProps={customProps}
        isLoading={isLoading}
        noRowsLabel={t('common:no_results_found')}
      ></GRTable>
    </TableContainer>
  )
}

const resolveIntervalSortValue = (eventStats: EventTypeStat, fieldName: keyof EventTypeStat['duration']) => {
  return +eventStats.duration[fieldName].value.end - +eventStats.duration[fieldName].value.start
}

const resolvePercentChangeSortValue = (value: number) => {
  return value === 0 ? TableSortValues.AlwaysLast : value
}
