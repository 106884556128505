import { useMemo } from 'react'
import { v4 as uuidv4 } from 'uuid'

import { DemographyGroup, Tag, useGetFeatureDemographicsAvgsQuery } from '../../../api/core'
import { SortOrder } from '../../../components/GRTable/GRTable'
import { intersection } from '../../../helpers/intersection'
import { isNil } from '../../../helpers/isNil'
import utilsService from '../../../services/UtilsService'
import { useRoleCheck } from '../../account/hooks/roleHooks'
import { RoleEnum } from '../../account/types/RoleEnum'
import { SortBy } from '../components/MarketExplorerFeaturesData/MarketExplorerFeaturesData'
import { SegmentQueryDataType } from '../components/MarketExplorerSegments/MarketExplorerSegment/MarketExplorerSegment'
import { AverageMotivationSegments, MarketExplorerSegmentDataFeature, MotivationData, Popularity } from '../types/MarketExplorerSegmentData'

export type MotivationRow = {
  motivation?: { [key: string]: number }
}

export type ArchetypeRow = {
  archetypes?: AverageMotivationSegments
}

export type FeatureDataRow = DemographyGroup &
  MotivationRow &
  ArchetypeRow &
  Popularity & {
    avgEffect: number
    categoryId: string
    choiceLegacyId: number
    choiceLabel: string
    compareValue: string | null
    difference20Percent: number
    difference50Percent: number
    featureId: string
    featureLabel?: string
    featureLegacyId: number
    firstSegmentIndex: number
    isDifferentiating?: boolean
    isDifferentiatingSegmentsFeature?: boolean
    marketIso: string
    originalChoiceLabel: string
    segmentGroup: number
    tags?: string[]
    featureSegmentKey: string
  }

/** Copied from old Client */
const isTopFeatureDifferentiating = ({ avgEffect, featureLegacyId, popularity }: MarketExplorerSegmentDataFeature) =>
  avgEffect > 0.3 &&
  popularity['20Percent'] / popularity.Overall > 1.1 &&
  popularity['50Percent'] / popularity.Overall > 1.1 &&
  popularity.Overall > 5 &&
  featureLegacyId !== 174

const extractRow = (feature: MarketExplorerSegmentDataFeature): Partial<FeatureDataRow> => {
  const { avgEffect, categoryId, choiceLabel, choiceLegacyId, featureId, featureLabel, featureLegacyId, originalChoiceLabel, popularity, tags } = feature
  return {
    avgEffect,
    categoryId,
    choiceLabel,
    choiceLegacyId,
    featureId,
    featureLabel,
    featureLegacyId,
    tags,
    originalChoiceLabel,
    isDifferentiating: isTopFeatureDifferentiating(feature),
    difference20Percent: popularity['20Percent'] - popularity['Overall'],
    difference50Percent: popularity['50Percent'] - popularity['Overall'],
    ...popularity,
  }
}

/**
 * Extract motivation related params (motivation, archetypes) from feature block,
 *
 * @param feature for segment
 * @param motivationData from API
 */
const extractMotivation = (feature: MarketExplorerSegmentDataFeature, motivationData?: MotivationData): MotivationRow & ArchetypeRow => {
  const motivationFeature = motivationData?.features?.find(
    ({ choiceLegacyId, featureLegacyId }) => choiceLegacyId === feature.choiceLegacyId && featureLegacyId === feature.featureLegacyId
  )
  return !motivationFeature
    ? {}
    : {
        motivation: motivationFeature.attributes,
        archetypes: motivationFeature.segments,
      }
}

type ComparableSegment = { [featureChoiceId: string]: Partial<FeatureDataRow> }

/**
 * Combine market data, segment data, demographics. Add a virtual comparison value, that combines
 * all the segment rows together. Note: if sorting order is not set (correctly) the virtual value
 * will fail and the segments won't stay grouped together. If there are any better ideas how to
 * implement the "grouping sorter", please do!
 *
 * @param sortBy order for comparator
 * @param segments to show
 */
export const useFeatureData = (sortBy: SortBy, segments?: SegmentQueryDataType[]) => {
  const motivationsEnabled = useRoleCheck(RoleEnum.motivations)

  const [sortSegment, sortOrder, sortColumn] = sortBy
  const markets = segments?.map((segment) => segment?.segmentConfiguration?.marketIso)
  const {
    data: demographicsData,
    isLoading: isDemographicsAvgsLoading,
    isFetching: isDemographicsAvgsFetching,
  } = useGetFeatureDemographicsAvgsQuery(markets as string[], { skip: !segments })
  const referenceSegment = segments ? segments[sortSegment] : null

  return useMemo(() => {
    // The 'main' segment which is selected as sorting source; Group features by choice id
    const comparableSegment = referenceSegment?.data?.features.reduce((acc, feature) => {
      return {
        ...acc,
        [feature.featureId + feature.choiceLegacyId]: {
          ...extractRow(feature),
          ...extractMotivation(feature, referenceSegment.data?.motivationData),
        },
      }
    }, {} as ComparableSegment)

    // group differentiating feature data by feature and choice
    const differentiatingFeatureGroups = segments
      ?.flatMap((segment) => segment?.data?.features || [])
      .reduce((acc, feature) => {
        const isDifferentiatingFeature = isTopFeatureDifferentiating(feature)
        const key = `${feature.featureLegacyId}_${feature.choiceLegacyId}`
        if (!acc[key]) {
          acc[key] = isDifferentiatingFeature
        } else {
          return acc
        }

        return acc
      }, {} as { [key: string]: boolean })

    const featureSegments: { [key: string]: number } = {}
    const features = segments
      ?.map(
        (segment, index) =>
          segment?.data?.features.map((feature) => {
            // record the first appearence of a feature in a segment, this is used to display feature grouping indicators in features data table
            featureSegments[`${feature.featureLegacyId}_${feature.choiceLegacyId}`] = isNil(
              featureSegments[`${feature.featureLegacyId}_${feature.choiceLegacyId}`]
            )
              ? index
              : featureSegments[`${feature.featureLegacyId}_${feature.choiceLegacyId}`]
            const market = demographicsData?.[segment.segmentConfiguration.marketIso]?.find(({ choiceLegacyId }) => choiceLegacyId === feature.choiceLegacyId)
            const rowValues = extractRow(feature)
            const motivation = motivationsEnabled ? extractMotivation(feature, segment?.data?.motivationData) : {}
            const demographics = utilsService.demographicsIsAvailableForMarket(segment.segmentConfiguration.marketIso) ? market?.demographics : {}
            const isDifferentiatingSegmentsFeature = differentiatingFeatureGroups?.[`${feature.featureLegacyId}_${feature.choiceLegacyId}`]

            // compareValue is structured to support sorting by segment and column - segment index is used to keep the segments in the same order by feature
            // compareValue also works as a row key for the table component so it has to be unique per row
            const compareData = comparableSegment ? comparableSegment[feature.featureId + feature.choiceLegacyId] : null
            const val = (
              compareData
                ? compareData[sortColumn] ||
                  compareData?.motivation?.[sortColumn] ||
                  compareData?.archetypes?.[sortColumn as keyof AverageMotivationSegments] ||
                  demographics?.[sortColumn as keyof DemographyGroup] ||
                  0
                : 0
            ) as number | string
            const compareValue =
              typeof val === 'number'
                ? val / 1000
                : val + '-' + feature.featureLegacyId + '_' + feature.choiceLegacyId + '_' + (sortOrder === SortOrder.DESC ? segments.length - index : index)

            return {
              uuid: uuidv4(),
              compareValue,
              segmentGroup: index + 1,
              isDifferentiatingSegmentsFeature,
              marketIso: segment.segmentConfiguration.marketIso,
              firstSegmentIndex: featureSegments[`${feature.featureLegacyId}_${feature.choiceLegacyId}`],
              featureSegmentKey: `${feature.featureLegacyId}_${feature.choiceLegacyId}`,
              ...rowValues,
              ...motivation,
              ...demographics,
            } as FeatureDataRow
          }) || []
      )
      .flat()

    const featureSegmentKeys = Object.keys(featureSegments)
    const sortedFeatures = features
      ?.sort((a, b) => featureSegmentKeys.indexOf(a.featureSegmentKey) - featureSegmentKeys.indexOf(b.featureSegmentKey))
      .filter((feature) => feature.marketIso !== undefined)

    return {
      data: sortedFeatures || [],
      isLoading: isDemographicsAvgsLoading || isDemographicsAvgsFetching,
    }
  }, [
    demographicsData,
    isDemographicsAvgsFetching,
    isDemographicsAvgsLoading,
    motivationsEnabled,
    referenceSegment?.data?.features,
    referenceSegment?.data?.motivationData,
    segments,
    sortColumn,
    sortOrder,
  ])
}

/**
 * Include all having at least one matching tag.
 */
export const useTagFilter = (tags: Tag[], onlyDifferentiating: boolean) =>
  useMemo(
    () =>
      (row: FeatureDataRow): boolean => {
        if (onlyDifferentiating && !row.isDifferentiatingSegmentsFeature) {
          return false
        }

        // if no tags are selected, show all
        if (!tags?.length) {
          return true
        }

        // if no tags are assigned to the feature, don't show it
        if (!row.tags?.length) {
          return false
        }

        const tagIds = tags.map(({ id }) => id)
        return intersection(tagIds, row.tags).length > 0
      },
    [onlyDifferentiating, tags]
  )

/**
 * Include all containing the keyword, case-insensitive.
 */
export const useKeywordFilter = (keyword?: string) =>
  useMemo(
    () =>
      (row: FeatureDataRow): boolean => {
        if (!keyword || keyword === '') {
          return true
        }
        return !!row.featureLabel?.toLowerCase().includes(keyword.toLowerCase())
      },
    [keyword]
  )
