import { useSelector } from 'react-redux'

import { useGetUserProfileQuery } from '../../../api/combined'
import { selectUserProfile } from '../selectors/selectors'
import { RoleEnum } from '../types/RoleEnum'
import { User } from '../types/User'

/**
 * Checks if current user has all given roles
 */
export const useRoleCheck = (roles: RoleEnum | RoleEnum[]) => {
  const { data: currentUser } = useGetUserProfileQuery()

  return hasAllRoles(currentUser, roles)
}

export const useMarketPermission = () => {
  const { data: currentUser } = useGetUserProfileQuery()

  return currentUser?.roles.filter((role) => role.slice(0, 7) === 'market_')
}

/**
 * Checks if current user has some (at least one) of given roles
 */
export const useSomeRolesCheck = (roles: RoleEnum | RoleEnum[]) => {
  const { data: currentUser } = useGetUserProfileQuery()
  if (Array.isArray(roles)) {
    return roles.reduce((acc, role) => {
      return acc || currentUser?.roles.includes(role) || false
    }, false)
  } else {
    return currentUser?.roles.includes(roles) || false
  }
}

/**
 * Checks if current user has all given roles using selectors
 */
export const useRoleCheckSelector = (roles: RoleEnum | RoleEnum[]) => {
  const currentUser = useSelector(selectUserProfile)

  return hasAllRoles(currentUser, roles)
}

/**
 * Checks if user has all given roles
 */
const hasAllRoles = (currentUser: User | undefined, roles: RoleEnum | RoleEnum[]) => {
  if (Array.isArray(roles)) {
    return roles.reduce((acc, role) => {
      return acc && (currentUser?.roles.includes(role) || false)
    }, true)
  } else {
    return currentUser?.roles.includes(roles) || false
  }
}

export const useGameUpdateImpactsAccessCheck = () => {
  return useRoleCheck(RoleEnum.game_update_impacts)
}

export const useAnalystReviewsAndCommentsAccessCheck = () => {
  return useRoleCheck(RoleEnum.analyst_reviews_and_comments)
}

export const useSubgenresAccessCheck = () => {
  return !useRoleCheck(RoleEnum.hide_subgenres)
}

export const useMarketExplorerAccessCheck = () => {
  return useRoleCheck(RoleEnum.market_explorer)
}

export const useAnalyzerUnlockedCheck = () => {
  const isGameAnalyzerUnlimited = useRoleCheck(RoleEnum.game_analyzer_unlimited)
  const isGameAnalyzerFree = useRoleCheck(RoleEnum.game_analyzer_free)
  return isGameAnalyzerFree || isGameAnalyzerUnlimited
}

export const useAnalyzerFreeCheck = () => {
  return useRoleCheck(RoleEnum.game_analyzer_free)
}

export const useNewsFeedAccessCheck = () => {
  return useSomeRolesCheck([RoleEnum.news_feed, RoleEnum.news_feed_limited])
}

export const useNewsFeedLimitedAccessCheck = () => {
  return useRoleCheck(RoleEnum.news_feed_limited)
}

export const useImplementationExamplesAccessCheck = () => {
  return useRoleCheck(RoleEnum.feature_implementation_examples_unlimited)
}

export const useMarketTrendsAccessCheck = () => {
  return useRoleCheck(RoleEnum.features_trending)
}

export const useMotivationsAccessCheck = () => {
  return useRoleCheck(RoleEnum.motivations)
}

export const useCompareGamesMotivationsAccessCheck = () => {
  return useRoleCheck(RoleEnum.game_comparison_motivations)
}

export const useCompareGamesAccessCheck = () => {
  return useRoleCheck(RoleEnum.game_comparison_multiple)
}

export const useFTUEVideosAccessCheck = () => {
  return useRoleCheck(RoleEnum.ftue_videos)
}

export const useAllGamesUnlockedCheck = () => {
  return useRoleCheck(RoleEnum.all_games_unlocked)
}

export const useAnalystReviewsAndCommentsCheck = () => {
  return useRoleCheck(RoleEnum.analyst_reviews_and_comments)
}

export const useSoftLaunchAccessCheck = () => {
  return useRoleCheck(RoleEnum.soft_launch)
}

export const useAskAnalystAccessCheck = () => {
  return useRoleCheck(RoleEnum.ask_analyst)
}

export const useLiveEventsTrackerAccessCheck = () => {
  return useRoleCheck(RoleEnum.live_events_tracker)
}

export const useDemographicsDataAccessCheck = () => {
  return useRoleCheck(RoleEnum.demographics)
}

export const useAIAnalystAccessCheck = () => {
  return useRoleCheck(RoleEnum.ai_analyst)
}

export const useGameSlotsUnlimitedAccessCheck = () => {
  return useRoleCheck(RoleEnum.game_slots_unlimited)
}

export const useUserIsAdminCheck = () => {
  return useSomeRolesCheck([RoleEnum.admin, RoleEnum.oadmin])
}

export const useUserIsOrganizationAdminCheck = () => {
  return useRoleCheck(RoleEnum.oadmin)
}

export const useMarketShareAceessCheck = () => {
  return useRoleCheck(RoleEnum.market_share)
}

export const useFeatureTagsAccessCheck = () => {
  return useRoleCheck(RoleEnum.feature_tags)
}
