import { FC, memo, useCallback, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { BookType } from 'xlsx'

import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import { Box, Card, Fab, TableContainer, Tooltip, Typography } from '@mui/material'

import { AnalystCommentDialog } from '../../../../components/AnalystCommentDialog/AnalystCommentDialog'
import { ExportDataButton } from '../../../../components/ExportDataButton/ExportDataButton'
import { ColumnHeaderLabelWithFilter } from '../../../../components/GRTable/ColumnHeaderLabelWithFilter/ColumnHeaderLabelWithFilter'
import { GRTable, GRTableColumn, SortOrder } from '../../../../components/GRTable/GRTable'
import { ReviewIndicator } from '../../../../components/ReviewIndicator/ReviewIndicator'
import { ValueType } from '../../../../components/ValueIndicator/ValueIndicator'
import { isNil } from '../../../../helpers/isNil'
import analyticsService from '../../../../services/AnalyticsService'
import utilsService from '../../../../services/UtilsService'
import { TableSortValues } from '../../../../types/TableSortValues'
import { RoleEnum } from '../../../account/types/RoleEnum'
import { GameCardContent } from '../../../game/components/GameCard/GameCard'
import { UpdateImpactGame } from '../../../game/types/Game'
import { useUpdateImpactFilters } from '../../hooks/useUpdateImpactFilters'
import { UpdateImpactAggregates } from '../../services/UpdateImpactAggregates'
import { AggregateValueColumnFilterName, ColumnFilterValue, EMPTY_AGGREGATE_VALUE, FeaturesFilterValue } from '../../types/ColumnFilter'
import { TableRowUpdateImpact } from '../../types/TableRowUpdateImpact'
import { UpdateImpactRowFilters } from '../../types/UpdateImpactRowFilters'
import { AggregateValue } from '../AggregateValue/AggregateValue'
import { AggregateValueColumnHeader } from '../AggregateValueColumnHeader/AggregateValueColumnHeader'
import { ContentIndicators } from '../ContentIndicators/ContentIndicators'
import { FeaturesColumnFilter } from '../FeaturesColumnFilter/FeaturesColumnFilter'
import { FeaturesContainer } from '../FeaturesContainer/FeaturesContainer'
import { GameUpdatePowerscore } from '../GameUpdatePowerscore/GameUpdatePowerscore'
import { VersionInfo } from '../VersionInfo/VersionInfo'

/**
 * Component that describes the content of the update impacts table
 */
type UpdateImpactsTableProps = {
  rows: TableRowUpdateImpact[]
  filters: UpdateImpactRowFilters
  onColumnFilterChange?: (value: ColumnFilterValue) => void
  isLoading?: boolean
  tableConfig?: UpdateImpactsTableConfig
  onOpenUpdateClick?: (value: TableRowUpdateImpact) => void
  onDataExport?: (data: BookType) => void
  maxRows?: number
}

type UpdateImpactsTableConfig = {
  showGameColumn?: boolean
  showVersionTags?: boolean
  showPowerscore?: boolean
  showExportButton?: boolean
  showFeaturesColumn?: boolean
  showOpenUpdateColumn?: boolean
  showAnalystCommentIndicator?: boolean
  globalLaunchDate?: number
  powerscoreUnlocked?: boolean
  impactDataUnlocked?: boolean
  analystReviewsAndCommentsUnlocked?: boolean
  showSortingIcon?: boolean
}

const maxTrendValue = 10

export const UpdateImpactsTable: FC<UpdateImpactsTableProps> = memo(
  ({ rows, filters, onColumnFilterChange, isLoading, tableConfig, onOpenUpdateClick, maxRows, onDataExport }) => {
    const { t } = useTranslation()
    const containerRef = useRef(null)
    const [analystCommentDialogRow, setAnalystCommentDialogRow] = useState<TableRowUpdateImpact | null>(null)
    const navigate = useNavigate()
    const handleShowUpdate = useCallback(
      (value: TableRowUpdateImpact) => {
        if (onOpenUpdateClick) {
          analyticsService.trackEvent('Update Impacts: Show Update', {
            data: {
              gameName: value.gameName,
              gameId: value.id,
              version: value.version,
            },
          })
          onOpenUpdateClick(value)
        }
      },
      [onOpenUpdateClick]
    )

    const handleLabelClick = (typeId: string) => {
      analyticsService.trackEvent('Update Impacts: Sorted List', { data: { typeId } })
    }

    const checkSortAccessor = useCallback(
      (row: TableRowUpdateImpact, sortOrder?: SortOrder) => {
        const parsedComparisonInterval = filters.comparisonInterval ? parseInt(filters.comparisonInterval) : 0
        const isTooFresh = row.daysSinceReleased < parsedComparisonInterval
        if (row.isTooOld || row.isFirstVersion || isTooFresh) {
          return sortOrder === 'asc' ? Number.MAX_SAFE_INTEGER : Number.MIN_SAFE_INTEGER
        }
      },
      [filters]
    )

    const initialColumns: GRTableColumn<TableRowUpdateImpact, typeof customProps>[] = useMemo(() => {
      return [
        {
          labelAccessor: '',
          columns: [
            {
              labelAccessor: (
                <Box
                  onClick={() => {
                    handleLabelClick('date')
                  }}
                >
                  {t('common:version_date')}
                </Box>
              ),
              accessor: ({ row, customTableProps }) => (
                <VersionInfo
                  releaseDate={row.releaseDate}
                  globalLaunchDate={customTableProps?.tableConfig?.globalLaunchDate || row.globalLaunchDate}
                  version={row.version}
                  versionTags={customTableProps?.showVersionTags ? row.versionTags : []}
                />
              ),
              sortable: tableConfig?.showSortingIcon === undefined ? true : tableConfig?.showSortingIcon,
              sortOrder: SortOrder.DESC,
              sortAccessor: 'releaseDate',
              headerCellProps: { sx: { minWidth: 120 } },
            },
            {
              labelAccessor: (
                <Box
                  onClick={() => {
                    handleLabelClick('name')
                  }}
                >
                  {t('common:game')}
                </Box>
              ),
              accessor: ({ row }) => (
                <Box onClick={() => navigate(`/game/${row.gameId}/updatehistory`)}>
                  <GameCardContent
                    game={
                      new UpdateImpactGame({
                        resolvedName: row.gameName,
                        artist: row.gameArtist,
                        conventionalSubgenre: row.conventionalSubgenre,
                        icons: row.icons,
                        id: row.gameId,
                      })
                    }
                    variant="table"
                  />
                </Box>
              ),
              sortable: tableConfig?.showSortingIcon === undefined ? true : tableConfig?.showSortingIcon,
              sortAccessor: 'gameName',
              cellProps: { align: 'left' },
              headerCellProps: { sx: { minWidth: 210 } },
              hidden: ({ customTableProps }) => !customTableProps?.tableConfig?.showGameColumn,
            },

            {
              labelAccessor: '',
              accessor: ({ row, customTableProps }) => customTableProps?.tableConfig?.analystReviewsAndCommentsUnlocked && <ContentIndicators impact={row} />,
              headerCellProps: { sx: { minWidth: 50 } },
              hidden: ({ customTableProps }) => !customTableProps?.impactDataUnlocked,
            },
            {
              labelAccessor: t('common:game_power_score'),
              accessor: ({ row, customTableProps }) => (
                <GameUpdatePowerscore
                  value={row.gps}
                  valueChange={row.gpsChange}
                  isUnlocked={customTableProps?.tableConfig?.powerscoreUnlocked && customTableProps?.impactDataUnlocked}
                />
              ),
              sortable: tableConfig?.showSortingIcon === undefined ? true : tableConfig?.showSortingIcon,
              sortAccessor: [
                ({ row, col: { sortOrder } }) => {
                  if (row.gps === undefined && sortOrder === SortOrder.DESC) return TableSortValues.AlwaysLast
                  return row.gps
                },
              ],
              headerCellProps: { sx: { minWidth: 100 } },
              hidden: ({ customTableProps }) => !customTableProps?.tableConfig?.showPowerscore,
            },
            {
              labelAccessor: '',
              accessor: ({ row }) =>
                row.commentId && <ReviewIndicator tooltip={t('reviews:tooltip_open_analyst_comment')} onClick={() => setAnalystCommentDialogRow(row)} />,
              headerCellProps: { sx: { minWidth: 50 } },
              hidden: ({ customTableProps }) => !customTableProps?.tableConfig?.showAnalystCommentIndicator,
            },
          ],
        },

        {
          labelAccessor: '',
          columns: [
            {
              labelAccessor: ({ col, handleSort, customTableProps }) => {
                if (customTableProps?.tableConfig?.showSortingIcon === false) return t('common:revenue_text')
                return <RevenueLabel column={col} onSort={handleSort} customTableProps={customTableProps} />
              },
              accessor: ({ row, customTableProps }) => {
                const aggregates = new UpdateImpactAggregates(row.aggregates, customTableProps?.filters.comparisonInterval)
                return (
                  <AggregateValue
                    type={ValueType.Currency}
                    value={aggregates.revenueImpact}
                    trendValue={aggregates.revenueTrend}
                    maxTrendValue={maxTrendValue}
                    releaseDate={row.releaseDate}
                    daysSinceReleased={row.daysSinceReleased}
                    comparisonInterval={customTableProps?.filters.comparisonInterval}
                    isTooOld={row.isTooOld}
                    isFirstVersion={row.isFirstVersion}
                    isBeforeGlobalLaunch={row.isBeforeGlobalLaunch}
                    isAvailableForCurrentMarket={true}
                    isUnlocked={customTableProps?.impactDataUnlocked}
                  />
                )
              },
              sortable: 'manual',
              sortAccessor: ({ row, customTableProps, col: { sortOrder } }) => {
                if (checkSortAccessor(row, sortOrder)) return checkSortAccessor(row, sortOrder)
                return new UpdateImpactAggregates(row.aggregates, customTableProps?.filters.comparisonInterval).revenueTrend
              },
              headerCellProps: { sx: { minWidth: 100 } },
            },
            {
              labelAccessor: ({ col, handleSort, customTableProps }) => {
                if (customTableProps?.tableConfig?.showSortingIcon === false) return t('common:downloads_text')
                return <DownloadsLabel column={col} onSort={handleSort} customTableProps={customTableProps} />
              },
              accessor: ({ row, customTableProps }) => {
                const aggregates = new UpdateImpactAggregates(row.aggregates, customTableProps?.filters.comparisonInterval)
                return (
                  <AggregateValue
                    type={ValueType.Number}
                    value={aggregates.downloadsImpact}
                    trendValue={aggregates.downloadsTrend}
                    maxTrendValue={maxTrendValue}
                    releaseDate={row.releaseDate}
                    daysSinceReleased={row.daysSinceReleased}
                    comparisonInterval={customTableProps?.filters.comparisonInterval}
                    isTooOld={row.isTooOld}
                    isFirstVersion={row.isFirstVersion}
                    isBeforeGlobalLaunch={row.isBeforeGlobalLaunch}
                    isAvailableForCurrentMarket={true}
                    isUnlocked={customTableProps?.impactDataUnlocked}
                  />
                )
              },
              sortable: 'manual',
              sortAccessor: ({ row, customTableProps, col: { sortOrder } }) => {
                if (checkSortAccessor(row, sortOrder)) return checkSortAccessor(row, sortOrder)
                return new UpdateImpactAggregates(row.aggregates, customTableProps?.filters.comparisonInterval).downloadsTrend
              },
              headerCellProps: { sx: { minWidth: 100 } },
            },
            {
              labelAccessor: ({ col, handleSort, customTableProps }) => {
                if (customTableProps?.tableConfig?.showSortingIcon === false) return t('common:revenue_downloads_ratio_shorthand')
                return <RevenueDownloadsRatioLabel column={col} onSort={handleSort} customTableProps={customTableProps} />
              },
              accessor: ({ row, customTableProps }) => {
                const aggregates = new UpdateImpactAggregates(row.aggregates, customTableProps?.filters.comparisonInterval)
                return (
                  <AggregateValue
                    type={ValueType.Currency}
                    value={aggregates.revenueAndDownloadsRatio}
                    trendValue={aggregates.revenueAndDownloadsRatioTrend}
                    maxTrendValue={maxTrendValue}
                    releaseDate={row.releaseDate}
                    daysSinceReleased={row.daysSinceReleased}
                    comparisonInterval={customTableProps?.filters.comparisonInterval}
                    isTooOld={row.isTooOld}
                    isFirstVersion={row.isFirstVersion}
                    isBeforeGlobalLaunch={row.isBeforeGlobalLaunch}
                    isAvailableForCurrentMarket={true}
                    isUnlocked={customTableProps?.impactDataUnlocked}
                  />
                )
              },
              sortable: 'manual',
              sortAccessor: ({ row, customTableProps, col: { sortOrder } }) => {
                if (checkSortAccessor(row, sortOrder)) return checkSortAccessor(row, sortOrder)
                return new UpdateImpactAggregates(row.aggregates, customTableProps?.filters.comparisonInterval).revenueAndDownloadsRatioTrend
              },

              headerCellProps: { sx: { minWidth: 100 } },
            },
          ],
        },
        {
          labelAccessor: '',
          hidden: ({ customTableProps }) => !customTableProps?.tableConfig?.showFeaturesColumn,
          columns: [
            {
              labelAccessor: ({ col, customTableProps }) => {
                const options = [
                  { value: FeaturesFilterValue.Any, name: t('common:any_choice_filter_text') },
                  { value: FeaturesFilterValue.Changed, name: t('common:feature_changes') },
                  { value: FeaturesFilterValue.Updated, name: t('common:updated_features') },
                ]
                const filterValue = customTableProps?.filters.features
                const hasActiveSelection =
                  (!isNil(filterValue) && Object.keys(filterValue?.selectedFeatures || {}).length > 0) ||
                  (!isNil(filterValue) &&
                    !isNil(filterValue!.featureType) &&
                    (filterValue!.featureType !== FeaturesFilterValue.Any || Object.keys(filterValue?.selectedFeatures || {}).length > 0))
                return (
                  <ColumnHeaderLabelWithFilter label={t('common:features')} column={col} active={hasActiveSelection}>
                    <FeaturesColumnFilter name="features" options={options} onChange={customTableProps?.handleColumnFilterChange} value={filterValue || {}} />
                  </ColumnHeaderLabelWithFilter>
                )
              },
              accessor: ({ row, customTableProps }) => {
                return (
                  <FeaturesContainer
                    updatedFeatures={row.updatedFeatures}
                    changedFeatures={row.changedFeatures}
                    isUnlocked={customTableProps?.impactDataUnlocked}
                  />
                )
              },
              headerCellProps: { sx: { minWidth: 230 } },
              cellProps: { align: 'left' },
            },
          ],
        },

        {
          labelAccessor: '',
          hidden: ({ customTableProps }) => !customTableProps?.tableConfig?.showOpenUpdateColumn,
          columns: [
            {
              labelAccessor: ({ customTableProps }) =>
                customTableProps?.handleDataExport && (
                  <ExportDataButton
                    hideLabel={true}
                    accessRoles={[RoleEnum.csv_game_update_impacts]}
                    analyticsEventOrigin="Update Impacts Table"
                    onChooseFormat={customTableProps?.handleDataExport}
                  />
                ),
              accessor: ({ row, customTableProps }) => (
                <Fab
                  color="primary"
                  size="small"
                  onClick={() => customTableProps?.handleShowUpdate(row)}
                  sx={{ whiteSpace: 'nowrap', boxShadow: (theme) => theme.shadows[2] }}
                >
                  <Tooltip title={t('common:show_update') as string}>
                    <OpenInNewIcon />
                  </Tooltip>
                </Fab>
              ),
              headerCellProps: { sx: { minWidth: 60 } },
            },
          ],
        },
      ] as GRTableColumn<TableRowUpdateImpact, typeof customProps>[]
    }, [t, tableConfig?.showSortingIcon, navigate, checkSortAccessor])

    const { filteredUpdateImpacts } = useUpdateImpactFilters(rows, filters)
    const [columns, setColumns] = useState<GRTableColumn<TableRowUpdateImpact, typeof customProps>[]>(initialColumns)
    const handleColumnsUpdate = useCallback((updatedColumns: GRTableColumn<TableRowUpdateImpact, typeof customProps>[]) => {
      setColumns(updatedColumns)
    }, [])

    // NOTE: to provide dynamic values for table cells (headers and rows) we need to pass them through the table component
    // and provide them in accessor functions as a parameter
    const customProps = useMemo(() => {
      return {
        filters,
        handleColumnFilterChange: onColumnFilterChange,
        handleShowUpdate,
        handleDataExport: onDataExport,
        showVersionTags: tableConfig?.impactDataUnlocked || tableConfig?.showVersionTags,
        impactDataUnlocked: tableConfig?.impactDataUnlocked,
        tableConfig,
      }
    }, [filters, onColumnFilterChange, handleShowUpdate, onDataExport, tableConfig])

    return (
      <>
        <Typography variant="body1" my={2}>
          {t('common:game_update_impacts_description_text')}
        </Typography>
        <TableContainer component={Card} ref={containerRef}>
          <GRTable
            maxRows={maxRows}
            columns={columns}
            rows={filteredUpdateImpacts}
            isLoading={isLoading}
            rowIdKey="id"
            scroller={containerRef}
            onColumnsUpdated={handleColumnsUpdate}
            noRowsLabel={t('common:no_game_update_impacts_found')}
            customProps={customProps}
            virtualOverscan={10}
            hoverable
          />
        </TableContainer>
        <AnalystCommentDialog
          commentId={analystCommentDialogRow?.commentId}
          hasAccessRights={tableConfig?.analystReviewsAndCommentsUnlocked}
          gameName={analystCommentDialogRow?.gameName}
          open={!!analystCommentDialogRow}
          onClose={() => setAnalystCommentDialogRow(null)}
        />
      </>
    )
  }
)

type CustomTableProps = {
  filters: UpdateImpactRowFilters
  handleColumnFilterChange?: (value: ColumnFilterValue) => void
}

type ColumnLabelProps<RowType, CustomPropsType> = {
  column: GRTableColumn<RowType, CustomPropsType>
  onSort?: (value: GRTableColumn<RowType, CustomPropsType>) => void
  customTableProps?: CustomTableProps
}

const RevenueLabel = <RowType extends object, CustomPropsType extends object>({
  column,
  onSort,
  customTableProps,
}: ColumnLabelProps<RowType, CustomPropsType>) => {
  const { t } = useTranslation()
  const percentChangeValues = [
    EMPTY_AGGREGATE_VALUE,
    0.1,
    0.2,
    0.3,
    0.4,
    0.5,
    0.6,
    0.7,
    0.8,
    0.9,
    1.0,
    1.1,
    1.2,
    1.3,
    1.4,
    1.5,
    1.6,
    1.7,
    1.8,
    1.9,
    2.0,
  ] as const
  const valueChangeValues = [EMPTY_AGGREGATE_VALUE, 10000, 50000, 100000, 250000, 500000, 1000000, 2000000] as const

  return (
    <AggregateValueColumnHeader
      label={t('common:revenue_text')}
      name={AggregateValueColumnFilterName.revenue}
      column={column}
      onSort={onSort}
      value={customTableProps?.filters?.revenue}
      percentChangeValues={percentChangeValues}
      valueChangeValues={valueChangeValues}
      onColumnFilterChange={customTableProps?.handleColumnFilterChange}
      valueFormatter={utilsService.formatCurrency}
    />
  )
}

const DownloadsLabel = <RowType extends object, CustomPropsType extends object>({
  column,
  onSort,
  customTableProps,
}: ColumnLabelProps<RowType, CustomPropsType>) => {
  const { t } = useTranslation()
  const percentChangeValues = [
    EMPTY_AGGREGATE_VALUE,
    0.1,
    0.2,
    0.3,
    0.4,
    0.5,
    0.6,
    0.7,
    0.8,
    0.9,
    1.0,
    1.1,
    1.2,
    1.3,
    1.4,
    1.5,
    1.6,
    1.7,
    1.8,
    1.9,
    2.0,
  ] as const
  const valueChangeValues = [EMPTY_AGGREGATE_VALUE, 10000, 50000, 100000, 250000, 500000, 1000000, 2000000] as const

  return (
    <AggregateValueColumnHeader
      label={t('common:downloads_text')}
      name={AggregateValueColumnFilterName.downloads}
      column={column}
      onSort={onSort}
      value={customTableProps?.filters?.downloads}
      percentChangeValues={percentChangeValues}
      valueChangeValues={valueChangeValues}
      onColumnFilterChange={customTableProps?.handleColumnFilterChange}
      valueFormatter={utilsService.formatNumber}
    />
  )
}

const RevenueDownloadsRatioLabel = <RowType extends object, CustomPropsType extends object>({
  column,
  onSort,
  customTableProps,
}: ColumnLabelProps<RowType, CustomPropsType>) => {
  const { t } = useTranslation()
  const percentChangeValues = [
    EMPTY_AGGREGATE_VALUE,
    0.1,
    0.2,
    0.3,
    0.4,
    0.5,
    0.6,
    0.7,
    0.8,
    0.9,
    1.0,
    1.1,
    1.2,
    1.3,
    1.4,
    1.5,
    1.6,
    1.7,
    1.8,
    1.9,
    2.0,
  ] as const
  const valueChangeValues = [EMPTY_AGGREGATE_VALUE, 0.1, 0.2, 0.5, 1, 1.5, 2, 3, 4, 5, 10] as const

  return (
    <AggregateValueColumnHeader
      label={t('common:revenue_downloads_ratio_shorthand')}
      name={AggregateValueColumnFilterName.revenueDownloadsRatio}
      column={column}
      onSort={onSort}
      value={customTableProps?.filters?.revenueDownloadsRatio}
      percentChangeValues={percentChangeValues}
      valueChangeValues={valueChangeValues}
      onColumnFilterChange={customTableProps?.handleColumnFilterChange}
      valueFormatter={utilsService.formatCurrency}
    />
  )
}
