import { t } from 'i18next'
import { FC, ReactNode, useCallback, useEffect, useMemo, useState } from 'react'
import { Trans } from 'react-i18next'

import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  Divider,
  FormControlLabel,
  Grid,
  Switch,
  Typography,
} from '@mui/material'

import { ConceptTag, Tag, useGetKeywordListQuery } from '../../../../api/core'
import GRDialogTitle from '../../../../components/GRDialogTitle/GRDialogTitle'
import { difference } from '../../../../helpers/difference'
import { useFeatureTagsAccessCheck } from '../../../account/hooks/roleHooks'
import { useCurrentUserLanguage } from '../../../account/hooks/userHooks'
import { useConceptTags, useConceptTagsByTagIds } from '../../../concepts/hooks/conceptHooks'
import { EnrichedFeature } from '../../../implementation-examples'
import { useGameFeatureMemo } from '../../../implementation-examples/hooks/useInitialState'
import { useFeatureTags } from '../../hooks/useFeatureTags'
import { useFeatures } from '../../hooks/useFeatures'
import { FeatureAndKeywordSearchResult } from '../../types/types'
import { FeaturesAndKeywordsAutocomplete } from '../FeaturesAndKeywordsAutocomplete/FeaturesAndKeywordsAutocomplete'
import { TagList } from '../TagList/TagList'
import { ConceptList } from './ConceptList/ConceptList'
import { FeatureList } from './FeatureList/FeatureList'

/**
 * Dialog component for displaying a list of selectable features and/or choices
 * with tag selector and feature/keyword search.
 */
export type FeatureSelectDialogResult = {
  featureChoices: FeatureChoices
  conceptTags: ConceptTag[]
}

export type FeatureChoices = { [featureId: number]: number[] }

export enum FeatureSelectionMode {
  Features = 'Features',
  Choices = 'Choices',
}

type FeatureSelectDialogProps = DialogProps & {
  selectedFeatures: FeatureChoices
  selectedConceptTagIds?: string[]
  onConfirm: (value: FeatureSelectDialogResult) => void
  marketIso: string
  selectionMode?: FeatureSelectionMode
  showScreenshotCounts?: boolean
  includeHiddenScreenshots?: boolean
  onLoading?: (isLoading: boolean) => void
  children?: ReactNode
  optionalFiltered?: (feature: EnrichedFeature) => void
  onSelect?: (selection: FeatureChoices) => void
  concepts?: boolean
}

export const FeatureSelectDialog: FC<FeatureSelectDialogProps> = ({
  selectedFeatures,
  selectedConceptTagIds,
  onConfirm,
  selectionMode = FeatureSelectionMode.Features,
  marketIso,
  showScreenshotCounts,
  includeHiddenScreenshots,
  onLoading,
  children,
  optionalFiltered,
  onSelect,
  concepts = false,
  ...dialogProps
}) => {
  const hasAccessToFeatureTags = useFeatureTagsAccessCheck()
  const [useGranularSearch, setUseGranularSearch] = useState(true)
  const userLanguage = useCurrentUserLanguage()
  const featureTagsQuery = useFeatureTags()
  const conceptTags = useConceptTags({ skip: !concepts || !useGranularSearch })
  const [selectedTags, setSelectedTags] = useState<Tag[]>([])
  const featureKeywords = useGetKeywordListQuery({ type: 'feature', userLanguage })
  const featureCategories = useFeatures({ skipCounts: !showScreenshotCounts, includeHiddenScreenshots, marketIso })
  const [searchResult, setSearchResult] = useState<FeatureAndKeywordSearchResult>(null)
  const [selection, setSelection] = useState<FeatureChoices>(selectedFeatures)
  const currentState = useGameFeatureMemo()
  const defaultSelectedChoice = useMemo(() => currentState?.featureIds || {}, [currentState?.featureIds])
  const [featureSelection, setFeatureSelection] = useState<FeatureChoices>(selectedFeatures)
  const [conceptSelection, setConceptSelection] = useState<string[]>(selectedConceptTagIds || [])
  const conceptTagSelection = useConceptTagsByTagIds(conceptSelection || [])

  const isLoading =
    featureTagsQuery.isLoading ||
    featureTagsQuery.isFetching ||
    featureKeywords.isLoading ||
    featureKeywords.isFetching ||
    featureCategories.isLoading ||
    featureCategories.isFetching ||
    conceptTags.isFetching ||
    conceptTags.isLoading
  const features = useMemo(() => featureCategories?.data?.flatMap((featureCategory) => featureCategory.features) || [], [featureCategories])

  useEffect(() => {
    onLoading && onLoading(isLoading)
  }, [isLoading, onLoading])

  useEffect(() => {
    if (!!Object.keys(selectedFeatures).length) {
      setFeatureSelection(selectedFeatures)
      setSelection(selectedFeatures)
    } else {
      setFeatureSelection({})
      setSelection({})
    }
  }, [selectedFeatures])

  useEffect(() => {
    if (selectedConceptTagIds) {
      setConceptSelection(selectedConceptTagIds)
    } else {
      setConceptSelection([])
    }
  }, [onSelect, selectedConceptTagIds, selection])

  const handleSelectedTagChange = useCallback(
    (tags: Tag[]) => {
      // only one tag can be selected at a time
      const differenceTags = difference(tags, selectedTags)
      if (differenceTags.length === 1) {
        setSelectedTags(differenceTags)
        setSearchResult('')
      } else {
        setSelectedTags(tags)
      }

      setSearchResult('')
    },
    [selectedTags]
  )

  const handleSearchResultChange = useCallback((value: FeatureAndKeywordSearchResult) => {
    setSearchResult(value)
    setSelectedTags([])
  }, [])

  const handleConfirm = useCallback(() => {
    onConfirm({ featureChoices: featureSelection, conceptTags: conceptTagSelection })
    dialogProps.onClose && dialogProps.onClose({}, 'escapeKeyDown')
  }, [conceptTagSelection, dialogProps, featureSelection, onConfirm])

  const handleClose = useCallback(() => {
    dialogProps.onClose && dialogProps.onClose({}, 'escapeKeyDown')
  }, [dialogProps])

  const selectedFeaturesCount = Object.keys(featureSelection || {}).map((val) => +val).length
  const selectedConceptTagsCount = conceptSelection.length || 0

  const filteredTags = useMemo(() => {
    const uniqueFeatureTags = [...new Set(features.flatMap((feature) => feature.tags))]
    const uniqueConceptTags = [...new Set(conceptTags.data?.map((conceptTag) => conceptTag.concept.featureTagId) || [])]
    const combinedUniqueTags = [...new Set([...uniqueFeatureTags, ...uniqueConceptTags])]

    if (!featureTagsQuery || !featureTagsQuery.data) return []
    return featureTagsQuery.data.map((category) => {
      return { ...category, tags: category.tags.filter((tag) => combinedUniqueTags.includes(tag.id)) }
    })
  }, [conceptTags.data, featureTagsQuery, features])

  return (
    <Dialog maxWidth="lg" fullWidth {...dialogProps}>
      <GRDialogTitle onClose={handleClose}>
        {concepts ? <Trans i18nKey="feature-select-modal:select_features_and_tags" /> : <Trans i18nKey="feature-select-modal:select_features" />}
      </GRDialogTitle>
      <DialogContent dividers sx={{ height: '75vh' }}>
        {isLoading ? (
          <Grid container justifyContent="center" mt={3} mb={2}>
            <CircularProgress color="primary" />
          </Grid>
        ) : (
          <>
            <TagList tagGroups={filteredTags} selectedTags={selectedTags} onChange={handleSelectedTagChange} />

            {hasAccessToFeatureTags && concepts && (
              <FormControlLabel
                control={
                  <Switch
                    size="small"
                    checked={useGranularSearch}
                    onChange={() => {
                      setUseGranularSearch(!useGranularSearch)

                      if (!useGranularSearch) {
                        setConceptSelection([])
                      }
                    }}
                  />
                }
                label={t('feature-select-modal:use_advanced_granular_search') as string}
              />
            )}

            <Divider light sx={{ my: 2 }} />

            <FeaturesAndKeywordsAutocomplete
              value={searchResult}
              features={features}
              conceptTags={conceptTags.data}
              keywords={featureKeywords.data}
              onChange={handleSearchResultChange}
              isLoading={isLoading}
            />

            {concepts && useGranularSearch && (selectedTags.length > 0 || searchResult) && (
              <Box mt={2}>
                <h3>
                  <Trans i18nKey="common:features" />
                </h3>
              </Box>
            )}

            <FeatureList
              features={featureCategories.data}
              searchResult={searchResult}
              selectedTag={selectedTags[0] || null}
              selectionMode={selectionMode}
              selection={featureSelection}
              onSelectionChange={(selection) => setFeatureSelection(selection)}
              showScreenshotCounts={showScreenshotCounts}
              optionalFiltered={optionalFiltered}
              keywords={featureKeywords.data}
            />

            {hasAccessToFeatureTags && concepts && useGranularSearch && (selectedTags.length > 0 || searchResult) && (
              <Box mt={2}>
                <h3>
                  <Trans i18nKey="feature-select-modal:granular_feature_tags" />
                </h3>

                <ConceptList
                  searchResult={searchResult}
                  selection={conceptTagSelection}
                  selectedTag={selectedTags[0] || null}
                  onSelectionChange={(selection) => setConceptSelection(selection)}
                  showScreenshotCounts={showScreenshotCounts}
                  conceptTags={conceptTags.data}
                  keywords={featureKeywords.data}
                />
              </Box>
            )}
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item xs={2}>
            <Button
              variant="contained"
              color="warning"
              onClick={() => {
                setFeatureSelection({})
                setConceptSelection([])
              }}
              disabled={selectedFeaturesCount <= 0 && selectedConceptTagsCount <= 0}
            >
              {t('common:clear_selected')}
            </Button>
          </Grid>
          <Grid item xs={8}>
            {hasAccessToFeatureTags && concepts && useGranularSearch ? (
              <Typography align="center">
                <Trans
                  i18nKey="feature-select-modal:features_and_tags_selected"
                  values={{ count: selectedFeaturesCount, concepts_count: conceptSelection.length }}
                />
              </Typography>
            ) : (
              <Typography align="center">
                <Trans i18nKey="feature-select-modal:features_selected" values={{ count: selectedFeaturesCount }} />
              </Typography>
            )}
          </Grid>
          <Grid item xs={2} textAlign="right">
            <Button variant="contained" color="success" onClick={handleConfirm} disabled={selection === defaultSelectedChoice}>
              {t('common:done')}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}
