import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, Navigate, useParams } from 'react-router-dom'
import { BookType } from 'xlsx'

import { Info } from '@mui/icons-material'
import { Box, Card, CircularProgress, Divider, FormControl, Grid, MenuItem, Select, SelectChangeEvent, Tab, Tabs } from '@mui/material'

import { useGetQuartersQuery } from '../../api/top-grossing'
import { ExportDataButton } from '../../components/ExportDataButton/ExportDataButton'
import { GRTooltip } from '../../components/GRTooltip/GRTooltip'
import { LimitedFunctionalityBanner } from '../../components/LimitedFunctionalityBanner/LimitedFunctionalityBanner'
import { useRoleCheck } from '../../features/account/hooks/roleHooks'
import { RoleEnum } from '../../features/account/types/RoleEnum'
import { generateExport } from '../../features/export-data/util/workbook'
import MarketShareQuarterComparison from '../../features/market-share/components/MarketShareQuarterComparison/MarketShareQuarterComparison'
import MarketShareQuarterOverview from '../../features/market-share/components/MarketShareQuarterOverview/MarketShareQuarterOverview'
import MarketShareTopGames from '../../features/market-share/components/MarketShareTopGames/MarketShareTopGames'
import { MarketShareScopeTypeFilter, MarketShareScopeTypeFilterAll } from '../../features/market-share/types/MarketShareScopeTypeFilter'
import { MarketShareSearchFilter } from '../../features/market-share/types/MarketShareSearchFilters'
import { MarketShareSearchParams } from '../../features/market-share/types/MarketShareSearchParams'
import { MarketShareSubpage } from '../../features/market-share/types/MarketShareSubpage'
import { useCurrentMarket } from '../../features/markets'
import quarterService from '../../features/quarter/services/QuarterService'
import { Quarter } from '../../features/quarter/types/Quarter'
import { useMobileSize } from '../../features/responsiveness/hooks/responsivenessHooks'
import {
  marketShareNumberTypes,
  marketShareScopeTypes,
  useFilteredQuarters,
  useGetMarketShareData,
  useGetTopGames,
} from '../../features/top-game/hooks/topGrossingGameHooks'
import { useExportDataMarketSharePage } from '../../hooks/exportDataHooks'
import { useDocumentTitle } from '../../hooks/useDocumentTitle'
import usePage from '../../hooks/usePage'
import { useSearchParams } from '../../hooks/useSearchParams'
import analyticsService from '../../services/AnalyticsService'
import PageService from '../../services/PageService'
import { LockedFeatureId } from '../../types/LockedFeature'
import './MarketSharePage.scss'

const topGamesInclude = [
  'appId',
  'name',
  'artist',
  'rank',
  'gps',
  'genre',
  'genreId',
  'gpsChange',
  'tags',
  'images',
  'subGenre',
  'subGenreId',
  'drank',
  'drankYesterday',
  'gameId',
  'rankYesterday',
  'conventionalCategoryId',
  'conventionalGenreId',
  'conventionalSubgenreId',
  'reviewId',
  'reviewPublished',
]

const MarketSharePage: React.FC = () => {
  const { t } = useTranslation()

  usePage(PageService.getPageWithId('market-share'), 'Visited Market Share')
  useDocumentTitle(t('market-share:main_title'))

  const { parsedParams, setSearchParams } = useSearchParams<MarketShareSearchParams, MarketShareSearchFilter>()
  const marketShareUnlocked = useRoleCheck(RoleEnum.market_share)
  const mobileSize = useMobileSize()

  const marketShareSubpages: MarketShareSubpage[] = useMemo(() => {
    return [
      {
        id: 'quarter-overview',
        name: t('market-share:single_quarter_tab'),
      },
      {
        id: 'quarter-comparison',
        name: t('market-share:quartes_tab'),
      },
      {
        id: 'top-500-games',
        name: t('market-share:games_tab'),
      },
    ]
  }, [t])

  const urlParams: { id?: string; subpage?: string } = useParams()
  const currentSubpage: MarketShareSubpage | undefined = marketShareSubpages.find((subpage) => subpage.id === urlParams.subpage)
  const [selectedSubpage, setSelectedSubpage] = useState(currentSubpage ? marketShareSubpages.findIndex((subpage) => subpage.id === urlParams.subpage) : 0)

  useEffect(() => {
    analyticsService.trackEvent('Market Share: Changed Tab', {
      data: {
        tab: marketShareSubpages[selectedSubpage].id,
      },
    })
  }, [marketShareSubpages, selectedSubpage])

  useEffect(() => {
    setSelectedSubpage(currentSubpage ? marketShareSubpages.findIndex((subpage) => subpage.id === currentSubpage.id) : 0)
  }, [currentSubpage, marketShareSubpages])

  const searchParamsBasedScopeType = marketShareScopeTypes.find((type) => type.id === parsedParams.scopeTypeId)
  const [marketShareScopeType, setMarketShareScopeType] = useState(searchParamsBasedScopeType ? searchParamsBasedScopeType : marketShareScopeTypes[0])

  const searchParamsBasedNumberType = marketShareNumberTypes.find((type) => type.id === parsedParams.numberTypeId)
  const [marketShareNumberType, setMarketShareNumberType] = useState(searchParamsBasedNumberType ? searchParamsBasedNumberType : marketShareNumberTypes[0])

  const { currentMarketIso: mainMarketIso } = useCurrentMarket()
  const { data: quarters } = useGetQuartersQuery({ marketIso: mainMarketIso })
  const [quarterOverviewQuarter, setQuarterOverviewQuarter] = useState<Quarter | undefined>()
  const [quarterComparisonQuarters, setQuarterComparisonQuarters] = useState<Quarter[] | undefined>()
  const [marketShareScopeTypeFilter, setMarketShareScopeTypeFilter] = useState<MarketShareScopeTypeFilter | undefined>()

  const { filteredQuarters } = useFilteredQuarters()

  const { topGames, isLoading: isFetchingTopGames } = useGetTopGames(topGamesInclude)
  const { marketShareData } = useGetMarketShareData(marketShareScopeType, topGames, isFetchingTopGames)

  const handleSubpageChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedSubpage(newValue)
  }

  const handleMarketShareScopeTypeChange = (event: SelectChangeEvent) => {
    const value = event.target.value as string
    const type = marketShareScopeTypes.find((type) => type.id === value)
    if (type) {
      setMarketShareScopeType(type)
      if (marketShareScopeTypeFilters && marketShareScopeTypeFilters.length > 0) {
        setMarketShareScopeTypeFilter(marketShareScopeTypeFilters[0])
      }
    }
  }

  const handleMarketShareNumberTypeChange = (event: SelectChangeEvent) => {
    const value = event.target.value as string
    const type = marketShareNumberTypes.find((type) => type.id === value)
    if (type) {
      setMarketShareNumberType(type)
    }
  }

  const handleQuarterChange = (quarterIdentifier: string) => {
    const selectedQuarter = filteredQuarters?.find((quarter) => quarter.quarterIdentifier === quarterIdentifier)

    if (selectedQuarter) {
      setQuarterOverviewQuarter(selectedQuarter)
    }
  }

  const handleQuartersChange = (quarterIdentifier1: string, quarterIdentifier2: string) => {
    const selectedQuarter1 = filteredQuarters?.find((quarter) => quarter.quarterIdentifier === quarterIdentifier1)
    const selectedQuarter2 = filteredQuarters?.find((quarter) => quarter.quarterIdentifier === quarterIdentifier2)

    if (selectedQuarter1 && selectedQuarter2) {
      setQuarterComparisonQuarters([selectedQuarter1, selectedQuarter2])
    }
  }

  const handleMarketShareLinkClick = (marketShareName: string) => {
    if (marketShareScopeTypeFilters) {
      const marketShareScopeTypeFilter = marketShareScopeTypeFilters.find((filter) => filter.name === marketShareName)
      setMarketShareScopeTypeFilter(marketShareScopeTypeFilter || marketShareScopeTypeFilters[0])
      setSelectedSubpage(2)
    }
  }

  const marketShareScopeTypeFilters = useMemo(() => {
    if (marketShareData) {
      const filtersMap: { [key: string]: string } = {}
      marketShareData.topGamesWithGameQuarters.forEach((game) => {
        const gameDataValue = game[marketShareScopeType.gameDataValueId]
        if (gameDataValue && !filtersMap[gameDataValue]) {
          filtersMap[gameDataValue] = game[marketShareScopeType.gameDataValueName]
        }
      })

      const filters: MarketShareScopeTypeFilter[] = Object.keys(filtersMap).map((key) => {
        return { id: key, name: filtersMap[key] }
      })

      filters.sort((a, b) => (a.name < b.name ? -1 : 1))
      filters.unshift({ id: MarketShareScopeTypeFilterAll, name: t(marketShareScopeType.allLocalizationKey) })

      return filters
    } else {
      return null
    }
  }, [marketShareData, marketShareScopeType, t])

  useEffect(() => {
    if (!marketShareScopeTypeFilter && marketShareScopeTypeFilters) {
      if (parsedParams.scopeTypeFilterId) {
        const marketShareScopeTypeFilter = marketShareScopeTypeFilters.find((filter) => filter.id === parsedParams.scopeTypeFilterId)
        setMarketShareScopeTypeFilter(marketShareScopeTypeFilter || marketShareScopeTypeFilters[0])
      } else {
        setMarketShareScopeTypeFilter(marketShareScopeTypeFilters[0])
      }
    }
  }, [marketShareScopeTypeFilters, marketShareScopeTypeFilter, parsedParams.scopeTypeFilterId])

  useEffect(() => {
    if (currentSubpage && marketShareScopeTypeFilter && quarterOverviewQuarter && quarterComparisonQuarters) {
      const quarterIdentifiers = quarterComparisonQuarters[0].quarterIdentifier + 'vs' + quarterComparisonQuarters[1].quarterIdentifier

      setSearchParams({
        scopeTypeId: marketShareScopeType.id,
        numberTypeId: marketShareNumberType.id,
        scopeTypeFilterId: marketShareScopeTypeFilter.id,
        quarterIdentifier: quarterOverviewQuarter.quarterIdentifier,
        quarterIdentifiers,
      })
    }
  }, [
    setSearchParams,
    marketShareScopeType,
    marketShareScopeTypeFilter,
    marketShareNumberType,
    quarterOverviewQuarter,
    quarterComparisonQuarters,
    currentSubpage,
  ])

  useEffect(() => {
    if (filteredQuarters && !quarterOverviewQuarter && !quarterComparisonQuarters) {
      const latestQuarter = filteredQuarters[filteredQuarters.length - 1]
      const previousQuarter = filteredQuarters.find(
        (quarter) => quarter.quarterIdentifier === quarterService.getPreviousQuarterIdentifierForQuarter(latestQuarter.quarter, latestQuarter.year)
      )

      if (parsedParams.quarterIdentifier && parsedParams.quarterIdentifiers) {
        const quarterOverviewQuarter = filteredQuarters.find((quarter) => quarter.quarterIdentifier === parsedParams.quarterIdentifier)
        setQuarterOverviewQuarter(quarterOverviewQuarter || latestQuarter)

        const quarterIdentifiersArray = parsedParams.quarterIdentifiers.split('vs')
        const quarterComparisonQuarter1 = filteredQuarters.find((quarter) => quarter.quarterIdentifier === quarterIdentifiersArray[0])
        const quarterComparisonQuarter2 = filteredQuarters.find((quarter) => quarter.quarterIdentifier === quarterIdentifiersArray[1])

        if (quarterComparisonQuarter1 && quarterComparisonQuarter2) {
          setQuarterComparisonQuarters([quarterComparisonQuarter1, quarterComparisonQuarter2])
        } else {
          setQuarterComparisonQuarters([latestQuarter, previousQuarter || latestQuarter])
        }
      } else {
        setQuarterOverviewQuarter(latestQuarter)
        setQuarterComparisonQuarters([latestQuarter, previousQuarter || latestQuarter])
      }
    }
  }, [filteredQuarters, quarterOverviewQuarter, quarterComparisonQuarters, parsedParams.quarterIdentifier, parsedParams.quarterIdentifiers])

  useEffect(() => {
    if (
      marketShareScopeTypeFilter &&
      marketShareScopeTypeFilters &&
      !marketShareScopeTypeFilters.find((filter) => filter.id === marketShareScopeTypeFilter.id)
    ) {
      setMarketShareScopeTypeFilter(marketShareScopeTypeFilters[0])
    }
  }, [mainMarketIso, marketShareScopeTypeFilters, marketShareScopeTypeFilter, setMarketShareScopeTypeFilter])

  const [isExporting, setIsExporting] = useState(false)
  const [exportFormat, setExportFormat] = useState('csv' as BookType)
  const { exportRows, headerRows, filename } = useExportDataMarketSharePage(
    currentSubpage,
    marketShareData,
    quarters,
    marketShareScopeType,
    topGames,
    isExporting
  )

  useEffect(() => {
    if (!isExporting || !exportRows.length) return
    setIsExporting(false)
    generateExport(exportFormat, exportRows, 'Market Share', filename, headerRows)
  }, [exportRows, headerRows, filename, isExporting, exportFormat])

  const handleExportData = (format: BookType) => {
    setExportFormat(format)
    setIsExporting(true)
  }

  if (!urlParams.subpage || !currentSubpage) {
    return <Navigate replace to={`/market-share/quarter-overview`} />
  } else {
    return (
      <div className="MarketSharePage">
        <Card className={!mobileSize ? 'MarketSharePage__header' : 'MarketSharePage__header MarketSharePage__header--mobile'} sx={{ mb: 3 }}>
          <Tabs value={selectedSubpage} onChange={handleSubpageChange} variant="scrollable" scrollButtons="auto">
            {marketShareSubpages.map((subpage) => (
              <Tab key={subpage.id} label={subpage.name} component={Link} to={`/market-share/${subpage.id}`} />
            ))}
          </Tabs>
        </Card>

        <Card>
          {!marketShareUnlocked && (
            <Box m={1}>
              <Grid item flexBasis="100%">
                <LimitedFunctionalityBanner lockedFeatureId={LockedFeatureId.MarketShare} />
              </Grid>
            </Box>
          )}
          <div className="MarketSharePage__content">
            <Box mx={1.5} my={2} sx={{ display: 'inline-flex', width: '100%' }}>
              <Grid container flexWrap="wrap" spacing={2} alignItems="center" columns={{ xs: 2, md: 5 }}>
                <Grid item xs={3} md={1.7}>
                  <strong>{t('market-share:description_text', { marketIso: mainMarketIso.toUpperCase() })}:</strong>
                </Grid>

                <Grid item md={1}>
                  <Grid container spacing={1} alignItems="center">
                    <Grid item>
                      <FormControl size="small" variant="outlined" disabled={!marketShareUnlocked}>
                        <Select value={marketShareScopeType.id} onChange={handleMarketShareScopeTypeChange}>
                          {marketShareScopeTypes.map((type) => {
                            return (
                              <MenuItem key={type.id} value={type.id}>
                                {t(type.name)}
                              </MenuItem>
                            )
                          })}
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item>
                      <FormControl size="small" variant="outlined">
                        <Select value={marketShareNumberType.id} onChange={handleMarketShareNumberTypeChange}>
                          {marketShareNumberTypes.map((type) => {
                            return (
                              <MenuItem key={type.id} value={type.id}>
                                {t(type.name)}
                              </MenuItem>
                            )
                          })}
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item zIndex={2}>
                      <GRTooltip content={t('market-share:market_share_type_tooltip')}>
                        <Info color="primary" fontSize="small" />
                      </GRTooltip>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item sx={{ mr: '20px', ml: 'auto' }}>
                  {currentSubpage.id !== 'quarter-overview' && (
                    <ExportDataButton
                      analyticsEventOrigin="Market Share"
                      disabled={isFetchingTopGames}
                      accessRoles={[RoleEnum.csv_market_share]}
                      onChooseFormat={handleExportData}
                    />
                  )}
                </Grid>
              </Grid>
            </Box>

            <Divider></Divider>

            {!marketShareData && (
              <div className="text-center">
                <Box m={6}>
                  <CircularProgress color="primary" />
                </Box>
              </div>
            )}

            {marketShareData != null && currentSubpage && (
              <div className="MarketSharePage__content__subpage">
                {currentSubpage.id === 'quarter-overview' && quarterOverviewQuarter && (
                  <MarketShareQuarterOverview
                    calculatedQuarters={marketShareData.calculatedQuarters}
                    marketShares={marketShareData.marketShares}
                    marketShareScopeType={marketShareScopeType}
                    marketShareNumberType={marketShareNumberType}
                    selectedQuarter={quarterOverviewQuarter}
                    onQuarterChangeCallback={handleQuarterChange}
                    onMarketShareLinkClickCallback={handleMarketShareLinkClick}
                  ></MarketShareQuarterOverview>
                )}

                {currentSubpage.id === 'quarter-comparison' && quarterComparisonQuarters && (
                  <MarketShareQuarterComparison
                    calculatedQuarters={marketShareData.calculatedQuarters}
                    marketShares={marketShareData.marketShares}
                    marketShareScopeType={marketShareScopeType}
                    marketShareNumberType={marketShareNumberType}
                    selectedQuarters={quarterComparisonQuarters}
                    onQuartersChangeCallback={handleQuartersChange}
                    onMarketShareLinkClickCallback={handleMarketShareLinkClick}
                  ></MarketShareQuarterComparison>
                )}

                {currentSubpage.id === 'top-500-games' && quarterComparisonQuarters && marketShareScopeTypeFilter && marketShareScopeTypeFilters && (
                  <MarketShareTopGames
                    calculatedQuarters={marketShareData.calculatedQuarters}
                    topGamesWithGameQuarters={marketShareData.topGamesWithGameQuarters}
                    marketShareScopeType={marketShareScopeType}
                    marketShareNumberType={marketShareNumberType}
                    selectedQuarters={quarterComparisonQuarters}
                    marketShareScopeTypeFilter={marketShareScopeTypeFilter}
                    marketShareScopeTypeFilters={marketShareScopeTypeFilters}
                    onMarketShareScopeTypeFilterChangeCallback={setMarketShareScopeTypeFilter}
                    onQuartersChangeCallback={handleQuartersChange}
                    maxRows={500}
                  ></MarketShareTopGames>
                )}
              </div>
            )}
          </div>
        </Card>
      </div>
    )
  }
}

export default MarketSharePage
