import { useMemo } from 'react'

import {
  useGetGameVersionInfoListQuery,
  useGetMultipleGameVersionInfoListQuery,
  useGetUpdateImpactsForGameInMarketQuery,
  useGetUpdateImpactsForMultipleGamesInMarketQuery,
} from '../../../api/core'
import { NewsEntriesForGameResponse, useGetNewsEntriesForGameQuery, useGetNewsEntriesForMultipleGamesQuery } from '../../../api/feed'
import { dateDifference } from '../../../helpers/date'
import { uniq } from '../../../helpers/uniq'
import { VersionInfo } from '../../../types/VersionInfo'
import { Game } from '../../game'
import { useCurrentMarket } from '../../markets'
import { NewsEntry, NewsEntryType } from '../../news'
import { NewsEntryFeature, NewsEntryScreenshot } from '../../news/types/UnifiedNewsEntry'
import { UpdateImpact } from '../../update-impacts'
import { resolveChangeTypes, resolveGameVersionFeatures, resolveVersionTags } from '../../update-impacts/hooks/useUpdateImpacts'
import { ChangeType } from '../../update-impacts/types/ChangeType'
import { TableRowUpdateImpact } from '../../update-impacts/types/TableRowUpdateImpact'
import { UPDATE_IMPACTS_SINCE, UPDATE_IMPACT_ANALYSIS_DATA_SINCE } from '../constants/constants'

const NEWS_ENTRY_TYPES = [NewsEntryType.GpsNew, NewsEntryType.GpsOutside, NewsEntryType.GpsUpdate, NewsEntryType.GpsSystem]

// we are only interested in news entries and game versions after given timestamp
const NEWS_ENTRIES_SINCE = new Date(Date.UTC(2016, 8)).getTime()
const GAME_VERSIONS_SINCE = NEWS_ENTRIES_SINCE
type getGameImpactProps = {
  games?: Game[]
  gameVersions?: VersionInfo[]
  newsEntries?: NewsEntriesForGameResponse
  updateImpacts?: UpdateImpact[]
}
const useGetImpacts = ({ games, gameVersions, newsEntries, updateImpacts }: getGameImpactProps): TableRowUpdateImpact[] => {
  return useMemo(() => {
    let previousPowerscore: number
    if (!gameVersions) return []

    const entries = newsEntries?.entries ? [...newsEntries?.entries] : []
    const sortedNewsEntries = entries.sort((a: NewsEntry, b: NewsEntry) => (a.entryData.versionReleaseDate || 0) - (b.entryData.versionReleaseDate || 0))

    return (
      [...gameVersions]
        .sort((a: VersionInfo, b: VersionInfo) => a.versionReleaseDate - b.versionReleaseDate)
        .filter((versionInfo) => versionInfo.versionReleaseDate >= GAME_VERSIONS_SINCE)
        .map((gameVersion, index, array) => {
          const versionUpdateImpact = updateImpacts?.find((impact) => impact.appId === gameVersion.appId && impact.version === gameVersion.version)
          const versionNewsEntries =
            newsEntries?.entries.filter(
              (newsEntry) => newsEntry.entryData.appId === gameVersion.appId && newsEntry.entryData.gameVersion === gameVersion.version
            ) || []

          // omit changed features if entry type is gpssystem
          const features = versionNewsEntries?.reduce((acc, newsEntry) => {
            return newsEntry?.type !== NewsEntryType.GpsSystem && newsEntry?.entryData?.features ? [...acc, ...newsEntry?.entryData?.features] : acc
          }, [] as NewsEntryFeature[])

          // omit updated features if entry type is other than gpsupdate
          const screenshots = versionNewsEntries?.reduce((acc, newsEntry) => {
            return newsEntry?.type === NewsEntryType.GpsUpdate && newsEntry?.entryData?.screenshots ? [...acc, ...newsEntry?.entryData?.screenshots] : acc
          }, [] as NewsEntryScreenshot[])

          const gps = versionNewsEntries
            .sort((a: NewsEntry, b: NewsEntry) => a.publishedAt - b.publishedAt)
            .reduce((acc, value) => (value.entryData.gps ? value.entryData.gps : acc), previousPowerscore)
          const gpsBefore = previousPowerscore
          previousPowerscore = gps

          const versionReleaseDate = versionNewsEntries.reduce((acc, value) => {
            acc = value.entryData.versionReleaseDate || acc
            return acc
          }, versionUpdateImpact?.versionReleaseDate || gameVersion.versionReleaseDate)

          const { changedFeatures, updatedFeatures } = resolveGameVersionFeatures(features, screenshots, versionReleaseDate)
          const game = games?.find((game) => game.appId === gameVersion.appId)
          const globalLaunchDate = game?.globalLaunchDate || game?.released || new Date().getTime()
          const isFirstGameVersionForMarket = sortedNewsEntries?.[0]?.entryData.gameVersion === gameVersion.version

          return {
            id: gameVersion.id,
            releaseDate: versionReleaseDate,
            version: gameVersion.version,
            versionTags: resolveVersionTags(gameVersion.tags, updatedFeatures, changedFeatures),
            gameId: game?.id,
            gameName: game?.resolvedName,
            gameArtist: gameVersion.artist,
            conventionalSubgenre: versionUpdateImpact?.conventionalSubgenre || game?.conventionalSubgenre,
            conventionalSubgenreId: versionUpdateImpact?.conventionalSubgenreId || game?.conventionalSubgenreId,
            icons: versionUpdateImpact?.icons || game?.icons,
            aggregates: versionUpdateImpact?.versioningAggregate,
            changedFeatures,
            globalLaunchDate,
            updatedFeatures,
            changeTypes: uniq(versionNewsEntries?.flatMap((newsEntry) => resolveChangeTypes(newsEntry, changedFeatures))),
            commentId: versionNewsEntries.reduce((acc, value) => (value.entryData.commentPublished ? value.entryData.commentId : acc), undefined as any),
            daysSinceReleased: dateDifference(new Date(gameVersion.versionReleaseDate), new Date()),
            gps,
            gpsChange: gps && gpsBefore ? gps - gpsBefore : 0,
            isTooOld: versionReleaseDate < UPDATE_IMPACTS_SINCE,
            isFirstVersion: index === 0 && globalLaunchDate < versionReleaseDate && isFirstGameVersionForMarket,
            isBeforeGlobalLaunch: globalLaunchDate > versionReleaseDate,
            releaseNote: gameVersion.releaseNotes || gameVersion.description,
            screenshots: versionNewsEntries.reduce((acc, newsEntry) => [...acc, ...(newsEntry.entryData?.screenshots || [])], [] as NewsEntryScreenshot[]),
          } as TableRowUpdateImpact
        })
        .map(({ gps, gpsChange, ...tableRow }) =>
          tableRow.releaseDate >= UPDATE_IMPACT_ANALYSIS_DATA_SINCE ? { ...tableRow, gps, gpsChange } : { ...tableRow }
        ) || []
    )
  }, [gameVersions, games, newsEntries?.entries, updateImpacts]) as TableRowUpdateImpact[]
}

export const useGameUpdateHistory = (marketIso: string, game: Game | undefined) => {
  const { currentMarketIso } = useCurrentMarket()
  const {
    data: gameVersions = [],
    isLoading: isGameVersionsLoading,
    isFetching: isGameVersionsFetching,
    error: gameVersionsError,
  } = useGetGameVersionInfoListQuery({ marketIso, appId: game?.appId }, { skip: !game })
  const {
    data: updateImpacts,
    isLoading: isUpdateImpactsLoading,
    isFetching: isUpdateImpactsFetching,
    error: updateImpactsError,
  } = useGetUpdateImpactsForGameInMarketQuery({ appId: game?.appId, marketIso }, { skip: !game })
  const {
    data: newsEntries,
    isLoading: isNewsEntriesLoading,
    isFetching: isNewsEntriesFetching,
    error: newsEntriesError,
  } = useGetNewsEntriesForGameQuery(
    {
      gameId: game?.id,
      marketIso: currentMarketIso,
      ts: NEWS_ENTRIES_SINCE,
      types: NEWS_ENTRY_TYPES,
    },
    { skip: !game }
  )

  const impacts = useGetImpacts({ games: game ? [game] : [], gameVersions, newsEntries, updateImpacts })
  const chartImpacts = useHighlightedUpdateImpacts(impacts)

  return {
    ...chartImpacts,
    updateImpactsMappedForTable: impacts || [],
    isLoading: isUpdateImpactsLoading || isNewsEntriesLoading || isGameVersionsLoading,
    isFetching: isUpdateImpactsFetching || isNewsEntriesFetching || isGameVersionsFetching,
    error: gameVersionsError || updateImpactsError || newsEntriesError,
  }
}

export type gameUpdateHistory = {
  appId: number
  marketIso: string
}
export const useMultipleGameUpdateHistory = (apps: gameUpdateHistory[], games?: Game[]) => {
  const {
    data: gameVersions = [],
    isLoading: isGameVersionsLoading,
    isFetching: isGameVersionsFetching,
    error: gameVersionsError,
  } = useGetMultipleGameVersionInfoListQuery({ apps }, { skip: !apps.length })
  const {
    data: updateImpacts,
    isLoading: isUpdateImpactsLoading,
    isFetching: isUpdateImpactsFetching,
    error: updateImpactsError,
  } = useGetUpdateImpactsForMultipleGamesInMarketQuery({ apps }, { skip: !apps.length })

  const gamesQuery = useMemo(() => {
    return apps
      .map((app) => {
        const game = games?.find((g) => g.appId === app.appId) || { id: undefined }
        return {
          marketIso: app.marketIso,
          gameId: game.id,
        }
      })
      .filter((gameQuery) => gameQuery.gameId !== undefined) as { marketIso: string; gameId: string }[]
  }, [apps, games])

  const {
    data: newsEntries,
    isLoading: isNewsEntriesLoading,
    isFetching: isNewsEntriesFetching,
    error: newsEntriesError,
  } = useGetNewsEntriesForMultipleGamesQuery(
    {
      games: gamesQuery,
      ts: NEWS_ENTRIES_SINCE,
      types: NEWS_ENTRY_TYPES,
    },
    { skip: !games }
  )
  const impacts = useGetImpacts({ games: games || [], gameVersions, newsEntries, updateImpacts })
  const chartImpacts = useHighlightedUpdateImpacts(impacts)

  return {
    ...chartImpacts,
    updateImpactsMappedForTable: impacts || [],
    isLoading: isUpdateImpactsLoading || isNewsEntriesLoading || isGameVersionsLoading,
    isFetching: isUpdateImpactsFetching || isNewsEntriesFetching || isGameVersionsFetching,
    isGameVersionsFetching,
    isUpdateImpactsFetching,
    isNewsEntriesFetching,
    error: gameVersionsError || updateImpactsError || newsEntriesError,
  }
}

const useHighlightedUpdateImpacts = (impacts: TableRowUpdateImpact[]) => {
  return impacts.reduce(
    (acc, impact) => {
      if (impact.changeTypes.includes(ChangeType.FeatureChanges)) {
        acc.highlightedImpacts.push(impact)
      } else {
        acc.impacts.push(impact)
      }

      return acc
    },
    { impacts: [], highlightedImpacts: [] } as { impacts: TableRowUpdateImpact[]; highlightedImpacts: TableRowUpdateImpact[] }
  )
}
