import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { RootState } from '../../../store'

type HighlightedEventState = {
  id?: string
  meta?: {
    trackedGameId?: string
    eventStartTime?: number
    eventEndTime?: number
    eventType?: string
    timeout?: number
  }
}

type LiveEventModalState = {
  highlightedEvent: HighlightedEventState
}

const initialState: LiveEventModalState = { highlightedEvent: {} }

export const liveEventModalSlice = createSlice({
  name: 'liveEventModal',
  initialState,
  reducers: {
    highlightEvent: (state, action: PayloadAction<HighlightedEventState>) => {
      state.highlightedEvent = action.payload
    },
    clearHighlightedEvent: (state) => {
      state.highlightedEvent = {}
    },
  },
})

export const selectHighlightedEvent = (state: RootState) => state.liveEventModal.highlightedEvent.id
export const selectLiveEventModalState = (state: RootState) => state.liveEventModal.highlightedEvent

export const { highlightEvent, clearHighlightedEvent } = liveEventModalSlice.actions

export default liveEventModalSlice.reducer
