import { t } from 'i18next'
import React, { MouseEventHandler, useEffect, useMemo, useState } from 'react'
import { Trans } from 'react-i18next'

import { Info, MarkAsUnread, NewReleases, PestControl } from '@mui/icons-material'
import { Masonry } from '@mui/lab'
import { Badge, FormControl, FormControlLabel, Grid, Radio, RadioGroup, SpeedDial, SpeedDialAction, Switch, Typography } from '@mui/material'
import { Box } from '@mui/system'

import { useGetTagListQuery } from '../../../../api/core'
import { useGetReportsQuery } from '../../../../api/reports'
import FeatureTagSelector, { FilterTag } from '../../../../components/FeatureTagSelector/FeatureTagSelector'
import { GRBanner } from '../../../../components/GRBanner/GRBanner'
import { GRTooltip } from '../../../../components/GRTooltip/GRTooltip'
import { SearchInputWithDebounce } from '../../../../components/SearchInputWithDebounce/SearchInputWithDebounce'
import { useRoleCheck } from '../../../account/hooks/roleHooks'
import { useCurrentUserLanguage } from '../../../account/hooks/userHooks'
import { useIsOnFreePlan } from '../../../account/hooks/userHooks'
import { RoleEnum } from '../../../account/types/RoleEnum'
import { useGetUnreadReportsIds, useMarkAllReportsAsUnRead } from '../../hooks/reportsHooks'
import { ReportVisibility } from '../../types/Report'
import ReportCard from '../ReportCard/ReportCard'
import './ReportList.scss'

/**
 * ReportList
 */
interface ReportListProps {}
const ReportList: React.FC<ReportListProps> = () => {
  const { data: reports, isLoading: isLoadingReports, isFetching: isFetchingReports } = useGetReportsQuery()
  const [visibilityFilter, setVisibilityFilter] = useState('ALL')
  const userLanguage = useCurrentUserLanguage()
  const { data: reportTags } = useGetTagListQuery({ type: 'report', userLanguage })
  const [tags, setTags] = useState<FilterTag[]>([])
  const unreadReports = useGetUnreadReportsIds()
  const [showOnlyUnreadReports, setShowOnlyUnreadReports] = React.useState(false)
  const isInternalUser = useRoleCheck(RoleEnum.internal)
  const markAllAsUnread = useMarkAllReportsAsUnRead(reports?.map((report) => report.id))
  const isOnFreePlan = useIsOnFreePlan()
  const [searchText, setSearchText] = useState('')

  const filteredReports = useMemo(() => {
    if (!reports) return []

    let result = []

    // Filter by visibilityFilter
    if (visibilityFilter === 'ALL') {
      result = reports
    } else {
      result = reports.filter((report) => report.visibility === visibilityFilter)
    }

    // Filter by showOnlyUnreadReports
    if (showOnlyUnreadReports) {
      result = result.filter((report) => unreadReports.includes(report.id))
    }

    // Filter by search text (title & description fields any of the languages en, ja, zh)
    if (searchText) {
      const searchLower = searchText.toLowerCase()
      result = result.filter((report) => {
        return (
          report.title?.en?.toLowerCase().includes(searchLower) ||
          report.title?.ja?.toLowerCase().includes(searchLower) ||
          report.title?.zh?.toLowerCase().includes(searchLower) ||
          report.comment?.en?.toLowerCase().includes(searchLower) ||
          report.comment?.ja?.toLowerCase().includes(searchLower) ||
          report.comment?.zh?.toLowerCase().includes(searchLower)
        )
      })
    }

    // If no report tags are selected, return filtered results up to this point
    if (!tags.find(({ selected }) => selected)) return result

    // Filter by selected tags
    return result.filter((report) => {
      return tags.find(({ selected, id }) => selected && report.tags?.includes(id))
    })
  }, [reports, visibilityFilter, showOnlyUnreadReports, tags, searchText, unreadReports])

  const handleVisibilityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setVisibilityFilter(event.target.value)
  }

  useEffect(() => {
    if (!tags.length && reportTags) {
      setTags(reportTags.map((tag) => ({ ...tag, selected: false })) ?? [])
    }
  }, [reportTags, tags.length])

  const onToggleTag = (toggledTag: FilterTag) => {
    const newTags = [...tags]
    const existingTag = newTags.find((tag) => tag === toggledTag)
    if (existingTag) {
      existingTag.selected = !existingTag.selected
    }

    setTags(newTags)
  }

  const onTagsClearSelection: MouseEventHandler = () => {
    setTags(tags.map((tag) => ({ ...tag, selected: false })))
  }

  const onSearchTextChange = (newSearchText: string) => {
    setSearchText(newSearchText)
  }

  const isLoading = isFetchingReports || isLoadingReports

  return (
    <div className="ReportList">
      {isInternalUser && (
        <SpeedDial className="debugdial" ariaLabel="Debug" sx={{ position: 'fixed', bottom: 90, right: 12 }} icon={<PestControl />}>
          <SpeedDialAction icon={<MarkAsUnread />} tooltipTitle="Mark all as unread" onClick={markAllAsUnread}></SpeedDialAction>
        </SpeedDial>
      )}

      <Box my={2}>
        <Typography>{t('reports:reports_description')}</Typography>
      </Box>

      <Box mb={1}>
        <FeatureTagSelector tags={tags} onToggleTag={onToggleTag} onClearSelection={onTagsClearSelection} />
      </Box>

      <Box mb={6}>
        <FormControl>
          <RadioGroup
            row
            aria-labelledby="visibility-radio-buttons-group-label"
            name="row-radio-buttons-group"
            value={visibilityFilter}
            onChange={handleVisibilityChange}
          >
            <FormControlLabel value={ReportVisibility.all} control={<Radio />} label={<Trans i18nKey={'common:all'} />} />
            <FormControlLabel value={ReportVisibility.public} control={<Radio />} label={<Trans i18nKey={'reports:visibility_public'} />} />
            <FormControlLabel value={ReportVisibility.private} control={<Radio />} label={<Trans i18nKey={'reports:visibility_private'} />} />
          </RadioGroup>
        </FormControl>

        {!isOnFreePlan && (
          <FormControlLabel
            sx={{ marginLeft: 2 }}
            control={
              <Switch
                onChange={() => {
                  setShowOnlyUnreadReports(!showOnlyUnreadReports)
                }}
              />
            }
            label={t('reports:show_unread')}
          />
        )}

        <Box marginTop={2} width="30vw" minWidth="350px">
          <SearchInputWithDebounce fieldText={t('reports:search_reports')} onDebounce={onSearchTextChange} />
        </Box>
      </Box>

      {isLoading && (
        <Grid container columnGap={2} rowGap={2} alignContent="center" wrap="wrap" style={{ height: '100%' }}>
          {Array.from({ length: 6 }).map((_, index) => {
            return (
              <Grid key={index} item xs={12} sm={5} xl={3}>
                <ReportCard loading={isLoading} />
              </Grid>
            )
          })}
        </Grid>
      )}

      {!isLoading && (
        <>
          {filteredReports && !filteredReports.length && (
            <Box display="flex" justifyContent="center" alignItems="center">
              <Box my={2} width="60vh">
                <GRBanner severity="guidance" icon={<Info />}>
                  {t('reports:no_reports')}
                </GRBanner>
              </Box>
            </Box>
          )}

          {filteredReports && filteredReports.length > 0 && (
            <Masonry columns={{ xs: 1, sm: 2, md: 2, lg: 3 }} spacing={3}>
              {filteredReports.map((report) => {
                return (
                  <Box key={report.id}>
                    {unreadReports.includes(report.id) ? (
                      <Badge
                        className="ReportCard__Badge"
                        badgeContent={
                          <GRTooltip content={t('common:new')}>
                            <Box
                              sx={{
                                height: '30px',
                                width: '30px',
                                backgroundColor: 'white',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                marginLeft: '5px',
                                marginTop: '5px',
                                borderRadius: '50%',
                              }}
                            >
                              <NewReleases color="secondary" sx={{ height: '40px', width: '40px' }} />
                            </Box>
                          </GRTooltip>
                        }
                        anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'left',
                        }}
                      >
                        <ReportCard report={report} />
                      </Badge>
                    ) : (
                      <ReportCard report={report} />
                    )}
                  </Box>
                )
              })}
            </Masonry>
          )}
        </>
      )}
    </div>
  )
}

export default ReportList
