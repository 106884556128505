import { FC, ReactNode } from 'react'

import { Box, Divider, Grid, Typography } from '@mui/material'

import Powerscore from '../../../../components/Powerscore/Powerscore'
import { Breakdown, PowerscoreBreakdownCategory, PowerscoreItem } from '../../types/types'
import './PowerscoreBreakdown.scss'

const powerscoreBreakdownCategoryOrder = [
  PowerscoreBreakdownCategory.Genre,
  PowerscoreBreakdownCategory.Brand,
  PowerscoreBreakdownCategory.Feature,
  PowerscoreBreakdownCategory.Publisher,
]

type PowerscoreBreakdownProps = {
  breakdown: Breakdown
  onItemClick?: (value: string) => void
}

/**
 * Component for representing Game Powerscore breakdown
 */
export const PowerscoreBreakdown: FC<PowerscoreBreakdownProps> = ({ breakdown, onItemClick: onClick }) => {
  const { [PowerscoreBreakdownCategory.Total]: total, ...rest } = breakdown

  return (
    <Grid container gap={3} className="PowerscoreBreakdown">
      <Grid item container xs={4} alignItems="center">
        <PowerscoreBreakdownItem item={total} value={<Powerscore powerscore={total.value} size={35} fontSize={16} />} />
      </Grid>
      <Divider flexItem orientation="vertical" />
      <Grid container wrap="wrap" spacing={3}>
        {Object.entries(rest)
          .sort(([aKey], [bKey]) => {
            return (
              powerscoreBreakdownCategoryOrder.indexOf(aKey as PowerscoreBreakdownCategory) -
              powerscoreBreakdownCategoryOrder.indexOf(bKey as PowerscoreBreakdownCategory)
            )
          })
          .map(([key, item]) => {
            return (
              <Grid item xs={6} key={key}>
                <PowerscoreBreakdownItem
                  item={item}
                  value={<Typography variant="h3">{item.value.toFixed(0)}</Typography>}
                  onClick={(value) => onClick && onClick(value)}
                />
              </Grid>
            )
          })}
      </Grid>
    </Grid>
  )
}

const PowerscoreBreakdownItem: FC<{ item: PowerscoreItem; value?: ReactNode; onClick?: (value: string) => void }> = ({ item, value, onClick }) => {
  return (
    <Grid container direction="column" wrap="wrap" gap={1}>
      <Grid item container alignItems="center" justifyContent="space-between" gap={2}>
        <Grid item>
          <Box onClick={() => onClick && onClick(item.name)} className="PowerscoreBreakdown__selectedItem">
            <Typography variant="h3">{item.name}</Typography>
          </Box>
        </Grid>
        <Grid item flexGrow={1}>
          <Divider />
        </Grid>

        <Grid item>{value}</Grid>
      </Grid>
      <Grid item>
        <Typography variant="body2">{item.description}</Typography>
      </Grid>
    </Grid>
  )
}
