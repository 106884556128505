import { useGetTagListQuery } from '../../../api/core'
import languageService from '../../../services/LanguageService'
import { useCurrentUserLanguage } from '../../account/hooks/userHooks'

type Tags = { [id: string]: string }

type Result = {
  data?: Tags
  isFetching: boolean
}

export const useVideoChapterTags = (): Result => {
  const userLanguage = useCurrentUserLanguage()
  const { data: chapterData, isFetching } = useGetTagListQuery({ type: 'chapter', userLanguage })
  const data =
    chapterData &&
    [...chapterData]
      .sort((a, b) => a.order - b.order)
      .reduce(
        (map, tag) => ({
          ...map,
          [tag.id]: languageService.getTranslation('tags', tag.id) || tag.name,
        }),
        {} as Tags
      )
  return { data, isFetching }
}
