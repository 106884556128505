import { useCurrentUserLanguage } from '../../account/hooks/userHooks'
import { Game } from '../../game'
import { getMarketIsosByUserLanguage } from '../utils/utils'

// resolve market based on user language, used as a fallback impact data not available for currently selected market
export const useAvailableMarketIsoForUserLanguage = (game?: Game) => {
  const language = useCurrentUserLanguage()
  const resolvedMarketIsos = getMarketIsosByUserLanguage(language)

  const resolvedMarketIso = resolvedMarketIsos.reduce((acc, marketIso) => {
    return !acc && game?.storeLinks[marketIso] ? marketIso : acc
  }, undefined as string | undefined)

  return resolvedMarketIso || game?.storeLinks[0] || 'us'
}
