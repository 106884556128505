import { FC } from 'react'
import { useIntercom } from 'react-use-intercom'

import { ImageSlider } from '../../../../components/ImageSlider/ImageSlider'
import { MultiSliderProps } from '../../../../components/MultiSlider/MultiSlider'
import { useLiveEventsTrackerAccessCheck } from '../../../account/hooks/roleHooks'
import { UseLiveEventModalHookParams, useLiveEventModal } from '../../../live-events/hooks/useLiveEventModal'

type LiveEventScreenshotSliderProps = Omit<MultiSliderProps, 'children'> & {
  screenshotUrls: string[]
  liveEventDialogConfig: UseLiveEventModalHookParams
}

export const LiveEventScreenshotSlider: FC<LiveEventScreenshotSliderProps> = ({ screenshotUrls, liveEventDialogConfig, ...multiSlideProps }) => {
  const hasAccessToLiveEventsTracker = useLiveEventsTrackerAccessCheck()
  const { show } = useIntercom()
  const { showModal: showEventModal } = useLiveEventModal()

  const handleClick = (url: string, index: number) => {
    showEventModal({ ...liveEventDialogConfig, screenshotIndex: index })
  }

  const handleLimitedAccessClick = (url: string, index: number) => {
    show()
  }

  return (
    <ImageSlider
      imageUrls={screenshotUrls}
      onImageClick={handleClick}
      hasLimitedAccess={!hasAccessToLiveEventsTracker}
      onLimitedAccessImageClick={handleLimitedAccessClick}
      {...multiSlideProps}
    />
  )
}
