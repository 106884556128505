import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile'
import { Box, Button, Card, CardActions, CardContent, CardHeader, Chip, Divider, Grid, Skeleton, Stack } from '@mui/material'

import { useGetTagListQuery } from '../../../../api/core'
import { useGetReportFileMutation, useGetReportQuery } from '../../../../api/reports'
import { Analyst, Analysts } from '../../../../components/Analyst/Analyst'
import { DownloadButton } from '../../../../components/DownloadButton/DownloadButton'
import { GRTooltip } from '../../../../components/GRTooltip/GRTooltip'
import { HtmlContentParser } from '../../../../components/HtmlContentParser/HtmlContentParser'
import { LockedFeature } from '../../../../components/LockedFeature/LockedFeature'
import { screenshotsModalParserOptions } from '../../../../components/Review/Review'
import ShareUrlButton from '../../../../components/ShareUrlButton/ShareUrlButton'
import { useConventionalCategoriesMap, useConventionalGenresMap, useConventionalSubgenresMap } from '../../../../hooks/useConventionalHooks'
import { useDocumentTitle } from '../../../../hooks/useDocumentTitle'
import usePage from '../../../../hooks/usePage'
import PageService from '../../../../services/PageService'
import { useCurrentUserLanguage } from '../../../account/hooks/userHooks'
import { GameIcon, GameLink } from '../../../game'
import { useGames } from '../../../game/hooks/gameHooks'
import { useUpdateUserUnreadReports } from '../../hooks/reportsHooks'
import { ReportCoverSlider } from '../ReportCoverSlider/ReportCoverSlider'
import './ReportCardPreview.scss'

/**
 * ReportCardPreview
 */
interface ReportCardPreviewProps {}
const ReportCardPreview: React.FC<ReportCardPreviewProps> = () => {
  const { t } = useTranslation()
  const { reportId } = useParams()
  const { data: report, isFetching: reportIsFetching } = useGetReportQuery({ id: reportId || '' }, { skip: !reportId })
  const userLanguage = useCurrentUserLanguage()
  const { games: reportGames, isLoading: gamesIsLoading } = useGames(report?.gameIds || [])
  const [getReportFile] = useGetReportFileMutation()
  const [fileDownloading, setFileDownloading] = useState(false)
  const { data: reportTags } = useGetTagListQuery({ type: 'report', userLanguage })
  const { map: subgenres } = useConventionalSubgenresMap()
  const { map: genres } = useConventionalGenresMap()
  const { map: categories } = useConventionalCategoriesMap()
  const updateUnreadReports = useUpdateUserUnreadReports(report?.id)
  const navigate = useNavigate()

  usePage(PageService.getPageWithId('reports'))
  useDocumentTitle(`${t('sidebar:reports')} | ${report?.title[userLanguage]}`)
  updateUnreadReports()

  const handleDownload = async () => {
    setFileDownloading(true)

    try {
      await getReportFile({ id: reportId || '' })
      setFileDownloading(false)
    } catch (error) {
      setFileDownloading(false)
    }
  }

  const actionItems = () => {
    return (
      <Stack direction="row" spacing={1} mr={1}>
        <DownloadButton onClick={handleDownload} loading={fileDownloading} disabled={isLoading || !report?.downloadable} />
        <ShareUrlButton disabled={isLoading} />
      </Stack>
    )
  }

  const isLoading = reportIsFetching || gamesIsLoading

  if (isLoading)
    return (
      <>
        <Box mb={2}>
          <Button variant="outlined" startIcon={<ArrowBackIcon />} onClick={() => navigate('/reports')}>
            {t('sidebar:reports')}
          </Button>
        </Box>

        <Card className="ReportCardPreview">
          <CardHeader title={<Skeleton animation="wave" width={500} />} action={actionItems()} className="header-title" />
          <Skeleton variant="rectangular" width="100%" height={500} />
          <CardContent sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.05)' }}>
            <Grid container direction="row" alignItems="center">
              <Grid item xs={6} md={8}>
                <Grid container rowSpacing={1} flexWrap="wrap" spacing={1}>
                  <Grid item>
                    <Skeleton variant="rectangular" sx={{ borderRadius: '20%' }} width={60} height={60} />
                  </Grid>
                  <Grid item>
                    <Skeleton variant="rectangular" sx={{ borderRadius: '20%' }} width={60} height={60} />
                  </Grid>
                  <Grid item>
                    <Skeleton variant="rectangular" sx={{ borderRadius: '20%' }} width={60} height={60} />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={6} md={4}>
                <Grid container alignItems="center" spacing={2} className="Analyst" display="flex" flexDirection="row" justifyContent="flex-end">
                  <Grid item>
                    <Skeleton animation="wave" width={200} />
                    <Skeleton animation="wave" width={200} />
                  </Grid>
                  <Grid item>
                    <Skeleton variant="circular" width={60} height={60} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
          <CardContent sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.05)' }}>
            <Skeleton animation="wave" height={10} style={{ marginBottom: 6 }} />
            <Skeleton animation="wave" height={10} width="80%" style={{ marginBottom: 6 }} />
            <Skeleton animation="wave" height={10} width="50%" />
          </CardContent>
          <CardActions>
            <Box display="flex" flexDirection="row" justifyContent="flex-end" width="100%">
              {actionItems()}
            </Box>
          </CardActions>
        </Card>
      </>
    )

  return (
    <>
      <Box mb={2}>
        <Stack direction="row" minHeight="35px">
          <Button variant="outlined" startIcon={<ArrowBackIcon />} onClick={() => navigate('/reports')} sx={{ position: 'absolute' }}>
            {t('sidebar:reports')}
          </Button>
          {!report?.downloadable && (
            <Box display="flex" justifyContent="center" flexDirection="row" width="100%">
              <LockedFeature.Title />
            </Box>
          )}
        </Stack>
      </Box>

      <Card className="ReportCardPreview">
        <CardHeader
          title={report?.title[userLanguage] !== '' ? report?.title[userLanguage] : report?.title['en']}
          action={actionItems()}
          className="header-title"
        />

        {report?.coverUrls && report.coverUrls.length && (
          <ReportCoverSlider coverUrls={report.coverUrls} count={1} padding={0} showIndex={false} infinite={true} />
        )}

        <CardContent sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.05)' }}>
          <Grid container direction="row" alignItems="center">
            <Grid item xs={6} md={8}>
              <Grid container rowSpacing={1} flexWrap="wrap" spacing={1}>
                {reportGames?.map((game) => {
                  return (
                    <Grid item key={game.id}>
                      <GRTooltip key={game.id} content={game.resolvedName}>
                        <GameLink game={game}>
                          <GameIcon src={game.icons || game.icon} gameName={game.resolvedName} size="normal" />
                        </GameLink>
                      </GRTooltip>
                    </Grid>
                  )
                })}
              </Grid>
            </Grid>

            <Grid item xs={6} md={4}>
              {/* One analyst */}
              {report?.analysts && report?.analysts.length === 1 && (
                <Analyst name={report.analysts[0].name} picture={report.analysts[0]?.picture} title={report.analysts[0]?.title} direction="row-reverse" />
              )}

              {/* Multiple analysts */}
              {report?.analysts && report.analysts.length > 1 && <Analysts analysts={report.analysts} max={4} />}

              {/* No analyst */}
              {(!report?.analysts || report.analysts.length === 0) && (
                <Analyst name="GameRefinery" title="Report" direction="row-reverse" icon={<InsertDriveFileIcon />} mr={2} />
              )}
            </Grid>
          </Grid>
        </CardContent>

        <CardContent sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.05)' }}>
          <Stack display="flex" direction="row" alignItems="center" flexWrap="wrap">
            {report?.conventionalCategories && report?.conventionalCategories.length > 0 && (
              <>
                {report.conventionalCategories.map((categoryId) => {
                  const category = categories?.[categoryId]
                  return <Chip key={categoryId} label={category?.name} className="chip" />
                })}

                <Divider orientation="vertical" flexItem className="divider" />
              </>
            )}
            {report?.conventionalGenres && report?.conventionalGenres.length > 0 && (
              <>
                {report.conventionalGenres.map((genreId) => {
                  const genre = genres?.[genreId]
                  return <Chip key={genreId} label={genre.genre.name} className="chip" />
                })}
                <Divider orientation="vertical" flexItem className="divider" />
              </>
            )}

            {report?.conventionalSubgenres && report?.conventionalSubgenres.length > 0 && (
              <>
                {report?.conventionalSubgenres.map((subgenreId) => {
                  const subgenre = subgenres?.[subgenreId]
                  return <Chip key={subgenreId} label={subgenre.subgenre.name} className="chip Chip--secondary-light" />
                })}
                <Divider orientation="vertical" flexItem className="divider" />
              </>
            )}
            {report?.tags &&
              report?.tags.map((tagId) => {
                const tag = reportTags?.find((tag) => tag.id === tagId)
                return <Chip key={tagId} label={tag?.name} className="chip Chip--primary-light" />
              })}
          </Stack>
          <Box>
            <HtmlContentParser
              rawHtml={report?.comment[userLanguage] !== '' ? report?.comment[userLanguage] : report?.comment['en']}
              parserOptions={screenshotsModalParserOptions}
            />
          </Box>
        </CardContent>

        <CardActions>
          <Box display="flex" flexDirection="row" justifyContent="flex-end" width="100%">
            {actionItems()}
          </Box>
        </CardActions>
      </Card>
    </>
  )
}

export default ReportCardPreview
