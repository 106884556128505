import { Screenshot, useGetScreenshotsByConceptIdsQuery, useGetScreenshotsByFeaturesQuery } from '../../../api/core'
import { uniqueArray } from '../../../helpers/arrays'
import { useGetGameList } from '../../game/hooks/gameHooks'
import { FeatureGame } from '../../game/types/Game'

type LegacyScreenshot = Screenshot & {
  id?: string
  features: { [legacyId: string]: number }
  conceptIds: string[]
}

/**
 * We need to add feature legacyIds, because Genre selection filters are using it. Also, some places
 * are using 'screenshotId' param instead of just 'id'. Maybe fix that, if possible?
 *
 * @param screenshot to add needed params
 */
const legacyFeatureMapper = (screenshot: LegacyScreenshot): Screenshot => ({
  ...screenshot,
  screenshotId: screenshot.screenshotId || screenshot.id,
  features: Object.keys(screenshot.features).map((featureLegacyId) => ({
    featureLegacyId: parseInt(featureLegacyId, 10),
  })),
})

/**
 * Three queries: First ones gets all screenshots with given features & concepts. Then third one fetches all games
 * that screenshots are from. Return function combines games with screenshots.
 *
 * @param choices
 */
export const useGamesByFeatureScreenshots = (choices: number[], concepts: string[]) => {
  const { data: screenshotsByFeatures } = useGetScreenshotsByFeaturesQuery({ choices }, { skip: !choices?.length })
  const { data: screenshotsByConcepts } = useGetScreenshotsByConceptIdsQuery(concepts, { skip: !concepts?.length })

  // Combine the arrays
  const combinedScreenshots: Screenshot[] = [...(screenshotsByFeatures ?? []), ...(screenshotsByConcepts ?? [])]
  // Filter out duplicate screenshot IDs
  const uniqueScreenshotIds: string[] = [...new Set(combinedScreenshots.map((screenshot) => screenshot?.id).filter((id) => id))] as string[]
  // Filter out screenshots that are not in the uniqueScreenshotIds array
  const screenshots = combinedScreenshots
    .filter((screenshot) => {
      if (screenshot && screenshot.id) {
        return uniqueScreenshotIds.includes(screenshot.id)
      }
      return false
    })
    .sort((a, b) => (a.versionReleaseDate || 0) - (b.versionReleaseDate || 0))

  const { data: games, isFetching } = useGetGameList(
    {
      gameIds: uniqueArray(screenshots?.map(({ gameId }) => gameId) || []) as string[],
      include: 'name,artist,icon,icons,visibility,genreId,archive,sranks,sdranks,appId,conventionalSubgenreId',
    },
    { skip: !screenshots }
  )
  const featureScreenshots = (screenshotsByFeatures as LegacyScreenshot[])?.map(legacyFeatureMapper)
  const conceptScreenshots = (screenshotsByConcepts as LegacyScreenshot[])?.map(legacyFeatureMapper)

  return {
    data: !games
      ? undefined
      : games.map((game) => {
          const featureGame = new FeatureGame(game)
          featureGame.featureScreenshots = featureScreenshots?.filter(({ gameId }) => gameId === game.id)
          featureGame.conceptScreenshots = conceptScreenshots?.filter(({ gameId }) => gameId === game.id)
          return featureGame
        }),
    isFetching,
  }
}
