import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Box, Divider, Typography } from '@mui/material'

import GameSubgenrePicker from '../../../../components/GameSubgenrePicker/GameSubgenrePicker'
import { SearchInputWithDebounce } from '../../../../components/SearchInputWithDebounce/SearchInputWithDebounce'
import { useError } from '../../../../hooks/useError'
import { PromiseWithAbort } from '../../../../types/PromiseWithAbort'
import { SubgenreMap } from '../../../account'
import { GameSearchDialogConfig } from '../../../game-search/types/GameSearchDialogConfig'
import { SearchGamesQueryParams } from '../../../game-search/types/SearchGamesQueryParams'
import { useGameSearch } from '../../../game/hooks/gameHooks'
import { Game } from '../../../game/types/Game'
import SelectedSubgenreChips from '../../../news/components/SelectedSubgenresChips/SelectedSubgenreChips'
import { useGamesOpenForEveryone } from '../../../settings'
import { GameWithMarketIso } from '../GameSearchDialog/GameSearchDialog'
import { GameSearchResultsTable } from '../GameSearchResultsTable/GameSearchResultsTable'
import './GameSearchDialogSearchTab.scss'

const gameIncludes = [
  'name',
  'names',
  'icon',
  'icons',
  'artist',
  'gpsPerMarket',
  'genreId',
  'subGenreId',
  'analysisOutdated',
  'internal',
  'appId',
  'ranks',
  'owner',
  'internal',
  'stageId',
  'analysisProgressPerMarket',
  'tags',
  'lastAnalyzedVersions',
  'versions',
  'lastAnalysisAt',
  'conventionalSubgenreId',
  'conventionalSubgenre',
  'sranks',
  'sdranks',
  'owner',
  'archive',
]
interface Props {
  marketIso: string
  gameSearchDialogConfig: GameSearchDialogConfig
  selectedGames: { [gameId: string]: Game }
  selectedSubgenres: SubgenreMap
  onGameSelect: (game: Game) => void
  onGameDeselect: (game: Game) => void
  gamesWithMarketIso?: GameWithMarketIso
  enableMarketSelector?: boolean
  selectedGamesCount?: number
  searchResultFilter?: (games: Game[]) => Game[]
  useRawResults?: boolean
  hideSetAsDefault?: boolean
  showTopGames?: boolean
  onClose?: (event: {}, reason: string) => void
  onlyGamesWithPowerscore?: boolean
  onlyGamesWithPowerscoreOrRank?: boolean
}

const GameSearchDialogSearchTab: React.FC<Props> = ({
  marketIso,
  gameSearchDialogConfig,
  selectedGames,
  onGameSelect,
  onGameDeselect,
  gamesWithMarketIso,
  enableMarketSelector,
  selectedGamesCount,
  selectedSubgenres,
  searchResultFilter,
  useRawResults = false,
  hideSetAsDefault,
  showTopGames,
  onClose,
  onlyGamesWithPowerscore = false,
  onlyGamesWithPowerscoreOrRank = false,
}) => {
  const { t } = useTranslation()
  const SEARCH_TEXT_MIN_LENGTH = 2

  const [selectedSubgenresMap, setSelectedSubgenresMap] = useState(selectedSubgenres)
  const [searchText, setSearchText] = useState('')
  const {
    searchGames,
    result: { data: searchedGames, error: searchedGamesError, isLoading: isLoadingSearchedGames },
  } = useGameSearch()
  useError({ error: searchedGamesError })
  const gamesOpenForEveryone = useGamesOpenForEveryone()

  const subgenresSelectionChanged = (selectedSubgenresMap: SubgenreMap) => {
    setSelectedSubgenresMap(selectedSubgenresMap)
  }

  const onSearchTextChange = (newSearchText: string) => {
    setSearchText(newSearchText)
  }

  useEffect(() => {
    const selectedSubgenresArray = Object.keys(selectedSubgenresMap)
    let newSearchGamesPromise: PromiseWithAbort
    if (searchText.length >= SEARCH_TEXT_MIN_LENGTH || selectedSubgenresArray.length > 0) {
      const searchGamesParams: Omit<SearchGamesQueryParams, 'userLanguage'> = {
        term: searchText,
        marketIso: marketIso,
        include: gameIncludes.join(','),
        owned: false,
        onlyGamesWithPowerscore: onlyGamesWithPowerscore,
        onlyGamesWithPowerscoreOrRank: onlyGamesWithPowerscoreOrRank,
      }

      if (!!selectedSubgenresArray.length) {
        searchGamesParams.conventionalSubgenreIds = selectedSubgenresArray.join(',')
      }

      newSearchGamesPromise = searchGames(searchGamesParams)
    }

    return () => newSearchGamesPromise && newSearchGamesPromise.abort()
  }, [marketIso, onlyGamesWithPowerscore, onlyGamesWithPowerscoreOrRank, searchGames, searchText, selectedSubgenresMap])

  const games = useMemo(() => {
    if (!searchedGames || !searchedGames.length) return

    let games = [...searchedGames]
    if (!!useRawResults) {
      return games
    }

    // filter archived games and games not included in selected subgenres
    games = games
      .filter((game) => game.archive)
      .filter((game) => (Object.keys(selectedSubgenres).length ? Object.keys(selectedSubgenres).includes(game.conventionalSubgenreId) : true))

    // filter games with custom filter function
    games = searchResultFilter ? searchResultFilter(games) : games

    return games
  }, [searchResultFilter, searchedGames, selectedSubgenres, useRawResults])

  return (
    <div className="GameSearchDialogSearchTab">
      {gameSearchDialogConfig.hideSubgenrePicker ? (
        <Box mb={2}>
          <SelectedSubgenreChips selectedSubgenres={selectedSubgenresMap} />
        </Box>
      ) : (
        <>
          <h4 className="bottom-margin">{t('select-game:conventionals_picker_title')}</h4>
          <GameSubgenrePicker
            selectionChanged={subgenresSelectionChanged}
            selectedSubgenres={selectedSubgenresMap}
            hideSetAsDefault={hideSetAsDefault}
            showSubgenreDefinitionBtn
            onClose={onClose}
          />
        </>
      )}
      <SearchInputWithDebounce fieldText={t('common:search_game_by_name_publisher')} onDebounce={onSearchTextChange} />
      <Divider className="vertical-margin">
        {!isLoadingSearchedGames && (Object.keys(selectedSubgenresMap).length > 0 || searchText.length >= SEARCH_TEXT_MIN_LENGTH) && games ? (
          games.length > 1 ? (
            <Typography textAlign="center">{t('common:found_matching_the_search_plural', { count: games.length })}</Typography>
          ) : (
            <Typography textAlign="center">{t('common:found_matching_the_search', { count: games.length })}</Typography>
          )
        ) : null}
      </Divider>

      <div className="GameSearchDialog__results">
        {!isLoadingSearchedGames && Object.keys(selectedSubgenresMap).length === 0 && searchText.length < SEARCH_TEXT_MIN_LENGTH && (
          <div className="text-center light-text-color">{t('select-game:games_search_help_text')}</div>
        )}
        {(Object.keys(selectedSubgenresMap).length > 0 || searchText.length >= SEARCH_TEXT_MIN_LENGTH) && (
          <GameSearchResultsTable
            games={games || []}
            marketIso={marketIso}
            isLoading={isLoadingSearchedGames}
            onGameSelect={onGameSelect}
            onGameDeselect={onGameDeselect}
            gameSearchDialogConfig={gameSearchDialogConfig}
            selectedGames={selectedGames}
            enableMarketSelector={enableMarketSelector}
            gamesWithMarketIso={gamesWithMarketIso}
            selectedGamesCount={selectedGamesCount}
            gamesOpenForEveryone={gamesOpenForEveryone}
          />
        )}
      </div>
    </div>
  )
}

export default GameSearchDialogSearchTab
