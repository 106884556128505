import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { CloseRounded } from '@mui/icons-material'
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, IconButton, Typography } from '@mui/material'

import { ScreenshotMap, useGetScreenshotCountsByGameAndCountryQuery } from '../../../../api/core'
import GameSubgenrePicker from '../../../../components/GameSubgenrePicker/GameSubgenrePicker'
import { SearchInputWithDebounce } from '../../../../components/SearchInputWithDebounce/SearchInputWithDebounce'
import { uniqueArray } from '../../../../helpers/arrays'
import { useSubgenrePicker } from '../../../../hooks/useSubgenrePicker'
import { PromiseWithAbort } from '../../../../types/PromiseWithAbort'
import { SubgenreMap } from '../../../account'
import { Game } from '../../../game'
import { useGameSearch, useGames } from '../../../game/hooks/gameHooks'
import { useCurrentMarket } from '../../../markets'
import { GameSearchDialogType } from '../../types/GameSearchDialogConfig'
import { SearchGamesQueryParams } from '../../types/SearchGamesQueryParams'
import { GameSearchResultsTable } from '../GameSearchResultsTable/GameSearchResultsTable'
import './GameSearchWithImplementationsDialog.scss'

const MAX_SELECTABLE = 20

interface Props {
  open: boolean
  onClose: () => void
  onSelectGameIds: (gameIds: string[]) => void
  initialGameIds?: string[]
}

type SelectedGames = { [id: string]: Game }
const emptyInitialGames: string[] = []

const GameSearchWithImplementationsDialog: React.FC<Props> = ({ onClose, onSelectGameIds, open, initialGameIds }) => {
  const { t } = useTranslation()
  const [selectedGames, setSelectedGames] = useState<SelectedGames>({})
  const [searchGamesPromise, setSearchGamesPromise] = useState<PromiseWithAbort>(undefined)
  const {
    searchGames,
    result: { data: searchedGames, isLoading: isLoadingSearchedGames },
  } = useGameSearch()
  const { subgenres, handleSubgenresChange } = useSubgenrePicker()
  const { currentMarketIso } = useCurrentMarket()
  const { games: initGames } = useGames((initialGameIds || emptyInitialGames) as string[], currentMarketIso, true)

  const { data: screenshotMap, isFetching: isFetchingScreenshots } = useGetScreenshotCountsByGameAndCountryQuery()
  const gameIds: string[] = useMemo(() => {
    return uniqueArray(
      Object.values((screenshotMap || {}) as ScreenshotMap)
        .map((map) => Object.keys(map))
        .flat()
    )
  }, [screenshotMap])
  const [searchText, setSearchText] = useState('')

  const onSearchTextChange = (newSearchText: string) => {
    setSearchText(newSearchText)
    doSearchGames(newSearchText, subgenres)
  }

  const getGameSearchParams = useCallback(
    (searchText: string) => {
      const selectedSubgenresArray = Object.keys(subgenres)
      const searchGamesParams: Omit<SearchGamesQueryParams, 'userLanguage'> = {
        term: searchText,
        marketIso: currentMarketIso,
        include:
          'name,names,icon,icons,artist,gpsPerMarket,genreId,subGenreId,analysisOutdated,internal,appId,ranks,owner,internal,stageId,analysisProgressPerMarket,tags,lastAnalyzedVersions,versions,lastAnalysisAt,conventionalSubgenreId,conventionalSubgenre,sranks,sdranks,owner,archive',
        owned: false,
        onlyGamesWithImplExamples: true,
      }
      if (selectedSubgenresArray.length > 0) {
        searchGamesParams.conventionalSubgenreIds = selectedSubgenresArray.join(',')
      }
      return searchGamesParams
    },
    [currentMarketIso, subgenres]
  )

  const doSearchGames = useCallback(
    (newSearchText: string, newSelectedSubgenresMap: SubgenreMap) => {
      if (searchGamesPromise) {
        searchGamesPromise.abort()
      }
      const selectedSubgenresArray = Object.keys(newSelectedSubgenresMap)
      if (newSearchText.length >= 2 || selectedSubgenresArray.length > 0) {
        const newSearchGamesPromise = searchGames(getGameSearchParams(newSearchText))
        setSearchGamesPromise(newSearchGamesPromise)
      }
    },
    [getGameSearchParams, searchGames, searchGamesPromise]
  )

  useEffect(() => {
    if (Object.keys(subgenres).length) {
      searchGames(getGameSearchParams(''))
    }
  }, [getGameSearchParams, searchGames, subgenres])

  useEffect(() => {
    const rawInitGames = initGames ? initGames : undefined

    if (rawInitGames) {
      const initSelectedGames = rawInitGames.reduce((result, game) => {
        result[game.id] = game
        return result
      }, {} as SelectedGames)
      setSelectedGames(initSelectedGames)
    }
  }, [initGames])

  const onClickDone = () => {
    onSelectGameIds(Object.keys(selectedGames))
    onClose()
  }

  const onToggleGame = (game: Game) => {
    if (selectedGames[game.id]) {
      const clone = { ...selectedGames }
      delete clone[game.id]
      setSelectedGames(clone)
    } else {
      setSelectedGames({ ...selectedGames, [game.id]: game })
    }
  }

  const renderableGames = useMemo(() => {
    if (!Object.keys(subgenres).length && !searchText.length) return []

    if (searchedGames && searchedGames.length) {
      const rGames = [...searchedGames].filter((game) => gameIds.includes(game.id))
      return rGames
    } else {
      return searchedGames
    }
  }, [subgenres, searchText.length, searchedGames, gameIds])
  const selectedGamesCount = Object.keys(selectedGames).length

  return (
    <Dialog className="gameSearchWithImplementationsDialog" fullWidth maxWidth="md" open={open} onClose={onClose} closeAfterTransition>
      <DialogTitle>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>{t('compare:select_games')}</Grid>
          <Grid item>
            <IconButton aria-label="close" onClick={onClose} sx={{ m: -1 }}>
              <CloseRounded />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent dividers>
        <div className="gameSearchWithImplementationsDialog__Content">
          <Typography variant="h4" mb={1}>
            {t('select-game:conventionals_picker_title')}
          </Typography>
          <GameSubgenrePicker selectionChanged={handleSubgenresChange} selectedSubgenres={subgenres} hideSetAsDefault showSubgenreDefinitionBtn />
          <SearchInputWithDebounce fieldText={t('common:search_game_by_name_publisher')} onDebounce={onSearchTextChange} />
          <Divider className="vertical-margin">
            {renderableGames ? (
              renderableGames.length > 1 ? (
                <Typography textAlign="center">{t('common:found_matching_the_search_plural', { count: renderableGames.length })}</Typography>
              ) : (
                <Typography textAlign="center">{t('common:found_matching_the_search', { count: renderableGames.length })}</Typography>
              )
            ) : null}
          </Divider>
          {isFetchingScreenshots || isLoadingSearchedGames ? (
            <Grid container justifyContent="center" my={2}>
              <CircularProgress />
            </Grid>
          ) : (
            renderableGames && (
              <GameSearchResultsTable
                games={renderableGames as Game[]}
                marketIso={currentMarketIso}
                gameSearchDialogConfig={{ type: GameSearchDialogType.GameSelector, gameSelectorCounts: { min: 1, max: MAX_SELECTABLE } }}
                selectedGames={selectedGames}
                onGameSelect={onToggleGame}
                selectedGamesCount={selectedGamesCount}
              />
            )
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item xs={4}>
            <Button variant="contained" color="warning" onClick={() => setSelectedGames({})} disabled={!Object.keys(selectedGames).length}>
              {t('select-game:clear_selected_games')}
            </Button>
          </Grid>
          <Grid item xs={4} textAlign="center">
            <Typography
              variant="subtitle2"
              dangerouslySetInnerHTML={{ __html: t('select-game:games_selected', { count: Object.keys(selectedGames).length, max: MAX_SELECTABLE }) }}
            />
          </Grid>
          <Grid item xs={4} textAlign="right">
            <Button variant="contained" color="success" onClick={onClickDone}>
              {t('common:done')}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}

export default GameSearchWithImplementationsDialog
