import classNames from 'classnames'
import { FC, MouseEventHandler, ReactNode } from 'react'

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { TableSortLabel } from '@mui/material'

import { SortOrder } from '../../GRTable'
import styles from './SortLabel.module.scss'

type SortLabelProps = {
  children?: ReactNode
  active?: boolean
  direction?: SortOrder
  onClick: MouseEventHandler<HTMLElement>
  className?: string
}

export const SortLabel: FC<SortLabelProps> = ({ active, direction, onClick, className, children }) => {
  const sortLabelClasses = classNames(styles.root, className, { [styles.active]: active })
  return (
    <TableSortLabel active={active} direction={direction} onClick={onClick} className={sortLabelClasses} IconComponent={ArrowDropDownIcon}>
      {children}
    </TableSortLabel>
  )
}
