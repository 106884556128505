import { Category, Feature } from '../../../api/core'
import { Analysis } from './../../analysis/types/Analysis'

export const getCategoryEffect = (category: Category, analysis: Analysis, feature?: Feature): number => {
  if (analysis && analysis.topFeatures && analysis.topFeatures.hasOwnProperty(category.id)) {
    let categoryEffects = analysis.topFeatures[category.id]

    if (feature) {
      let featureEffect = categoryEffects.find((effect) => effect.featureId === feature.id)
      if (featureEffect) {
        return +featureEffect.gpsEffect.toFixed(2)
      }
      return 0
    }

    let totalEffect = 0
    categoryEffects.forEach((effect) => {
      totalEffect += effect.gpsEffect
    })
    return +totalEffect.toFixed(2)
  }

  return 0
}
