import { MarketShare } from '../../market-share/types/MarketShare'
import { MarketShareNumberTypeId } from '../../market-share/types/MarketShareNumberType'
import { TopGame } from '../../top-game/types/TopGame'
import { GameQuarter } from '../types/GameQuarter'
import { MarketShareQuarter } from '../types/MarketShareQuarter'
import { Quarter } from '../types/Quarter'

export enum GameDataValueId {
  ConventionalSubgenreId = 'conventionalSubgenreId',
  Artist = 'artist',
  ConventionalCategoryId = 'conventionalCategoryId',
  ConventionalGenreId = 'conventionalGenreId',
}

export enum GameDataValueName {
  ConventionalSubgenre = 'conventionalSubgenre',
  Artist = 'artist',
  ConventionalCategory = 'conventionalCategory',
  ConventionalGenre = 'conventionalGenre',
}

export enum QuarterDataKey {
  RevenuePercentage = 'revenuePercentage',
  RevenueTop500Value = 'revenueTop500Value',
  DownloadsPercentage = 'downloadsPercentage',
  DownloadsTop500Value = 'downloadsTop500Value',
}

class QuarterService {
  getPreviousQuarterIdentifierForQuarter(quarter: number, year: number) {
    let newQuarter
    let newYear

    switch (quarter) {
      case 1:
        newQuarter = 4
        newYear = year - 1
        break

      case 2:
        newQuarter = 1
        newYear = year
        break

      case 3:
        newQuarter = 2
        newYear = year
        break

      case 4:
        newQuarter = 3
        newYear = year
        break
    }

    return `${newQuarter}-${newYear}`
  }

  getQuarterStartAndEndTimestamp(quarter: number, year: number) {
    let quarterStartMonth = 0
    let quarterEndMonth = 0

    switch (quarter) {
      case 1:
        quarterStartMonth = 0
        quarterEndMonth = 2
        break
      case 2:
        quarterStartMonth = 3
        quarterEndMonth = 5
        break
      case 3:
        quarterStartMonth = 6
        quarterEndMonth = 8
        break
      case 4:
        quarterStartMonth = 9
        quarterEndMonth = 11
        break
    }

    return {
      startTimestamp: new Date(Date.UTC(year, quarterStartMonth, 1)).getTime(),
      endTimestamp: new Date(Date.UTC(year, quarterEndMonth + 1, 0, 23, 59, 59)).getTime(),
    }
  }

  getChangeDataForPreviousQuarterValue(dataKey: QuarterDataKey, currentQuarterId: string, quarters: { [quarterIdentifier: string]: GameQuarter }) {
    const previousQuarterIdentifier = this.getPreviousQuarterIdentifierForQuarter(quarters[currentQuarterId].quarter, quarters[currentQuarterId].year)

    if (quarters[previousQuarterIdentifier]) {
      return this.getChangeDataForComparisonQuarterValue(dataKey, currentQuarterId, previousQuarterIdentifier, quarters)
    } else {
      return 0
    }
  }

  getChangeDataForComparisonQuarterValue(
    dataKey: QuarterDataKey,
    currentQuarterId1: string,
    currentQuarterId2: string,
    quarters: { [quarterIdentifier: string]: GameQuarter }
  ) {
    return quarters[currentQuarterId1][dataKey] - quarters[currentQuarterId2][dataKey]
  }

  getAndCalculateGameQuarters(games: TopGame[], quarters: Quarter[]) {
    const gameMarketLevelQuartersPerAppId = new Map<string, GameQuarter>()
    const top500ValuesPerQuarterIdentifier = new Map<string, { revenueTop500Value: number; downloadsTop500Value: number }>()

    games.forEach((game) => {
      quarters.forEach((quarter) => {
        let top500ValuesForQuarter = top500ValuesPerQuarterIdentifier.get(quarter.quarterIdentifier)
        const appQuarterId = `${game.appId}-${quarter.quarterIdentifier}`

        if (!top500ValuesForQuarter) {
          top500ValuesPerQuarterIdentifier.set(quarter.quarterIdentifier, {
            revenueTop500Value: 0,
            downloadsTop500Value: 0,
          })

          top500ValuesForQuarter = top500ValuesPerQuarterIdentifier.get(quarter.quarterIdentifier)
        }

        gameMarketLevelQuartersPerAppId.set(appQuarterId, new GameQuarter(quarter))
        const revenueDownloadsObject = quarter.revenuesAndDownloads[game.appId.toString()]

        if (revenueDownloadsObject) {
          let gameMarketLevelForQuarter = gameMarketLevelQuartersPerAppId.get(appQuarterId)
          let top500ValuesForQuarter = top500ValuesPerQuarterIdentifier.get(quarter.quarterIdentifier)

          const revenueValue = revenueDownloadsObject.revenue
          const downloadsValue = revenueDownloadsObject.downloads

          if (gameMarketLevelForQuarter && top500ValuesForQuarter) {
            if (quarter.grossingGames.includes(game.appId) || quarter.freeGames.includes(game.appId)) {
              gameMarketLevelForQuarter.revenueTop500Value = revenueValue
              top500ValuesForQuarter.revenueTop500Value += gameMarketLevelForQuarter.revenueTop500Value
              gameMarketLevelForQuarter.downloadsTop500Value = downloadsValue
              top500ValuesForQuarter.downloadsTop500Value += gameMarketLevelForQuarter.downloadsTop500Value
            }
          }
        }
      })
    })

    const valuesForGameMarketLevelQuartersPerAppId = gameMarketLevelQuartersPerAppId.values()

    for (let gameQuarter of valuesForGameMarketLevelQuartersPerAppId) {
      const totalQuarter = top500ValuesPerQuarterIdentifier.get(gameQuarter.quarterIdentifier)
      if (totalQuarter) {
        gameQuarter.revenuePercentage = totalQuarter.revenueTop500Value ? gameQuarter.revenueTop500Value / totalQuarter.revenueTop500Value : 0
        gameQuarter.downloadsPercentage = totalQuarter.downloadsTop500Value ? gameQuarter.downloadsTop500Value / totalQuarter.downloadsTop500Value : 0
      }
    }

    return {
      topGamesWithGameQuarters: games.map((game) => {
        quarters.forEach((quarter) => {
          const quarterForGame = gameMarketLevelQuartersPerAppId.get(`${game.appId}-${quarter.quarterIdentifier}`)

          if (!game.marketLevelQuarters) {
            game.marketLevelQuarters = {}
          }

          if (quarterForGame) {
            game.marketLevelQuarters[quarter.quarterIdentifier] = quarterForGame
          }
        })
        return game
      }),
      calculatedQuarters: quarters.map((quarter) => {
        const top500ValuesPerQuarter = top500ValuesPerQuarterIdentifier.get(quarter.quarterIdentifier)

        if (top500ValuesPerQuarter) {
          quarter.revenueTop500Value = top500ValuesPerQuarter.revenueTop500Value | 0
          quarter.downloadsTop500Value = top500ValuesPerQuarter.downloadsTop500Value | 0
        }
        return quarter
      }),
    }
  }

  getAndCalculateMarketShares(games: TopGame[], quarters: Quarter[], gameDataValueId: GameDataValueId, gameDataValueName: GameDataValueName) {
    const newMarketShares: { [key: string]: MarketShare } = {}

    games.forEach((game) => {
      if (!newMarketShares[game[gameDataValueId]] && game[gameDataValueName]) {
        newMarketShares[game[gameDataValueId]] = new MarketShare(game[gameDataValueId], game[gameDataValueName])

        quarters.forEach((quarter) => {
          const newQuarter = new MarketShareQuarter(quarter)
          newMarketShares[game[gameDataValueId]].quarters[newQuarter.quarterIdentifier] = newQuarter
        })
      }
    })

    const sortedGrossingGamesByQuarter: { [key: string]: TopGame[] } = {}
    const sortedFreeGamesByQuarter: { [key: string]: TopGame[] } = {}

    Object.values(newMarketShares).forEach((newMarketShare) => {
      Object.values(newMarketShare.quarters).forEach((quarter) => {
        if (!sortedGrossingGamesByQuarter[quarter.quarterIdentifier]) {
          sortedGrossingGamesByQuarter[quarter.quarterIdentifier] = [...games].sort((a: TopGame, b: TopGame) => {
            return a.marketLevelQuarters[quarter.quarterIdentifier].revenueTop500Value < b.marketLevelQuarters[quarter.quarterIdentifier].revenueTop500Value
              ? 1
              : -1
          })
        }
        let grossingGames = sortedGrossingGamesByQuarter[quarter.quarterIdentifier]
        grossingGames.length = grossingGames.length > 500 ? 500 : grossingGames.length
        grossingGames = grossingGames.filter((game) => {
          return game[gameDataValueId] === newMarketShare.id
        })
        quarter.grossingGames = grossingGames.map((game) => game.appId)

        if (!sortedFreeGamesByQuarter[quarter.quarterIdentifier]) {
          sortedFreeGamesByQuarter[quarter.quarterIdentifier] = [...games].sort((a: TopGame, b: TopGame) => {
            return a.marketLevelQuarters[quarter.quarterIdentifier].downloadsTop500Value < b.marketLevelQuarters[quarter.quarterIdentifier].downloadsTop500Value
              ? 1
              : -1
          })
        }
        let freeGames = sortedFreeGamesByQuarter[quarter.quarterIdentifier]
        freeGames.length = freeGames.length > 500 ? 500 : freeGames.length
        freeGames = freeGames.filter((game) => {
          return game[gameDataValueId] === newMarketShare.id
        })
        quarter.freeGames = freeGames.map((game) => game.appId)
        quarter.games = [...new Set([...grossingGames, ...freeGames])]
      })
    })

    // Calculate needed data for market share quarters
    games.forEach((game) => {
      Object.values(game.marketLevelQuarters).forEach((gameQuarter) => {
        if (newMarketShares[game[gameDataValueId]]) {
          const marketShareQuarter = newMarketShares[game[gameDataValueId]].quarters[gameQuarter.quarterIdentifier]

          marketShareQuarter.revenueValue = marketShareQuarter.revenueValue + gameQuarter.revenueValue
          if (marketShareQuarter.grossingGames.includes(game.appId)) {
            marketShareQuarter.revenueTop500Value = marketShareQuarter.revenueTop500Value + gameQuarter.revenueTop500Value
          }

          marketShareQuarter.downloadsValue = marketShareQuarter.downloadsValue + gameQuarter.downloadsValue
          if (marketShareQuarter.freeGames.includes(game.appId)) {
            marketShareQuarter.downloadsTop500Value = marketShareQuarter.downloadsTop500Value + gameQuarter.downloadsTop500Value
          }
        }
      })
    })

    Object.values(newMarketShares).forEach((marketShare) => {
      Object.values(marketShare.quarters).forEach((marketShareQuarter) => {
        const matchingQuarter = quarters.find((quarter) => quarter.quarterIdentifier === marketShareQuarter.quarterIdentifier)
        if (matchingQuarter) {
          marketShareQuarter.revenuePercentage = marketShareQuarter.revenueTop500Value
            ? marketShareQuarter.revenueTop500Value / matchingQuarter.revenueTop500Value
            : 0
          marketShareQuarter.downloadsPercentage = marketShareQuarter.downloadsTop500Value
            ? marketShareQuarter.downloadsTop500Value / matchingQuarter.downloadsTop500Value
            : 0
        }
      })
    })

    // Calculate needed data for game market share quarters
    games.forEach((game) => {
      game.selectedScopeTypeQuarters = {}

      quarters.forEach((quarter) => {
        const gameQuarter = new GameQuarter(quarter)
        const newMarketShareForGameDataValueId = newMarketShares[game[gameDataValueId]]

        gameQuarter.revenueValue = game.marketLevelQuarters[gameQuarter.quarterIdentifier].revenueValue
        // Calculate game quarters revenue data if game is grossing game
        if (newMarketShareForGameDataValueId && newMarketShareForGameDataValueId.quarters[gameQuarter.quarterIdentifier].grossingGames.includes(game.appId)) {
          gameQuarter.revenueTop500Value = game.marketLevelQuarters[gameQuarter.quarterIdentifier].revenueTop500Value

          const newMarketShareQuarterData = newMarketShareForGameDataValueId.quarters[gameQuarter.quarterIdentifier]
          gameQuarter.revenuePercentage = newMarketShareQuarterData.revenueTop500Value
            ? gameQuarter.revenueTop500Value / newMarketShareQuarterData.revenueTop500Value
            : 0
        }

        gameQuarter.downloadsValue = game.marketLevelQuarters[gameQuarter.quarterIdentifier].downloadsValue
        // Calculate game quarters downloads data if game is top free game
        if (newMarketShareForGameDataValueId && newMarketShareForGameDataValueId.quarters[gameQuarter.quarterIdentifier].freeGames.includes(game.appId)) {
          gameQuarter.downloadsTop500Value = game.marketLevelQuarters[gameQuarter.quarterIdentifier].downloadsTop500Value

          const newMarketShareQuarterData = newMarketShareForGameDataValueId.quarters[gameQuarter.quarterIdentifier]
          gameQuarter.downloadsPercentage = newMarketShareQuarterData.downloadsTop500Value
            ? gameQuarter.downloadsTop500Value / newMarketShareQuarterData.downloadsTop500Value
            : 0
        }

        game.selectedScopeTypeQuarters[gameQuarter.quarterIdentifier] = gameQuarter
      })
    })

    return Object.values(newMarketShares)
  }

  getIsQuarterComplete(quarter: Quarter) {
    const quarterEndDate = new Date(quarter.endTimestamp)
    quarterEndDate.setDate(quarterEndDate.getDate() + 7)
    const nowTimestamp = +new Date()
    return nowTimestamp < quarterEndDate.getTime()
  }

  mirrorSelectedQuartersIfNeeded(value: string, index: Number, selectedQuarters: Quarter[]) {
    let quarterIdentifierArray: string[] = []

    if (value === selectedQuarters[index === 0 ? 1 : 0].quarterIdentifier) {
      quarterIdentifierArray = [selectedQuarters[1].quarterIdentifier, selectedQuarters[0].quarterIdentifier]
    } else if (index === 0) {
      quarterIdentifierArray = [value, selectedQuarters[1].quarterIdentifier]
    } else if (index === 1) {
      quarterIdentifierArray = [selectedQuarters[0].quarterIdentifier, value]
    }

    return quarterIdentifierArray
  }

  grTableSortAccessorForRevenue(marketShare: MarketShare, quarterIdentifier: string | undefined, marketShareNumberTypeId: MarketShareNumberTypeId | undefined) {
    if (marketShareNumberTypeId && quarterIdentifier) {
      switch (marketShareNumberTypeId) {
        case MarketShareNumberTypeId.Percentage:
          return marketShare.quarters[quarterIdentifier].revenuePercentage

        case MarketShareNumberTypeId.Value:
          return marketShare.quarters[quarterIdentifier].revenueTop500Value
      }
    }

    return 0
  }

  grTableSortAccessorForRevenueChange(
    marketShare: MarketShare,
    quarterIdentifier1: string | undefined,
    quarterIdentifier2: string | undefined,
    marketShareNumberTypeId: MarketShareNumberTypeId | undefined
  ) {
    if (marketShareNumberTypeId && quarterIdentifier1 && quarterIdentifier2) {
      switch (marketShareNumberTypeId) {
        case MarketShareNumberTypeId.Percentage:
          return quarterService.getChangeDataForComparisonQuarterValue(
            QuarterDataKey.RevenuePercentage,
            quarterIdentifier1,
            quarterIdentifier2,
            marketShare.quarters
          )

        case MarketShareNumberTypeId.Value:
          return quarterService.getChangeDataForComparisonQuarterValue(
            QuarterDataKey.RevenueTop500Value,
            quarterIdentifier1,
            quarterIdentifier2,
            marketShare.quarters
          )
      }
    }

    return 0
  }

  grTableSortAccessorForDownloads(
    marketShare: MarketShare,
    quarterIdentifier: string | undefined,
    marketShareNumberTypeId: MarketShareNumberTypeId | undefined
  ) {
    if (marketShareNumberTypeId && quarterIdentifier) {
      switch (marketShareNumberTypeId) {
        case MarketShareNumberTypeId.Percentage:
          return marketShare.quarters[quarterIdentifier].downloadsPercentage

        case MarketShareNumberTypeId.Value:
          return marketShare.quarters[quarterIdentifier].downloadsTop500Value
      }
    }

    return 0
  }

  grTableSortAccessorForDownloadsChange(
    marketShare: MarketShare,
    quarterIdentifier1: string | undefined,
    quarterIdentifier2: string | undefined,
    marketShareNumberTypeId: MarketShareNumberTypeId | undefined
  ) {
    if (marketShareNumberTypeId && quarterIdentifier1 && quarterIdentifier2) {
      switch (marketShareNumberTypeId) {
        case MarketShareNumberTypeId.Percentage:
          return quarterService.getChangeDataForComparisonQuarterValue(
            QuarterDataKey.DownloadsPercentage,
            quarterIdentifier1,
            quarterIdentifier2,
            marketShare.quarters
          )

        case MarketShareNumberTypeId.Value:
          return quarterService.getChangeDataForComparisonQuarterValue(
            QuarterDataKey.DownloadsTop500Value,
            quarterIdentifier1,
            quarterIdentifier2,
            marketShare.quarters
          )
      }
    }

    return 0
  }

  grTableSortAccessorForTopGameRevenueChange(
    quarterIdentifier1: string | undefined,
    quarterIdentifier2: string | undefined,
    gameQuarters: { [quarterIdentifier: string]: GameQuarter },
    marketShareNumberTypeId: MarketShareNumberTypeId | undefined
  ) {
    if (marketShareNumberTypeId && quarterIdentifier1 && quarterIdentifier2 && gameQuarters) {
      switch (marketShareNumberTypeId) {
        case MarketShareNumberTypeId.Percentage:
          return quarterService.getChangeDataForComparisonQuarterValue(QuarterDataKey.RevenuePercentage, quarterIdentifier1, quarterIdentifier2, gameQuarters)

        case MarketShareNumberTypeId.Value:
          return quarterService.getChangeDataForComparisonQuarterValue(QuarterDataKey.RevenueTop500Value, quarterIdentifier1, quarterIdentifier2, gameQuarters)
      }
    }

    return 0
  }

  grTableSortAccessorForTopGameDownloadsChange(
    quarterIdentifier1: string | undefined,
    quarterIdentifier2: string | undefined,
    gameQuarters: { [quarterIdentifier: string]: GameQuarter },
    marketShareNumberTypeId: MarketShareNumberTypeId | undefined
  ) {
    if (marketShareNumberTypeId && quarterIdentifier1 && quarterIdentifier2 && gameQuarters) {
      switch (marketShareNumberTypeId) {
        case MarketShareNumberTypeId.Percentage:
          return quarterService.getChangeDataForComparisonQuarterValue(QuarterDataKey.DownloadsPercentage, quarterIdentifier1, quarterIdentifier2, gameQuarters)

        case MarketShareNumberTypeId.Value:
          return quarterService.getChangeDataForComparisonQuarterValue(
            QuarterDataKey.DownloadsTop500Value,
            quarterIdentifier1,
            quarterIdentifier2,
            gameQuarters
          )
      }
    }

    return 0
  }
}

const quarterService = new QuarterService()

export default quarterService
