import React, { useMemo, useState } from 'react'

import { Close } from '@mui/icons-material'
import { Card, Dialog, Grid, IconButton } from '@mui/material'
import Player from '@vimeo/player'

import { FtueVideo } from '../../../../api/core'
import { Game } from '../../../game'
import { Size, useDomSize } from '../../hooks/useDomSize'
import GameChapterList, { SelectedChapterTag } from '../GameChapterList/GameChapterList'
import './FtueVideoFullscreenModal.scss'

interface FtueVideoFullscreenModalProps {
  autoPlay?: boolean
  game?: Game
  onClose: () => void
  position: number
  video: FtueVideo
  title?: string
  volume?: number
  onVolumeChange?: (volume: number) => void
  onTimeChange?: (time: number) => void
  isMuted?: boolean
  handleVolumeMuted?: (isMuted: boolean) => void
  selectedChapterTag?: SelectedChapterTag
  onChapterTagClick?: ({ chapterId, tagId }: SelectedChapterTag) => void
}

const FtueVideoFullscreenModal: React.FC<FtueVideoFullscreenModalProps> = ({
  autoPlay,
  game,
  onClose,
  position,
  video,
  title,
  volume,
  onVolumeChange,
  onTimeChange,
  isMuted,
  handleVolumeMuted,
  selectedChapterTag,
  onChapterTagClick,
}) => {
  const [playerSize, setPlayerSize] = useState<Size>()
  const [currentPosition, setCurrentPosition] = useState(0)
  const [mediaContainer, setMediaContainer] = useState<HTMLDivElement | null>(null)
  const [playerElement, setPlayerElement] = useState<HTMLDivElement | null>(null)

  const player = useMemo(() => {
    if (playerElement && playerSize) {
      const player = new Player(playerElement, {
        autoplay: autoPlay,
        id: parseInt(video.vimeoId, 10),
        ...playerSize,
      })
      if (position) {
        player.setCurrentTime(position)
        setCurrentPosition(position)
      }
      player.on('timeupdate', ({ seconds }) => {
        setCurrentPosition(seconds)
        onTimeChange && onTimeChange(seconds)
      })
      player.on('volumechange', async ({ volume }) => {
        if (onVolumeChange) onVolumeChange(volume)
        const isPlayerMuted = await player.getMuted()
        if (handleVolumeMuted) handleVolumeMuted(isPlayerMuted)
      })

      player.setVolume(volume || 0.5)
      player.setMuted(isMuted || false)
      return player
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playerElement, playerSize, volume, isMuted])

  useDomSize(mediaContainer, 0, setPlayerSize)

  const setPosition = async (seconds: number) => {
    await player?.setCurrentTime(seconds)
    player?.play()
  }

  return (
    <Dialog fullScreen={true} open={true} onClose={onClose} closeAfterTransition className="FtueVideoFullscreenModal">
      <Grid container justifyContent="space-between">
        <Grid item sm={9} textAlign="center" ref={setMediaContainer}>
          <div ref={setPlayerElement} style={playerSize} />
        </Grid>
        <Grid item sm={3} className="sideBar">
          <IconButton className="close" onClick={onClose}>
            <Close />
          </IconButton>
          <Card className="chapterList">
            <GameChapterList
              title={title}
              game={game}
              video={video}
              setPosition={setPosition}
              currentPosition={currentPosition}
              selectedChapterTag={selectedChapterTag}
              onChapterTagClick={onChapterTagClick}
            />
          </Card>
        </Grid>
      </Grid>
    </Dialog>
  )
}

export default FtueVideoFullscreenModal
