import { useEffect, useMemo } from 'react'

import { useGetRevenueAndDownloadAggregatesQuery } from '../../../api/core'
import { useCurrentUserLanguage } from '../../account/hooks/userHooks'
import { SearchGamesQueryParams } from '../../game-search/types/SearchGamesQueryParams'
import { Game } from '../types/Game'
import { useGameSearch } from './gameHooks'

const gameIncludes = [
  'name',
  'names',
  'icon',
  'icons',
  'artist',
  'gpsPerMarket',
  'genreId',
  'subGenreId',
  'appId',
  'owner',
  'tags',
  'conventionalSubgenreId',
  'conventionalSubgenre',
  'archive',
  'reviewId',
  'reviewPublished',
]

const useGetOtherSubgenreGames = (conventionalSubgenreId: string, marketIso: string, skip: boolean = false, tags?: string) => {
  const userLanguage = useCurrentUserLanguage()
  const {
    searchGames,
    result: { data: searchedGames, isLoading: isFetchingOtherSubgenreGames },
  } = useGameSearch()

  useEffect(() => {
    if (!skip) {
      let searchGamesParams: Omit<SearchGamesQueryParams, 'userLanguage'> = {
        term: '',
        marketIso: marketIso,
        include: gameIncludes.join(','),
        owned: false,
        conventionalSubgenreIds: conventionalSubgenreId,
        tags,
      }

      searchGames(searchGamesParams)
    }
  }, [searchGames, conventionalSubgenreId, marketIso, skip, tags])

  let otherSubgenreGames: Game[] | undefined

  if (searchedGames) {
    otherSubgenreGames = searchedGames.filter((game) => {
      return game.gpsPerMarket[marketIso] > 0
    })
  }

  const marketIsos = useMemo(() => {
    return [marketIso]
  }, [marketIso])

  let otherSubgenreGamesAppIds
  if (otherSubgenreGames) {
    otherSubgenreGamesAppIds = otherSubgenreGames.map((game) => {
      return game.appId
    })
  }

  const { data: revenueAndDownloadByApp, isFetching: isFetchingRevenueAndDownloadByApp } = useGetRevenueAndDownloadAggregatesQuery(
    { appIds: otherSubgenreGamesAppIds ? otherSubgenreGamesAppIds : [], marketIsos },
    { skip: skip && isFetchingOtherSubgenreGames }
  )

  const otherSubgenreGamesRevenueAndDownloadAggregates = useMemo(() => {
    if (!otherSubgenreGames || !revenueAndDownloadByApp) {
      return []
    }

    return otherSubgenreGames
      .map((game) => {
        const appAggregates = revenueAndDownloadByApp && revenueAndDownloadByApp[game.appId]
        return new Game(
          {
            ...game,
            revenueAndDownloadAggregatesMap: appAggregates?.countryAggregates || {},
          } as any,
          { userLanguage }
        )
      })
      .sort((a, b) => (a.resolvedName < b.resolvedName ? -1 : 1))
  }, [otherSubgenreGames, revenueAndDownloadByApp, userLanguage])

  return {
    data: otherSubgenreGamesRevenueAndDownloadAggregates,
    isFetching: isFetchingOtherSubgenreGames || isFetchingRevenueAndDownloadByApp,
  }
}

export default useGetOtherSubgenreGames
