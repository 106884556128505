import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Hardware } from '@mui/icons-material'
import { Box, Card, Grid, IconButton, TableContainer, Typography } from '@mui/material'

import { Choice, Tag, useGetAllMarketFeaturesQuery, useGetKeywordListQuery, useGetScreenshotCountsQuery } from '../../../../api/core'
import { GRTable, GRTableColumn } from '../../../../components/GRTable/GRTable'
import { GRTooltip } from '../../../../components/GRTooltip/GRTooltip'
import languageService from '../../../../services/LanguageService'
import { useCurrentUserLanguage } from '../../../account/hooks/userHooks'
import { FeatureLink } from '../../../feature/components/FeatureLink'
import { FeaturesAndKeywordsAutocomplete } from '../../../feature/components/FeaturesAndKeywordsAutocomplete/FeaturesAndKeywordsAutocomplete'
import { TagList } from '../../../feature/components/TagList/TagList'
import { isFeature, isKeyword } from '../../../feature/helpers/helpers'
import { useFeatureTags } from '../../../feature/hooks/useFeatureTags'
import { FeatureAndKeywordSearchResult } from '../../../feature/types/types'
import { EnrichedFeature } from '../../../implementation-examples'
import { useCurrentMarket } from '../../../markets'
import './FeatureTabView.scss'

type FeatureTag = {
  name: string
  id: string
  featureTagId?: string
  featureTagName?: string
  colorHex?: string
  featureTagOrder: number
}

type FeatureRow = EnrichedFeature & {
  tagGroup: FeatureTag & { id: string; name: string }
  screenshotCounts?: number
}

/**
 * FeatureTabView
 */
const FeatureTabView: React.FC = () => {
  const { t } = useTranslation()

  const { currentMarketIso } = useCurrentMarket()
  const userLanguage = useCurrentUserLanguage()
  const { data: featureTags } = useFeatureTags()
  const { data: rawFeatures, isLoading } = useGetAllMarketFeaturesQuery({ marketIso: currentMarketIso })
  const { data: keywords } = useGetKeywordListQuery({ type: 'feature', userLanguage })
  const { data: featureScreenshotCounts = {} } = useGetScreenshotCountsQuery({})

  const containerRef = useRef(null)

  const [selectedTags, setSelectedTags] = useState<Tag[]>([])
  const [searchResult, setSearchResult] = useState<FeatureAndKeywordSearchResult>(null)

  const filteredSelectedTags = useMemo(() => {
    const selectedTargets = selectedTags.map((selectedTag) => selectedTag.targets).flat()
    return [...new Set(selectedTargets)]
  }, [selectedTags])

  const desturctedFeatureTags = useMemo(() => {
    if (!featureTags) return

    return featureTags.reduce((result, featureTag) => {
      featureTag.tags.forEach((tag) => {
        result[tag.id] = {
          name: tag.name,
          id: tag.id,
          featureTagId: featureTag.id,
          featureTagName: featureTag.name,
          colorHex: featureTag.colorHex,
          featureTagOrder: featureTag.order,
        }
      })

      return result
    }, {} as { [key: string]: FeatureTag })
  }, [featureTags])

  const features: FeatureRow[] = useMemo(() => {
    if (!rawFeatures || !desturctedFeatureTags) {
      return []
    }

    const features = rawFeatures
      .map((feature) => ({
        ...feature,
        name: languageService.getTranslation('features', feature.legacyId.toString()),
        tagGroup: { ...desturctedFeatureTags[feature.tags[0]] },
        screenshotCounts: featureScreenshotCounts && featureScreenshotCounts[feature.legacyId],
      }))
      .flat()
      .sort((a, b) => {
        return a.tagGroup.featureTagOrder - b.tagGroup.featureTagOrder || a.tagGroup.name.localeCompare(b.tagGroup.name) || a.ordr - b.ordr
      })
    return features
  }, [desturctedFeatureTags, featureScreenshotCounts, rawFeatures])

  const filteredFeatures = useMemo(() => {
    return features
      .filter((feature) => {
        if (!filteredSelectedTags || !filteredSelectedTags.length) {
          return true
        }
        return filteredSelectedTags.includes(feature.legacyId)
      })
      .filter((feature) => {
        // filter by selected feature, keyword or string
        if (isKeyword(searchResult)) {
          return searchResult.linkedIds.includes(feature.legacyId)
        } else if (isFeature(searchResult)) {
          return searchResult.legacyId === feature.legacyId
        } else if (typeof searchResult === 'string') {
          if (!keywords) return false

          const keywordMatch = keywords
            .filter((keyword) => keyword.name.toLocaleLowerCase().includes(searchResult.toLocaleLowerCase()))
            .some((keyword) => keyword.linkedIds.includes(feature.legacyId))

          return keywordMatch || feature.name.toLowerCase().includes(searchResult.toLowerCase())
        }

        return true
      })
  }, [features, filteredSelectedTags, searchResult, keywords])

  const buildRowChoice = (choices: Choice[]) => {
    const convertChoice = (choice: string) => {
      switch (choice.toLowerCase().trim()) {
        case 'yes':
          return 'yes'

        case 'no':
        case 'n/a':
        case 'na':
          return 'no'

        default:
          return 'text'
      }
    }
    const filteredChoice = choices
      .filter((choice) => !['yes', 'no'].includes(convertChoice(choice.choice)))
      .map((choice) => languageService.getTranslation('choices', choice.weightId.toString()))

    return !filteredChoice.length ? '' : `: ${filteredChoice.join(' / ')}`
  }

  const initialColumns: GRTableColumn<FeatureRow, FeatureRow>[] = useMemo(() => {
    return [
      {
        labelAccessor: '',
        columns: [
          {
            labelAccessor: t('common:tag_group'),
            accessor: ({ row }) => (
              <Typography sx={{ color: row.tagGroup.colorHex, fontWeight: 'bold', fontSize: '14px' }}>{row.tagGroup.featureTagName}</Typography>
            ),
            sortable: true,
            sortAccessor: ({ row }) => row.tagGroup.featureTagName,
            headerCellProps: { sx: { maxWidth: 100 } },
            cellProps: { align: 'left' },
          },
        ],
      },
      {
        columns: [
          {
            labelAccessor: t('common:feature'),
            accessor: ({ row }) => (
              <Box>
                <Grid container spacing={3} sx={{ flexWrap: 'wrap' }}>
                  <Grid item xs={9}>
                    <FeatureLink feature={{ featureName: row.name, featureLegacyId: row.legacyId }}>
                      <Typography className="FeatureTabView__featureText" sx={{ textAlign: 'left' }}>
                        {row.name}
                        <span className="FeatureTabView__featureChoice">{buildRowChoice(row.choices)}</span>
                      </Typography>
                    </FeatureLink>
                  </Grid>
                  <Grid item xs={3} sx={{ textAlign: 'right', alignSelf: 'center' }}>
                    {!!row.screenshotCounts && (
                      <FeatureLink feature={{ featureName: row.name, featureLegacyId: row.legacyId }} initialTab="implementation">
                        <GRTooltip content={t('common:open_feature_implementation')}>
                          <IconButton className="FeatureTabView__icon" color="primary" component="span" size="small" sx={{ p: '4px', textAlign: 'right' }}>
                            <Hardware sx={{ width: '16px', height: '16px' }} />
                          </IconButton>
                        </GRTooltip>
                      </FeatureLink>
                    )}
                  </Grid>
                </Grid>
              </Box>
            ),
            sortable: true,
            sortAccessor: ({ row }) => row.name,
            headerCellProps: { sx: { minWidth: 300 } },
            cellProps: { align: 'left' },
          },
        ],
      },
      {
        columns: [
          {
            labelAccessor: t('common:tags'),
            accessor: ({ row }) => (
              <>
                {desturctedFeatureTags &&
                  row.tags.map((tagId) => (
                    <Box
                      key={tagId}
                      sx={{
                        display: 'inline-flex',
                        fontSize: '12px',
                        px: 1,
                        py: 0.5,
                        mr: 0.5,
                        textTransform: 'uppercase',
                        color: 'white',
                        backgroundColor: desturctedFeatureTags[tagId].colorHex,
                        border: `1px solid ${desturctedFeatureTags[tagId].colorHex}`,
                        borderRadius: '16px',
                      }}
                    >
                      {desturctedFeatureTags[tagId].name}
                    </Box>
                  ))}
              </>
            ),
            headerCellProps: { sx: { minWidth: 150 } },
            cellProps: { align: 'left' },
          },
        ],
      },
    ] as GRTableColumn<FeatureRow, FeatureRow>[]
  }, [desturctedFeatureTags, t])

  const [columns, setColumns] = useState<GRTableColumn<FeatureRow, FeatureRow>[]>(initialColumns)
  const handleColumnsUpdate = useCallback((updatedColumns: GRTableColumn<FeatureRow, FeatureRow>[]) => {
    setColumns(updatedColumns)
  }, [])

  const handleSearchResultChange = useCallback((value: FeatureAndKeywordSearchResult) => {
    setSearchResult(value)
  }, [])

  useEffect(() => {
    handleColumnsUpdate(initialColumns)
  }, [handleColumnsUpdate, initialColumns])

  const handleClearSelectedFeatureTags = useCallback(() => {
    setSelectedTags([])
  }, [])

  return (
    <Box sx={{ marginTop: 7 }} className="FeatureTabView">
      <TagList tagGroups={featureTags} selectedTags={selectedTags} onChange={setSelectedTags} onClear={handleClearSelectedFeatureTags} />
      {features && (
        <Card sx={{ mt: 2 }}>
          <Box sx={{ margin: 2 }}>
            <FeaturesAndKeywordsAutocomplete
              isLoading={isLoading}
              features={features}
              value={searchResult}
              keywords={keywords}
              onChange={handleSearchResultChange}
            />
          </Box>
          <TableContainer ref={containerRef}>
            <GRTable
              columns={columns}
              rows={filteredFeatures}
              scroller={containerRef}
              onColumnsUpdated={handleColumnsUpdate}
              rowIdKey="id"
              isLoading={isLoading}
              hoverable
              striped
            />
          </TableContainer>
        </Card>
      )}
    </Box>
  )
}

export default FeatureTabView
