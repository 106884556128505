import { startOfDay } from 'date-fns'
import { Unit } from 'react-calendar-timeline'

import { startOfNextDay } from '../../../helpers/date'
import { limitValue } from '../../../helpers/limitValue'
import { durationByDurationCategory } from '../const/const'
import { LiveEventCalendarAdditionalData, LiveEventCalendarAdditionalDataId } from '../types/LiveEventAdditionalCalendarData'
import { LiveEventDuration } from '../types/LiveEventDuration'
import { EventTypeStat, IntervalsByDurationCategory, LiveEventTypeDurationCategory } from '../types/LiveEventStatistics'
import { LiveEventTrackerTab } from '../types/LiveEventTrackerTab'
import { TrackingEvent } from '../types/TrackingEvents'

export const resolveTrackingEventMaxTime = (events: TrackingEvent[], initialValue: number = 0) => {
  return events.reduce((acc, event) => {
    return event.end > acc ? event.end : acc
  }, initialValue)
}

export const getLiveEventDurations = () => {
  return [
    new LiveEventDuration('Duration1Day', '1', 1),
    new LiveEventDuration('Duration2Days', '2', 2),
    new LiveEventDuration('Duration3Days', '3', 3),
    new LiveEventDuration('Duration4Days', '4', 4),
    new LiveEventDuration('Duration5Days', '5', 5),
    new LiveEventDuration('Duration6Days', '6', 6),
    new LiveEventDuration('Duration7Days', '7', 7),
    new LiveEventDuration('Duration8-13Days', '8-13', 8, 13),
    new LiveEventDuration('Duration14Days', '14', 14),
    new LiveEventDuration('Duration15-27Days', '15-27', 15, 27),
    new LiveEventDuration('Duration28-31Days', '28-31', 28, 31),
    new LiveEventDuration('Duration32+Days', '32+', 32, Number.MAX_SAFE_INTEGER),
  ]
}

export const getLiveEventCalendarAdditionalDatas = () => {
  return [
    new LiveEventCalendarAdditionalData(LiveEventCalendarAdditionalDataId.GameVersion, 'live-events:game_versions_timeline_group'),
    new LiveEventCalendarAdditionalData(LiveEventCalendarAdditionalDataId.TopIAPs, 'live-events:top_iaps_calendar_group_name'),
    new LiveEventCalendarAdditionalData(LiveEventCalendarAdditionalDataId.RevenueChange, 'live-events:performance_effect_type_revenue'),
    new LiveEventCalendarAdditionalData(LiveEventCalendarAdditionalDataId.DownloadChange, 'live-events:performance_effect_type_download'),
    new LiveEventCalendarAdditionalData(LiveEventCalendarAdditionalDataId.AUChange, 'live-events:performance_effect_type_au'),
    new LiveEventCalendarAdditionalData(LiveEventCalendarAdditionalDataId.AnalystReview, 'live-events:analyst_review_timeline_group'),
  ]
}

/**
 * Get change values (absolute change, percent change) for Event Type Stats duration values
 */
export const getEventStatDurationChange = (stat: EventTypeStat, referenceStat: EventTypeStat | undefined, durationField: keyof EventTypeStat['duration']) => {
  const referenceDurationStart = +(referenceStat?.duration[durationField].value.start || 0)
  const referenceDurationEnd = +(referenceStat?.duration[durationField].value.end || 0)
  const changeValue = +stat.duration[durationField].value.end - +stat.duration[durationField].value.start - (referenceDurationEnd - referenceDurationStart)
  const percentChange = parseFloat((changeValue / (referenceDurationEnd - referenceDurationStart)).toFixed(2))

  return [changeValue, percentChange]
}

/**
 * Categorizes the given event by its duration to given duration categories
 */
export const convertTrackingEventToDurationCategory = (event: TrackingEvent, categories: IntervalsByDurationCategory = durationByDurationCategory) => {
  const eventLength = startOfNextDay(event.end) - startOfDay(event.start).getTime()
  let resolvedCategory: LiveEventTypeDurationCategory = LiveEventTypeDurationCategory.Days0To1
  Object.entries(categories).every(([category, interval]) => {
    if (eventLength >= +interval.start && eventLength <= +interval.end) {
      resolvedCategory = category as LiveEventTypeDurationCategory
      return false
    }

    return true
  })

  return resolvedCategory
}

export const getPreviousCalendarHeaderUnit = (unit: Unit) => {
  return getCalendarUnitByOffset(unit, -1)
}

export const getNextCalendarHeaderUnit = (unit: Unit) => {
  return getCalendarUnitByOffset(unit, 1)
}

const getCalendarUnitByOffset = (unit: Unit, offset: number) => {
  const units: Unit[] = ['second', 'minute', 'hour', 'day', 'month', 'year']
  const currentUnitIndex = units.findIndex((u) => u === unit)
  const newUnitIndex = limitValue(currentUnitIndex + offset, 0, units.length - 1)

  return units[newUnitIndex]
}

/**
 * Resolve additional data item disabled state based on selected subpage and dataId
 */
export const resolveAdditionalDataDisabledState = (subpage: LiveEventTrackerTab, dataId: LiveEventCalendarAdditionalDataId) => {
  return (
    [LiveEventTrackerTab.Events, LiveEventTrackerTab.Statistics].includes(subpage) ||
    (subpage === LiveEventTrackerTab.Feed && dataId !== LiveEventCalendarAdditionalDataId.AnalystReview)
  )
}
/*
const helpLinksByPerformanceEffectType: { [key in PerformanceEffectType]?: string } = {
  [PerformanceEffectType.DAU]: 'https://docs.gamerefinery.com/en/articles/9113643-active-user-dau-mau-metrics-methodology',
  [PerformanceEffectType.MAU]: 'https://docs.gamerefinery.com/en/articles/9113643-active-user-dau-mau-metrics-methodology',
}
export const getHelpLinkByPerformanceEffectType = (dataId: PerformanceEffectType) => {
  return helpLinksByPerformanceEffectType[dataId]
}
*/
