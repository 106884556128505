import { FC, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

import { Dialog, DialogContent, DialogProps } from '@mui/material'

import GRDialogTitle from '../../../../components/GRDialogTitle/GRDialogTitle'
import { GamesDataTableColumnType } from '../../types/MarketExplorerGamesDataTableColumns'
import {
  MarketExplorerGamesDataTable,
  MarketExplorerGamesDataTableRow,
} from '../MarketExplorerGamesData/MarketExplorerGamesDataTable/MarketExplorerGamesDataTable'

type MarketExplorerGamesDataDialogProps = DialogProps & {
  selectedColumns: GamesDataTableColumnType[]
  onSelectedColumnsChange: (columnIds: GamesDataTableColumnType[]) => void
  games?: MarketExplorerGamesDataTableRow[]
  children?: ReactNode
}

/**
 * Modal component for displaying selected segment breakdown games data.
 */
export const MarketExplorerGamesDataDialog: FC<MarketExplorerGamesDataDialogProps> = ({
  open,
  onClose,
  selectedColumns,
  onSelectedColumnsChange,
  games = [],
}) => {
  const { t } = useTranslation()

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
      <GRDialogTitle onClose={() => onClose && onClose({}, 'escapeKeyDown')}>{t('common:list_of_games_title')}</GRDialogTitle>
      <DialogContent dividers>
        <MarketExplorerGamesDataTable rows={games} selectedColumns={selectedColumns} onSelectedColumnsChange={onSelectedColumnsChange} isLoading={false} />
      </DialogContent>
    </Dialog>
  )
}
