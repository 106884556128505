import { startOfDay } from 'date-fns'
import { t } from 'i18next'
import { useMemo } from 'react'

import { useGetQuartersQuery, useGetTopGamesWithAppIdsQuery, useGetTopGrossingGameQuery, useGetTopGrossingGamesQuery } from '../../../api/top-grossing'
import { average } from '../../../helpers/average'
import { RoleEnum } from '../../account'
import { useRoleCheck } from '../../account/hooks/roleHooks'
import { useSubgenresAccessCheck } from '../../account/hooks/roleHooks'
import { Game } from '../../game'
import { MarketShareNumberType, MarketShareNumberTypeId } from '../../market-share/types/MarketShareNumberType'
import { MarketShareScopeType } from '../../market-share/types/MarketShareScopeType'
import { MarketShareScopeTypeFilter, MarketShareScopeTypeFilterAll } from '../../market-share/types/MarketShareScopeTypeFilter'
import { useCurrentMarket } from '../../markets'
import quarterService, { GameDataValueId, GameDataValueName } from '../../quarter/services/QuarterService'
import { getTimestampByGranularity } from '../../revenue-and-downloads/helpers/helpers'
import { GranularityValue } from '../../revenue-and-downloads/types/Filters'
import { useIsGameOpenForEveryone } from '../../settings'
import { PerformanceRank } from '../../update-history/types/types'
import { TopGame } from '../types/TopGame'

export function useTopGrossingGames(marketIso: string, includeArray: string[], limit: number, enableCache: boolean = true) {
  const include = includeArray.join(',')
  const { data: topGames, error, isLoading, isFetching } = useGetTopGrossingGamesQuery({ marketIso, include, limit, enableCache })

  return {
    data: isLoading || isFetching ? [] : topGames,
    isLoading: isLoading || isFetching,
    error,
  }
}

/**
 * Hook that loads rank data for a given app, filters data with optional date range
 */

type UseGameUpdateRankDataHookParams = {
  appId: number
  marketIso: string
  granularity?: GranularityValue
}

export const useTopGrossingGameRanks = ({ appId, marketIso, granularity = GranularityValue.Day }: UseGameUpdateRankDataHookParams) => {
  const { data: topGame, isLoading, isFetching, error } = useGetTopGrossingGameQuery({ appId, marketIso })

  const topGrossingRanks: PerformanceRank[] = useMemo(() => {
    return resolveRanks(topGame?.history || [])
  }, [topGame])

  const freeRanks: PerformanceRank[] = useMemo(() => {
    return resolveRanks(topGame?.dhistory || [])
  }, [topGame])

  // group by granularity
  const [groupedTopGrossingRanks, groupedFreeRanks] = useTopGrossingGameRanksByGranularity({
    ranks: [topGrossingRanks, freeRanks],
    granularity,
  })
  return { topGrossingRanks: groupedTopGrossingRanks, freeRanks: groupedFreeRanks, isLoading: isLoading || isFetching, error }
}

/**
 * Hook that groups ranks by granularity and calculates average rank for each granularity group
 */
const useTopGrossingGameRanksByGranularity = ({
  ranks,
  granularity = GranularityValue.Day,
}: {
  ranks: [topGrossingRanks: PerformanceRank[], freeRanks: PerformanceRank[]]
  granularity?: GranularityValue
}) => {
  return useMemo(() => {
    return ranks.map((ranks) => {
      const ranksGroupedByGranularity = ranks.reduce((acc, rank) => {
        const granularityParsedDate = new Date(rank.ts)
        const parsedDate = getTimestampByGranularity(granularity, granularityParsedDate).getTime()
        const dateFormattedByGranularity = parsedDate
        const current = acc[dateFormattedByGranularity]

        if (!current) {
          acc[dateFormattedByGranularity] = [{ ...rank, ts: dateFormattedByGranularity }]
        } else {
          acc[dateFormattedByGranularity] = [...current, { ...rank, ts: dateFormattedByGranularity }]
        }

        return acc
      }, {} as { [key: number]: PerformanceRank[] })

      const rankAverages = Object.entries(ranksGroupedByGranularity).map(([date, ranks]) => {
        const averageValue = average(ranks.map((rank) => rank.rank))
        const ts = +date
        return { rank: averageValue, ts }
      })

      return resolveRanks(Object.values(rankAverages))
    }) as [groupedTopGrossingRanks: PerformanceRank[], groupedFreeRanks: PerformanceRank[]]
  }, [granularity, ranks])
}

const resolveRanks = (ranks: PerformanceRank[]) => {
  return [...ranks].sort((a, b) => a.ts - b.ts).map((rank) => ({ ...rank, ts: startOfDay(rank.ts).getTime() }))
}

export const useFilteredQuarters = () => {
  const { currentMarketIso: mainMarketIso } = useCurrentMarket()
  const { data: quarters, isFetching: isFetchingQuarters } = useGetQuartersQuery({ marketIso: mainMarketIso })

  const marketShareUnlocked = useRoleCheck(RoleEnum.market_share)
  const filteredQuarters = useMemo(() => {
    if (!quarters) {
      return
    }

    if (marketShareUnlocked) {
      return quarters
    }
    return [quarters[0], quarters[1]]
  }, [quarters, marketShareUnlocked])
  return { filteredQuarters, isLoading: isFetchingQuarters }
}

export const useGetTopGames = (topGamesInclude: string[]) => {
  const { currentMarketIso: mainMarketIso } = useCurrentMarket()
  const { filteredQuarters, isLoading: isFetchingQuarters } = useFilteredQuarters()

  const uniqueAppIds = useMemo(() => {
    let appIds: number[] = []
    filteredQuarters?.forEach((quarter) => {
      appIds = [...appIds, ...quarter.grossingGames, ...quarter.freeGames, ...[1544884479]]
    })

    return [...new Set(appIds.filter((appId) => !!appId))]
  }, [filteredQuarters])

  const { data: topGames, isFetching: isFetchingTopGames } = useGetTopGamesWithAppIdsQuery(
    { marketIso: mainMarketIso, appIds: uniqueAppIds, include: topGamesInclude.join(',') },
    { skip: uniqueAppIds.length === 0 }
  )

  return { topGames, isLoading: isFetchingQuarters || isFetchingTopGames }
}

export const marketShareScopeTypes: MarketShareScopeType[] = [
  {
    name: 'common:conventional_subgenre',
    id: 'subgenre',
    gameDataValueId: GameDataValueId.ConventionalSubgenreId,
    gameDataValueName: GameDataValueName.ConventionalSubgenre,
    allLocalizationKey: 'common:all_subgenres',
  },
  {
    name: 'common:conventional_genre',
    id: 'genre',
    gameDataValueId: GameDataValueId.ConventionalGenreId,
    gameDataValueName: GameDataValueName.ConventionalGenre,
    allLocalizationKey: 'common:all_conventional_genres',
  },
  {
    name: 'common:conventional_category',
    id: 'category',
    gameDataValueId: GameDataValueId.ConventionalCategoryId,
    gameDataValueName: GameDataValueName.ConventionalCategory,
    allLocalizationKey: 'common:all_conventional_categories',
  },
  {
    name: 'common:publisher',
    id: 'publisher',
    gameDataValueId: GameDataValueId.Artist,
    gameDataValueName: GameDataValueName.Artist,
    allLocalizationKey: 'common:all_publishers',
  },
]
export const marketShareNumberTypes: MarketShareNumberType[] = [
  {
    name: 'market-share:number_type_percentages_name',
    id: MarketShareNumberTypeId.Percentage,
  },
  {
    name: 'market-share:number_type_value_name',
    id: MarketShareNumberTypeId.Value,
  },
]

export const useGetMarketShareData = (marketShareScopeType: MarketShareScopeType, topGames?: TopGame[], isFetchingTopGames?: boolean) => {
  const { filteredQuarters, isLoading: isFetchingQuarters } = useFilteredQuarters()

  const marketShareData = useMemo(() => {
    if (topGames && filteredQuarters && !isFetchingTopGames && !isFetchingQuarters) {
      const { topGamesWithGameQuarters, calculatedQuarters } = quarterService.getAndCalculateGameQuarters(topGames, filteredQuarters)
      return {
        topGamesWithGameQuarters,
        calculatedQuarters,
        marketShares: quarterService.getAndCalculateMarketShares(
          topGamesWithGameQuarters,
          calculatedQuarters,
          marketShareScopeType.gameDataValueId,
          marketShareScopeType.gameDataValueName
        ),
      }
    } else {
      return null
    }
  }, [topGames, filteredQuarters, marketShareScopeType, isFetchingTopGames, isFetchingQuarters])

  return { marketShareData, isLoading: isFetchingQuarters }
}

export const useGetMarketShareScopeTypeFilters = (topGames: TopGame[], marketShareScopeType: MarketShareScopeType, isFetchingTopGame?: boolean) => {
  const { marketShareData } = useGetMarketShareData(marketShareScopeType, topGames, isFetchingTopGame)
  const marketShareScopeTypeFilters = useMemo(() => {
    if (marketShareData) {
      const filtersMap: { [key: string]: string } = {}

      marketShareData.topGamesWithGameQuarters.forEach((game) => {
        const gameDataValue = game[marketShareScopeType.gameDataValueId]
        if (gameDataValue && !filtersMap[gameDataValue]) {
          filtersMap[gameDataValue] = game[marketShareScopeType.gameDataValueName]
        }
      })

      const filters: MarketShareScopeTypeFilter[] = Object.keys(filtersMap).map((key) => {
        return { id: key, name: filtersMap[key] }
      })

      filters.sort((a, b) => (a.name < b.name ? -1 : 1))
      filters.unshift({ id: MarketShareScopeTypeFilterAll, name: t(marketShareScopeType.allLocalizationKey) })

      return filters
    } else {
      return null
    }
  }, [marketShareData, marketShareScopeType])

  return { marketShareScopeTypeFilters, isLoading: isFetchingTopGame }
}

export const useShowSubgenreForGame = (game: TopGame | Game): boolean => {
  const hasAccessToSubgenres = useSubgenresAccessCheck()
  const isGameUnlockedForEveryone = useIsGameOpenForEveryone(game)

  if (!hasAccessToSubgenres) {
    if (isGameUnlockedForEveryone) {
      return true
    }

    if (game.rank <= 10) {
      return true
    }

    return false
  }

  return true
}
