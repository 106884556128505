import { difference } from './difference'

type SimpleType = string | number | boolean

const pre = ['string', 'number', 'bool']

const simpleTypeComparator = (x: SimpleType, y: SimpleType): number => {
  if (typeof x !== typeof y) {
    return pre.indexOf(typeof y) - pre.indexOf(typeof x)
  }
  if (x === y) {
    return 0
  }
  return x > y ? 1 : -1
}

type ObjectWithName = Object & { name?: string }

export const nameComparator = (o1: ObjectWithName, o2: ObjectWithName): number => {
  const n1 = o1.name?.toLowerCase()
  const n2 = o2.name?.toLowerCase()
  if (!n1) {
    return -1
  }
  if (!n2) {
    return 1
  }
  if (n1 === n2) {
    return 0
  }
  return n1 > n2 ? 1 : -1
}

/**
 * Note: It doesn't work with arrays of simpleTypes. Could have done that with
 * recursion, but type
 * @param a
 * @param b
 */
export const arraysEqual = (a: SimpleType[], b: SimpleType[]): boolean => {
  if (!a || !b || a.length !== b.length) {
    return false
  }
  const sortedB = [...b].sort(simpleTypeComparator)
  return [...a].sort(simpleTypeComparator).every((elemA, index) => elemA === sortedB[index])
}

/**
 * Remove duplicates from a (recently flattened or other) array
 *
 * @param array of any objects
 */
export const uniqueArray = (array: any[]) => array.filter((item, index) => array.indexOf(item) === index)

/**
 * Inserts an item to an array at given index.
 *
 * @returns a new array with inserted item
 */
export const insertAtIndex = (array: any[], item: any, atIndex: number) => {
  array.splice(atIndex, 0, item)

  return [...array]
}

/**
 * Removes an item from array at index
 */
export const removeFromIndex = <T>(array: T[], atIndex: number): T[] => {
  if (array && array.length > atIndex) {
    const copyOfArray = [...array]
    copyOfArray.splice(atIndex, 1)

    return copyOfArray
  } else {
    // eslint-disable-next-line no-console
    console.warn(`Cannot remove item at index ${atIndex} from an array with length ${array?.length || 0}`)
    return array
  }
}

const merge = <T>(left: T[], right: T[], compare?: (a: T, b: T) => number) => {
  let sortedArr = [] // the sorted items will go here
  while (left.length && right.length) {
    // Insert the smallest item into sortedArr
    const compareValue = compare ? compare(right[0], left[0]) < 0 : right[0] < left[0]
    if (compareValue) {
      sortedArr.push(right.shift() as T)
    } else {
      sortedArr.push(left.shift() as T)
    }
  }
  // Use spread operators to create a new array, combining the three arrays
  return [...sortedArr, ...left, ...right]
}

/**
 * Stable sorting algorithm using merge sort
 */
export const stableSort = <T>(arr: T[], compare?: (a: T, b: T) => number) => {
  // Base case
  if (arr.length <= 1) return arr
  let mid = Math.floor(arr.length / 2)
  // Recursive calls
  let left: T[] = stableSort(arr.slice(0, mid), compare)
  let right: T[] = stableSort(arr.slice(mid), compare)
  return merge(left, right, compare)
}

/**
 * Checks if two arrays contain the exact same items
 */
export const containSameElements = <T>(arr1: T[], arr2: T[]) => {
  return difference(arr1, arr2).length === 0 && difference(arr2, arr1).length === 0
}
