import { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { Grid, Typography } from '@mui/material'

import { LimitedFunctionalityBanner } from '../../components/LimitedFunctionalityBanner/LimitedFunctionalityBanner'
import ShareUrlButton from '../../components/ShareUrlButton/ShareUrlButton'
import { useGameUpdateImpactsAccessCheck } from '../../features/account/hooks/roleHooks'
import { useCurrentMarket } from '../../features/markets'
import { UpdateImpactsTableContainer } from '../../features/update-impacts'
import { useDocumentTitle } from '../../hooks/useDocumentTitle'
import usePage from '../../hooks/usePage'
import pageService from '../../services/PageService'
import { revenueIsAvailableForMarket } from '../../services/RevenueService'
import { LockedFeatureId } from '../../types/LockedFeature'

export const UpdateImpactsPage: FC<{}> = () => {
  const { t } = useTranslation()

  usePage(pageService.getPageWithId('game-update-impacts'), 'Visited Game Update Impacts')
  useDocumentTitle(t('sidebar:game-update-impacts'))

  const { currentMarketIso } = useCurrentMarket()
  const isFeatureAvailableForMarket = revenueIsAvailableForMarket(currentMarketIso)
  const hasAccessRights = useGameUpdateImpactsAccessCheck()

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])

  return isFeatureAvailableForMarket ? (
    <>
      <Grid container columnSpacing={3} rowSpacing={1} wrap="wrap">
        {!hasAccessRights && (
          <Grid item flexBasis="100%">
            <LimitedFunctionalityBanner lockedFeatureId={LockedFeatureId.GameUpdateImpacts} />
          </Grid>
        )}
        <Grid container item justifyContent="space-between" wrap="nowrap">
          <Grid item xs={9}>
            <Typography variant="body1" mb={3} mt={1}>
              {t('common:game_update_impacts_description')}
            </Typography>
          </Grid>
          <Grid item>
            <ShareUrlButton />
          </Grid>
        </Grid>
      </Grid>
      <Typography variant="h2" mb={3} mt={1}>
        {t('common:filter_game_updates')}
      </Typography>
      <UpdateImpactsTableContainer showSubgenrePicker showTimeSinceUpdatedSelector />
    </>
  ) : (
    <Typography variant="body1">{t('common:update_impacts_not_available_text')}</Typography>
  )
}
