import { SvgIconComponent } from '@mui/icons-material'

import { RoleEnum } from '../features/account'

export class Page {
  component: React.FC // | undefined
  id: string
  href: string
  localizationKey: string
  type: string
  groupId?: string
  iconUrl?: string
  iconComponent?: SvgIconComponent | undefined
  startUrl?: string
  hideWithoutRole?: RoleEnum // hide page completely if user does not have this role
  showBetaBadge?: boolean // show beta badge on page

  constructor(
    component: React.FC, // | undefined,
    id: string,
    href: string,
    localizationKey: string,
    type: string,
    groupId?: string,
    iconUrl?: string,
    iconComponent?: SvgIconComponent | undefined,
    startUrl?: string,
    hideWithoutRole?: RoleEnum,
    showBetaBadge?: boolean
  ) {
    this.component = component
    this.id = id
    this.href = href
    this.localizationKey = localizationKey
    this.type = type
    this.groupId = groupId
    this.iconUrl = iconUrl
    this.iconComponent = iconComponent
    this.startUrl = startUrl
    this.hideWithoutRole = hideWithoutRole
    this.showBetaBadge = showBetaBadge
  }
}
