import { FC, ReactNode, useEffect, useMemo, useState } from 'react'

import { CircularProgress } from '@mui/material'

import { useGetUserProfileQuery, useGetUserSettingsQuery } from '../../../../api/combined'
import { useGetMarketsQuery, useGetStoresQuery, useGetGenreTaxonomyQuery } from '../../../../api/core'
import AppLogo from '../../../../assets/images/gamerefinery-logo.png'
import { useAppDispatch, useAppSelector } from '../../../../hooks/storeHooks'
import languageService from '../../../../services/LanguageService'
import mixPanelService from '../../../../services/MixPanelService'
import { useCurrentUserLanguage } from '../../../account/hooks/userHooks'
import { mainStoreIdChanged } from '../../../store'
import styles from './AppLoader.module.scss'

type AppLoaderProps = {
  children: ReactNode
}

/**
 * App loader loads all the background data required by the app. Also works as a guard avoiding drawing the UI
 * until all background data has been successfully loaded
 */
export const AppLoader: FC<AppLoaderProps> = ({ children }) => {
  const dispatch = useAppDispatch()
  const [languageMapsLoaded, setLanguageMapsLoaded] = useState<boolean>(false)
  const mainStoreId = useAppSelector((state) => state.mainStoreId)
  const userLanguage = useCurrentUserLanguage()
  const { data: currentUser, isSuccess: currentUserLoaded } = useGetUserProfileQuery()
  const { data: userSettings, isSuccess: userSettingsLoaded } = useGetUserSettingsQuery(undefined, { skip: !languageMapsLoaded })
  const { data: markets, isSuccess: marketsLoaded } = useGetMarketsQuery({ userLanguage }, { skip: !languageMapsLoaded || !userLanguage })
  const { data: stores, isSuccess: storesLoaded } = useGetStoresQuery(undefined, { skip: !languageMapsLoaded })
  const { data: genreTaxonomy, isSuccess: genreTaxonomyLoaded } = useGetGenreTaxonomyQuery({ userLanguage }, { skip: !languageMapsLoaded })

  // load language maps after current user has been successfully fetched
  useEffect(() => {
    if (currentUserLoaded) {
      languageService.loadLanguageMaps(currentUser?.language || 'en').then(() => {
        setLanguageMapsLoaded(true)
      })

      // tell language defined for current user
      languageService.updateUserLanguage(currentUser?.language || 'en')
    }
  }, [dispatch, currentUser, currentUserLoaded])

  // set iOS App Store as the current store (no other stores currently available)
  useEffect(() => {
    if (storesLoaded) {
      dispatch(mainStoreIdChanged(stores?.find((store) => store.ecosystem === 'iOS')?.id || ''))
    }
  }, [dispatch, stores, storesLoaded])

  // initialize mixpanel
  useEffect(() => {
    if (currentUserLoaded && currentUser) {
      mixPanelService.init(currentUser)
    }
  }, [currentUser, currentUserLoaded])

  const initialDataLoaded = useMemo(() => {
    return (
      currentUserLoaded &&
      currentUser &&
      marketsLoaded &&
      markets?.length &&
      storesLoaded &&
      stores?.length &&
      userSettingsLoaded &&
      userSettings &&
      mainStoreId &&
      genreTaxonomyLoaded &&
      genreTaxonomy &&
      languageMapsLoaded
    )
  }, [
    currentUserLoaded,
    currentUser,
    marketsLoaded,
    markets,
    storesLoaded,
    stores,
    userSettings,
    userSettingsLoaded,
    mainStoreId,
    genreTaxonomyLoaded,
    genreTaxonomy,
    languageMapsLoaded,
  ])

  return initialDataLoaded ? (
    <>{children}</>
  ) : (
    <div className={styles.root}>
      <div className={styles.loader}>
        <div className={styles.logo}>
          <img src={AppLogo} alt="" />
        </div>
        <CircularProgress className={styles.CircularProgress} color="primary" />
      </div>
    </div>
  )
}
