import querystring from 'query-string'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation } from 'react-router-dom'

import {
  Alert,
  Box,
  Checkbox,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Pagination,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material'
import { SerializedError } from '@reduxjs/toolkit'
import { FetchBaseQueryError } from '@reduxjs/toolkit/query/react'

import { Tag, useGetKeywordListQuery } from '../../api/core'
import DateRangePicker, { DateRangeValue } from '../../components/DateRangePicker/DateRangePicker'
import { GRCountryFlag } from '../../components/GRCountryFlag/GRCountryFlag'
import GameSubgenrePicker, { SingleSubgenreSelection } from '../../components/GameSubgenrePicker/GameSubgenrePicker'
import { LimitedFunctionalityBanner } from '../../components/LimitedFunctionalityBanner/LimitedFunctionalityBanner'
import { useMarketTrendsAccessCheck } from '../../features/account/hooks/roleHooks'
import { useCurrentUserLanguage } from '../../features/account/hooks/userHooks'
import { FeaturesAndKeywordsAutocomplete } from '../../features/feature/components/FeaturesAndKeywordsAutocomplete/FeaturesAndKeywordsAutocomplete'
import { TagList } from '../../features/feature/components/TagList/TagList'
import { useFeatureTags } from '../../features/feature/hooks/useFeatureTags'
import { FeatureCategory, useFeatures } from '../../features/feature/hooks/useFeatures'
import { FeatureAndKeywordSearchResult } from '../../features/feature/types/types'
import { MarketTrendGraph } from '../../features/market-trends/components'
import { useTrendGraph } from '../../features/market-trends/hooks/useChartHooks'
import { useTrendHistories, useMarketTrends, formatISODate, useInitialLocationState } from '../../features/market-trends/hooks/useMarketTrendHooks'
import { useCurrentMarket } from '../../features/markets'
import { useDocumentTitle } from '../../hooks/useDocumentTitle'
import usePage from '../../hooks/usePage'
import { ITrackEventOptions } from '../../services/AnalyticsService'
import PageService from '../../services/PageService'
import { AnalyticsProviders } from '../../types/IAnalyticsProvider'
import { LockedFeatureId } from '../../types/LockedFeature'
import './MarketTrendsPage.scss'

const topAmountOptions = [0.1, 0.2, 0.5]
const chartsPerPage = 10
const minDate = new Date('2018-01-01 00:00:00')

type RenderableError = FetchBaseQueryError & { error?: string }

const Error: React.FC<{ error?: RenderableError | SerializedError }> = ({ error }) => {
  if (!error) {
    return <Alert severity="warning">Unknown error</Alert>
  }
  const e = error as RenderableError
  return (
    <Alert severity="error" onClose={() => {}}>
      {e.status}: {e.error ? e.error : e.data ? Object.keys(e.data as Object) : 'No details available. :('}
    </Alert>
  )
}

/**
 * The Market Trends main view. Does not take any props.
 */
const MarketTrendsPage: React.FC = () => {
  const { t } = useTranslation()
  const userLanguage = useCurrentUserLanguage()
  usePage(PageService.getPageWithId('market-trends'), 'Visited Market Trends', { serviceToExclude: [AnalyticsProviders.intercom] } as ITrackEventOptions)
  useDocumentTitle(t('sidebar:market-trends'))

  const hasMarketTrendsAccess = useMarketTrendsAccessCheck()
  const { currentMarketIso } = useCurrentMarket()
  const { data: featureCategories } = useFeatures({ skipCounts: true, includeHiddenScreenshots: true })
  const { data: keywords } = useGetKeywordListQuery({ type: 'feature', userLanguage })
  const featureTags = useFeatureTags()
  const navigate = useNavigate()
  const location = useLocation()

  const [currentPage, setCurrentPage] = useState(1)
  const [searchResult, setSearchResult] = useState<FeatureAndKeywordSearchResult>(null)

  // Selection values, initially from location query params
  const initial = useInitialLocationState()
  const [dateRange, setDateRange] = useState<DateRangeValue | undefined>(initial.dateRange)
  const [source, setSource] = useState<string>(initial.source)
  const [sortBy, setSortBy] = useState<string>(initial.sortBy)
  const [topAmount, setTopAmount] = useState<number>(initial.top)
  const [scaleYAxis, setScaleYAxis] = useState<boolean>(initial.scaleYAxis)
  const [selectedTags, setSelectedTags] = useState<string[]>(initial.tags.split(','))
  const [selectedCategory, setSelectedCategory] = useState<string>(initial.category)
  const [selectedSubgenre, setSelectedSubgenre] = useState<string>(initial.subgenre)
  const [selectedGenre, setSelectedGenre] = useState<string>(initial.genre)

  // Resolve tags from string selection
  const resolvedTags = useMemo(() => {
    return (featureTags.data?.flatMap((group) => group.tags).filter((tag) => selectedTags.includes(tag.id)) as Tag[]) || []
  }, [featureTags.data, selectedTags])

  // Resolve subgenre from string selection
  const resolvedSubgenre = useMemo(() => {
    return {
      conventionalCategoryId: selectedCategory || '',
      conventionalGenreId: selectedGenre || '',
      conventionalSubgenreId: selectedSubgenre || '',
    } as SingleSubgenreSelection
  }, [selectedCategory, selectedGenre, selectedSubgenre])

  const { data: trendData, isFetching: isTrendsFetching, isError, error } = useMarketTrends(currentMarketIso, source, sortBy, dateRange, resolvedSubgenre)
  const trendHistories = useTrendHistories(trendData, resolvedTags, searchResult, featureCategories as FeatureCategory[])
  const features = useMemo(() => featureCategories?.flatMap((featureCategory) => featureCategory.features) || [], [featureCategories])

  const setQueryParam = (value: { [name: string]: string | undefined }) => {
    const search = querystring.stringify({ ...querystring.parse(location.search), ...value })
    navigate({ search }, { replace: true })
  }

  const handleChangeSort = ({ target }: SelectChangeEvent) => {
    setSortBy(target.value)
    setQueryParam({ sortBy: target.value })
  }

  const handleChangeTopAmount = ({ target }: SelectChangeEvent<number>) => {
    const top = target.value as number
    setTopAmount(top)
    setQueryParam({ top: (top * 100).toString() })
  }

  const handleChangeSource = ({ target }: SelectChangeEvent) => {
    setSource(target.value)
    setQueryParam({ source: target.value })
  }

  const handleChangeDates = (range?: DateRangeValue) => {
    if (dateRange?.fromDate === range?.fromDate && dateRange?.toDate === range?.toDate) {
      return
    }

    setDateRange(range)
    setQueryParam({
      fromDate: formatISODate(range?.fromDate) || undefined,
      toDate: formatISODate(range?.toDate) || undefined,
    })
  }

  const handleChangeScaleYAxis = () => {
    setScaleYAxis(!scaleYAxis)
    setQueryParam({ scaleYAxis: scaleYAxis ? 'false' : 'true' })
  }

  const handleSelectedTagsChange = (tags: Tag[]) => {
    setSelectedTags(tags.map(({ id }) => id))
    setQueryParam({ tags: tags.map(({ id }) => id).join(',') })
  }

  const handleClearSelectedTags = () => {
    setSelectedTags([])
    setQueryParam({ tags: undefined })
  }

  useEffect(() => {
    // filteres changed reset page to 1
    setCurrentPage(1)
  }, [selectedSubgenre, selectedTags, dateRange, searchResult])

  const data = useTrendGraph(t, chartsPerPage, currentPage, topAmount * 100, sortBy, trendData, trendHistories)
  const totalCharts = useMemo(() => (trendHistories ? Object.keys(trendHistories).length : 0), [trendHistories])

  return (
    <Box className="MarketTrendsPage">
      {!hasMarketTrendsAccess && (
        <Box mb={2}>
          <LimitedFunctionalityBanner lockedFeatureId={LockedFeatureId.MarketTrends}></LimitedFunctionalityBanner>
        </Box>
      )}
      <GameSubgenrePicker
        selectedSubgenres={{}}
        selectedSubgenre={resolvedSubgenre}
        singleSelectionChanged={(selection) => {
          if (!selection) return
          // clear
          if (!selection.conventionalCategoryId && !selection.conventionalGenreId && !selection.conventionalSubgenreId) {
            setSelectedCategory('')
            setSelectedGenre('')
            setSelectedSubgenre('')
            setQueryParam({ category: undefined, genre: undefined, subgenre: undefined })
          }
          // category
          if (selection.conventionalCategoryId && selection.conventionalCategoryId !== selectedCategory) {
            setSelectedCategory(selection.conventionalCategoryId)
            setSelectedGenre('')
            setSelectedSubgenre('')
            setQueryParam({ category: selection.conventionalCategoryId, genre: undefined, subgenre: undefined })
          }
          // genre
          if (selection.conventionalGenreId && selection.conventionalGenreId !== selectedGenre) {
            setSelectedCategory('')
            setSelectedGenre(selection.conventionalGenreId)
            setSelectedSubgenre('')
            setQueryParam({ category: undefined, genre: selection.conventionalGenreId, subgenre: undefined })
          }
          // subgenre
          if (selection.conventionalSubgenreId && selection.conventionalSubgenreId !== selectedSubgenre) {
            setSelectedCategory('')
            setSelectedGenre('')
            setSelectedSubgenre(selection.conventionalSubgenreId)
            setQueryParam({ category: undefined, genre: undefined, subgenre: selection.conventionalSubgenreId })
          }
        }}
        disabled={!hasMarketTrendsAccess}
        showSubgenreDefinitionBtn
      />
      <TagList
        tagGroups={featureTags.data}
        selectedTags={resolvedTags}
        onChange={handleSelectedTagsChange}
        disabled={!hasMarketTrendsAccess}
        onClear={handleClearSelectedTags}
      />
      <Grid container wrap="wrap" justifyContent="flex-start" mt={1} spacing={2} alignItems="center">
        <Grid item sm={12} lg={7} xl={5}>
          {t('common:timespan')}
          <DateRangePicker
            value={dateRange}
            onChangeValue={handleChangeDates}
            minDate={minDate}
            disabled={!hasMarketTrendsAccess}
            allowSameFromAndToDate={false}
          />
        </Grid>
        <Grid item>
          {t('common:sort_by')}
          <FormControl className="SelectDropdown" disabled={!hasMarketTrendsAccess}>
            <Select size="small" value={sortBy} onChange={handleChangeSort}>
              <MenuItem value="up">{t('common:trending_up')}</MenuItem>
              <MenuItem value="down">{t('common:trending_down')}</MenuItem>
            </Select>
          </FormControl>
          <FormControl className="SelectDropdown" disabled={!hasMarketTrendsAccess}>
            <Select size="small" value={topAmount} onChange={handleChangeTopAmount}>
              {topAmountOptions.map((amount) => (
                <MenuItem key={amount} value={amount} sx={{ textTransform: 'capitalize' }}>
                  {t(`common:top_${amount * 100}_popularity_percent_short`)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl className="SelectDropdown" disabled={!hasMarketTrendsAccess}>
            <Select size="small" value={source} onChange={handleChangeSource}>
              <MenuItem value="revenue">{t('common:revenue_text')}</MenuItem>
              <MenuItem value="download">{t('common:downloads_text')}</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          {t('common:data') + ': '}
          <GRCountryFlag countryCode={currentMarketIso} />
        </Grid>
        <Grid item>
          <FormControlLabel control={<Checkbox checked={scaleYAxis} onClick={handleChangeScaleYAxis} />} label={t<string>('trending-features:scale_y_axis')} />
        </Grid>
      </Grid>
      <Divider sx={{ my: 2 }} />
      {isError ? (
        <Error error={error} />
      ) : !data || isTrendsFetching ? (
        <Box my={4} textAlign="center">
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Box mb={2}>
            <FeaturesAndKeywordsAutocomplete features={features} value={searchResult} keywords={keywords} onChange={setSearchResult} />
          </Box>
          {data.map((graph, index) => (
            <MarketTrendGraph key={index} scaleYAxis={scaleYAxis} data={graph} topAmount={topAmount} selectedSubgenre={resolvedSubgenre} />
          ))}
          <Grid container justifyContent="space-around">
            {totalCharts > 0 && data.length !== 0 && data[0].avgEffect ? (
              <Pagination count={Math.ceil(totalCharts / chartsPerPage)} page={currentPage} onChange={(evt, page) => setCurrentPage(page)} />
            ) : (
              <Typography>{t('common:no_matching_features_found')}</Typography>
            )}
          </Grid>
        </>
      )}
    </Box>
  )
}

export default MarketTrendsPage
