import { FC, ReactNode } from 'react'

import { Box, Grid } from '@mui/material'

import { Analyst } from '../../../../../../components/Analyst/Analyst'
import ShowMore from '../../../../../../components/ShowMore/ShowMore'
import { Analyst as IAnalyst } from '../../../../../../types/Analyst'
import AskAnalystButton from '../../../../../ask-analyst/components/AskAnalystButton/AskAnalystButton'
import { NewsCardSectionDivider } from '../../NewsCardSectionDivider/NewsCardSectionDivider'
import { NewsCardContentSectionProps } from '../NewsCardContentSections'
import './NewsCardContentAnalystContentSection.scss'

type NewsCardContentAnalystContentSectionProps = NewsCardContentSectionProps & {
  title: string
  analyst: IAnalyst
  children?: ReactNode
}

export const NewsCardContentAnalystContentSection: FC<NewsCardContentAnalystContentSectionProps> = ({ newsEntry, title, analyst, children }) => {
  const gameName = newsEntry.feedEntry.entryData.game

  return (
    <Box className="NewsCardContentAnalystContentSection" mb={2}>
      <NewsCardSectionDivider>{title}</NewsCardSectionDivider>
      <Grid container direction="row" justifyContent="space-between" alignItems="center" mb={2}>
        <Grid item>
          <Analyst name={analyst?.name} picture={analyst?.picture} title={analyst?.title} />
        </Grid>
        <Grid item mr={2}>
          <AskAnalystButton gameName={gameName} analyst={analyst}></AskAnalystButton>
        </Grid>
      </Grid>
      <ShowMore collapsedSize={200}>
        <Box className="NewsCardContentAnalystContentSection__content">{children}</Box>
      </ShowMore>
    </Box>
  )
}
