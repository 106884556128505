import React from 'react'
import { useTranslation } from 'react-i18next'

import { Button, Dialog, DialogContent } from '@mui/material'

import { ConfirmDialogData } from '../../types/ConfirmDialogData'
import GRDialogTitle from '../GRDialogTitle/GRDialogTitle'
import './ConfirmDialog.scss'

interface ConfirmDialogProps<DataType> {
  open: boolean
  confirmDialogData?: ConfirmDialogData<DataType>
  onClose: (previousConfirmDialogData?: ConfirmDialogData<DataType>) => void
}

const ConfirmDialog = <DataType extends object>({ open, confirmDialogData, onClose }: ConfirmDialogProps<DataType>) => {
  const { t } = useTranslation()
  const handleClose = (currentConfirmDialogData?: ConfirmDialogData<DataType>) => {
    onClose(currentConfirmDialogData)
  }

  return (
    <Dialog className="ConfirmDialog" onClose={handleClose} fullWidth={true} maxWidth="xs" open={open}>
      {confirmDialogData?.title && <GRDialogTitle onClose={() => onClose()}>{confirmDialogData.title}</GRDialogTitle>}
      <DialogContent className="DialogContent" dividers>
        {confirmDialogData?.confirmText && <div className="text-center">{confirmDialogData.confirmText}</div>}
        <div className="text-center">
          <Button className="horizontal-margin vertical-margin" onClick={() => onClose()} variant="contained">
            {confirmDialogData?.cancelText ? confirmDialogData.cancelText : t('common:cancel')}
          </Button>

          <Button
            className={confirmDialogData?.destructiveAction ? 'destructive-button horizontal-margin vertical-margin' : 'horizontal-margin vertical-margin'}
            onClick={() => handleClose(confirmDialogData)}
            variant="contained"
            color="primary"
          >
            {confirmDialogData?.actionText}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default ConfirmDialog
