import { useMemo } from 'react'

import { tier1Markets } from '..'
import { useGetGamesByStageQuery, useGetRevenueAndDownloadAggregatesQuery } from '../../../api/core'
import languageService from '../../../services/LanguageService'
import { RoleEnum } from '../../account'
import { useRoleCheck } from '../../account/hooks/roleHooks'
import { useCurrentUserLanguage } from '../../account/hooks/userHooks'
import { EstimateTypes, Game } from '../../game/types/Game'
import { GameStageId } from '../../game/types/GameStageId'
import { useCurrentMarket } from '../../markets'
import { useGamesOpenForEveryone } from '../../settings'
import { tier1MarketIso, tier1MarketIsos } from '../constants/constants'

const include = [
  'icon',
  'icons',
  'name',
  'artist',
  'gpsPerMarket',
  'genreId',
  'genre',
  'subGenreId',
  'subGenre',
  'prevGpsPerMarket',
  'conventionalSubgenreId',
  'storeLinks',
  'appId',
  'stageId',
  'archive',
  'released',
]

/**
 * Custom hook for loading soft launch games with aggregated revenue, downloads and trend data
 */
export const useSoftLaunchGames = () => {
  const estimatesUnlocked = useRoleCheck(RoleEnum.revenue_estimates)
  const { currentMarketIso: mainMarketIso } = useCurrentMarket()
  const userLanguage = useCurrentUserLanguage()

  // retrieve games open for everyone
  const {
    openGamesSetting,
    featuredGamesSetting,
    isLoading: isLoadingGamesOpenForEveryone,
    isFetching: isFetchingGamesOpenForEveryone,
    error: gamesOpenForEveryoneError,
  } = useGamesOpenForEveryone()

  // query games in soft launch state
  const {
    data: softLaunchGames,
    isLoading: isLoadingSoftLaunchGames,
    isFetching: isFetchingSoftLaunchGames,
    error: softLaunchGamesError,
  } = useGetGamesByStageQuery({
    stageId: GameStageId.soft_launch,
    marketIso: mainMarketIso,
    limit: 0,
    include: include,
    includeUnanalyzed: true,
    userLanguage,
  })

  // aggregate all games to an array of app ids
  const appIds = useMemo(() => {
    const openGameIds: string[] = openGamesSetting?.data.openGames || []
    const featuredGameIds: string[] = featuredGamesSetting?.data.featuredGames || []
    const allOpenGameIds = [...openGameIds, ...featuredGameIds]
    const openAppIds: number[] =
      softLaunchGames?.reduce((acc, game) => {
        if (allOpenGameIds.includes(game.id)) {
          return [...acc, game.appId]
        }

        return acc
      }, [] as number[]) || []
    const softLaunchedGameAppIds: number[] = softLaunchGames?.map((slg) => slg.appId) || []

    // if revenue estimates are unlocked, load aggregate data only for open games
    return estimatesUnlocked ? [...openAppIds, ...softLaunchedGameAppIds] : openAppIds
  }, [openGamesSetting?.data.openGames, featuredGamesSetting?.data.featuredGames, softLaunchGames, estimatesUnlocked])

  // with aggregated app ids retrieve revenue and downloads data for tier 1 markets
  const {
    data: revenueAndDownloadByApp,
    isLoading: isLoadingRevenueAndDownloadByApp,
    isFetching: isFetchingRevenueAndDownloadByApp,
    error: revenueAndDownloadByAppError,
  } = useGetRevenueAndDownloadAggregatesQuery({ appIds, marketIsos: tier1MarketIsos }, { skip: appIds.length === 0 })

  // aggregate revenue and downloads data for all tier 1 markets
  const apps = useMemo(() => {
    return softLaunchGames?.map((game) => {
      const appAggregates = revenueAndDownloadByApp && revenueAndDownloadByApp[game.appId]
      return new Game({
        ...game,
        revenueAndDownloadAggregatesMap: {
          [tier1MarketIso]: {
            [EstimateTypes.REVENUE_30_DAY]: tier1MarketIsos.reduce((acc, value) => {
              const aggregateValue = appAggregates?.countryAggregates[value]?.revenue30d || 0
              return aggregateValue + acc
            }, 0),
            [EstimateTypes.DOWNLOADS_30_DAY]: tier1MarketIsos.reduce((acc, value) => {
              const aggregateValue = appAggregates?.countryAggregates[value]?.downloads30d || 0
              return aggregateValue + acc
            }, 0),
            [EstimateTypes.REVENUE_60_DAY]: tier1MarketIsos.reduce((acc, value) => {
              const aggregateValue = appAggregates?.countryAggregates[value]?.revenue60d || 0
              return aggregateValue + acc
            }, 0),
            [EstimateTypes.DOWNLOADS_60_DAY]: tier1MarketIsos.reduce((acc, value) => {
              const aggregateValue = appAggregates?.countryAggregates[value]?.downloads60d || 0
              return aggregateValue + acc
            }, 0),
          },
        },
      } as Game)
    })
  }, [softLaunchGames, revenueAndDownloadByApp])

  const gamesWithSoftLaunchMarkets = useMemo(() => {
    return apps?.map((app) => {
      const uniqueMarkets = [...new Set(Object.keys(app.storeLinks).map((countryCode) => countryCode.toLowerCase()))].map((countryCode) => ({
        name: languageService.getTranslation('markets', countryCode) || languageService.getTranslation('markets', countryCode.toUpperCase()),
        iso: countryCode.toUpperCase(),
        order: tier1Markets[countryCode] ? tier1Markets[countryCode].order : 1000,
        tier1Market: tier1Markets[countryCode] ? tier1Markets[countryCode].tier1Market : false,
      }))

      return new Game({
        ...app,
        softLaunchMarkets: [...uniqueMarkets],
      } as Game)
    })
  }, [apps])

  const isError = gamesOpenForEveryoneError || softLaunchGamesError || revenueAndDownloadByAppError

  return {
    games: isError ? [] : gamesWithSoftLaunchMarkets || [],
    isLoading: isLoadingGamesOpenForEveryone || isLoadingSoftLaunchGames || isLoadingRevenueAndDownloadByApp,
    isFetching: isFetchingGamesOpenForEveryone || isFetchingSoftLaunchGames || isFetchingRevenueAndDownloadByApp,
    error: gamesOpenForEveryoneError || softLaunchGamesError || revenueAndDownloadByAppError,
  }
}
