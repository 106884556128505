import classNames from 'classnames'
import React, { CSSProperties, FC, memo, ReactNode, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { Block, MoreVert, PriorityHigh } from '@mui/icons-material'
import BlockIcon from '@mui/icons-material/Block'
import { Box, Button, Card, CardContent, Chip, Grid, Popover } from '@mui/material'

import { useGetUserProfileQuery } from '../../../../api/combined'
import { GRCountryFlag } from '../../../../components/GRCountryFlag/GRCountryFlag'
import { GRTooltip } from '../../../../components/GRTooltip/GRTooltip'
import { LockedData } from '../../../../components/LockedData/LockedData'
import Powerscore from '../../../../components/Powerscore/Powerscore'
import { ReviewIndicator } from '../../../../components/ReviewIndicator/ReviewIndicator'
import { useCurrentMarket } from '../../../markets'
import { isEmpty } from '../../../news/utils/utils'
import { useShowSubgenreForGame } from '../../../top-game/hooks/topGrossingGameHooks'
import { useGameAndAnalysis } from '../../hooks/gameHooks'
import { AppType, Game } from '../../types/Game'
import { GameAndAnalysis } from '../../types/GameAndAnalysis'
import { GameAnalysisOutdatedIndicator } from '../GameAnalysisOutdatedIndicator/GameAnalysisOutdatedIndicator'
import GameIcon from '../GameIcon/GameIcon'
import { GameLink } from '../GameLink/GameLink'
import styles from './GameCard.module.scss'

type extraTag = {
  label?: string
  customComponent?: ReactNode
}

interface Props {
  game: Game
  onCardClick?: (game: Game) => void
  showLastSeenBadge?: boolean
  children?: ReactNode
  showCountryFlag?: boolean
  marketIso?: string
  extraInfo?: ReactNode
  extraTags?: extraTag[]
  disableGameLink?: boolean
  showPrivateIcon?: boolean
}

interface SubComponents {
  Actions: React.FC<GameCardButtonActionsProps>
}

const BUTTON_ACTIONS_NAME = 'button-actions'

const GameCard: React.FC<Props> & SubComponents = (props: Props) => {
  const buttonActions = React.Children.map(props.children, (child: any) => {
    if (child?.type?.displayName !== BUTTON_ACTIONS_NAME) return null

    return child
  })
  const { currentMarketIso: mainMarketIso } = useCurrentMarket()
  const { gameAndAnalysis } = useGameAndAnalysis(props.game.id, mainMarketIso)
  const marketIso = props.marketIso ? props.marketIso : mainMarketIso

  return (
    <Box className={styles.root}>
      {!props.onCardClick ? (
        <Link to={`/game/${props.game.id}`}>
          <GameCardInner
            showCountryFlag={props.showCountryFlag}
            marketIso={marketIso}
            game={props.game}
            showLastSeenBadge={props.showLastSeenBadge}
            buttonActions={buttonActions}
            extraInfo={props.extraInfo}
            extraTags={props.extraTags}
            showPrivateIcon={props.showPrivateIcon}
            gameAndAnalysis={gameAndAnalysis}
          ></GameCardInner>
        </Link>
      ) : (
        <Box
          onClick={() => {
            props.onCardClick && props.onCardClick(props.game)
          }}
        >
          <GameCardInner
            showCountryFlag={props.showCountryFlag}
            marketIso={marketIso}
            game={props.game}
            showLastSeenBadge={props.showLastSeenBadge}
            buttonActions={buttonActions}
            extraInfo={props.extraInfo}
            extraTags={props.extraTags}
            showPrivateIcon={props.showPrivateIcon}
            gameAndAnalysis={gameAndAnalysis}
            disableGameLink={props.disableGameLink}
          ></GameCardInner>
        </Box>
      )}
    </Box>
  )
}

interface GameCardInnerProps extends Props {
  buttonActions?: ReactNode
  marketIso: string
  showCountryFlag?: boolean
  extraInfo?: ReactNode
  extraTags?: extraTag[]
  showPrivateIcon?: boolean
  gameAndAnalysis?: GameAndAnalysis
}
const GameCardInner: React.FC<GameCardInnerProps> = (props) => {
  const { t } = useTranslation()
  const isGameSoftLaunch = () => {
    if (!props.gameAndAnalysis) {
      return false
    }

    return props.gameAndAnalysis.game.isSoftLaunch()
  }

  return (
    <Card className={styles['card-container']}>
      <CardContent className={styles['card-content']}>
        {props.game.RIBBON_TYPE === 'featured' ? <div className={styles.ribbon}>{t('common:free')}</div> : null}
        {isGameSoftLaunch() && <div className={styles.ribbon}>{t('game-header:soft_launch')}</div>}
        {props.extraInfo}
        <GameCardContent
          showCountryFlag={props.showCountryFlag}
          game={props.game}
          showLastSeenBadge={props.showLastSeenBadge}
          mainMarketIso={props.marketIso}
          extraTags={props.extraTags}
          showPrivateIcon={props.showPrivateIcon}
          disableGameLink={props.disableGameLink}
        />

        {props.buttonActions}
      </CardContent>
    </Card>
  )
}

type GameCardContentProps = {
  game: Game
  mainMarketIso?: string
  showLastSeenBadge?: boolean
  rank?: number
  variant?: 'card' | 'table'
  marketIso?: string
  showCountryFlag?: boolean
  extraTags?: extraTag[]
  showPrivateIcon?: boolean
  disableGameLink?: boolean
  onReviewClick?: (game: Game) => void
  gameAnalysisOutdated?: boolean
  customStyles?: CSSProperties
  customLinkStyles?: CSSProperties
  hidePowerscore?: boolean
  displayGameType?: boolean
}
export const GameCardContent: FC<GameCardContentProps> = memo(
  ({
    game,
    showLastSeenBadge,
    mainMarketIso,
    rank,
    variant = 'card',
    showCountryFlag,
    extraTags,
    showPrivateIcon,
    onReviewClick,
    disableGameLink,
    gameAnalysisOutdated,
    customStyles,
    customLinkStyles,
    hidePowerscore,
    displayGameType,
  }) => {
    const { t } = useTranslation()
    const rootClasses = classNames(styles['game-card-content'], styles[variant])
    const { data: currentUser } = useGetUserProfileQuery()
    const showSubgenreForGame = useShowSubgenreForGame(game)

    return (
      <Grid className={rootClasses} container alignItems="stretch" spacing={1}>
        <Grid item>
          <div className={styles.icon}>
            {game.isPrivate() && showPrivateIcon ? (
              <GRTooltip content={t('my-games:game_is_private')}>
                <Block className={styles['GameCard__blockIcon']} fontSize="small" />
              </GRTooltip>
            ) : null}
            <GameLink game={game} disabled={disableGameLink || game.appType === AppType.PC_CONSOLE}>
              <GameIcon
                src={game.getIcon() as string}
                gameName={game.resolvedName}
                size="normal"
                appType={displayGameType ? game.appType : undefined}
                gamePlatforms={game.platforms}
              ></GameIcon>
            </GameLink>
            {showLastSeenBadge ? (
              <div className={styles.badge} title={t('newsfeed:new_updates_available_tooltip')}>
                <PriorityHigh className={styles.PriorityHigh}></PriorityHigh>
              </div>
            ) : null}
          </div>
        </Grid>
        <Grid item container xs direction="column" justifyContent="space-between">
          <Grid item>
            <Box style={customStyles}>
              <GameLink game={game} disabled={disableGameLink || game.appType === AppType.PC_CONSOLE}>
                <div className={styles['game-name']} style={customLinkStyles} title={game.resolvedName}>
                  {rank ? `${rank}. ` : ''}
                  {!isEmpty(game.resolvedName)
                    ? game.resolvedName
                    : t('common:game_by', {
                        createdBy: game.createdBy,
                      })}
                </div>
              </GameLink>
              {currentUser && gameAnalysisOutdated && mainMarketIso && <GameAnalysisOutdatedIndicator game={game} marketIso={mainMarketIso} />}
            </Box>
            <div className={styles.publisher} title={game.artist || '-'}>
              {game.artist || '-'}
            </div>
          </Grid>
          {showSubgenreForGame ? (
            game.conventionalSubgenre ? (
              <Grid item className={styles.subgenre}>
                <Chip className={styles.Chip} size="small" label={game.conventionalSubgenre}></Chip>
              </Grid>
            ) : null
          ) : (
            <LockedData />
          )}
          {extraTags &&
            extraTags.map((tag, index) =>
              tag.customComponent ? tag.customComponent : <Chip className={styles.Chip} size="small" label={tag.label} key={index}></Chip>
            )}
        </Grid>
        {mainMarketIso && game?.gpsPerMarket[mainMarketIso] > 0 && !hidePowerscore && (
          <Grid
            item
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Powerscore powerscore={game.gpsPerMarket[mainMarketIso]} size={40} fontSize={18}></Powerscore>
            {showCountryFlag && <GRCountryFlag countryCode={mainMarketIso} />}
          </Grid>
        )}
        {mainMarketIso && !game.gpsPerMarket[mainMarketIso] && !hidePowerscore && (
          <Grid
            item
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <GRTooltip content={t('common:analysis_not_available')}>
              <BlockIcon sx={{ fontSize: 40 }} color="warning" />
            </GRTooltip>
          </Grid>
        )}
        {game.reviewId && game.reviewPublished && onReviewClick && (
          <Grid item>
            <ReviewIndicator onClick={() => onReviewClick(game)} />
          </Grid>
        )}
      </Grid>
    )
  }
)

type GameCardButtonActionsProps = {
  children?: ReactNode
  className?: string
}

const GameCardButtonActions: React.FC<GameCardButtonActionsProps> = (props: GameCardButtonActionsProps) => {
  const { t } = useTranslation()

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [popoverOpen, setPopoverOpen] = useState(false)

  const handlePopoverOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
    setPopoverOpen(!popoverOpen)
  }

  const handlePopoverClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation()
    setPopoverOpen(!popoverOpen)
  }

  return (
    <>
      <Button size="small" style={{ margin: '5px 0px' }} variant="contained" color="secondary" startIcon={<MoreVert />} onClick={handlePopoverOpen} fullWidth>
        {t('common:actions')}
      </Button>

      <Popover
        open={popoverOpen}
        onClose={handlePopoverOpen}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        className={styles['action-button-popover']}
      >
        <div className={classNames(['action-button-popover-content', props.className])} onClick={handlePopoverClick}>
          {props.children}
        </div>
      </Popover>
    </>
  )
}
GameCardButtonActions.displayName = 'button-actions'

GameCard.Actions = GameCardButtonActions
export default GameCard
