import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { LazyLoadImage } from 'react-lazy-load-image-component'

import { Info } from '@mui/icons-material'
import { Box, Button, Card, CardContent, Chip, CircularProgress, Divider, Grid, Rating, Typography } from '@mui/material'

import { useGetFtueVideosByGameQuery, useGetGameReviewQuery } from '../../../../api/core'
import { AnalystOverview } from '../../../../components/AnalystOverview/AnalystOverview'
import GRCircularProgress from '../../../../components/GRCircularProgress/GRCircularProgress'
import { GRTooltip } from '../../../../components/GRTooltip/GRTooltip'
import { ImageCarouselModal } from '../../../../components/ImageCarouselModal/ImageCarouselModal'
import MultiSlider from '../../../../components/MultiSlider/MultiSlider'
import OtherGamesWithTagDialog from '../../../../components/OtherGamesWithTagDialog/OtherGamesWithTagDialog'
import { ReviewHeader } from '../../../../components/Review/Review'
import ShowMore from '../../../../components/ShowMore/ShowMore'
import analyticsService from '../../../../services/AnalyticsService'
import appStoreService from '../../../../services/AppStoreService'
import languageService from '../../../../services/LanguageService'
import { AnalyticsProviders } from '../../../../types/IAnalyticsProvider'
import { Analysis } from '../../../analysis/types/Analysis'
import FtueVideoPreview from '../../../ftue-videos/components/FtueVideoPreview/FtueVideoPreview'
import { SelectedChapterTag } from '../../../ftue-videos/components/GameChapterList/GameChapterList'
import { useDateTimeFormatter } from '../../../live-events/hooks/useDateTimeFormatter'
import { useCurrentMarket } from '../../../markets'
import { useGameFeatureAndCreativeMetadataTags } from '../../../metadata-tag/hooks/metadataTagHooks'
import { useIsGameOpenForEveryone } from '../../../settings'
import { useGetGameVersionInfo, useOwnGameCheck } from '../../hooks/gameHooks'
import { Game } from '../../types/Game'
import { GameReview } from '../../types/GameReview'
import GameEditModal from '../GameEditModal/GameEditModal'
import GameKeyIndicators from '../GameKeyIndicators/GameKeyIndicators'
import GameMotivationPieChart from '../GameMotivationPieChart/GameMotivationPieChart'
import './GameOverview.scss'

interface Props {
  game: Game
  analysis?: Analysis
  analysisProgress?: Object
}

const GameOverview: React.FC<Props> = (props) => {
  const [otherGamesWithTagDialogTag, setOtherGamesWithTagDialogTag] = useState<string | undefined>(undefined)
  const [storeScreenshotModalOpen, setStoreScreenshotModalOpen] = useState<boolean>(false)
  const [selectedStoreScreenshotModalIndex, setSelectedScreenshotModalIndex] = useState<number>()
  const [selectedChapterTag, setSelectedChapterTag] = useState<SelectedChapterTag>({ chapterId: 0, tagId: '' })

  const { currentMarketIso: mainMarketIso, englishLanguageMarketIsos } = useCurrentMarket()
  const isGameOpenForEveryone = useIsGameOpenForEveryone(props.game)
  const isOwnGame = useOwnGameCheck(props.game)
  const gameAppId = (!isOwnGame && props.game.isUnlocked()) || isGameOpenForEveryone ? props.game.appId : undefined
  const version = props.game.versions[mainMarketIso] === undefined ? props.game.version : props.game.versions[mainMarketIso]
  const availableMarketIsoForUserLanguage = useMemo(() => {
    return Object.keys(props.game.versions)
      .filter((versionMarket) => englishLanguageMarketIsos.includes(versionMarket))
      .sort((a, b) => englishLanguageMarketIsos.indexOf(a) - englishLanguageMarketIsos.indexOf(b))[0]
  }, [englishLanguageMarketIsos, props.game.versions])
  const { data: featureAndCreativeMetadataTags } = useGameFeatureAndCreativeMetadataTags(gameAppId)
  const { data: gameVersionInfo, isLoading: gameVersionInfoLoading } = useGetGameVersionInfo(
    props.game.appId,
    availableMarketIsoForUserLanguage || mainMarketIso,
    version
  )
  const { data: gameReview, isLoading: isGameReviewLoading } = useGetGameReviewQuery(props.game.reviewId, { skip: isOwnGame || !props.game.reviewId })
  const { data: videos, isLoading: isVideoLoading } = useGetFtueVideosByGameQuery(props.game.id)
  const screenshots = useMemo(() => gameVersionInfo?.screenshots || [], [gameVersionInfo])
  const [editGame, setEditGame] = useState<Game | undefined>()
  const dateFormatter = useDateTimeFormatter()

  const { t } = useTranslation()

  const handleOpenStoreScreenshotModal = useCallback((screenshotIndex: number) => {
    setSelectedScreenshotModalIndex(screenshotIndex)
    setStoreScreenshotModalOpen(true)
  }, [])

  const onGameEditDone = (game: Game) => {
    analyticsService.trackEvent('Modified game', { data: { gameName: game.resolvedName, gameId: game.id } })
    setEditGame(undefined)
  }

  // Track game overview visited
  useEffect(() => {
    const gameStatus = () => {
      if (props.game.isFollowed()) {
        return ': Followed'
      }

      if (props.game.isUnlocked()) {
        return ': Unlocked'
      }

      return ': Locked'
    }

    analyticsService.trackEvent('Visited Game Overview' + gameStatus(), {
      data: {
        gameId: props.game.id,
        gameName: props.game.resolvedName,
        analysisId: props.analysis?.id,
      },
      serviceToExclude: [AnalyticsProviders.hubspot],
    })
  }, [props.analysis?.id, props.game, props.game.id, props.game.resolvedName])

  // Track screenshots viewed
  useEffect(() => {
    if (storeScreenshotModalOpen && props.game.id && props.game.resolvedName) {
      analyticsService.trackEvent('Screenshots viewed', {
        data: {
          gameId: props.game.id,
          gameName: props.game.resolvedName,
        },
        serviceToExclude: [AnalyticsProviders.hubspot],
      })
    }
  }, [storeScreenshotModalOpen, props.game.id, props.game.resolvedName])
  analyticsService.trackEvent('Visited Game Overview: Overview')

  return (
    <div className="GameOverview">
      {!!editGame && <GameEditModal onGameEditDone={onGameEditDone} game={editGame} open={!!editGame} onClose={() => setEditGame(undefined)} />}

      <Grid container spacing={2} className="GameOverview__grid-container" wrap="wrap">
        {props.game.reviewId && gameReview && (
          <Grid item xs={12}>
            <AnalystOverviewCard gameReview={gameReview} gameName={props.game.resolvedName} isLoading={isGameReviewLoading} />
          </Grid>
        )}
        <Grid item xs={12} lg={8}>
          <Card className="Card Card--full-height">
            <CardContent>
              <Box sx={{ display: 'inline-flex' }}>
                <h3>{t('common:summary')}</h3>
                {!props.analysis && featureAndCreativeMetadataTags?.length && (
                  <GRTooltip content={t('overview:feature_tags_ai_generated_note')}>
                    <Info color="primary" sx={{ ml: 0.5 }} />
                  </GRTooltip>
                )}
              </Box>
              {props.analysis && (
                <>
                  <Grid container spacing={2} wrap="wrap" style={{ marginBottom: '20px' }}>
                    {props.game.coreName && (
                      <Grid item style={{ paddingRight: '20px' }}>
                        {t('common:genre')}: <strong>{props.game.coreName}</strong>
                      </Grid>
                    )}

                    {props.game.metaName && (
                      <Grid item>
                        {t('common:subgenre')}: <strong>{props.game.metaName}</strong>
                      </Grid>
                    )}
                  </Grid>

                  <div className="small-top-margin">
                    {[...(props.game.tags?.[mainMarketIso] || [])]
                      .sort((tagIdA, tagIdB) => (languageService.getTranslation('tags', tagIdA) < languageService.getTranslation('tags', tagIdB) ? -1 : 1))
                      .map((tagId) => {
                        return (
                          <Chip
                            key={tagId}
                            label={languageService.getTranslation('tags', tagId)}
                            onClick={() => {
                              setOtherGamesWithTagDialogTag(tagId)
                            }}
                          ></Chip>
                        )
                      })}

                    {otherGamesWithTagDialogTag && (
                      <OtherGamesWithTagDialog
                        modalOpen={otherGamesWithTagDialogTag ? true : false}
                        game={props.game}
                        tagId={otherGamesWithTagDialogTag}
                        onClose={() => setOtherGamesWithTagDialogTag(undefined)}
                        marketIso={mainMarketIso}
                      />
                    )}
                  </div>
                </>
              )}

              {!props.analysis && featureAndCreativeMetadataTags && (
                <div className="small-top-margin">
                  {[...featureAndCreativeMetadataTags]
                    ?.filter((tag) => tag.type && tag.type === 'featureTags')
                    .sort((tagA, tagB) => (tagA.name < tagB.name ? -1 : 1))
                    .map((tag) => {
                      return <Chip key={tag.id} label={tag.name}></Chip>
                    })}
                </div>
              )}

              {props.analysisProgress && featureAndCreativeMetadataTags && (
                <div className="small-top-margin">
                  {[...featureAndCreativeMetadataTags]
                    ?.filter((tag) => tag.type && tag.type === 'visualTags')
                    .sort((tagA, tagB) => (tagA.name < tagB.name ? -1 : 1))
                    .map((tag) => {
                      return <Chip key={tag.id} className="Chip Chip--primary-light" label={tag.name}></Chip>
                    })}
                </div>
              )}

              <Divider className="vertical-margin" />

              <GameKeyIndicators game={props.game} marketIso={mainMarketIso} />
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} lg={4}>
          <GameMotivationPieChart game={props.game} gameAnalyzed={props.analysis ? true : false} />
        </Grid>
      </Grid>

      {!isOwnGame && (
        <Grid container spacing={2} className="GameOverview__grid-container" wrap="wrap">
          <Grid item xs={12} lg={6}>
            <Card className="Card Card--full-height">
              <CardContent>
                <h3>{t('common:screenshots')}</h3>
                {screenshots.length > 0 && (
                  <>
                    <MultiSlider infinite count={3} padding={20} showIndex>
                      {screenshots.map((screenshot, index) => (
                        <Box key={`screenshot-${index}`} sx={{ '&:hover': { cursor: 'pointer' } }}>
                          <LazyLoadImage
                            key={screenshot}
                            alt="a screenshot"
                            src={screenshot}
                            effect="blur"
                            onClick={() => handleOpenStoreScreenshotModal(index)}
                          />
                        </Box>
                      ))}
                    </MultiSlider>
                    <ImageCarouselModal
                      imageUrls={screenshots}
                      open={storeScreenshotModalOpen}
                      onClose={() => setStoreScreenshotModalOpen(false)}
                      title={t('common:screenshots')}
                      initialIndex={selectedStoreScreenshotModalIndex}
                    />
                  </>
                )}
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} lg={6}>
            <FtueVideoPreview
              game={props.game}
              isLoading={isVideoLoading}
              videos={videos}
              selectedChapterTag={selectedChapterTag}
              onChapterTagClick={setSelectedChapterTag}
            />
          </Grid>
        </Grid>
      )}

      <Grid container spacing={2} className="GameOverview__grid-container" wrap="wrap">
        <Grid item xs={12} lg={isOwnGame ? 12 : 6}>
          <Card className="Card Card--full-height">
            <CardContent>
              <h3>{t('common:description')}</h3>
              {!gameVersionInfo && gameVersionInfoLoading && (
                <div className="text-center top-margin">
                  <CircularProgress color="primary" />
                </div>
              )}
              {!props.game.resolvedDescription ? (
                <Typography>{t('common:no_game_description_available')}</Typography>
              ) : (
                <pre>{props.game.resolvedDescription}</pre>
              )}
              {isOwnGame && (
                <Button
                  variant="contained"
                  sx={{ marginTop: '15px' }}
                  onClick={() => {
                    setEditGame(props.game)
                  }}
                >
                  {t('common:edit')}
                </Button>
              )}
            </CardContent>
          </Card>
        </Grid>

        {!isOwnGame && (
          <Grid item xs={12} lg={6}>
            <Grid container spacing={2} wrap="wrap">
              <Grid item xs={12}>
                <Card className="Card Card--full-height">
                  <CardContent>
                    <h3>{t('common:latest_release_notes')}</h3>
                    {!gameVersionInfo && gameVersionInfoLoading && (
                      <div className="text-center top-margin">
                        <CircularProgress color="primary" />
                      </div>
                    )}
                    {gameVersionInfo && !gameVersionInfoLoading && (
                      <>
                        <strong style={{ display: 'block', marginBottom: '10px' }}>
                          {t('common:version')} {gameVersionInfo.version} - {dateFormatter.format(gameVersionInfo.versionReleaseDate)}
                        </strong>
                        <pre>{gameVersionInfo.releaseNotes}</pre>
                      </>
                    )}
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Card className="Card Card--full-height">
                  <CardContent>
                    <h3>{t('common:app_store_info')}</h3>
                    {!gameVersionInfo && gameVersionInfoLoading && (
                      <div className="text-center top-margin">
                        <CircularProgress color="primary" />
                      </div>
                    )}
                    {gameVersionInfo && !gameVersionInfoLoading && (
                      <>
                        <Grid container spacing={2} alignItems="center" className="AppStoreInfoRow">
                          <Grid item xs={4}>
                            <strong>{t('common:game_all_rating')}:</strong>
                          </Grid>
                          <Grid item xs={8}>
                            <span style={{ verticalAlign: 'middle', display: 'inline-block', marginRight: '10px' }}>
                              <Rating size="small" value={Math.round(props.game.avgUserRating[mainMarketIso])} readOnly />
                            </span>
                            <span
                              className="light-text-color"
                              style={{ verticalAlign: 'middle', display: 'inline-block', fontSize: '12px', marginTop: '-2px' }}
                            >
                              ({props.game.userRatingCount[mainMarketIso] && props.game.userRatingCount[mainMarketIso].toLocaleString()})
                            </span>
                          </Grid>
                        </Grid>

                        <Grid container spacing={2} alignItems="top" className="app-store-info-row">
                          <Grid item xs={4}>
                            <strong>{t('common:genres_text')}:</strong>
                          </Grid>
                          <Grid item xs={8}>
                            {props.game.appGenres
                              ?.filter((genreId: number) => (appStoreService.appStoreGenreMap[genreId.toString()] ? true : false))
                              .map((genreId: number, index: number, genres: number[]) => {
                                return (
                                  <span key={genreId}>
                                    <span>{appStoreService.appStoreGenreMap[genreId.toString()]}</span>
                                    {index + 1 < genres.length && <span>, </span>}
                                  </span>
                                )
                              })}
                          </Grid>
                        </Grid>

                        <Grid container spacing={2} alignItems="center" className="app-store-info-row">
                          <Grid item xs={4}>
                            <strong>{t('common:release_date_text')}:</strong>
                          </Grid>
                          <Grid item xs={8}>
                            {dateFormatter.format(gameVersionInfo.releaseDate)}
                          </Grid>
                        </Grid>

                        <Grid container spacing={2} alignItems="center" className="app-store-info-row">
                          <Grid item xs={4}>
                            <strong>{t('common:game_size')}:</strong>
                          </Grid>
                          <Grid item xs={8}>
                            {props.game.getSize()}
                          </Grid>
                        </Grid>

                        <Grid container spacing={2} alignItems="top" className="app-store-info-row">
                          <Grid item xs={4}>
                            <strong>{t('common:languages_text')}:</strong>
                          </Grid>
                          <Grid item xs={8}>
                            {props.game.languages.map((languageCode: string, index: number) => {
                              return (
                                <span key={index}>
                                  <span>{languageService.languageCodeMap[languageCode.toLowerCase()]}</span>
                                  {index + 1 < props.game.languages.length && <span>, </span>}
                                </span>
                              )
                            })}
                          </Grid>
                        </Grid>
                      </>
                    )}
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </div>
  )
}

export default GameOverview

type AnalystOverviewCardProps = {
  gameReview?: GameReview
  isLoading?: boolean
  gameName: string
}

const AnalystOverviewCard: FC<AnalystOverviewCardProps> = ({ gameReview, gameName, isLoading }) => {
  const { t } = useTranslation()

  return (
    <Card>
      {isLoading ? (
        <GRCircularProgress />
      ) : (
        <>
          <CardContent>
            <ReviewHeader
              analyst={gameReview?.analyst}
              title={t('reviews:label_analyst_overview')}
              subtitle={t('reviews:label_our_analysts_key_takeouts')}
              direction="row-reverse"
              gameName={gameName}
            />
            <ShowMore collapsedSize={300}>
              <AnalystOverview review={gameReview?.gameReview} isLoading={isLoading} />
            </ShowMore>
          </CardContent>
        </>
      )}
    </Card>
  )
}
