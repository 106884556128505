import { FC } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'

import { Link } from '@mui/material'

import MultiSlider, { MultiSliderProps } from '../MultiSlider/MultiSlider'

/**
 * Component that creates a slider based on given image urls.
 */
type ImageSliderProps = Omit<MultiSliderProps, 'children'> & {
  imageUrls: string[]
  hasLimitedAccess?: boolean
  onLimitedAccessImageClick?: (url: string, index: number) => void
  onImageClick?: (url: string, index: number) => void
}

export const ImageSlider: FC<ImageSliderProps> = ({ imageUrls, hasLimitedAccess, onImageClick, onLimitedAccessImageClick, ...multiSlideProps }) => {
  const isInfinite = imageUrls.length > multiSlideProps.count

  return (
    <MultiSlider infinite={isInfinite} {...multiSlideProps}>
      {imageUrls.map((url, i) =>
        hasLimitedAccess ? (
          <Link key={i} onClick={() => onLimitedAccessImageClick && onLimitedAccessImageClick(url, i)} underline="none">
            <LazyLoadImage alt="an image" src={url} effect="blur" />
          </Link>
        ) : (
          <Link key={i} onClick={() => onImageClick && onImageClick(url, i)} underline="none">
            <LazyLoadImage alt={'an image'} src={url} effect="blur" />
          </Link>
        )
      )}
    </MultiSlider>
  )
}
