import { TableCellProps } from '@mui/material'

import { GRTableColumn } from '../GRTable'

export const findParentColumn = (parents: GRTableColumn<any, any, any>[], column: GRTableColumn<any, any, any>) => {
  return parents.find((parent) => parent.columns?.find((child) => child === column))
}

export const isLastVisibleColumnOfParent = (
  columns: GRTableColumn<any, any, any>[] = [],
  column: GRTableColumn<any, any, any>,
  visibilityResolver: (col: GRTableColumn<any, any, any>) => boolean
) => {
  let result = false
  columns.forEach((col) => {
    const childColumns = col.columns || []
    const visibleChildColumns = childColumns.filter((col) => !visibilityResolver(col))
    result = result || visibleChildColumns[visibleChildColumns.length - 1] === column
  })

  return result
}

export const getChildColumns = <RowType, CustomPropsType, ColumnIdType>(columns: GRTableColumn<RowType, CustomPropsType, ColumnIdType>[]) =>
  columns.flatMap((col) => col.columns).filter((childCol) => !!childCol) as GRTableColumn<RowType, CustomPropsType, ColumnIdType>[]

export const stickyColumnProps: TableCellProps = {
  sx: {
    position: 'sticky',
    left: 0,
    zIndex: 1,
    backgroundColor: '#fff',
    boxShadow: 'inset -8px 0px 5px -5px rgba(0,0,0, 0.1)',
  },
}
