import { useAppSelector } from '../../../hooks/storeHooks'
import { selectCurrentMarketIso } from '../../account'
import { useCurrentUserLanguage } from '../../account/hooks/userHooks'
import { selectMarketByIso } from '../selectors/selectors'

export const useCurrentMarket = () => {
  const currentMarketIso: string = useAppSelector(selectCurrentMarketIso)
  const userLanguage = useCurrentUserLanguage()
  const currentMarket = useAppSelector((state) => selectMarketByIso(userLanguage)(state, currentMarketIso))
  const englishLanguageMarketIsos = ['us', 'gb', 'au', 'ca', 'nz', 'sg', 'fi', 'se', 'ph']

  return { currentMarketIso, currentMarket, englishLanguageMarketIsos }
}
