import React, { useMemo } from 'react'

import { useGetGamesByStageQuery } from '../../../../api/core'
import { useCurrentUserLanguage } from '../../../account/hooks/userHooks'
import { GameSearchDialogConfig } from '../../../game-search/types/GameSearchDialogConfig'
import { Game } from '../../../game/types/Game'
import { GameStageId } from '../../../game/types/GameStageId'
import { GameSearchResultsTable } from '../GameSearchResultsTable/GameSearchResultsTable'
import './GameSearchDialogSoftLaunchTab.scss'

interface Props {
  marketIso: string
  gameSearchDialogConfig: GameSearchDialogConfig
  selectedGames: { [gameId: string]: Game }
  onGameSelect: (game: Game) => void
  onGameDeselect: (game: Game) => void
  selectedGamesCount?: number
}

const GameSearchDialogSoftLaunchTab: React.FC<Props> = ({
  marketIso,
  gameSearchDialogConfig,
  selectedGames,
  onGameSelect,
  onGameDeselect,
  selectedGamesCount,
}) => {
  const userLanguage = useCurrentUserLanguage()
  const gameInclude = useMemo(() => {
    return 'appId,name,artist,icon,icons,gpsPerMarket,visibility,genreId,internal,owner,archive,stageId,conventionalSubgenreId,conventionalSubgenre'.split(',')
  }, [])
  const { data: games, isLoading } = useGetGamesByStageQuery({
    stageId: GameStageId.soft_launch,
    marketIso,
    limit: 0,
    include: gameInclude,
    includeUnanalyzed: false,
    userLanguage,
  })

  return (
    <div className="GameSearchDialogSoftLaunchTab">
      <GameSearchResultsTable
        games={games || []}
        marketIso={marketIso}
        isLoading={isLoading}
        hideRankData={true}
        onGameSelect={onGameSelect}
        onGameDeselect={onGameDeselect}
        gameSearchDialogConfig={gameSearchDialogConfig}
        selectedGamesCount={selectedGamesCount}
        selectedGames={selectedGames}
      />
    </div>
  )
}

export default GameSearchDialogSoftLaunchTab
