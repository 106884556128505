import { useGetTrackedGamesQuery } from '../../../api/core'
import { useCurrentUserLanguage } from '../../account/hooks/userHooks'

type UseTrackedGamesHookParams = {
  include?: string[]
  eventTypes?: string[]
}

export const useTrackedGames = ({ eventTypes }: UseTrackedGamesHookParams) => {
  const userLanguage = useCurrentUserLanguage()
  return useGetTrackedGamesQuery({ eventTypes, userLanguage })
}
