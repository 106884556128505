import { t } from 'i18next'
import { FC, ReactNode } from 'react'

import { Card, Dialog, DialogContent, DialogProps } from '@mui/material'

import GRDialogTitle from '../../../../../components/GRDialogTitle/GRDialogTitle'
import MarketExplorerSegmentsBreakdown, { MarketExplorerSegmentsBreakdownProps } from '../MarketExplorerSegmentsBreakdown'

type MarketExplorerSegmentBreakdownModalProps = DialogProps &
  MarketExplorerSegmentsBreakdownProps & {
    children?: ReactNode
  }

/**
 * Component for displaying market explorer segment breakdown for selected segment. Simply recycles MarketExplorerSegmentsBreakdown component.
 */
export const MarketExplorerSegmentBreakdownModal: FC<MarketExplorerSegmentBreakdownModalProps> = ({
  open,
  onClose,
  segments,
  yAxis,
  yAxisMax,
  yAxisMin,
  xAxis,
  xAxisMax,
  xAxisMin,
  chartNodes,
  selectedColumns,
  onSelectedColumnsChange,
  onSegmentsChange,
  onSegmentsQueryDataChange,
  segmentData,
}) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
      <GRDialogTitle onClose={() => onClose && onClose({}, 'escapeKeyDown')}>{t('market-explorer:genre_breakdown_modal_title')}</GRDialogTitle>
      <DialogContent dividers>
        <Card>
          <MarketExplorerSegmentsBreakdown
            segments={segments}
            yAxis={yAxis}
            yAxisMax={yAxisMax}
            yAxisMin={yAxisMin}
            xAxis={xAxis}
            xAxisMax={xAxisMax}
            xAxisMin={xAxisMin}
            chartNodes={chartNodes}
            selectedColumns={selectedColumns}
            onSelectedColumnsChange={onSelectedColumnsChange}
            segmentData={segmentData}
            onSegmentsChange={onSegmentsChange}
            onSegmentsQueryDataChange={onSegmentsQueryDataChange}
          />
        </Card>
      </DialogContent>
    </Dialog>
  )
}
