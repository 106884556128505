import { useMemo } from 'react'

import { ConceptTag, useGetConceptQuery, useGetConceptsQuery, useGetTagListQuery } from '../../../api/core'
import { useCurrentUserLanguage } from '../../account/hooks/userHooks'
import { Concept } from '../types/Concept'

type useConceptTagsHookParams = {
  removeTagsWithoutExamples?: boolean
  skip?: boolean
}

/**
 * ConceptTags with Concepts
 * @param param0
 * @returns ConceptTags
 */
export const useConceptTags = ({ removeTagsWithoutExamples = true, skip = false }: useConceptTagsHookParams) => {
  const userLanguage = useCurrentUserLanguage()
  const conceptTagsResults = useGetTagListQuery({ type: 'concept', userLanguage }, { skip })
  const concepts = useGetConceptsQuery(undefined, { skip })

  return useMemo(() => {
    const result = {
      isSuccess: conceptTagsResults.isSuccess && concepts.isSuccess,
      isError: conceptTagsResults.isError || concepts.isError,
      isFetching: conceptTagsResults.isFetching || concepts.isFetching,
      isLoading: conceptTagsResults.isLoading || concepts.isLoading,
    }

    if (conceptTagsResults.data && concepts.data) {
      const data = conceptTagsResults.data
        .map(
          (tag) =>
            ({
              ...tag,
              concept: concepts.data?.find((c) => c.tags.includes(tag.id)) as Concept,
            } as ConceptTag)
        )
        .filter((tag) => {
          // Remove tags without Implementation Examples
          if (removeTagsWithoutExamples) {
            return tag.concept.screenshotIds.length > 0
          }
          return true
        })

      return {
        data,
        ...result,
      }
    } else {
      return {
        data: undefined,
        ...result,
      }
    }
  }, [
    conceptTagsResults.data,
    conceptTagsResults.isError,
    conceptTagsResults.isFetching,
    conceptTagsResults.isLoading,
    conceptTagsResults.isSuccess,
    concepts.data,
    concepts.isError,
    concepts.isFetching,
    concepts.isLoading,
    concepts.isSuccess,
    removeTagsWithoutExamples,
  ])
}

/**
 * Get ConceptTags by Concept ids
 * @param conceptIds Concept ids
 * @returns  ConceptTags
 */
export const useConceptTagsByConceptIds = (conceptIds: string[]) => {
  const conceptTags = useConceptTags({ skip: conceptIds.length === 0 })

  return useMemo(() => {
    if (conceptTags.data) {
      return conceptTags.data.filter((tag) => conceptIds.includes(tag.concept.id))
    } else {
      return []
    }
  }, [conceptTags.data, conceptIds])
}

/**
 * Get ConceptTags by ConceptTag ids
 * @param tagIds ConceptTag ids
 * @returns ConceptTags
 */
export const useConceptTagsByTagIds = (tagIds: string[]) => {
  const conceptTags = useConceptTags({ skip: tagIds.length === 0 })

  return useMemo(() => {
    if (conceptTags.data) {
      return conceptTags.data.filter((tag) => tagIds.includes(tag.id))
    } else {
      return []
    }
  }, [conceptTags.data, tagIds])
}
/**
 * Get concepts by concept tag ids
 * @param tagIds ConceptTag ids
 * @returns Concepts
 */
export const useConceptsByTags = (tagIds: string[]) => {
  const concepts = useGetConceptsQuery(undefined, { skip: tagIds.length === 0 })

  return useMemo(() => {
    if (concepts.data) {
      // return concepts that concept.id is in tagIds
      return concepts.data.filter((concept) => concept.tags.some((tagId) => tagIds.includes(tagId)))
    } else {
      return []
    }
  }, [concepts.data, tagIds])
}

/**
 * Get all concept tags
 * @returns Concept tags
 */
export const useConcepts = () => {
  return useGetConceptsQuery()
}

/**
 * Get concept by id
 * @param conceptId Concept id
 * @returns Concept
 */
export const useConcept = (conceptId: string) => {
  return useGetConceptQuery(conceptId, { skip: !conceptId })
}
