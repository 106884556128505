import { FC, ReactNode, useEffect, useState } from 'react'

import { Box } from '@mui/material'

import keycloakService from '../../../../services/KeycloakService'

type KeycloakInitializerProps = {
  children?: ReactNode
}

/**
 * Component for initializing Keycloak and handling the authentication status
 */
export const KeycloakInitializer: FC<KeycloakInitializerProps> = ({ children }) => {
  const [initialized, setInitialized] = useState<boolean>(false)
  useEffect(() => {
    const initKeycloak = async () => {
      await keycloakService.init()
      setInitialized(true)
    }

    initKeycloak().catch((error) => {
      // eslint-disable-next-line no-console
      console.error('Keycloaker: Failed to initialize Keycloak', error)
      setInitialized(false)
    })
  }, [])
  return <Box sx={{ background: (theme) => theme.palette.background.default, width: '100%', height: '100%' }}>{initialized ? <>{children}</> : null}</Box>
}
