import { t } from 'i18next'
import React, { useCallback, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Settings } from '@mui/icons-material'
import { Button, Card, Grid, TableContainer, Typography } from '@mui/material'

import { SlackbotList, useGetSlackbotHooksQuery } from '../../api/feed'
import { GRTable, GRTableColumn, SortOrder } from '../../components/GRTable/GRTable'
import SlackButton from '../../features/slack/components/SlackButton'
import { useDocumentTitle } from '../../hooks/useDocumentTitle'
import usePage from '../../hooks/usePage'
import PageService from '../../services/PageService'

const SlackIntegrationPage: React.FC = () => {
  useDocumentTitle(t('newsfeed:slack'))
  usePage(PageService.getPageWithId('slack'), 'Visited Slack Page')

  const { data: slackHooks } = useGetSlackbotHooksQuery()
  const navigate = useNavigate()
  const initialColumns: GRTableColumn<SlackbotList, SlackbotList>[] = useMemo(() => {
    return [
      {
        columns: [
          {
            labelAccessor: t('newsfeed:slack_team'),
            accessor: ({ row }) => {
              return <Typography>{row.teamName}</Typography>
            },
            sortable: true,
            sortOrder: SortOrder.DESC,
            sortAccessor: ({ row }) => row.teamName,
            headerCellProps: { sx: { minWidth: 200 } },
            cellProps: { align: 'left' },
          },
        ],
      },
      {
        columns: [
          {
            labelAccessor: t('newsfeed:slack_channel'),
            accessor: ({ row }) => {
              return <Typography>{row.channel || ''}</Typography>
            },
            sortable: true,
            sortOrder: SortOrder.DESC,
            sortAccessor: ({ row }) => row.channel,
            headerCellProps: { sx: { minWidth: 200 } },
            cellProps: { align: 'left' },
          },
        ],
      },
      {
        columns: [
          {
            accessor: ({ row }) => {
              return (
                <Button
                  variant="contained"
                  size="small"
                  color="primary"
                  startIcon={<Settings />}
                  onClick={() => {
                    // go to slack preferences page
                    navigate(`/slack/preferences/${row.teamName}/${row.teamId}/${encodeURIComponent(row.channel)}`, { replace: true })
                  }}
                >
                  {t('newsfeed:show_options')}
                </Button>
              )
            },
            sortable: true,
            headerCellProps: { sx: { minWidth: 200 } },
            cellProps: { align: 'center' },
          },
        ],
      },
    ]
  }, [navigate]) as GRTableColumn<SlackbotList, SlackbotList>[]

  const containerRef = useRef(null)
  const [columns, setColumns] = useState(initialColumns)
  const handleColumnsUpdate = useCallback((updatedColumns: GRTableColumn<SlackbotList, SlackbotList, void>[]) => {
    setColumns(updatedColumns)
  }, [])

  const slackMessage = useMemo(() => {
    if (slackHooks) {
      if (!slackHooks.length) {
        return t('newsfeed:slack_no_integrations')
      }
      return t('newsfeed:slack_note')
    }
    return ''
  }, [slackHooks])

  return (
    <div className="SlackIntegrationPage">
      <Grid container spacing={2}>
        <Grid item xs sx={{ paddingBottom: 2, display: 'flex', alignItems: 'center' }}>
          <Typography variant="h2">{t('newsfeed:slack_integrations')}</Typography>
        </Grid>
        <Grid item sx={{ paddingBottom: 2 }}>
          <SlackButton />
        </Grid>
      </Grid>
      {slackHooks && (
        <Grid container spacing={2} sx={{ paddingLeft: 2, marginTop: 2 }}>
          <TableContainer component={Card} ref={containerRef} sx={{ borderRadius: 1 }}>
            <GRTable columns={columns} rows={slackHooks} scroller={containerRef} onColumnsUpdated={handleColumnsUpdate} rowIdKey="channel" hoverable striped />
            <Typography sx={{ pl: '9px ', py: 1 }}>{slackMessage}</Typography>
          </TableContainer>
        </Grid>
      )}
    </div>
  )
}

export default SlackIntegrationPage
