import React from 'react'
import { useTranslation } from 'react-i18next'

import { Button, Chip, Grid, Typography } from '@mui/material'

import { SingleSubgenreSelection } from '../../../../components/GameSubgenrePicker/GameSubgenrePicker'
import languageService from '../../../../services/LanguageService'
import { SubgenreMap } from '../../../account'
import './SelectedSubgenreChips.scss'

interface Props {
  onClearSelection?: () => void
  selectedSubgenres: SubgenreMap
  singleSelected?: SingleSubgenreSelection
  smallChips?: boolean
  showTitle?: boolean
}

const SelectedSubgenreChips: React.FC<Props> = ({ onClearSelection, singleSelected, selectedSubgenres, smallChips, showTitle = true }) => {
  const { t } = useTranslation()

  return (
    <Grid container className="SelectedSubgenreChips" alignItems="center" spacing={1} wrap="wrap">
      {showTitle && (
        <Grid item>
          <Typography variant="h5" className="SelectedSubgenreChips__title">
            {singleSelected ? (
              (singleSelected.conventionalCategoryId !== undefined && singleSelected && singleSelected.conventionalCategoryId !== '') ||
              (singleSelected.conventionalGenreId !== undefined && singleSelected.conventionalGenreId !== '') ||
              (singleSelected.conventionalSubgenreId !== undefined && singleSelected.conventionalSubgenreId !== '') ? (
                <>
                  {t('common:selected') + ' '}
                  {singleSelected.conventionalCategoryId && t('common:conventional_category').toLowerCase()}
                  {singleSelected.conventionalGenreId && t('common:conventional_genre').toLowerCase()}
                  {singleSelected.conventionalSubgenreId && t('common:conventional_subgenre').toLowerCase()}:
                </>
              ) : null
            ) : (
              t('select-game:conventionals_picker_selected_subgenres_title')
            )}
          </Typography>
        </Grid>
      )}
      {!!singleSelected ? (
        <>
          {singleSelected.conventionalSubgenreId && (
            <Grid item>
              <Chip color="secondary" label={languageService.getTranslation('conventionalSubgenres', singleSelected.conventionalSubgenreId)} />
            </Grid>
          )}
          {singleSelected.conventionalGenreId && (
            <Grid item>
              <Chip color="secondary" label={languageService.getTranslation('conventionalGenres', singleSelected.conventionalGenreId)} />
            </Grid>
          )}
          {singleSelected.conventionalCategoryId && (
            <Grid item>
              <Chip color="secondary" label={languageService.getTranslation('conventionalCategories', singleSelected.conventionalCategoryId)} />
            </Grid>
          )}
        </>
      ) : (
        Object.keys(selectedSubgenres).map((subgenreId) => (
          <Grid item key={subgenreId}>
            <Chip color="secondary" size={smallChips ? 'small' : undefined} label={languageService.getTranslation('conventionalSubgenres', subgenreId)} />
          </Grid>
        ))
      )}
      {onClearSelection && singleSelected
        ? ((singleSelected.conventionalCategoryId !== undefined && singleSelected && singleSelected.conventionalCategoryId !== '') ||
            (singleSelected.conventionalGenreId !== undefined && singleSelected.conventionalGenreId !== '') ||
            (singleSelected.conventionalSubgenreId !== undefined && singleSelected.conventionalSubgenreId !== '')) && (
            <Grid item>
              <Button size="small" onClick={onClearSelection}>
                {t('tags-list:tags_clear_selection')}
              </Button>
            </Grid>
          )
        : null}
      {onClearSelection && selectedSubgenres
        ? selectedSubgenres !== undefined &&
          Object.keys(selectedSubgenres).length > 0 && (
            <Grid item>
              <Button size="small" onClick={onClearSelection}>
                {t('tags-list:tags_clear_selection')}
              </Button>
            </Grid>
          )
        : null}
    </Grid>
  )
}

export default SelectedSubgenreChips
