import { t } from 'i18next'
import { FC, useState } from 'react'

import { ImageCarouselModal } from '../../../../components/ImageCarouselModal/ImageCarouselModal'
import { ImageSlider } from '../../../../components/ImageSlider/ImageSlider'
import { MultiSliderProps } from '../../../../components/MultiSlider/MultiSlider'
import { useNewsFeedLimitedAccessCheck } from '../../../account/hooks/roleHooks'

type GameVersionScreenshotSliderProps = Omit<MultiSliderProps, 'children'> & {
  screenshotUrls: string[]
}

export const GameVersionScreenshotSlider: FC<GameVersionScreenshotSliderProps> = ({ screenshotUrls, ...multiSlideProps }) => {
  const hasNewsFeedLimitedAccess = useNewsFeedLimitedAccessCheck()
  const [imageCrouselModalOpen, setImageCarouselModalOpen] = useState<boolean>(false)
  const [selectedScreenshotIndex, setSelectedScreenshotIndex] = useState<number>()

  const handleClick = (url: string, index: number) => {
    setImageCarouselModalOpen(true)
    setSelectedScreenshotIndex(index)
  }

  return (
    <>
      <ImageSlider imageUrls={screenshotUrls} onImageClick={handleClick} hasLimitedAccess={hasNewsFeedLimitedAccess} {...multiSlideProps} />
      {imageCrouselModalOpen && (
        <ImageCarouselModal
          imageUrls={screenshotUrls}
          open={imageCrouselModalOpen}
          onClose={() => setImageCarouselModalOpen(false)}
          title={t('common:screenshots')}
          initialIndex={selectedScreenshotIndex}
        />
      )}
    </>
  )
}
