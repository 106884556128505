import { SegmentQueryReturnType } from '../components/MarketExplorerSegments/MarketExplorerSegment/MarketExplorerSegment'
import { MarketExplorerSegmentFilterConfig } from '../types/MarketExplorerSegmentFilterConfig'

export const hasMotivationData = (segment: SegmentQueryReturnType) => {
  return (segment.data?.motivationData.motivations?.length || 0) > 0
}

/**
 * Helper method for extracting segment filter min/max values for data requests
 */
export const getRanksDemographicsAndMotivationFilterValues = ({
  filterConfig,
  minValue,
  maxValue,
}: {
  filterConfig: MarketExplorerSegmentFilterConfig<string>
  minValue?: number
  maxValue?: number
}) => {
  const minValueDeviated = minValue !== filterConfig.min
  const maxValueDeviated = maxValue !== filterConfig.max
  let resolvedMinValue, resolvedMaxValue

  if (minValueDeviated && maxValueDeviated) {
    resolvedMinValue = minValue
    resolvedMaxValue = maxValue
  } else if (minValueDeviated) {
    resolvedMinValue = minValue
  } else if (maxValueDeviated) {
    resolvedMinValue = minValue
    resolvedMaxValue = maxValue
  }

  return { minValue: resolvedMinValue, maxValue: resolvedMaxValue }
}
