import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Grid } from '@mui/material'

import AddArea from '../../../../components/AddArea/AddArea'
import GRCircularProgress from '../../../../components/GRCircularProgress/GRCircularProgress'
import { LockedFeature } from '../../../../components/LockedFeature/LockedFeature'
import { removeFromIndex } from '../../../../helpers/arrays'
import { LockedFeatureId } from '../../../../types/LockedFeature'
import { useMarketExplorerAccessCheck } from '../../../account/hooks/roleHooks'
import { useCurrentMarket } from '../../../markets'
import { maxSegmentCount } from '../../constants/constants'
import { useDefaultSegment } from '../../hooks/defaultSegmentHooks'
import { MarketExplorerSegmentConfiguration } from '../../types/MarketExplorerSegmentConfiguration'
import { MarketExplorerSegmentEditDialog } from '../MarketExplorerSegmentEditDialog/MarketExplorerSegmentEditDialog'
import { MarketExplorerSegment, SegmentQueryDataType } from './MarketExplorerSegment/MarketExplorerSegment'

/**
 * A component for rendering market explorer segments and adding new segments
 */

export type SegmentsQueryReturnType = {
  data: SegmentQueryDataType[]
  isLoading?: boolean
  isSuccess?: boolean
  isError?: boolean
  error?: any
}

type MarketExplorerSegmentsProps = {
  onSegmentsChange: (segments: MarketExplorerSegmentConfiguration[]) => void
  onSegmentsQueryDataChange: (segmentsData: SegmentsQueryReturnType) => void
  segments: MarketExplorerSegmentConfiguration[]
  displaySegments?: MarketExplorerSegmentConfiguration[]
  showToggleVisibility?: boolean
  showAddNewSegment?: boolean
  chartNodesCount?: number
  preventInit?: boolean
  segmentIndex?: number
  overrideColorIndex?: number
  onSegmentDelete?: () => void
}

const MarketExplorerSegments: FC<MarketExplorerSegmentsProps> = memo(
  ({
    onSegmentsChange,
    onSegmentsQueryDataChange,
    segments = [],
    displaySegments,
    showToggleVisibility,
    showAddNewSegment,
    preventInit,
    chartNodesCount,
    segmentIndex,
    overrideColorIndex,
    onSegmentDelete,
  }) => {
    const { t } = useTranslation()
    const { currentMarketIso } = useCurrentMarket()
    const [segmentsInitialized, setSegmentsInitialized] = useState<boolean>(false)
    const hasMarketExplorerAccess = useMarketExplorerAccessCheck()
    const [newSegment, setNewSegment] = useState<MarketExplorerSegmentConfiguration>()
    const [segmentsQueryData, setSegmentsQueryData] = useState<SegmentsQueryReturnType>({ data: [] })
    const [editSegmentDialogIndex, setEditSegmentDialogIndex] = useState<number>(0)
    const [editSegmentDialogOpen, setEditSegmentDialogOpen] = useState<boolean>(false)
    const [lockedFeatureDialogOpen, setLockedFeatureDialogOpen] = useState<boolean>(false)
    const defaultUserSegment = useDefaultSegment()

    // format of a default segment when creating new segments and user has not defined a default segment as user setting
    const defaultSegment = useMemo(
      () => ({
        visible: true,
        marketIso: currentMarketIso,
        filters: {
          ranks: {},
          demographics: {},
          motivations: {},
          archetypes: {},
        },
      }),
      [currentMarketIso]
    )

    // add the first initial segment either from user preferences or a predefined one
    useEffect(() => {
      if (
        !preventInit &&
        (segments.length === 0 || !hasMarketExplorerAccess) &&
        !defaultUserSegment.isLoading &&
        defaultUserSegment.isSuccess &&
        !newSegment &&
        !editSegmentDialogOpen &&
        !segmentsInitialized
      ) {
        if (defaultUserSegment.data) {
          onSegmentsChange([{ ...defaultUserSegment.data }])
        } else if (!defaultUserSegment.data) {
          setNewSegment({ ...defaultSegment })
        }

        setSegmentsInitialized(true)
      }
    }, [
      segments.length,
      defaultUserSegment.data,
      defaultUserSegment.isLoading,
      defaultUserSegment.isSuccess,
      onSegmentsChange,
      defaultSegment,
      newSegment,
      editSegmentDialogOpen,
      segmentsInitialized,
      preventInit,
      hasMarketExplorerAccess,
    ])

    useEffect(() => {
      if (preventInit) return
      onSegmentsQueryDataChange(segmentsQueryData)
    }, [onSegmentsQueryDataChange, preventInit, segmentsQueryData])

    const handleSegmentChange = useCallback(
      (updatedSegment: MarketExplorerSegmentConfiguration, segmentIndex: number) => {
        const newSegments = [...segments]
        newSegments[segmentIndex] = updatedSegment
        onSegmentsChange(newSegments)
      },
      [onSegmentsChange, segments]
    )

    const handleCreateSegment = useCallback(() => {
      if (hasMarketExplorerAccess) {
        setNewSegment({ ...(defaultUserSegment.data || defaultSegment) })
      } else {
        setLockedFeatureDialogOpen(true)
      }
    }, [defaultSegment, defaultUserSegment, hasMarketExplorerAccess])

    const handleEditDialogClose = useCallback(
      (updatedSegment: MarketExplorerSegmentConfiguration | undefined) => {
        setEditSegmentDialogOpen(false)
        updatedSegment && handleSegmentChange(updatedSegment, editSegmentDialogIndex)
      },
      [handleSegmentChange, editSegmentDialogIndex]
    )

    const handleCreateDialogClose = useCallback(
      (createdSegment: MarketExplorerSegmentConfiguration | undefined) => {
        const newSegments = createdSegment ? [...segments, createdSegment] : segments

        onSegmentsChange(newSegments)
        setNewSegment(undefined)
      },
      [onSegmentsChange, segments]
    )

    const handleRemoveSegment = useCallback(
      (segmentIndex: number) => {
        const newSegments = removeFromIndex(segments, segmentIndex)
        !lockedFeatureDialogOpen && hasMarketExplorerAccess && onSegmentsChange([...newSegments])

        if (!hasMarketExplorerAccess) {
          setLockedFeatureDialogOpen(true)
        }
        if (onSegmentDelete) {
          onSegmentDelete()
        }
      },
      [hasMarketExplorerAccess, lockedFeatureDialogOpen, onSegmentDelete, onSegmentsChange, segments]
    )

    // filter away segment query data if segment has been removed
    useEffect(() => {
      setSegmentsQueryData((currentData) => {
        const newData = currentData.data.filter((segmentQuery) => segments.find((segment) => segmentQuery?.segmentConfiguration === segment))

        return {
          ...currentData,
          data: newData,
        }
      })
    }, [segments])

    const handleSegmentQueryDataChange = useCallback((newSegmentQueryData: SegmentQueryDataType, segmentIndex: number) => {
      setSegmentsQueryData((currentSegmentsQueryData) => {
        currentSegmentsQueryData.data[segmentIndex] = newSegmentQueryData
        const newSegmentsQueryData = [...currentSegmentsQueryData.data]
        const result = {
          ...currentSegmentsQueryData,
          segmentsQueryData: newSegmentsQueryData,
          isLoading: newSegmentsQueryData.reduce(
            (acc, segmentQueryData) => acc || !!segmentQueryData?.isLoading || !!segmentQueryData?.isFetching,
            false as boolean
          ),
          isSuccess: newSegmentsQueryData.reduce((acc, segmentQueryData) => acc || !!segmentQueryData?.isSuccess, false as boolean),
          isError: newSegmentsQueryData.reduce((acc, segmentQueryData) => acc || !!segmentQueryData?.isError, false as boolean),
          error: newSegmentsQueryData.find((segmentQueryData) => segmentQueryData?.error),
        }

        return result
      })
    }, [])

    const handleEdit = useCallback(
      (segmentIndex: number) => {
        if (hasMarketExplorerAccess) {
          setEditSegmentDialogIndex(segmentIndex)
          setEditSegmentDialogOpen(true)
        } else {
          setLockedFeatureDialogOpen(true)
        }
      },
      [hasMarketExplorerAccess]
    )

    const handleToggleVisibility = useCallback(
      (segmentIndex: number) => {
        const updatedSegments = segments.map((segment, index) => {
          return index === segmentIndex ? { ...segment, visible: !segment.visible } : segment
        })

        onSegmentsChange(updatedSegments)
      },
      [onSegmentsChange, segments]
    )

    const isEditSegmentLoading = segmentsQueryData.data[editSegmentDialogIndex]?.isFetching || segmentsQueryData.data[editSegmentDialogIndex]?.isLoading

    const showSegments = useMemo(() => (displaySegments ? displaySegments : segments), [displaySegments, segments])
    return (
      <>
        <Grid container wrap="wrap" spacing={2} className="MarketExplorerSegments">
          {defaultUserSegment.isLoading ? (
            <Grid item xs={12}>
              <GRCircularProgress />
            </Grid>
          ) : (
            <>
              {segments.length === 1 && showAddNewSegment ? (
                <MarketExplorerSegment
                  segment={segments[0]}
                  segmentIndex={segmentIndex ? segmentIndex : 0}
                  onSegmentQueryDataChange={handleSegmentQueryDataChange}
                  onEdit={handleEdit}
                  onRemove={handleRemoveSegment}
                  onToggleVisibility={handleToggleVisibility}
                  showToggleVisibility={showToggleVisibility}
                  chartNodesCount={chartNodesCount}
                  overrideColorIndex={overrideColorIndex}
                />
              ) : (
                showSegments.map((segment, index) => {
                  return (
                    <Grid item xl={6} lg={6} xs={12} key={index}>
                      <MarketExplorerSegment
                        segment={segment}
                        segmentIndex={segmentIndex ? segmentIndex : index}
                        onSegmentQueryDataChange={handleSegmentQueryDataChange}
                        onEdit={handleEdit}
                        onRemove={handleRemoveSegment}
                        onToggleVisibility={handleToggleVisibility}
                        showToggleVisibility={showToggleVisibility}
                        chartNodesCount={chartNodesCount}
                      />
                    </Grid>
                  )
                })
              )}
              {segments.length < maxSegmentCount && !showAddNewSegment && (
                <Grid item xl={6} lg={6} xs={12}>
                  <AddArea onClick={handleCreateSegment} label={t('segment:create_new_segment')} />
                </Grid>
              )}
            </>
          )}
        </Grid>

        {editSegmentDialogOpen && (
          <MarketExplorerSegmentEditDialog
            open={editSegmentDialogOpen}
            title={t('segment:segment_details_title')}
            onClose={handleEditDialogClose}
            initialSegment={segments[editSegmentDialogIndex]}
            segmentQueryData={segmentsQueryData.data[editSegmentDialogIndex]}
            isLoading={isEditSegmentLoading}
            useRawResults
          />
        )}

        {newSegment && (
          <MarketExplorerSegmentEditDialog
            open={!!newSegment}
            title={t('segment:segment_details_title')}
            onClose={handleCreateDialogClose}
            initialSegment={newSegment}
            useRawResults
          />
        )}

        <LockedFeature.Dialog
          lockedFeatureId={LockedFeatureId.MarketExplorer}
          open={lockedFeatureDialogOpen}
          onClose={() => setLockedFeatureDialogOpen(false)}
        />
      </>
    )
  }
)

export default MarketExplorerSegments
