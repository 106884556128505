import React, { useCallback, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'

import { useCloneGameMutation } from '../../../../api/core'
import { useAppDispatch } from '../../../../hooks/storeHooks'
import analyticsService from '../../../../services/AnalyticsService'
import { useCurrentUserLanguage } from '../../../account/hooks/userHooks'
import { displaySnackBar } from '../../../snackbar'
import { SnackBarMessage } from '../../../snackbar/types/SnackBarMessage'
import { Game } from '../../types/Game'
import GameEditModal from '../GameEditModal/GameEditModal'

/**
 * GameCloneDialog Open Dialog confirmation to clone game
 */
interface GameCloneDialogProps {
  open: boolean
  onClose: () => void
  originGame: Game
}
const GameCloneDialog: React.FC<GameCloneDialogProps> = ({ open, onClose, originGame }) => {
  const { t } = useTranslation()
  const userLanguage = useCurrentUserLanguage()

  const [newGameClone, setNewGameClone] = useState<Game | undefined>()
  const [cloneGame, { data: clonedGame, isLoading: isGameCloning, isSuccess: isGameCloningSuccess, error: gameCloningError }] = useCloneGameMutation()
  const dispatch = useAppDispatch()
  const showError = useCallback((errorPayload: SnackBarMessage) => dispatch(displaySnackBar(errorPayload)), [dispatch])
  const navigate = useNavigate()

  const onGameCloneClick = () => {
    cloneGame({ gameId: originGame.id, userLanguage })
  }

  const onGameEditDone = useCallback(
    (game: Game, isGameDeleted?: boolean) => {
      if (!isGameDeleted) {
        navigate(`/game-analyzer/${game.id}`, { replace: true })
        return
      }
      navigate('/game-analyzer')
      onClose()
    },
    [navigate, onClose]
  )

  useEffect(() => {
    if (isGameCloningSuccess && clonedGame) {
      analyticsService.trackEvent('Cloned game', { data: { clonedGameId: clonedGame.id, gameId: originGame.id, gameName: originGame.resolvedName } })
      setNewGameClone(clonedGame)
    }
  }, [clonedGame, isGameCloningSuccess, onGameEditDone, originGame.id, originGame.resolvedDescription, originGame.resolvedName])

  useEffect(() => {
    if (gameCloningError) {
      showError({ message: t('game-header:clone_error_body'), severity: 'error', open: true })
    }
  }, [gameCloningError, showError, t])

  const onGameEditClose = () => {
    setNewGameClone(undefined)
    onClose()
  }

  return (
    <>
      {!!newGameClone && <GameEditModal onGameEditDone={onGameEditDone} game={newGameClone} open={!!newGameClone} onClose={onGameEditClose} />}
      <Dialog open={open}>
        <DialogTitle>{t('game-header:clone_game')}</DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 2 }}>
            <Trans i18nKey="game-header:confirm_clone_text" values={{ gameName: originGame.resolvedName }} />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" disabled={isGameCloning} onClick={() => onGameCloneClick()}>
            {t('common:yes')}
          </Button>
          <Button disabled={isGameCloning} onClick={() => onClose()}>
            {t('common:no')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default GameCloneDialog
