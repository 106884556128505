import { configureStore } from '@reduxjs/toolkit'

import { accountApi, unauthenticatedAccountApi } from './api/account'
import { aiAnalystApi } from './api/ai-analyst'
import { combinedApi } from './api/combined'
import { connectServerApi } from './api/connect'
import { coreApi, unauthenticatedCoreApi } from './api/core'
import { feedApi } from './api/feed'
import { keycloakApi } from './api/keycloak'
import { reportsApi } from './api/reports'
import { topGrossingServerApi } from './api/top-grossing'
import allReducers from './reducers'

export const store = configureStore({
  reducer: allReducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(
      accountApi.middleware,
      unauthenticatedAccountApi.middleware,
      combinedApi.middleware,
      coreApi.middleware,
      unauthenticatedCoreApi.middleware,
      connectServerApi.middleware,
      topGrossingServerApi.middleware,
      feedApi.middleware,
      aiAnalystApi.middleware,
      reportsApi.middleware,
      keycloakApi.middleware
    ),
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
