import classNames from 'classnames'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

import LockIcon from '@mui/icons-material/Lock'
import { Box, Button, Card, CardActions, CardContent, Grid, Typography } from '@mui/material'

import { CounterButton } from '../../../../components/CounterButton/CounterButton'
import { LimitedFunctionalityBanner } from '../../../../components/LimitedFunctionalityBanner/LimitedFunctionalityBanner'
import { useDocumentTitle } from '../../../../hooks/useDocumentTitle'
import analyticsService from '../../../../services/AnalyticsService'
import { LockedFeatureId } from '../../../../types/LockedFeature'
import { useFeatureTagsAccessCheck, useImplementationExamplesAccessCheck } from '../../../account/hooks/roleHooks'
import { FeatureSelectDialog, FeatureSelectDialogResult, FeatureSelectionMode } from '../../../feature/components/FeatureSelectDialog/FeatureSelectDialog'
import GameSearchWithImplementationsDialog from '../../../game-search/components/GameSearchWithImplementationsDialog/GameSearchWithImplementationsDialog'
import { useCurrentMarket } from '../../../markets'
import { useGamesOpenForEveryone } from '../../../settings/hooks/useGamesOpenForEveryone'
import { examplesRootPath, useGameFeatureMemo } from '../../hooks/useInitialState'
import { buildImplementationSearchUrl } from '../../util/buildImplementationSearchUrl'
import GameByFeatureSelection from '../GameSelection/GameByFeatureSelection'
import GameSelection from '../GameSelection/GameSelection'
import './ImplementationExamples.scss'

const ImplementationExamples: React.FC = () => {
  useEffect(() => {
    analyticsService.trackEvent('Visited Feature Implementation Examples')
  }, [])

  const hasImplemenationExamplesAccess = useImplementationExamplesAccessCheck()
  const hasAccessToFeatureTags = useFeatureTagsAccessCheck()
  const gamesOpenForEveryone = useGamesOpenForEveryone()
  const limitedAccessGames = useMemo(
    () => gamesOpenForEveryone?.openGamesSetting?.data?.openGames.concat(gamesOpenForEveryone.featuredGamesSetting?.data.featuredGames),
    [gamesOpenForEveryone.featuredGamesSetting?.data.featuredGames, gamesOpenForEveryone?.openGamesSetting?.data?.openGames]
  )
  const { t } = useTranslation()
  const navigate = useNavigate()
  useDocumentTitle(t('sidebar:impl_examples'))

  const { currentMarketIso } = useCurrentMarket()
  const [gameModeDialogOpen, setGameModeDialogOpen] = useState(false)
  const [featuresLoading, setFeaturesLoading] = useState(false)
  const [featureSelectDialogOpen, setFeatureSelectDialogOpen] = useState<boolean>(false)

  const currentState = useGameFeatureMemo()
  const gameIds = hasImplemenationExamplesAccess ? currentState?.gameIds : limitedAccessGames

  const selectedFeatures = currentState?.featureIds || {}
  const selectedFeatureIds = Object.keys(currentState?.featureIds || {}).map((val) => +val)
  const selectedFeaturesCount = selectedFeatureIds.length

  const selectedConceptTags = currentState?.conceptTagIds || []
  const selectedConceptTagsCount = selectedConceptTags.length || 0

  const selectedChoiceIds = Object.values(currentState?.featureIds || {}).flatMap((choicesArr) => choicesArr)
  const mode = currentState?.mode === 'games' ? 'games' : 'features'

  const onChangeGameIds = (gameIds: string[]) => {
    navigate(buildImplementationSearchUrl(gameIds))
  }

  const handleFeatureSelectionChange = useCallback(
    (selection: FeatureSelectDialogResult) => {
      const featureSelectionAsPathParam = Object.entries(selection.featureChoices).reduce((acc, [featureLegacyId, choiceLegacyIds]) => {
        const selectionAsString = [featureLegacyId, ...choiceLegacyIds].join('-')
        const result = acc ? acc + ',' + selectionAsString : selectionAsString

        return result
      }, '')

      const conceptsTags = selection.conceptTags.map((conceptTag) => conceptTag.id).join(',')

      navigate(
        examplesRootPath +
          (featureSelectionAsPathParam ? '/features/' + featureSelectionAsPathParam : '') +
          (conceptsTags ? '/featuretags/' + conceptsTags : '')
      )
    },
    [navigate]
  )

  const handleLoading = useCallback((isLoading: boolean) => {
    setFeaturesLoading(isLoading)
  }, [])

  return (
    <div className="ImplementationExamples">
      {!hasImplemenationExamplesAccess && (
        <Box mb={2} mt={4}>
          <LimitedFunctionalityBanner lockedFeatureId={LockedFeatureId.ImplementationExamples} />
        </Box>
      )}
      <Typography variant="h2" mt={4}>
        {t('implementations:label_filter_implementations')}
      </Typography>
      <Typography variant="body1" mb={2}>
        <Trans i18nKey="implementations:start_description_text" />
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Card className={classNames({ selected: mode === 'features' })}>
            <CardContent>
              <Typography variant="h3" color="primary" gutterBottom>
                {t('implementations:label_features_filtermode')}
              </Typography>
              <Typography>
                {hasAccessToFeatureTags ? (
                  <Trans i18nKey="implementations:label_description_features_filtermode_with_tags" />
                ) : (
                  <Trans i18nKey="implementations:label_description_features_filtermode" />
                )}
              </Typography>
            </CardContent>
            <CardActions>
              <CounterButton
                label={hasAccessToFeatureTags ? t('feature-select-modal:select_features_and_tags') : t('feature-select-modal:select_features')}
                count={selectedFeaturesCount + selectedConceptTagsCount}
                loading={featuresLoading}
                onClick={() => setFeatureSelectDialogOpen(true)}
                disabled={!hasImplemenationExamplesAccess}
                startIcon={!hasImplemenationExamplesAccess ? <LockIcon /> : undefined}
              />
              {(!!selectedFeaturesCount || !!selectedConceptTagsCount) && (
                <Button
                  variant="contained"
                  color="warning"
                  size="large"
                  onClick={() => {
                    handleFeatureSelectionChange({ featureChoices: {}, conceptTags: [] })
                  }}
                >
                  {t('common:clear')}
                </Button>
              )}
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Card className={classNames({ selected: mode === 'games' })}>
            <CardContent>
              <Typography variant="h3" color="primary" gutterBottom>
                {t('implementations:label_features_gamemode')}
              </Typography>
              <Typography>
                <Trans i18nKey="implementations:label_description_features_gamesmode" />
              </Typography>
            </CardContent>
            <CardActions>
              <CounterButton
                label={t('segment:filter_select_games')}
                count={gameIds?.length}
                onClick={() => setGameModeDialogOpen(true)}
                disabled={!hasImplemenationExamplesAccess}
                startIcon={!hasImplemenationExamplesAccess ? <LockIcon /> : undefined}
              />
              {mode === 'games' && !!gameIds?.length && (
                <Button variant="contained" color="warning" size="large" onClick={() => onChangeGameIds([])}>
                  {t('common:clear')}
                </Button>
              )}
            </CardActions>
          </Card>
        </Grid>
      </Grid>
      <FeatureSelectDialog
        selectedFeatures={selectedFeatures}
        selectedConceptTagIds={selectedConceptTags}
        open={featureSelectDialogOpen}
        onClose={() => setFeatureSelectDialogOpen(false)}
        onConfirm={handleFeatureSelectionChange}
        selectionMode={FeatureSelectionMode.Features}
        marketIso={currentMarketIso}
        showScreenshotCounts
        onLoading={handleLoading}
        concepts={true}
      />
      {gameModeDialogOpen && (
        <GameSearchWithImplementationsDialog
          open={true}
          onClose={() => setGameModeDialogOpen(false)}
          onSelectGameIds={onChangeGameIds}
          initialGameIds={gameIds}
        />
      )}
      {selectedFeaturesCount || selectedConceptTagsCount ? (
        <GameByFeatureSelection choiceIds={selectedChoiceIds} featureIds={selectedFeatureIds} conceptTagIds={selectedConceptTags} />
      ) : (
        gameIds?.length && <GameSelection gameIds={gameIds} />
      )}
    </div>
  )
}

export default ImplementationExamples
