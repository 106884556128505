import querystring, { ParsedQuery } from 'query-string'
import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import { Tag, TrendingResponse, useGetKeywordListQuery, useGetTrendingQuery } from '../../../api/core'
import { DateRangeValue } from '../../../components/DateRangePicker/DateRangePicker'
import { SingleSubgenreSelection } from '../../../components/GameSubgenrePicker/GameSubgenrePicker'
import { intersection } from '../../../helpers/intersection'
import { useMarketTrendsAccessCheck } from '../../account/hooks/roleHooks'
import { useCurrentUserLanguage } from '../../account/hooks/userHooks'
import { FeatureCategory } from '../../feature/hooks/useFeatures'
import { TabOption } from '../../feature/types/feature-modal-tabs'
import { FeatureAndKeywordSearchResult } from '../../feature/types/types'
import { useInitialGameFeatureState } from '../../implementation-examples/hooks/useInitialState'
import { featureSearchResultFilter } from '../../implementation-examples/util/filters'

function padTo2Digits(num: number): string {
  return num.toString().padStart(2, '0')
}

export const formatISODate = (date?: Date | null): string | null => {
  if (!date) {
    return null
  }
  const y = date.getFullYear()
  const m = padTo2Digits(date.getMonth() + 1)
  const d = padTo2Digits(date.getDate())
  return [y, m, d].join('-')
}

export const useMarketTrends = (marketIso: string, source: string, sortBy: string, dateRange?: DateRangeValue, subgenre?: SingleSubgenreSelection) =>
  useGetTrendingQuery({
    enableCache: true,
    endDate: formatISODate(dateRange?.toDate),
    marketIso,
    noChoices: false,
    source,
    startDate: formatISODate(dateRange?.fromDate),
    trendingDown: true,
    ...subgenre,
  })

export type TrendHistory = {
  choice: string
  history?: any[]
  legacyId: number
  name: string
}

export type LocationState = {
  dateRange: { fromDate: Date | null; toDate: Date | null }
  initialTab?: TabOption
  sortBy: string
  source: string
  top: number
  scaleYAxis: boolean
  tags: string
  subgenre: string
  genre: string
  category: string
}

export type TrendHistories = { [weightId: string]: TrendHistory }

export const useTrendHistories = (
  trendData: TrendingResponse[] | undefined,
  selectedTags: Tag[],
  searchResult: FeatureAndKeywordSearchResult,
  featureCategories: FeatureCategory[]
) => {
  const userLanguage = useCurrentUserLanguage()
  const tagIds = selectedTags.map(({ id }) => id)
  const { data: keywords } = useGetKeywordListQuery({ type: 'feature', userLanguage })
  const searchFilter = featureSearchResultFilter(false, selectedTags, searchResult, keywords)
  const hasMarketTrendsAccess = useMarketTrendsAccessCheck()

  const featuresToMatch = useMemo(
    () =>
      trendData?.reduce((acc, { popularFeatures }) => {
        popularFeatures.forEach(({ featureLegacyId, choiceLegacyId }) => {
          acc[featureLegacyId] = choiceLegacyId
        })

        return acc
      }, {} as { [featureId: number]: number }),
    [trendData]
  )

  return useMemo(
    () =>
      featureCategories?.reduce((acc, { active, id, features, name: catName }) => {
        if (active) {
          features.filter(searchFilter).forEach((feature) => {
            if (!featuresToMatch) {
              return
            }

            const { active, choices, hidden, legacyId, name, tags } = feature
            if (hidden || !active || (tags && tagIds.length && !intersection(tags, tagIds).length)) {
              return
            }

            choices.forEach(({ active, choice, showInLists, weightId }) => {
              // skip if feature and choice is not found in featuresToMatch
              // this filters out unwated featres when user has no access to market trends
              if (!hasMarketTrendsAccess && (!featuresToMatch[legacyId] || featuresToMatch[legacyId] !== weightId)) {
                return
              }

              if (active && showInLists) {
                acc[weightId] = { choice, name, legacyId }
              }
            })
          })
        }
        return acc
      }, {} as TrendHistories),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [featureCategories, selectedTags, searchResult, featuresToMatch]
  )
}

export const useInitialLocationState = (): LocationState => {
  const { search } = useLocation()
  useInitialGameFeatureState()

  const params: ParsedQuery<string> = querystring.parse(search)
  const source = (params.source as string) || 'revenue'
  const sortBy = (params.sortBy as string) || 'up'
  const top = params.top ? parseInt(params.top as string, 10) / 100 : 0.2
  const scaleYAxis = params.scaleYAxis ? params.scaleYAxis === 'true' : true
  const tags = params.tags ? (params.tags as string) : ''
  const subgenre = params.subgenre ? (params.subgenre as string) : ''
  const genre = params.genre ? (params.genre as string) : ''
  const category = params.category ? (params.category as string) : ''

  const today = new Date()
  today.setHours(0, 0, 0, 0)
  let toDate = params.toDate ? new Date(params.toDate as string) : today
  const fromDate = params.fromDate ? new Date(params.fromDate as string) : new Date(new Date(today).setUTCFullYear(today.getUTCFullYear() - 1))

  return { source, sortBy, top, dateRange: { fromDate, toDate }, scaleYAxis, tags, subgenre, genre, category }
}
