import { FC, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

import { GRTooltip } from '../../../../../../components/GRTooltip/GRTooltip'
import { LockedDataIndicator } from '../../../../../../components/LockedDataIndicator/LockedDataIndicator'
import { MotivationalImportance } from '../../../../../../components/MotivationalImportance/MotivationalImportance'
import NotAvailableIcon from '../../../../../../components/NotAvailableIcon/NotAvailableIcon'
import { RankIndicatorProps, RankIndicator } from '../../../../../../components/RankIndicator/RankIndicator'
import { TrendIndicator } from '../../../../../../components/TrendIndicator/TrendIndicator'
import { ValueIndicatorProps, ValueIndicator } from '../../../../../../components/ValueIndicator/ValueIndicator'
import { revenueIsAvailableForMarket } from '../../../../../../services/RevenueService'
import { useRoleCheckSelector } from '../../../../../account/hooks/roleHooks'
import { RoleEnum } from '../../../../../account/types/RoleEnum'
import { Game } from '../../../../../game'
import { useIsGameOpenForEveryoneSelector } from '../../../../../settings/hooks/useIsGameOpenForEveryone'

const RankValue: FC<RankIndicatorProps & { game?: Game }> = ({ value, game }) => {
  const { t } = useTranslation()
  const isGamePublic = !!game ? game.isPublic() : true
  if (isGamePublic) {
    return <RankIndicator value={value} maxValue={1000} emptyValue="-" />
  } else {
    return <NotAvailableIcon tooltipContent={t('common:not_available_for_game_concepts')} />
  }
}

const EstimateValue: FC<ValueIndicatorProps & { game?: Game; marketIso: string }> = ({
  value,
  type,
  maximumFractionDigits,
  shorten,
  displayZero = true,
  emptyValue,
  minValue = 100,
  game,
  marketIso,
}) => {
  const { t } = useTranslation()
  const revenueEstimatesUnlocked = useRoleCheckSelector(RoleEnum.revenue_estimates)
  const isGameOpenForEveryone = useIsGameOpenForEveryoneSelector(game)
  // skip user right checks if no game is given
  const displayEstimates = !game || revenueEstimatesUnlocked || isGameOpenForEveryone
  const isGamePublic = !!game ? game.isPublic() : true

  if (!displayEstimates && isGamePublic) {
    return <LockedDataIndicator />
  } else if (!isGamePublic) {
    return <NotAvailableIcon tooltipContent={t('common:not_available_for_game_concepts')} />
  } else if (!revenueIsAvailableForMarket(marketIso) && isGamePublic) {
    return <NotAvailableIcon />
  } else {
    return (
      <ValueIndicator
        value={value}
        type={type}
        maximumFractionDigits={maximumFractionDigits}
        shorten={shorten}
        minValue={minValue}
        displayZero={displayZero}
        emptyValue={emptyValue}
      />
    )
  }
}

const DemographicsValue: FC<{ value: number; game?: Game; children?: ReactNode }> = ({ value, game, children }) => {
  const demographicsUnlocked = useRoleCheckSelector(RoleEnum.demographics)
  const isGameOpenForEveryone = useIsGameOpenForEveryoneSelector(game)
  // skip user right checks if no game is given
  const displayDemographics = !game || isGameOpenForEveryone || demographicsUnlocked

  return displayDemographics ? (
    value ? (
      <>
        <TrendIndicator value={value} sign={false} color={false} emptyValue="-" />
        {children}
      </>
    ) : (
      <NotAvailableIcon />
    )
  ) : (
    <LockedDataIndicator />
  )
}

const PlayerArchetypeValue: FC<{ value: number; game?: Game }> = ({ value, game }) => {
  const { t } = useTranslation()
  const motivationsUnlocked = useRoleCheckSelector(RoleEnum.motivations)
  const isGameOpenForEveryone = useIsGameOpenForEveryoneSelector(game)
  // skip user right checks if no game is given
  const displayPlayerArchetypeValue = !game || isGameOpenForEveryone || motivationsUnlocked

  if (!displayPlayerArchetypeValue) {
    return <LockedDataIndicator />
  } else if (!value) {
    return <NotAvailableIcon tooltipContent={t('motivations:motivation_data_na')} />
  } else {
    return <TrendIndicator value={value} maximumFractionDigits={1} sign={false} color={false} emptyValue="-" />
  }
}

const MotivationValue: FC<{ value: number; game?: Game; tooltipTranslationKey?: string }> = ({
  value,
  game,
  tooltipTranslationKey = 'motivations:motivation-avg-importance-game',
}) => {
  const { t } = useTranslation()
  const motivationsUnlocked = useRoleCheckSelector(RoleEnum.motivations)
  const isGameOpenForEveryone = useIsGameOpenForEveryoneSelector(game)
  // skip user right checks if no game is given
  const displayMotivationValue = !game || isGameOpenForEveryone || motivationsUnlocked

  if (!displayMotivationValue) {
    return <LockedDataIndicator />
  } else if (!value) {
    return <NotAvailableIcon tooltipContent={t('motivations:motivation_data_na')} />
  } else {
    return (
      <GRTooltip content={t(tooltipTranslationKey, { value: value.toFixed(1) })}>
        <MotivationalImportance value={value} />
      </GRTooltip>
    )
  }
}

export const MarketExplorerGamesDataTableValue = {
  Rank: RankValue,
  Estimate: EstimateValue,
  Demographics: DemographicsValue,
  Archetype: PlayerArchetypeValue,
  Motivation: MotivationValue,
}

export const EmptyRevDLRatioEstimate = () => {
  const { t } = useTranslation()
  return <NotAvailableIcon tooltipContent={t('common:revenue_download_ratio_not_accurate')} />
}
