import classNames from 'classnames'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { Button, Grid, Typography } from '@mui/material'
import { Box } from '@mui/system'

import { analyzerGridColumn } from '../..'
import GameIconWithPowerScore from '../../../../components/GameIconWithPowerScore/GameIconWithPowerScore'
import { LinearProgressBar } from '../../../../components/LinearProgressBar/LinearProgressBar'
import useGetScrollPosition from '../../../../hooks/useGetScrollPosition'
import { Analysis } from '../../../analysis/types/Analysis'
import { Game } from '../../../game/types/Game'
import BenchmarkGameSelector from '../BenchmarkGameSelector/BenchmarkGameSelector'
import './FeatureSetRow.scss'

const startPoint = 450
/**
 * FeatureSetRow show game icon, benchmark select game and become sticky header when scroll
 */
interface FeatureSetRowProps {
  game?: Game
  benchmarkGame?: Game
  title?: string
  onBenchmarkGameClick: () => void
  onUpdatePowerScoreClick: () => void
  answerProgress?: number
  analysis?: Analysis
  benchMarkAnalysis?: Analysis
}
const FeatureSetRow: React.FC<FeatureSetRowProps> = ({
  game,
  benchmarkGame,
  onBenchmarkGameClick,
  onUpdatePowerScoreClick,
  title,
  answerProgress,
  analysis,
  benchMarkAnalysis,
}) => {
  const { t } = useTranslation()
  const { scrollPosition } = useGetScrollPosition()
  const stickyMode = scrollPosition > startPoint

  return (
    <Box id="FeatureSetRow" className={classNames('FeatureSetRow', { FeatureSetRow__stickyMode: stickyMode })} sx={{ pt: 2, pb: 1, mb: 2 }}>
      {stickyMode && (
        <Box sx={{ mb: 2, pl: 3 }}>
          <LinearProgressBar value={answerProgress} variant="determinate" labelAfter={`${answerProgress}%`} />
        </Box>
      )}
      <Grid container sx={{ mb: 0.5 }}>
        {stickyMode ? (
          <Grid item xs={analyzerGridColumn.xs.name} sx={{ pl: 3 }}>
            <Button
              variant="outlined"
              sx={{ mr: 1 }}
              onClick={() => {
                window.scrollTo({ top: 0, behavior: 'smooth' })
              }}
            >
              {t('common:back_to_top')}
            </Button>
            <Button variant="contained" color="success" disabled={answerProgress !== 100} onClick={() => onUpdatePowerScoreClick()}>
              {t('analysis:calculate_gps')}
            </Button>
          </Grid>
        ) : (
          <Grid item xs={analyzerGridColumn.xs.name} display="flex" alignItems="center">
            <Typography variant="h2">{title || t('common:feature_set')}</Typography>
          </Grid>
        )}

        <Grid sx={{ ml: 0 }} item xs={analyzerGridColumn.xs.options}></Grid>

        <Grid sx={{}} item container xs={analyzerGridColumn.xs.score} justifyContent="center">
          {game && <GameIconWithPowerScore game={game} powerscore={analysis?.powerScore} />}
        </Grid>

        <Grid
          className="GameAnalyzerDetailPage__benchmarkContainer"
          item
          xs={analyzerGridColumn.xs.compare}
          sx={{ ml: 0 }}
          alignItems="center"
          display="flex"
          flexDirection="column"
        >
          {!!benchmarkGame ? (
            <GameIconWithPowerScore
              onClick={onBenchmarkGameClick}
              game={benchmarkGame}
              powerscore={benchMarkAnalysis?.powerScore}
              marketIso={benchMarkAnalysis?.marketIso}
            />
          ) : (
            <BenchmarkGameSelector onClick={onBenchmarkGameClick} />
          )}
        </Grid>
      </Grid>
    </Box>
  )
}

export default FeatureSetRow
