import { useMemo } from 'react'

import { useGetConventionalGenreListQuery, useGetConventionalSubgenreListQuery, useGetGenreTaxonomyQuery } from '../../../api/core'
import { useCurrentUserLanguage } from '../../account/hooks/userHooks'
import { Game } from '../../game'
import { useGetGameList } from '../../game/hooks/gameHooks'

export const useGetCategoriesGenreMap = () => {
  const userLanguage = useCurrentUserLanguage()
  const { data: subGenreListDetails } = useGetConventionalSubgenreListQuery({ userLanguage })
  const { data: genreListDetails } = useGetConventionalGenreListQuery({ userLanguage })
  const { data: genreTaxonomy } = useGetGenreTaxonomyQuery({ userLanguage })

  const categoriesGenreMap = useMemo(() => {
    if (!genreTaxonomy) {
      return
    }

    return genreTaxonomy
      .map(({ genres, name: categoryName }) =>
        genres.map((genre) => {
          const genreInfo = subGenreListDetails?.find((subGenreInfo) => genre.id === subGenreInfo.parentGenreId)
          const mappedSubgenres = genre.subgenres.map((subGenreId) => {
            const subGenreInfo = subGenreListDetails?.find((subGenreListDetail) => subGenreListDetail.id === subGenreId.id)
            return subGenreInfo
          })
          const genreDescription = genreListDetails?.find((genreInfo) => genre.id === genreInfo.id)
          return { ...genre, categoryName, ...genreInfo, genreDescription, mappedSubgenres }
        })
      )
      .flat()
  }, [genreTaxonomy, subGenreListDetails, genreListDetails])

  return categoriesGenreMap
}

export const useGetGameInfoList = () => {
  const categoriesGenreMap = useGetCategoriesGenreMap()

  const gameIds = useMemo(() => {
    if (!categoriesGenreMap) return
    const filteredGameIds = categoriesGenreMap.map((genre) => genre.exampleAppIds).flat()
    const subGenreExampleAppIds = categoriesGenreMap.map((genre) => genre.mappedSubgenres.map((subGenre) => subGenre?.exampleAppIds).flat()).flat()
    return [...new Set([...filteredGameIds, ...subGenreExampleAppIds])]
  }, [categoriesGenreMap])

  const { data: gamesData } = useGetGameList(
    {
      gameIds: gameIds as number[],
      include: 'name,artist,icon,icons,appId',
    },
    { skip: !gameIds }
  )

  const gamesDataList = useMemo(() => {
    if (!gamesData) return
    return gamesData.reduce((result, game) => {
      result[game.appId] = game
      return result
    }, {} as { [appId: string]: Game })
  }, [gamesData])

  return { gamesData, gamesDataList }
}
