import { FC } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { Link, To, useLocation } from 'react-router-dom'

import { useNewsFeedLimitedAccessCheck } from '../../features/account/hooks/roleHooks'
import { NewsEntryScreenshot } from '../../features/news/types/UnifiedNewsEntry'
import MultiSlider, { MultiSliderProps } from '../MultiSlider/MultiSlider'

type GameUpdateScreenshotsSliderProps = Omit<MultiSliderProps, 'children'> & {
  screenshots: NewsEntryScreenshot[]
}

export const GameUpdateScreenshotsSlider: FC<GameUpdateScreenshotsSliderProps> = ({ screenshots, count = 3, padding = 20, showIndex }) => {
  const hasNewsFeedLimitedAccess = useNewsFeedLimitedAccessCheck()
  const location = useLocation()
  const isInfinite = screenshots.length > count

  return (
    <MultiSlider infinite={isInfinite} count={count} padding={padding} showIndex={showIndex}>
      {screenshots.map((screenshot, i) => {
        const linkTo: To = {
          ...location,
          pathname: `${location.pathname}/screenshots/${screenshots.map((ss) => ss.screenshotId).join(',')}/${screenshot.screenshotId}`,
        }
        return hasNewsFeedLimitedAccess ? (
          <LazyLoadImage
            key={i}
            alt={screenshot.features ? screenshot.features.map((feature) => feature.featureName).join(', ') : 'a screenshot'}
            src={screenshot.url}
            effect="blur"
          />
        ) : (
          <Link key={i} to={linkTo}>
            <LazyLoadImage
              alt={screenshot.features ? screenshot.features.map((feature) => feature.featureName).join(', ') : 'a screenshot'}
              src={screenshot.url}
              effect="blur"
            />
          </Link>
        )
      })}
    </MultiSlider>
  )
}
