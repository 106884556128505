import { FC, useMemo, useRef } from 'react'
import { ReactCalendarItemRendererProps } from 'react-calendar-timeline'
import { useTranslation } from 'react-i18next'

import { FiberNew } from '@mui/icons-material'
import { Box, Chip, Divider, Grid } from '@mui/material'

import { GRTooltip } from '../../../../../../components/GRTooltip/GRTooltip'
import { HtmlContentParser } from '../../../../../../components/HtmlContentParser/HtmlContentParser'
import { liveEventReviewModalParserOptions } from '../../../../../../components/LiveEventModalLink/LiveEventModalLink'
import { htmlDecode } from '../../../../../../helpers/dom'
import { shadeColor } from '../../../../../../helpers/shadeColor'
import { useCurrentUserLanguage } from '../../../../../account/hooks/userHooks'
import { LiveEventTagGroup } from '../../../../types/LiveEventTagGroup'
import { TrackingEventTimelineItem } from '../../../../types/LiveEvents'

const defaultTimelineItemBGColor = '#273143'
const highlightedTimelineItemBGColor = '#38a73e'
const inactiveEventColor = '#b13d37'
const maxDescriptionLength = 300

export const LiveEventsCalendarTrackingEventItem: FC<ReactCalendarItemRendererProps<TrackingEventTimelineItem>> = ({
  item,
  itemContext,
  getItemProps,
  getResizeProps,
}) => {
  const { t } = useTranslation()
  const userLanguage = useCurrentUserLanguage()
  const itemRef = useRef(null)
  const { left: leftResizeProps, right: rightResizeProps } = getResizeProps()
  const highlightItem = item.highlighted
  const newItem = item.new

  const descriptionRawHtml = useMemo(() => {
    const descriptionHTML = item.trackingEvent.comment?.content?.comment?.[userLanguage]
      ? item.trackingEvent.comment?.content?.comment?.[userLanguage]
      : item.trackingEvent.comment?.content?.comment?.['en']

    if (descriptionHTML) {
      const paragraphs = descriptionHTML.split('</p>')

      // find the first paragraph with content
      const paragraph = paragraphs.find((paragraph) => {
        return !!paragraph.replace(/<\/?[^>]+(>|$)/g, '')
      })

      const strippedParagraph = paragraph?.replace(/<\/?[^>]+(>|$)/g, '') || ''
      const decodedParagraph = htmlDecode(strippedParagraph)

      if (decodedParagraph && decodedParagraph.length > maxDescriptionLength) {
        return decodedParagraph.substring(0, maxDescriptionLength) + '...'
      } else {
        return decodedParagraph
      }
    }
  }, [item, userLanguage])

  let bgColor = useMemo(() => {
    if (highlightItem && item.tagGroup && item.tagGroup.colorHex) {
      return highlightedTimelineItemBGColor
    } else if (newItem && item.tagGroup && item.tagGroup.colorHex) {
      return shadeColor(item.tagGroup.colorHex, -50)
    } else if (item.tagGroup && item.tagGroup.colorHex) {
      return item.tagGroup.colorHex
    } else {
      return defaultTimelineItemBGColor
    }
  }, [newItem, highlightItem, item.tagGroup])

  return (
    <GRTooltip
      content={
        <Box>
          <Grid container spacing={2} style={{ color: newItem ? bgColor : defaultTimelineItemBGColor }} maxWidth={440}>
            <Grid item xs>
              {newItem && <FiberNew sx={{ position: 'relative', fontSize: '20px', verticalAlign: 'middle', top: '-1px', mr: 0.5 }} />}
              <strong>{itemContext.title}</strong>
              {item.tagGroup && (
                <Box mt={1}>
                  {item.tagGroup && (
                    <Chip
                      label={item.tagGroup.name}
                      style={{ marginBottom: '6px', marginRight: '4px', color: 'white', backgroundColor: item.tagGroup ? item.tagGroup.colorHex : bgColor }}
                      size="small"
                    ></Chip>
                  )}
                </Box>
              )}
              {descriptionRawHtml && (
                <Box mt={1.5} mb={1.5}>
                  <HtmlContentParser rawHtml={descriptionRawHtml} parserOptions={liveEventReviewModalParserOptions(item.trackingEvent.gameId || '')} />
                </Box>
              )}
            </Grid>

            {item.trackingEvent.screenshotUrl && (
              <Grid item alignSelf={'center'}>
                <img
                  className="LiveEventsCalendar__ItemScreenshot"
                  src={item.trackingEvent.screenshotUrl}
                  alt="Screenshot"
                  style={{ maxWidth: '200px', maxHeight: '200px' }}
                />
              </Grid>
            )}
          </Grid>
          {newItem && (
            <Box textAlign={'center'}>
              <Divider style={{ margin: '10px 0px 10px' }} />
              <strong>
                <span style={{ fontSize: '13px' }}>{t('live-events:event_type_first_appearance')}</span>
              </strong>
            </Box>
          )}
        </Box>
      }
      popoverBorderColor={bgColor}
      anchorRef={itemRef}
      disableMaxWidth={true}
    >
      <div
        {...getItemProps({
          style: {
            background: bgColor,
            color: 'white',
            border: '1px solid white',
            borderTop: item.trackingEvent.active === false ? `3px solid ${inactiveEventColor}` : '1px solid white',
            borderRadius: 10,
            fontSize: item.tagGroup && item.tagGroup.id === LiveEventTagGroup.DEFINING_OCCURRENCES ? '15px' : '12px',
            fontWeight: item.tagGroup && item.tagGroup.id === LiveEventTagGroup.DEFINING_OCCURRENCES ? 'bold' : 'normal',
          },
        })}
        className={item.tagGroup && item.tagGroup.id === LiveEventTagGroup.DEFINING_OCCURRENCES ? 'rct-item rct-item--SpecialEvent' : 'rct-item'}
        ref={itemRef}
      >
        {itemContext.useResizeHandle ? <div {...leftResizeProps} /> : null}

        <div
          className="rct-item-content"
          style={{
            height: itemContext.dimensions.height,
            overflow: 'hidden',
            padding: '0px 6px',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            maxWidth: '100%',
            verticalAlign: 'top',
          }}
        >
          {newItem && <FiberNew sx={{ position: 'relative', fontSize: '16px', verticalAlign: 'middle', top: '-2px', mr: 1 }} />}
          {itemContext.title}
        </div>

        {itemContext.useResizeHandle ? <div {...rightResizeProps} /> : null}
      </div>
    </GRTooltip>
  )
}
