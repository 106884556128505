import { differenceInDays, format } from 'date-fns'
import { FC, ReactNode, useEffect, useState } from 'react'
import { Trans } from 'react-i18next'

import { TypographyProps, Typography, Zoom } from '@mui/material'

import { useDateTimeFormatter } from '../../hooks/useDateTimeFormatter'

type LiveEventInstanceTableTitleProps = TypographyProps & {
  interval?: Interval
}

export const LiveEventInstanceTableTitle: React.FC<LiveEventInstanceTableTitleProps> = ({ interval, ...typographyProps }) => {
  const dayFormatter = useDateTimeFormatter({
    weekday: 'short',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  })

  const monthFormatter = useDateTimeFormatter({
    month: 'long',
    year: 'numeric',
  })

  if (!interval) {
    return null
  }

  const difference = differenceInDays(interval?.end || 0, interval?.start || 0)
  const date =
    difference > 7
      ? monthFormatter.format(interval.start)
      : difference <= 7 && difference > 1
      ? format(interval.start, 'I / yyyy')
      : difference <= 1
      ? dayFormatter.format(interval.start)
      : null

  return date ? (
    <ChangeAnimation trigger={date} timeout={150}>
      <Typography variant="body1" {...typographyProps} mb={0}>
        <Trans i18nKey="live-events:event_instance_table_title" values={{ date }} />
      </Typography>
    </ChangeAnimation>
  ) : null
}

const ChangeAnimation: FC<{ children?: ReactNode; trigger: any; timeout?: number }> = ({ trigger, timeout = 300, children }) => {
  const [animating, setAnimating] = useState(false)
  const [node, setNode] = useState(children)

  useEffect(() => {
    setAnimating(true)
    const to = setTimeout(() => {
      setAnimating(false)
      setNode(children)
    }, timeout)
    return () => {
      clearTimeout(to)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger])

  return (
    <Zoom in={!animating} timeout={timeout}>
      <div style={{ display: 'inline-block' }}>{node}</div>
    </Zoom>
  )
}
