import { isBefore, sub } from 'date-fns'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { BookType } from 'xlsx'

import { Card, CardContent, Divider } from '@mui/material'

import { DateRangeValue } from '../../../../components/DateRangePicker/DateRangePicker'
import { useExportDataGameRevenueAndDownloads } from '../../../../hooks/exportDataHooks'
import analyticsService from '../../../../services/AnalyticsService'
import { useCurrentUserLanguage } from '../../../account/hooks/userHooks'
import { useGameEstimateData, useMarketIsosFromEstimates, useEstimatesList } from '../../../estimates'
import { EstimatePlatformType } from '../../../estimates/types/EstimatePlatformType'
import { generateExport } from '../../../export-data/util/workbook'
import { Game } from '../../../game'
import { selectAllMarketIsos } from '../../../markets/selectors/selectors'
import { isDataTypeRevenueAndDownloadsRatio, isPlotTypeCumulative } from '../../helpers/helpers'
import { useGameEstimateTotalsByMarket } from '../../hooks/revenueAndDownloadsHooks'
import { DataTypeValue, DeviceTypeValue, GameRevenueAndDownloadsFiltersValue, GranularityValue } from '../../types/Filters'
import { PlotType } from '../../types/PlotType'
import { GameRevenueAndDownloadsChart } from '../GameRevenueAndDownloadsChart/GameRevenueAndDownloadsChart'
import { GameRevenueAndDownloadsFilters } from '../GameRevenueAndDownloadsFilters/GameRevenueAndDownloadsFilters'
import { GameRevenueAndDownloadsTable } from '../GameRevenueAndDownloadsTable/GameRevenueAndDownloadsTable'

type GameRevenueAndDownloadsProps = {
  game: Game
}

const rollingDaysByDataType = {
  [DataTypeValue.Revenue]: 1,
  [DataTypeValue.Downloads]: 1,
  [DataTypeValue.RevenueDownloadsRatio7Days]: 7,
  [DataTypeValue.RevenueDownloadsRatio30Days]: 30,
  [DataTypeValue.RevenueDownloadsRatio90Days]: 90,
  [DataTypeValue.RevenueDownloadsRatioAllTime]: 0,
}

export const GameRevenueAndDownloads: FC<GameRevenueAndDownloadsProps> = ({ game }) => {
  useEffect(() => {
    analyticsService.trackEvent('Visited Game Overview: Revenue/Download Estimates')
  }, [])

  const [activeMarketsResolved, setActiveMarketsResolved] = useState<boolean>(false)
  const [dateRange, setDateRange] = useState<DateRangeValue>()
  const [deviceType, setDeviceType] = useState<DeviceTypeValue>(DeviceTypeValue.AllIOSDevices)
  const [granularity, setGranularity] = useState<GranularityValue>(GranularityValue.Day)
  const [dataType, setDataType] = useState<DataTypeValue>(DataTypeValue.Revenue)
  const [selectedMarketIsos, setSelectedMarketIsos] = useState<string[]>([])
  const [dateRangeDisabled, setDateRangeDisabled] = useState<boolean>(false)
  const [plotType, setPlotType] = useState<PlotType>(PlotType.Stacked)

  const filters: GameRevenueAndDownloadsFiltersValue = useMemo(() => {
    return {
      dateRange,
      deviceType,
      granularity,
      dataType,
      selectedMarketIsos,
    }
  }, [dataType, dateRange, deviceType, granularity, selectedMarketIsos])

  const userLanguage = useCurrentUserLanguage()
  const allMarketIsos = useSelector(selectAllMarketIsos(userLanguage))

  const appIds = useMemo(() => {
    return [game.appId]
  }, [game.appId])

  // query by app ids, all markets and all platforms
  const { estimatesList, isLoading: isEstimatesLoading } = useGameEstimateData({ appIds, markets: allMarketIsos })

  // resolve active markets from estimate list markets
  const activeMarkets = useMarketIsosFromEstimates(estimatesList)

  // initially set all active markets as selected
  useEffect(() => {
    setSelectedMarketIsos(activeMarkets)
    setActiveMarketsResolved(!isEstimatesLoading)
  }, [activeMarkets, isEstimatesLoading])

  const rollingDays = useMemo(() => {
    return rollingDaysByDataType[dataType]
  }, [dataType])

  const isCumulativePlotType = useMemo(() => {
    return isPlotTypeCumulative(plotType)
  }, [plotType])

  const platformTypes = useMemo(() => {
    return resolvePlatformTypes(deviceType)
  }, [deviceType])

  // construct filter object for estimate data
  const estimateFilters = useMemo(() => {
    return {
      rollingDays: rollingDays,
      platformTypes,
      marketIsos: selectedMarketIsos,
      granularity: granularity,
      isCumulative: isCumulativePlotType,
      dateFrom: dateRange?.fromDate?.getTime(),
      dateTo: dateRange?.toDate?.getTime(),
    }
  }, [rollingDays, platformTypes, selectedMarketIsos, granularity, isCumulativePlotType, dateRange?.fromDate, dateRange?.toDate])

  // filter by platform types, selected markets and rolling days (depends on data type)
  const estimates = useEstimatesList({ data: estimatesList, filters: estimateFilters })
  const tableRows = useGameEstimateTotalsByMarket(estimates)

  const phoneEstimates = useEstimatesList({ data: estimatesList, filters: { ...estimateFilters, platformTypes: [EstimatePlatformType.Phone] } })
  const tabletEstimates = useEstimatesList({ data: estimatesList, filters: { ...estimateFilters, platformTypes: [EstimatePlatformType.Tablet] } })

  const maxDate = useMemo(() => {
    return new Date(new Date(estimates.tsMax || Date.now()).setHours(0, 0, 0, 0))
  }, [estimates.tsMax])

  const minDate = useMemo(() => {
    return new Date(new Date(estimates.tsMin || Date.now()).setHours(0, 0, 0, 0))
  }, [estimates.tsMin])

  // resolve initial date range for data
  useEffect(() => {
    const targetInitialFromDate = sub((maxDate || new Date()).setHours(0, 0, 0, 0), { days: 90 })
    const initialFromDate = isBefore(minDate, targetInitialFromDate) ? targetInitialFromDate : minDate
    const initialToDate = maxDate || sub(new Date().setHours(0, 0, 0, 0), { days: 2 })
    setDateRange({ fromDate: initialFromDate, toDate: initialToDate })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maxDate])

  useEffect(() => {
    if (isDataTypeRevenueAndDownloadsRatio(dataType)) {
      setDateRange({ fromDate: minDate, toDate: maxDate })
      setDateRangeDisabled(true)
      setPlotType(PlotType.Line)
    } else {
      setDateRangeDisabled(false)
    }
  }, [dataType, minDate, maxDate])

  const handleDateRangeChange = useCallback((value: DateRangeValue) => {
    setDateRange(value)
  }, [])

  const handleDeviceTypeChange = useCallback((value: DeviceTypeValue) => {
    setDeviceType(value)
  }, [])

  const handleGranularityChange = useCallback((value: GranularityValue) => {
    setGranularity(value)
  }, [])

  const handleDataTypeChange = useCallback((value: DataTypeValue) => {
    setDataType(value)
  }, [])

  const handleSelectedMarketIsosChange = useCallback((value: string[]) => {
    setSelectedMarketIsos(value)
  }, [])

  const handlePlotTypeChange = useCallback((value: PlotType) => {
    setPlotType(value)
  }, [])

  const [isExporting, setIsExporting] = useState(false)
  const [exportFormat, setExportFormat] = useState('csv' as BookType)
  const { exportRows, headerRows, filename } = useExportDataGameRevenueAndDownloads(
    estimates,
    phoneEstimates,
    tabletEstimates,
    granularity,
    minDate,
    maxDate,
    game,
    isExporting
  )

  useEffect(() => {
    if (!isExporting || !exportRows.length) return
    setIsExporting(false)
    generateExport(exportFormat, exportRows, 'Game Revenue and Downloads', filename, headerRows)
  }, [exportFormat, exportRows, filename, game.appId, headerRows, isExporting])

  const handleDataExport = (format: BookType) => {
    setExportFormat(format)
    setIsExporting(true)
  }

  return (
    <Card>
      <CardContent>
        <GameRevenueAndDownloadsFilters
          marketIsos={activeMarkets}
          isLoading={isEstimatesLoading || !activeMarketsResolved}
          onDateRangeChanged={handleDateRangeChange}
          onDeviceTypeChanged={handleDeviceTypeChange}
          onGranularityChanged={handleGranularityChange}
          onDataTypeChanged={handleDataTypeChange}
          onSelectedMarketsChanged={handleSelectedMarketIsosChange}
          dateRangeDisabled={dateRangeDisabled}
          values={filters}
          minDate={minDate}
          maxDate={maxDate}
        />
      </CardContent>
      <Divider sx={{ mt: 1, mb: 2 }} />
      <CardContent sx={{ height: '500px' }}>
        <GameRevenueAndDownloadsChart
          estimates={estimates}
          dataType={dataType}
          isLoading={isEstimatesLoading}
          onPlotTypeChange={handlePlotTypeChange}
          onExportData={handleDataExport}
          plotType={plotType}
          granularity={granularity}
        />
      </CardContent>

      <Divider sx={{ mt: 6 }} />
      <GameRevenueAndDownloadsTable rows={tableRows} isLoading={isEstimatesLoading} />
    </Card>
  )
}

const resolvePlatformTypes = (value: DeviceTypeValue) => {
  switch (value) {
    case DeviceTypeValue.AllIOSDevices:
      return [EstimatePlatformType.Phone, EstimatePlatformType.Tablet]
    case DeviceTypeValue.IPhone:
      return [EstimatePlatformType.Phone]
    case DeviceTypeValue.IPad:
      return [EstimatePlatformType.Tablet]
  }
}
