import { t } from 'i18next'
import { FC } from 'react'
import { Trans } from 'react-i18next'

import Lock from '@mui/icons-material/Lock'
import { Box, Grid, Typography } from '@mui/material'

import { TrendIndicator, TrendIndicatorType } from '../../../../../../components/TrendIndicator/TrendIndicator'
import { ValueIndicator, ValueType } from '../../../../../../components/ValueIndicator/ValueIndicator'
import { useRevenueAndDownloadsDataAccessCheck } from '../../../../hooks/unifiedNewsEntryHooks'
import { NewsEntryType } from '../../../../types/UnifiedNewsEntry'
import { RevenueDownloadsChart, RankChart } from '../../Charts'
import { NewsCardSectionDivider } from '../../NewsCardSectionDivider/NewsCardSectionDivider'
import { NewsCardContentSectionProps } from '../NewsCardContentSections'
import './NewsCardContentRevenueAndDownloadsSection.scss'

type NewsCardContentRevenueAndDownloadsSectionProps = NewsCardContentSectionProps & {
  entryType: NewsEntryType
}

export const NewsCardContentRevenueAndDownloadsSection: FC<NewsCardContentRevenueAndDownloadsSectionProps> = ({ newsEntry, entryType }) => {
  const hideRevenueAndDownloadsData = useRevenueAndDownloadsDataAccessCheck(entryType)

  return (
    <Box className="NewsCardContentRevenueAndDownloadsSection" mb={2}>
      <NewsCardSectionDivider></NewsCardSectionDivider>

      {newsEntry.revenueDownloadsData ? (
        <>
          <Typography variant="body2" textAlign="center" mb={2} className="NewsCardContentRevenueAndDownloadsSection__rev-dl-description">
            <Trans i18nKey="newsfeed:update_impact_revenue_download_entry_description" />
          </Typography>
          <Grid container className="NewsCardContentRevenueAndDownloadsSection__header" alignItems="flex-start" justifyContent="space-evenly" mb={2}>
            <Grid item>
              <Typography variant="h6">{t('common:revenue_text')}</Typography>
              {hideRevenueAndDownloadsData ? (
                <Lock />
              ) : (
                <>
                  <TrendIndicator
                    value={newsEntry.revenueDownloadsData.percentageIncreaseRevenue / 100}
                    type={TrendIndicatorType.Percentage}
                    maximumFractionDigits={2}
                    sign
                    direction
                  />
                  <ValueIndicator
                    value={newsEntry.revenueDownloadsData.sum14AfterRevenue - newsEntry.revenueDownloadsData.sum14BeforeRevenue}
                    type={ValueType.Currency}
                    displayZero
                  />
                </>
              )}
            </Grid>
            <Grid item>
              <Typography variant="h6">{t('common:downloads_text')}</Typography>
              {hideRevenueAndDownloadsData ? (
                <Lock />
              ) : (
                <>
                  <TrendIndicator
                    value={newsEntry.revenueDownloadsData.percentageIncreaseDownload / 100}
                    type={TrendIndicatorType.Percentage}
                    maximumFractionDigits={2}
                    sign
                    direction
                  />
                  <ValueIndicator
                    value={newsEntry.revenueDownloadsData.sum14AfterDownloads - newsEntry.revenueDownloadsData.sum14BeforeDownloads}
                    type={ValueType.Number}
                    displayZero
                  />
                </>
              )}
            </Grid>
            <Grid item>
              <Typography variant="h6">{t('common:revenue_downloads_ratio_shorthand')}</Typography>
              {hideRevenueAndDownloadsData ? (
                <Lock />
              ) : (
                <>
                  <TrendIndicator
                    value={
                      (newsEntry.revenueDownloadsData.sum14AfterRevenue / newsEntry.revenueDownloadsData.sum14AfterDownloads -
                        newsEntry.revenueDownloadsData.sum14BeforeRevenue / newsEntry.revenueDownloadsData.sum14BeforeDownloads) /
                      (newsEntry.revenueDownloadsData.sum14BeforeRevenue / newsEntry.revenueDownloadsData.sum14BeforeDownloads)
                    }
                    type={TrendIndicatorType.Percentage}
                    maximumFractionDigits={2}
                    sign
                    direction
                  />
                  <ValueIndicator
                    value={
                      newsEntry.revenueDownloadsData.sum14AfterRevenue / newsEntry.revenueDownloadsData.sum14AfterDownloads -
                      newsEntry.revenueDownloadsData.sum14BeforeRevenue / newsEntry.revenueDownloadsData.sum14BeforeDownloads
                    }
                    type={ValueType.Currency}
                    maximumFractionDigits={3}
                    displayZero
                  />
                </>
              )}
            </Grid>
          </Grid>
          <div className="NewsCardContentRevenueAndDownloadsSection__chart">
            <RevenueDownloadsChart newsEntry={newsEntry} locked={hideRevenueAndDownloadsData} />
          </div>
        </>
      ) : (
        <div className="NewsCardContentRevenueAndDownloadsSection__chart">
          <RankChart newsEntry={newsEntry} />
        </div>
      )}
    </Box>
  )
}
