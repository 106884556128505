import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { ArrowBack, Close } from '@mui/icons-material'
import { Box, Button, Card, CircularProgress, Dialog, DialogContent, DialogTitle, Divider, Grid, IconButton, Typography } from '@mui/material'

import { useGetGameVisualAnalysisQuery, useGetGenreAnalysisQuery } from '../../../api/connect'
import analyticsService from '../../../services/AnalyticsService'
import { GameIcon } from '../../game'
import { useGames } from '../../game/hooks/gameHooks'
import { useCurrentMarket } from '../../markets'
import { VisualAnalysisWithRank } from '../types'
import { AppStoreGenreMap, combineStatistics } from '../util/visualStats'
import GameVisualsTable from './GameVisualsTable'

interface GameVisualsModalProps {
  isNested?: boolean
  item: VisualAnalysisWithRank
  onClose: () => void
}

const GameVisualsModal: React.FC<GameVisualsModalProps> = ({ isNested, item, onClose }) => {
  const { t } = useTranslation()
  const { currentMarketIso: marketIso } = useCurrentMarket()
  const {
    games: { 0: game },
  } = useGames([item.gameId], marketIso)
  const { data: visualsData } = useGetGameVisualAnalysisQuery(item.gameId)
  const { data: genreData } = useGetGenreAnalysisQuery({ genreIds: game?.appGenres || [], marketIso }, { skip: !game || !visualsData })

  useEffect(() => {
    if (!!game) {
      analyticsService.trackEvent('Opened Game Visuals Modal', {
        data: {
          gameName: game.resolvedName,
        },
      })
    }
  }, [game])

  const statsTables = useMemo(() => {
    const { genreId, rankType, top } = item
    return combineStatistics(genreId, top, rankType, visualsData, genreData)
  }, [item, genreData, visualsData])

  return (
    <Dialog open={true} onClose={onClose} maxWidth="lg" fullWidth className="GameVisualsModal" disableEscapeKeyDown={true}>
      <DialogTitle>
        <Grid container justifyContent="space-between" alignItems="flex-end">
          <Grid item>
            {isNested && (
              <IconButton aria-label="Back" onClick={onClose} sx={{ my: -1, mr: 1 }}>
                <ArrowBack />
              </IconButton>
            )}
            {game && game.resolvedName}
          </Grid>
          <Grid item textAlign="right">
            <IconButton aria-label="close" onClick={onClose} sx={{ m: -1 }}>
              <Close />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider />
      {!game ? (
        <Box mb={2} mt={4} textAlign="center">
          <CircularProgress />
        </Box>
      ) : (
        <DialogContent>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item xs={6}>
              <Link to={`/game/${game.id}/visuals`}>
                <GameIcon gameName={game.resolvedName} src={game.icons[0]} size="normal" />
                <Box ml={2} display="inline-block">
                  <Typography variant="h3">{game.resolvedName}</Typography>
                  <Typography variant="subtitle2">{game.artist}</Typography>
                </Box>
              </Link>
            </Grid>
            <Grid item xs={6} textAlign="right">
              <Button variant="contained" disabled color="secondary">
                {AppStoreGenreMap[item.genreId] + ' / '}
                {item.rankType === 'free' ? t('common:sustained_download_rank') + ': ' + item.sdrank : t('common:sustained_grossing_rank') + ': ' + item.srank}
              </Button>
            </Grid>
          </Grid>
          <Card sx={{ mb: 1, mt: 2 }}>
            {statsTables?.map((data, index) => (
              <GameVisualsTable
                data={data}
                key={`stats-${item.gameId}-${index}`}
                rankType={item.rankType}
                genreId={item.genreId}
                topCount={item.top}
                isNested={true}
              />
            ))}
          </Card>
        </DialogContent>
      )}
    </Dialog>
  )
}

export default GameVisualsModal
