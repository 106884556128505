import React, { FocusEvent, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { CircularProgress, Popper } from '@mui/material'

import { SearchInputWithDebounce } from '../../../../components/SearchInputWithDebounce/SearchInputWithDebounce'
import { PromiseWithAbort } from '../../../../types/PromiseWithAbort'
import { GameSearchDialogTab, GameSearchDialogType } from '../../../game-search/types/GameSearchDialogConfig'
import { SearchGamesQueryParams } from '../../../game-search/types/SearchGamesQueryParams'
import { useGameSearch } from '../../../game/hooks/gameHooks'
import { Game } from '../../../game/types/Game'
import { useCurrentMarket } from '../../../markets'
import GameSearchDialog from '../GameSearchDialog/GameSearchDialog'
import GameSearchRecentResult from '../GameSearchRecentResult/GameSearchRecentResult'
import GameSearchResult from '../GameSearchResult/GameSearchResult'
import './GameSearchInput.scss'

const GameSearchInput: React.FC = () => {
  const MAX_DISPLAYED_GAMES = 50
  const SEARCH_TEXT_MIN_LENGTH = 2
  const { t } = useTranslation()
  const { currentMarketIso: mainMarketIso } = useCurrentMarket()
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | undefined>(undefined)
  const [popoverOpen, setPopoverOpen] = useState(false)
  const [searchText, setSearchText] = useState('')
  const {
    searchGames,
    result: { data: searchedGames, isLoading: isLoadingSearchedGames },
  } = useGameSearch()
  const [searchGamesPromise, setSearchGamesPromise] = useState<PromiseWithAbort>(undefined)
  const [advancedSearchModalOpen, setAdvancedSearchModalOpen] = useState(false)
  const gameSearchDialogConfig = useMemo(() => {
    return {
      type: GameSearchDialogType.GameSearch,
      tabs: [GameSearchDialogTab.Search],
    }
  }, [])

  const onSearchTextChange = (newSearchText: string) => {
    setSearchText(newSearchText)
    if (searchGamesPromise) {
      searchGamesPromise.abort()
    }

    if (newSearchText.length >= SEARCH_TEXT_MIN_LENGTH) {
      const searchGamesParams: Omit<SearchGamesQueryParams, 'userLanguage'> = {
        term: newSearchText,
        marketIso: mainMarketIso,
        include: 'name,artist,icon,icons,gpsPerMarket,visibility,genreId,internal,owner,archive,stageId,sranks',
        owned: false,
      }

      const newSearchGamesPromise = searchGames(searchGamesParams)
      setSearchGamesPromise(newSearchGamesPromise)
    }
  }

  const openPopover = (event: FocusEvent<any>) => {
    setAnchorEl(event.target)
    setPopoverOpen(true)
  }

  const closePopoverWithDelay = () => {
    setTimeout(() => {
      setPopoverOpen(false)
    }, 200)
  }

  const toggleGameSearchDialog = () => {
    setAdvancedSearchModalOpen(!advancedSearchModalOpen)
  }

  const games = useMemo(() => {
    if (!searchedGames || !searchedGames.length) return []

    return [...searchedGames]
      .filter((game) => game.archive || game.artist.includes(searchText))
      .sort((a, b) => a.sranks[mainMarketIso] - b.sranks[mainMarketIso])
      .slice(0, MAX_DISPLAYED_GAMES)
  }, [mainMarketIso, searchText, searchedGames])

  return (
    <div className="GameSearchInput">
      <div className="GameSearchInput__input-wrapper">
        <SearchInputWithDebounce
          fieldText={t('common:search_games')}
          onDebounce={onSearchTextChange}
          onFocus={openPopover}
          onBlur={closePopoverWithDelay}
          onOpenGameSearchDialog={toggleGameSearchDialog}
          hasIconChange
        />
        <GameSearchDialog
          gameSearchDialogConfig={gameSearchDialogConfig}
          modalOpen={advancedSearchModalOpen}
          onClose={toggleGameSearchDialog}
          hideSetAsDefault
          enableMarketSelector
        />
      </div>
      <Popper className="GameSearchInput__popper" open={popoverOpen} anchorEl={anchorEl}>
        <div className="GameSearchInput__popper__content">
          {searchText.length === 0 && <GameSearchRecentResult />}
          {isLoadingSearchedGames && (
            <div className="text-center" style={{ margin: '20px auto' }}>
              <CircularProgress color="primary" />
            </div>
          )}
          {!isLoadingSearchedGames && searchText.length > 0 && (
            <div className="GameSearchInput__popper__result-message">
              {t('common:found_matching_the_search', {
                count: games.length,
              })}
            </div>
          )}
          {!isLoadingSearchedGames &&
            searchText.length > 0 &&
            games &&
            games.map((game: Game) => {
              return <GameSearchResult key={game.id} game={game} />
            })}
        </div>
      </Popper>
    </div>
  )
}

export default GameSearchInput
