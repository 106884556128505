import { LegacyFeatures, Screenshot, SimpleFeature } from '../../../api/core'
import { uniqueArray } from '../../../helpers/arrays'
import { FeatureGame } from '../../game/types/Game'
import { EnrichedFeature } from '../types/ImplementationExamples'

export const extractNeededLegacyIds = (games?: FeatureGame[]): number[] => {
  const ids =
    games
      ?.map((game) =>
        getCombinedScreenshots(game)
          .map(({ features }) => (features as SimpleFeature[]).map((f) => f.featureLegacyId))
          .flat()
      )
      .flat() || []
  return uniqueArray(ids)
}
/**
 * Returns screenshots from both featureScreenshots and conceptScreenshots without duplicates.
 * @param game
 * @returns
 */
export const getCombinedScreenshots = (game: FeatureGame): Screenshot[] => {
  // filter duplicates by id
  return [...(game.featureScreenshots ?? []), ...(game.conceptScreenshots ?? [])].filter(
    (screenshot, index, self) => self.findIndex((s) => s.id === screenshot.id) === index
  )
}

export const convertToSimpleFeature = (screenshot: Screenshot): Screenshot => {
  return {
    ...screenshot,
    features: Object.keys(screenshot.features as LegacyFeatures).map((featureLegacyId) => ({
      featureLegacyId: parseInt(featureLegacyId, 10),
    })) as SimpleFeature[],
  }
}

export const convertLegacyScreenshots = (screenshots: Screenshot[]) => screenshots.map(convertToSimpleFeature)

export const includeLegacyIdsFilter =
  (legacyIds: number[]) =>
  (feature: EnrichedFeature): boolean => {
    return legacyIds.includes(feature.legacyId)
  }

type FeaturesData = { features: EnrichedFeature[] }[]

export const pickFeaturesWithLegacyId = (legacyIds: number[], featuresData?: FeaturesData): EnrichedFeature[] =>
  Object.values(featuresData || {})
    .map(({ features }) => features)
    .flat()
    .filter(includeLegacyIdsFilter(legacyIds)) || []

/**
 * Returns the index in array.
 *
 * @param screenshots to search
 * @param screenshotId to match
 * @return undefined if not found
 */
export const screenshotIndexFromScreenshotId = (screenshots: Screenshot[], screenshotId: string): number | undefined => {
  const index = screenshots.reduce((val, { id, screenshotId: ssId }, index) => (screenshotId === ssId || screenshotId === id ? index : val), -1)
  return index >= 0 ? index : undefined
}
