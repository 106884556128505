import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Dialog, DialogContent, DialogProps, DialogTitle, Typography } from '@mui/material'

import { useGetScreenshotCommentQuery } from '../../api/core'
import { useCurrentUserLanguage } from '../../features/account/hooks/userHooks'
import { useAnalystComment } from '../../hooks/useAnalystComment'
import GRCircularProgress from '../GRCircularProgress/GRCircularProgress'
import { ReviewContent, ReviewHeader } from '../Review/Review'

/**
 * Component for loading and presenting analyst comment based on comment identifier
 */

type AnalystCommentDialogProps = DialogProps & {
  commentId?: string | null
  hasAccessRights?: boolean
  gameName?: string
  isScreenshotAnalysisComment?: boolean
}

export const AnalystCommentDialog: FC<AnalystCommentDialogProps> = ({
  commentId,
  hasAccessRights = false,
  gameName,
  isScreenshotAnalysisComment,
  ...dialogProps
}) => {
  const { t } = useTranslation()
  const language = useCurrentUserLanguage()
  const { analystComment, isLoading } = useAnalystComment(commentId, isScreenshotAnalysisComment || !commentId)
  const { data: screenshotComment } = useGetScreenshotCommentQuery(commentId as string, { skip: !isScreenshotAnalysisComment || !commentId })

  return (
    <Dialog sx={{ zIndex: 1301 }} maxWidth="md" fullWidth disableRestoreFocus keepMounted {...dialogProps}>
      {isLoading ? (
        <DialogContent>
          <GRCircularProgress />
        </DialogContent>
      ) : (
        <>
          <DialogTitle>
            <ReviewHeader
              analyst={isScreenshotAnalysisComment ? screenshotComment?.analyst : analystComment?.analyst}
              title={t('reviews:label_analyst_comment')}
              subtitle={gameName}
              direction="row-reverse"
              alignItems="center"
              titleProps={{ color: 'primary' }}
            />
          </DialogTitle>
          <DialogContent dividers>
            {hasAccessRights ? (
              <ReviewContent
                contentHtml={
                  isScreenshotAnalysisComment
                    ? screenshotComment?.screenshotComment?.content?.comment?.[language] || screenshotComment?.screenshotComment?.content?.comment?.['en']
                    : analystComment?.analysisComment?.content?.comment?.[language] || analystComment?.analysisComment?.content?.comment?.['en']
                }
              />
            ) : (
              <Typography variant="body1">{t('reviews:label_not_for_free')}</Typography>
            )}
          </DialogContent>
        </>
      )}
    </Dialog>
  )
}
