import React, { memo, useCallback, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, Card, Chip, Grid, TableContainer } from '@mui/material'

import { useGetUserProfileQuery } from '../../../../api/combined'
import { GRTable, GRTableColumn, SortOrder } from '../../../../components/GRTable/GRTable'
import { LockedFeature } from '../../../../components/LockedFeature/LockedFeature'
import NotAvailableIcon from '../../../../components/NotAvailableIcon/NotAvailableIcon'
import lockedFeatureService from '../../../../services/LockedFeatureService'
import { LockedFeatureId } from '../../../../types/LockedFeature'
import { Setting } from '../../../../types/Setting'
import { TableSortValues } from '../../../../types/TableSortValues'
import { useAllGamesUnlockedCheck } from '../../../account/hooks/roleHooks'
import { GameSearchDialogConfig, GameSearchDialogType } from '../../../game-search/types/GameSearchDialogConfig'
import { GameAnalysisOutdatedIndicator } from '../../../game/components/GameAnalysisOutdatedIndicator/GameAnalysisOutdatedIndicator'
import GameIcon from '../../../game/components/GameIcon/GameIcon'
import { GameLink } from '../../../game/components/GameLink/GameLink'
import { GamePowerscore } from '../../../game/components/GamePowerscore/GamePowerscore'
import { Game } from '../../../game/types/Game'
import { GameWithMarketIso } from '../GameSearchDialog/GameSearchDialog'
import GameSearchResultFollowGameButton from '../GameSearchFollowGameButton/GameSearchFollowGameButton'
import styles from './GameSearchResultsTable.module.scss'

export interface OpenGamesForEveryone {
  openGamesSetting?: Setting
  featuredGamesSetting?: Setting
}

type GameSearchResultsTableProps = {
  games: Game[]
  marketIso: string
  gameSearchDialogConfig: Pick<GameSearchDialogConfig, 'type' | 'gameSelectorCounts' | 'trackedGamesPermissionMap'>
  selectedGames: { [gameId: string]: Game }
  isLoading?: boolean
  hideRankData?: boolean
  onGameSelect: (game: Game) => void
  onSelectAllGames?: () => void
  onGameDeselect?: (game: Game) => void
  gamesWithMarketIso?: GameWithMarketIso
  enableMarketSelector?: boolean
  selectedGamesCount?: number
  gamesOpenForEveryone?: OpenGamesForEveryone
  hidePowerscore?: boolean
  displayAppType?: boolean
  displayNotAvailableIcon?: boolean
}

/**
 * Component for displaying Game Search Results Table
 */
export const GameSearchResultsTable: React.FC<GameSearchResultsTableProps> = memo(
  ({
    games,
    marketIso,
    isLoading,
    hideRankData,
    gameSearchDialogConfig,
    selectedGames,
    onGameSelect,
    onGameDeselect,
    onSelectAllGames,
    gamesWithMarketIso,
    enableMarketSelector,
    selectedGamesCount = 0,
    gamesOpenForEveryone,
    hidePowerscore,
    displayAppType,
    displayNotAvailableIcon = false,
  }) => {
    const MAX_RANK = 1000
    const { t } = useTranslation()
    const containerRef = useRef(null)
    const [lockedFeatureDialogOpen, setLockedFeatureDialogOpen] = useState<boolean>(false)
    const allGamesUnlocked = useAllGamesUnlockedCheck()
    const { data: currentUser } = useGetUserProfileQuery()

    const customProps = useMemo(() => {
      return {
        selectedGames,
        gameSearchDialogConfig,
        handleOnGameSelect: onGameSelect,
        handleSelectAllGames: onSelectAllGames,
        handleOnGameDeselect: onGameDeselect,
        gamesWithMarketIso,
        marketIso,
        selectedGamesCount,
      }
    }, [selectedGames, gameSearchDialogConfig, onGameSelect, onSelectAllGames, onGameDeselect, gamesWithMarketIso, marketIso, selectedGamesCount])

    const isGameSelected = useCallback(
      (game: Game, props?: typeof customProps) => {
        if (enableMarketSelector && props && props?.gamesWithMarketIso && props?.gamesWithMarketIso[game.id] && props?.gamesWithMarketIso[game.id].length) {
          return props.gamesWithMarketIso[game.id].includes(props.marketIso)
        }
        return props?.selectedGames[game.id]
      },
      [enableMarketSelector]
    )

    const isGameUnlocked = useCallback(
      (game: Game) => {
        if (allGamesUnlocked) return true
        return game.isUnlocked() || game.isOpenForEveryone(gamesOpenForEveryone?.openGamesSetting, gamesOpenForEveryone?.featuredGamesSetting)
      },
      [allGamesUnlocked, gamesOpenForEveryone?.openGamesSetting, gamesOpenForEveryone?.featuredGamesSetting]
    )

    const hasGamePermission = useCallback(
      (game: Game) => {
        if (gameSearchDialogConfig.trackedGamesPermissionMap) return gameSearchDialogConfig.trackedGamesPermissionMap[game.id]
        return false
      },
      [gameSearchDialogConfig.trackedGamesPermissionMap]
    )

    const cellClass = useCallback(
      (game: Game) =>
        gameSearchDialogConfig.trackedGamesPermissionMap && !gameSearchDialogConfig.trackedGamesPermissionMap[game.id]
          ? styles['no-permission-content-style']
          : '',
      [gameSearchDialogConfig.trackedGamesPermissionMap]
    )

    const shouldShowRowAlwaysLast = useCallback(
      (game: Game) => (gameSearchDialogConfig.trackedGamesPermissionMap && !gameSearchDialogConfig.trackedGamesPermissionMap[game.id] ? true : false),
      [gameSearchDialogConfig.trackedGamesPermissionMap]
    )

    const tableColumns: GRTableColumn<Game, typeof customProps>[] = useMemo(
      () => [
        {
          labelAccessor: t('common:game'),
          accessor: ({ row: game, customTableProps }) => {
            const selectedMarketIso = customTableProps?.marketIso || marketIso

            return (
              <Grid container spacing={1.2} className={cellClass(game)}>
                <Grid item>
                  <GameIcon
                    src={game.getIcon(false)}
                    gameName={game.resolvedName}
                    size="small"
                    appType={displayAppType ? game.appType : undefined}
                    gamePlatforms={game.platforms}
                  />
                </Grid>
                <Grid item xs>
                  {currentUser && game.isOutdatedForMarket(selectedMarketIso, currentUser) ? (
                    <div className={styles.gameContainer}>
                      <div>
                        <div className={styles.gameName}>{game.resolvedName}</div>
                        <div className={styles.gamePublisher}>{game.artist}</div>
                      </div>
                      <GameAnalysisOutdatedIndicator game={game} marketIso={selectedMarketIso} />
                    </div>
                  ) : (
                    <>
                      <div className={styles.gameName}>{game.resolvedName}</div>
                      <div className={styles.gamePublisher}>{game.artist}</div>
                    </>
                  )}
                </Grid>
              </Grid>
            )
          },
          sortable: true,
          sortAccessor: [
            ({ row }) => row.name || '',
            ({ row }) => {
              if (shouldShowRowAlwaysLast(row)) return TableSortValues.AlwaysLast

              return 0
            },
          ],
          cellProps: { align: 'left' },
          headerCellProps: { align: 'left', sx: { minWidth: 200 } },
          defaultSortOrder: SortOrder.DESC,
          sortOrder: hideRankData ? SortOrder.ASC : undefined,
        },
        {
          labelAccessor: t('common:conventional_subgenre'),
          accessor: ({ row: game }) => {
            return (
              <div className={cellClass(game)}>
                {game.conventionalSubgenre ? <Chip className={styles.subgenreChip} size="small" label={game.conventionalSubgenre}></Chip> : ''}
              </div>
            )
          },
          sortable: true,
          sortAccessor: [
            ({ row }) => row.conventionalSubgenre,
            ({ row }) => {
              if (shouldShowRowAlwaysLast(row)) return TableSortValues.AlwaysLast

              return 0
            },
          ],
          cellProps: { align: 'center' },
          defaultSortOrder: SortOrder.DESC,
        },
        {
          labelAccessor: t('common:game_power_score'),
          accessor: ({ row: game, customTableProps }) => (
            <div className={cellClass(game)}>
              <GamePowerscore value={game.gpsPerMarket[customTableProps?.marketIso || marketIso]} />
            </div>
          ),
          sortable: true,
          sortAccessor: [
            ({ row, customTableProps }) => {
              const selectedMarketIso = customTableProps?.marketIso || marketIso
              return row.gpsPerMarket[selectedMarketIso] || 0
            },
            ({ row }) => {
              if (shouldShowRowAlwaysLast(row)) return TableSortValues.AlwaysLast
              return 0
            },
          ],
          cellProps: { align: 'center' },
          hidden: hidePowerscore ? true : false,
        },
        {
          labelAccessor: t('common:sustained_grossing_rank_shorthand'),
          accessor: ({ row: game }) => {
            return (
              <div className={cellClass(game)}>
                {game.sranks[marketIso] || (!displayNotAvailableIcon && game.getSustainedGrossingRankForMarket(marketIso)) ? (
                  <strong>{game.sranks[marketIso] < MAX_RANK ? game.sranks[marketIso] : `${MAX_RANK}+`}</strong>
                ) : (
                  <NotAvailableIcon fontSize="18px" />
                )}
              </div>
            )
          },
          sortable: true,
          sortAccessor: [
            ({ row }) => {
              const srank = row.sranks[marketIso] || 0
              return srank < MAX_RANK ? srank || TableSortValues.AlwaysLast : MAX_RANK
            },
            ({ row }) => {
              if (shouldShowRowAlwaysLast(row)) return TableSortValues.AlwaysLast

              return 0
            },
          ],
          cellProps: { align: 'center' },
          sortOrder: !hideRankData ? SortOrder.ASC : undefined,
          defaultSortOrder: SortOrder.ASC,
          hidden: hideRankData,
        },
        {
          labelAccessor: t('common:sustained_download_rank_shorthand'),
          accessor: ({ row: game }) => {
            return (
              <div className={cellClass(game)}>
                {game.sdranks[marketIso] || (!displayNotAvailableIcon && game.getSustainedGrossingRankForMarket(marketIso)) ? (
                  <strong>{game.sdranks[marketIso] < MAX_RANK ? game.sdranks[marketIso] : `${MAX_RANK}+`}</strong>
                ) : (
                  <NotAvailableIcon fontSize="18px" />
                )}
              </div>
            )
          },
          sortable: true,
          sortAccessor: [
            ({ row }) => {
              const sdrank = row.sdranks[marketIso] || 0
              return sdrank < MAX_RANK ? sdrank || TableSortValues.AlwaysLast : MAX_RANK
            },
            ({ row }) => {
              if (shouldShowRowAlwaysLast(row)) return TableSortValues.AlwaysLast

              return 0
            },
          ],
          cellProps: { align: 'center' },
          defaultSortOrder: SortOrder.ASC,
          hidden: hideRankData,
        },
        {
          labelAccessor: ({ customTableProps }) => {
            return (
              <>
                {customTableProps?.handleSelectAllGames && (
                  <Button
                    size="small"
                    variant="contained"
                    color="success"
                    onClick={() => customTableProps?.handleSelectAllGames && customTableProps?.handleSelectAllGames()}
                  >
                    {t('common:select_all')}
                  </Button>
                )}
              </>
            )
          },
          accessor: ({ row: game, customTableProps }) => {
            return (
              <>
                {gameSearchDialogConfig.type === GameSearchDialogType.GameSearch && (
                  <div onClick={() => customTableProps?.handleOnGameSelect(game)}>
                    <GameLink game={game}>
                      <Button size="small" variant="contained" color="primary">
                        {t('common:open')}
                      </Button>
                    </GameLink>
                  </div>
                )}

                {gameSearchDialogConfig.type === GameSearchDialogType.GameBenchmark && (
                  <>
                    {isGameUnlocked(game) && !isGameSelected(game, customTableProps) && (
                      <div onClick={() => customTableProps?.handleOnGameSelect(game)}>
                        <Button size="small" variant="contained" color="primary">
                          {t('common:select')}
                        </Button>
                      </div>
                    )}

                    {isGameUnlocked(game) && isGameSelected(game, customTableProps) && (
                      <Button
                        size="small"
                        variant="contained"
                        color="warning"
                        onClick={() => {
                          customTableProps?.handleOnGameDeselect && customTableProps?.handleOnGameDeselect(game)
                        }}
                      >
                        {t('common:deselect')}
                      </Button>
                    )}

                    {!isGameUnlocked(game) && (
                      <Button size="small" variant="contained" onClick={() => setLockedFeatureDialogOpen(true)}>
                        {t('common:unlock')}
                      </Button>
                    )}
                  </>
                )}

                {gameSearchDialogConfig.type === GameSearchDialogType.GameSelector && (
                  <>
                    {isGameUnlocked(game) && !isGameSelected(game, customTableProps) && (
                      <Button
                        size="small"
                        variant="contained"
                        color="success"
                        disabled={
                          !customTableProps || customTableProps?.selectedGamesCount >= (customTableProps?.gameSearchDialogConfig.gameSelectorCounts?.max || 1)
                        }
                        onClick={() => customTableProps?.handleOnGameSelect(game)}
                      >
                        {t('common:select')}
                      </Button>
                    )}

                    {isGameUnlocked(game) && isGameSelected(game, customTableProps) && (
                      <Button
                        size="small"
                        variant="contained"
                        color="warning"
                        onClick={() => {
                          customTableProps?.handleOnGameSelect(game)
                        }}
                      >
                        {t('common:deselect')}
                      </Button>
                    )}

                    {!isGameUnlocked(game) && (
                      <Button size="small" variant="contained" onClick={() => setLockedFeatureDialogOpen(true)}>
                        {t('common:unlock')}
                      </Button>
                    )}
                  </>
                )}

                {gameSearchDialogConfig.type === GameSearchDialogType.GameFollow && (
                  <>
                    {!customTableProps?.selectedGames[game.id] && (
                      <GameSearchResultFollowGameButton
                        game={game}
                        onClick={() => {
                          customTableProps?.handleOnGameSelect(game)
                        }}
                      />
                    )}
                    {customTableProps?.selectedGames[game.id] && (
                      <Button size="small" variant="contained" color="warning" disabled>
                        {t('common:followed')}
                      </Button>
                    )}
                  </>
                )}

                {gameSearchDialogConfig.type === GameSearchDialogType.TrackedGames && (
                  <>
                    {hasGamePermission(game) && !isGameSelected(game, customTableProps) && (
                      <Button
                        size="small"
                        variant="contained"
                        color="success"
                        disabled={
                          !customTableProps || customTableProps?.selectedGamesCount >= (customTableProps?.gameSearchDialogConfig.gameSelectorCounts?.max || 1)
                        }
                        onClick={() => customTableProps?.handleOnGameSelect(game)}
                      >
                        {t('common:select')}
                      </Button>
                    )}

                    {hasGamePermission(game) && isGameSelected(game, customTableProps) && (
                      <Button
                        size="small"
                        variant="contained"
                        color="warning"
                        onClick={() => {
                          customTableProps?.handleOnGameSelect(game)
                        }}
                      >
                        {t('common:deselect')}
                      </Button>
                    )}

                    {!hasGamePermission(game) && (
                      <Button size="small" variant="contained" href={lockedFeatureService.contactUrl} target="_blank">
                        {t('common:unlock')}
                      </Button>
                    )}
                  </>
                )}
              </>
            )
          },
          sortable: false,
          cellProps: { align: 'center' },
          headerCellProps: { sx: { minWidth: '100px' } },
        },
      ],
      [
        t,
        hideRankData,
        hidePowerscore,
        marketIso,
        cellClass,
        displayAppType,
        currentUser,
        shouldShowRowAlwaysLast,
        displayNotAvailableIcon,
        gameSearchDialogConfig.type,
        isGameUnlocked,
        isGameSelected,
        hasGamePermission,
      ]
    )

    const [columns, setColumns] = useState<GRTableColumn<Game, typeof customProps>[]>(tableColumns)
    const handleColumnsUpdate = useCallback((updatedColumns: GRTableColumn<Game, typeof customProps>[]) => {
      setColumns(updatedColumns)
    }, [])

    return (
      <>
        <TableContainer component={Card} ref={containerRef} className={styles.container}>
          <GRTable
            rows={games}
            columns={columns}
            striped
            hoverable
            onColumnsUpdated={handleColumnsUpdate}
            rowIdKey="id"
            noRowsLabel={t('market-explorer:no_games_available')}
            isLoading={isLoading}
            scroller={containerRef}
            customProps={customProps}
          />
        </TableContainer>
        <LockedFeature.Dialog
          lockedFeatureId={LockedFeatureId.GameUnlock}
          open={lockedFeatureDialogOpen}
          hideImage={true}
          onClose={() => setLockedFeatureDialogOpen(false)}
        />
      </>
    )
  }
)
