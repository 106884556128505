import React, { FocusEvent, useCallback, useEffect, useMemo, useState } from 'react'

import { Close, OpenInNew, Search } from '@mui/icons-material'
import { IconButton, TextField, TextFieldProps, debounce } from '@mui/material'

import './SearchInputWithDebounce.scss'

type SearchInputWithDebounceProps = TextFieldProps & {
  fieldText: string
  onDebounce: (searchText: string) => void
  onFocus?: (event: FocusEvent<any>) => void
  onBlur?: (event: FocusEvent<any>) => void
  onOpenGameSearchDialog?: () => void
  hasIconChange?: boolean
}

export const SearchInputWithDebounce: React.FC<SearchInputWithDebounceProps> = (props) => {
  const [searchText, setSearchText] = useState('')
  const onDebounce = props.onDebounce

  const debounceSearch = useMemo(() => {
    return debounce((newSearchText: string) => {
      onDebounce(newSearchText)
    }, 200)
  }, [onDebounce])

  const handleGameSearchTermChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newSearchText = event.target.value
      setSearchText(newSearchText)
      debounceSearch(newSearchText)
    },
    [debounceSearch]
  )

  useEffect(() => {
    debounceSearch.clear()
  }, [debounceSearch])

  const onFocus = (event: FocusEvent<any>) => {
    if (props.onFocus) {
      props.onFocus(event)
    }
  }

  const onBlur = (event: FocusEvent<any>) => {
    if (props.onBlur) {
      props.onBlur(event)
    }
  }

  return (
    <>
      <TextField
        autoComplete="off"
        className="SearchInputWithDebounce"
        size="small"
        fullWidth
        label={
          <div>
            <Search style={{ fontSize: '18px', verticalAlign: 'middle', marginRight: '5px', marginTop: '-2px' }} />
            <span>{props.fieldText}</span>
          </div>
        }
        variant="outlined"
        color="primary"
        value={searchText}
        onChange={handleGameSearchTermChange}
        onFocus={onFocus}
        onBlur={onBlur}
      />
      {props.hasIconChange ? (
        searchText.length ? (
          <IconButton
            className="GameSearchInput__advanced-search"
            color="primary"
            onClick={() => {
              setSearchText('')
            }}
          >
            <Close />
          </IconButton>
        ) : (
          // can make this component more generic with children prop and pass the icon as children from parent
          <IconButton className="GameSearchInput__advanced-search" color="primary" onClick={props.onOpenGameSearchDialog}>
            <OpenInNew />
          </IconButton>
        )
      ) : null}
    </>
  )
}
