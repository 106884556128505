import { FC } from 'react'

import languageService from '../../../../../../../services/LanguageService'
import { useCurrentUserLanguage } from '../../../../../../account/hooks/userHooks'
import { UnifiedNewsEntry } from '../../../../../types/UnifiedNewsEntry'
import { LiveEventSubHeader } from '../LiveEventSubHeader'

type LiveEventAnalystNoteSubHeaderProps = {
  newsEntry: UnifiedNewsEntry
}

export const LiveEventAnalystNoteSubHeader: FC<LiveEventAnalystNoteSubHeaderProps> = ({ newsEntry }) => {
  const currentUserLanguage = useCurrentUserLanguage()

  const noteTitle =
    newsEntry.feedEntry.entryData.comment?.content?.title?.[currentUserLanguage] ||
    newsEntry.feedEntry.entryData.comment?.content?.title?.[languageService.defaultLanguage]
  const { start = 0, end = 0 } = newsEntry.feedEntry.entryData
  const interval = { start, end }

  return <LiveEventSubHeader linkLabel={noteTitle} interval={interval} />
}
