import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Box, CardContent, Link, TableContainer, Typography } from '@mui/material'

import { GRTable, GRTableColumn, SortOrder } from '../../../components/GRTable/GRTable'
import { GRTooltip } from '../../../components/GRTooltip/GRTooltip'
import { LinearProgressBar } from '../../../components/LinearProgressBar/LinearProgressBar'
import { TrendIndicator, TrendIndicatorType } from '../../../components/TrendIndicator/TrendIndicator'
import { GameIcon } from '../../game'
import { RankType, VisualAnalysisWithGenre, VisualAnalysisWithRank, VisualsTableRow } from '../types'
import { formatPercentage, visualsTranslationMap } from '../util/helpers'
import ColorSquare from './ColorSquare'
import GameIconAttributeModal from './GameIconAttributeModal'
import GameVisualsModal from './GameVisualsModal'
import './GameVisualsTable.scss'

interface GameVisualsTableProps {
  data: VisualAnalysisWithGenre
  genreId?: string
  isNested?: boolean
  rankType?: RankType
  topCount: number
}

/**
 * Note: This structure is recursive; There can be a GameVisualsModal (which contains another
 * GameVisualsTable) inside.
 *
 * @param data the analysis with all attribute rows
 * @param genreId from app store
 * @param isNested is opened by another modal
 * @param rankType from parent to pass on to children
 * @param topCount which top model is used as reference
 */
const GameVisualsTable: React.FC<GameVisualsTableProps> = ({ data, genreId, isNested, rankType, topCount }) => {
  const { t } = useTranslation()
  const containerRef = useRef(null)
  const [selectedAttr, setSelectedAttr] = useState<VisualsTableRow>()
  const [selectedItem, setSelectedItem] = useState<VisualAnalysisWithRank>()
  const defaultColumns: GRTableColumn<VisualsTableRow, undefined>[] = useMemo(
    () => [
      {
        labelAccessor: t(visualsTranslationMap[data.model]),
        accessor: ({ row }) => (
          <Link onClick={() => setSelectedAttr(row)}>
            {row.model.indexOf('color') < 0 ? (
              row.name
            ) : (
              <>
                <ColorSquare color={row.name} />
                {row.name}
              </>
            )}
          </Link>
        ),
        cellProps: { align: 'left', sx: { textTransform: 'capitalize' } },
        headerCellProps: { align: 'left', width: '25%' },
      },
      {
        labelAccessor: '',
        accessor: ({ row }) => <LinearProgressBar variant="determinate" value={row.value * 100} labelBefore={formatPercentage(row.value)} />,
        headerCellProps: { align: 'center', width: '25%' },
        sortable: true,
        sortAccessor: 'value',
        sortOrder: SortOrder.DESC,
      },
      {
        labelAccessor: () => (
          <Box display="flex" alignItems="center" justifyContent="center">
            {t('common:top_' + topCount)}&nbsp;
            <GRTooltip content={t('visuals-explorer:top_x_prevalence', { rank: topCount })}>
              <Typography color="primary" display="inline" variant="subtitle2">
                ?
              </Typography>
            </GRTooltip>
          </Box>
        ),
        accessor: ({ row }) => formatPercentage(row.top),
        headerCellProps: { align: 'center', width: '15%' },
      },
      {
        labelAccessor: () => (
          <Box display="flex" alignItems="center" justifyContent="center">
            {t('common:outside_top_' + topCount)}&nbsp;
            <GRTooltip content={t('visuals-explorer:outside_top_x_prevalence', { rank: topCount })}>
              <Typography color="primary" display="inline" variant="subtitle2">
                ?
              </Typography>
            </GRTooltip>
          </Box>
        ),
        accessor: ({ row }) => formatPercentage(row.outside),
        headerCellProps: { align: 'center', width: '15%' },
      },
      {
        labelAccessor: () => (
          <Box display="flex" alignItems="center" justifyContent="center">
            {t('common:difference')}&nbsp;
            <GRTooltip content={t('visuals-explorer:prevalence_difference', { rank: topCount })}>
              <Typography color="primary" display="inline" variant="subtitle2">
                ?
              </Typography>
            </GRTooltip>
          </Box>
        ),
        accessor: ({ row }) =>
          row.outside === null || row.top === null ? (
            '-'
          ) : (
            <TrendIndicator type={TrendIndicatorType.Value} direction={true} value={Math.round(100 * row.top) - Math.round(100 * row.outside)} />
          ),
        headerCellProps: { align: 'center', width: '20%' },
      },
    ],
    [data.model, t, topCount]
  )
  const [columns, setColumns] = useState(defaultColumns)

  useEffect(() => {
    setColumns(defaultColumns)
  }, [defaultColumns])

  const dimSmallValue = (row: VisualsTableRow) => (row.value < 0.1 ? { opacity: 0.5 } : {})

  const rows: VisualsTableRow[] = !data.attributes
    ? []
    : Object.keys(data.attributes).map((name) => ({
        name,
        model: data.model,
        value: data.attributes[name],
        top: data.top ? data.top[name] : null,
        outside: data.outside ? data.outside[name] : null,
      }))

  // Master 'Close All' when multiple modals open
  const keyDownHandler = ({ key }: KeyboardEvent) => {
    if (key === 'Escape') {
      setSelectedItem(undefined)
      setSelectedAttr(undefined)
    }
  }

  useEffect(() => {
    if (isNested) {
      return
    }
    window.addEventListener('keydown', keyDownHandler)
    return () => {
      window.removeEventListener('keydown', keyDownHandler)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <CardContent className="VisualsTableContainer">
      <GameIcon src={data.url} gameName={''} size="huge" />
      <TableContainer component={Box} ref={containerRef}>
        <GRTable
          columns={columns}
          rows={rows}
          onColumnsUpdated={setColumns}
          rowIdKey="name"
          defaultSortOrder={SortOrder.DESC}
          scroller={containerRef}
          getRowStyle={dimSmallValue}
        />
      </TableContainer>
      <div style={{ clear: 'left' }} />
      {selectedItem ? (
        <GameVisualsModal isNested={isNested} item={selectedItem} onClose={() => setSelectedItem(undefined)} />
      ) : (
        selectedAttr && (
          <GameIconAttributeModal
            attribute={selectedAttr}
            genreId={genreId}
            isNested={isNested}
            onClose={() => setSelectedAttr(undefined)}
            onSelectGame={setSelectedItem}
            rankType={rankType}
            top={topCount}
          />
        )
      )}
    </CardContent>
  )
}

export default GameVisualsTable
