import { useMemo } from 'react'

import { useGetGenreTaxonomyQuery } from '../../../api/core'
import { SubgenreMap } from '../../account'
import { useCurrentUserLanguage } from '../../account/hooks/userHooks'

export const useGetSubGenres = (selectedSubgenres?: SubgenreMap) => {
  const userLanguage = useCurrentUserLanguage()
  const { data: genreTaxonomy } = useGetGenreTaxonomyQuery({ userLanguage })
  const subGenres = useMemo(() => genreTaxonomy && genreTaxonomy.flatMap((category) => category.genres).flatMap((genre) => genre.subgenres), [genreTaxonomy])
  if (selectedSubgenres && !!Object.keys(selectedSubgenres).length) {
    const filteredSubgenres = subGenres?.filter((subGenre) => Object.keys(selectedSubgenres).includes(subGenre.id))
    return { subGenres: filteredSubgenres, genreTaxonomy }
  }
  return { subGenres, genreTaxonomy }
}
