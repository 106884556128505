import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { Chip } from '@mui/material'

import { useUpdateUserSettingMutation } from '../../api/combined'
import AccountBadge from '../../features/account/components/AccountBadge/AccountBadge'
import { UserSettingKeys } from '../../features/account/types/UserSettings'
import { GameSearchInput } from '../../features/game'
import { useGames } from '../../features/game/hooks/gameHooks'
import { MarketSelector, useCurrentMarket } from '../../features/markets'
import { isEmpty } from '../../features/news/utils/utils'
import { useMobileSize } from '../../features/responsiveness/hooks/responsivenessHooks'
import { useAppSelector } from '../../hooks/storeHooks'
import { Page } from '../../types/Page'
import './AppHeader.scss'

const pagesUseGameName = ['game', 'game-analyzer-detail']

interface AppHeaderProps {}

const AppHeader: React.FC<AppHeaderProps> = (props: AppHeaderProps) => {
  const { t } = useTranslation()
  const location = useLocation()
  const currentPage: Page | undefined = useAppSelector((state) => {
    return state.currentPage
  })

  const mobileSize = useMobileSize()
  const [gameId, setGameId] = useState<string | undefined>()

  const { currentMarketIso: marketIso } = useCurrentMarket()
  const {
    games: { 0: game },
  } = useGames(gameId ? [gameId] : [], marketIso)

  useEffect(() => {
    if (!!currentPage) {
      const pathName = location.pathname.split('/')
      if (pagesUseGameName.includes(currentPage.id) && !!pathName[2]) {
        setGameId(pathName[2])
      } else {
        setGameId(undefined)
      }
    }
  }, [currentPage, location.pathname])

  const headerName = (): string | null => {
    if (!currentPage) return null
    if (gameId && game) {
      return !isEmpty(game.resolvedName)
        ? game.resolvedName
        : t('common:game_by', {
            createdBy: game.createdBy,
          })
    }

    return t(currentPage.localizationKey)
  }
  const [saveUserSetting] = useUpdateUserSettingMutation()

  const onMarketSelectorChange = (marketIso: string) => {
    saveUserSetting({ settingKey: UserSettingKeys.userMarket, value: { iso: marketIso } })
  }

  return (
    <div className={!mobileSize ? 'AppHeader AppHeader--sticky' : 'AppHeader'}>
      <div id="app-header">
        <div id="app-page-name">
          {headerName()}
          {currentPage?.showBetaBadge && (
            <Chip label={t('common:beta').toUpperCase()} variant="outlined" size="small" sx={{ margin: 0, marginLeft: '10px', transform: 'translateY(1px)' }} />
          )}
        </div>
        <div id="app-market-switcher">
          <MarketSelector onChange={onMarketSelectorChange} />
        </div>
        {!mobileSize && (
          <>
            <div id="app-game-search">
              <GameSearchInput />
            </div>

            <div id="app-user-badge">
              <AccountBadge />
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default AppHeader
