import { useCallback, useState } from 'react'

import { useGetUserSettingsQuery } from '../api/combined'
import { SubgenreMap } from '../features/account'
import analyticsService, { ITrackEventOptions } from '../services/AnalyticsService'
import { AnalyticsProviders } from '../types/IAnalyticsProvider'

export const useSubgenrePicker = (initialSubgenres?: SubgenreMap, trackEventId?: string) => {
  const { data: userSettings } = useGetUserSettingsQuery()
  const [subgenres, setSubgenres] = useState<SubgenreMap>(initialSubgenres || userSettings?.userDefaultConventionalSubgenres || {})
  const handleSubgenresChange = useCallback(
    (subgenres: SubgenreMap) => {
      if (trackEventId) {
        analyticsService.trackEvent(`${trackEventId}: Filtered Games`, {
          data: {
            typeId: Object.keys(subgenres),
          },
          serviceToExclude: [AnalyticsProviders.hubspot],
        } as ITrackEventOptions)
      }
      setSubgenres(subgenres)
    },
    [trackEventId]
  )

  return { subgenres, handleSubgenresChange }
}
