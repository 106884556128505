export enum RoleEnum {
  all_games_unlocked = 'all_games_unlocked',
  csv_export_features = 'csv_export_features',
  csv_export_games = 'csv_export_games',
  feature_ideas_unlimited = 'feature_ideas_unlimited',
  feature_implementation_examples_unlimited = 'feature_implementation_examples_unlimited',
  features_facts = 'features_facts',
  features_trending = 'features_trending',
  follow_games_unlimited = 'follow_games_unlimited',
  game_analyzer_unlimited = 'game_analyzer_unlimited',
  game_comparison_multiple = 'game_comparison_multiple',
  game_explorer = 'game_explorer',
  game_search = 'game_search',
  game_slots_unlimited = 'game_slots_unlimited',
  news_feed = 'news_feed',
  soft_launch = 'soft_launch',
  top_200 = 'top_200',
  market_us = 'market_us',
  market_jp = 'market_jp',
  market_gb = 'market_gb',
  market_ca = 'market_ca',
  market_au = 'market_au',
  market_nz = 'market_nz',
  game_analyzer_free = 'game_analyzer_free',
  revenue_estimates = 'revenue_estimates',
  update_history = 'update_history',
  market_comparison = 'market_comparison',
  market_cn = 'market_cn',
  market_explorer = 'market_explorer',
  request_analysis = 'request_analysis',
  experimental_features_access = 'experimental_features_access',
  game_visuals = 'game_visuals',
  visuals_explorer = 'visuals_explorer',
  competitors_csv = 'competitors_csv',
  market_share = 'market_share',
  csv_visual_explorer = 'csv_visual_explorer',
  ftue_videos = 'ftue_videos',
  game_update_impacts = 'game_update_impacts',
  csv_market_share = 'csv_market_share',
  csv_game_private = 'csv_game_private',
  csv_game_public = 'csv_game_public',
  csv_market_explorer_features = 'csv_market_explorer_features',
  csv_market_explorer_games = 'csv_market_explorer_games',
  csv_game_comparison = 'csv_game_comparison',
  csv_top_grossing = 'csv_top_grossing',
  csv_update_history = 'csv_update_history',
  hide_subgenres = 'hide_subgenres',
  demographics = 'demographics',
  csv_game_update_impacts = 'csv_game_update_impacts',
  news_feed_limited = 'news_feed_limited',
  genre_essentials = 'genre_essentials',
  game_comparison_motivations = 'game_comparison_motivations',
  motivations = 'motivations',
  analyst_reviews_and_comments = 'analyst_reviews_and_comments',
  csv_game_revenue_downloads = 'csv_game_revenue_downloads',
  turbo_search = 'turbo_search',
  oadmin = 'oadmin',
  internal = 'internal',
  live_events_tracker = 'live_events_tracker',
  ask_analyst = 'contact_analyst',
  slack = 'slack',
  ai_analyst = 'ai_analyst',
  admin = 'admin',
  reports = 'reports',
  feature_tags = 'feature_tags',
}
