import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { containSameElements } from '../../../helpers/arrays'
import { intersection } from '../../../helpers/intersection'
import { uniq } from '../../../helpers/uniq'
import { RootState } from '../../../store'
import { TrackingEventWithGameTimelineGroup } from '../types/LiveEvents'
import { TrackedGame } from '../types/TrackedGame'

/**
 * Slice containing state for LET calendar visibility
 */
type LiveEventsTrackerCalendars = {
  selectedTrackedGames: TrackedGame[]
  visibility: {
    gameCalendars?: string[]
    eventTypeGroups?: string[]
  }
}

const initialState: LiveEventsTrackerCalendars = {
  selectedTrackedGames: [],
  visibility: {},
}
const maxOpenEventTypeGroups = 5
export const maxOpenGameCalendarsCount = 3

export const liveEventsTrackerCalendarsSlice = createSlice({
  name: 'liveEventsTrackerCalendars',
  initialState,
  reducers: {
    visibleEventTypeGroupsChanged: (state, action: PayloadAction<string[]>) => {
      const visibleGroupsCountLimit = resolveEventTypeGroupCountLimit(state.selectedTrackedGames.length)
      const newVisibleGroups = limitArray(action.payload, visibleGroupsCountLimit)
      if (!containSameElements(newVisibleGroups || [], state.visibility.eventTypeGroups || [])) {
        state.visibility.eventTypeGroups = newVisibleGroups
      }
    },
    visibleGameCalendarsChanged: (state, action: PayloadAction<string[]>) => {
      state.visibility.gameCalendars = action.payload.slice(0, maxOpenGameCalendarsCount)
    },
    retainExistingVisibleGroups: (state, action: PayloadAction<TrackingEventWithGameTimelineGroup[]>) => {
      const groupsToRetain = intersection(uniq(action.payload.map((group) => group.trackingEventType)), state.visibility.eventTypeGroups || [])
      const shouldUpdateState = !containSameElements(groupsToRetain, state.visibility.eventTypeGroups || [])
      state.visibility.eventTypeGroups = shouldUpdateState ? groupsToRetain : state.visibility.eventTypeGroups
    },
    toggleEventTypeGroupVisibility: (state, action: PayloadAction<string>) => {
      const groupId = action.payload
      const visibleGroups = state.visibility.eventTypeGroups
      const visibleGroupsCountLimit = resolveEventTypeGroupCountLimit(state.selectedTrackedGames.length)
      const newValue = toggleArrayValue(visibleGroups, groupId, visibleGroupsCountLimit)

      state.visibility.eventTypeGroups = newValue
    },
    gameCalendarVisibilityToggled: (state, action: PayloadAction<string>) => {
      const visibleGameCalendars = state.visibility.gameCalendars
      const newValue = toggleArrayValue(visibleGameCalendars, action.payload, maxOpenGameCalendarsCount)

      state.visibility.gameCalendars = newValue
    },
    trackedGamesSelectionChange: (state, action: PayloadAction<TrackedGame[]>) => {
      state.selectedTrackedGames = action.payload
      delete state.visibility.eventTypeGroups
    },
  },
})

export const selectVisibleEventTypeGroups = (state: RootState) => state.liveEventsTrackerCalendars.visibility.eventTypeGroups
export const selectVisibleGameCalendars = (state: RootState) => state.liveEventsTrackerCalendars.visibility.gameCalendars
export const selectSelectedTrackedGames = (state: RootState) => state.liveEventsTrackerCalendars.selectedTrackedGames

export const {
  gameCalendarVisibilityToggled,
  toggleEventTypeGroupVisibility,
  visibleEventTypeGroupsChanged,
  visibleGameCalendarsChanged,
  retainExistingVisibleGroups,
  trackedGamesSelectionChange,
} = liveEventsTrackerCalendarsSlice.actions

export default liveEventsTrackerCalendarsSlice.reducer

const toggleArrayValue = <T>(array: T[] | undefined, value: T, lengthLimit = 5) => {
  if (array?.find((val) => val === value)) {
    return array?.filter((val) => val !== value) || []
  } else if (lengthLimit && array && array?.length >= lengthLimit) {
    array?.shift()
    array?.push(value)
    return array ? [...array] : []
  } else {
    return array ? [...array, value] : [value]
  }
}

const resolveEventTypeGroupCountLimit = (trackedGamesCount: number) => {
  return trackedGamesCount <= maxOpenGameCalendarsCount ? 0 : maxOpenEventTypeGroups
}

const limitArray = <T>(array: T[] | undefined, limit: number) => {
  if (limit) {
    return array?.slice(0, limit)
  } else {
    return array
  }
}
