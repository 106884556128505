import classNames from 'classnames'
import { FC } from 'react'

import { Check } from '@mui/icons-material'
import { Chip } from '@mui/material'

import { GRCountryFlag } from '../../../../../components/GRCountryFlag/GRCountryFlag'
import { useAppSelector } from '../../../../../hooks/storeHooks'
import { useCurrentUserLanguage } from '../../../../account/hooks/userHooks'
import { Market } from '../../../../markets'
import { selectMarketByCaseInsensitiveIso } from '../../../../markets/selectors/selectors'
import './MarketChip.scss'

export const MarketChip: FC<{ marketIso: string; selected: boolean; onClick: (marketIso: string) => void; onFilter?: (market?: Market) => boolean }> = ({
  marketIso,
  selected,
  onClick,
  onFilter,
}) => {
  const userLanguage = useCurrentUserLanguage()
  const market = useAppSelector((state) => selectMarketByCaseInsensitiveIso(userLanguage)(state, marketIso))
  const classes = classNames('MarketChip', { selected })
  const isMatch = onFilter ? onFilter(market) : true
  return isMatch ? (
    <Chip
      className={classes}
      label={
        <>
          <GRCountryFlag countryCode={marketIso} size="small" className="flag" /> {market?.name}
        </>
      }
      icon={<Check color={selected ? 'success' : 'disabled'} className="icon" />}
      onClick={() => onClick(marketIso)}
      color={selected ? 'success' : undefined}
      variant="outlined"
    />
  ) : null
}
