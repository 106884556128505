import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Typography, Chip, Box } from '@mui/material'

import { Duration } from '../../../types/TrackingEvents'
import { EventHighlightButton } from '../../EventHighlightButton/EventHighlightButton'

/**
 * Component displaying an event header
 */
type EventHeaderProps = {
  duration?: Duration
  eventName?: string
  inactive?: boolean
  eventId?: string
  eventTypeId?: string
  trackedGameId?: string
  onEventHighlighted?: (eventId: string) => void
}

export const EventHeader: FC<EventHeaderProps> = ({ duration, eventName, inactive, eventId, eventTypeId, trackedGameId, onEventHighlighted }) => {
  const { t } = useTranslation()

  return (
    <div className="EventHeader">
      <Typography variant="h2" sx={{ mb: 1, pt: 0 }}>
        {eventName}
        {inactive && <Chip label={t('live-events:event_inactive')} color="warning" size="small" sx={{ verticalAlign: 'middle', ml: 1, mb: '3px' }} />}
      </Typography>

      <Box sx={{ textAlign: 'center', mb: 3 }}>
        <EventHighlightButton
          eventId={eventId || ''}
          trackedGameId={trackedGameId}
          eventType={eventTypeId}
          eventStartTime={duration?.start}
          onEventHighlighted={onEventHighlighted}
          size="small"
        />
      </Box>
    </div>
  )
}
