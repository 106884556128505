import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { ContentCopy, RemoveCircle, TableChartOutlined } from '@mui/icons-material'
import { Box, CircularProgress, ListItemIcon, ListItemText, MenuItem, MenuList } from '@mui/material'

import { useGetUserSettingsQuery } from '../../../../api/combined'
import { useAppDispatch } from '../../../../hooks/storeHooks'
import analyticsService from '../../../../services/AnalyticsService'
import { GameCardButtons } from '../../../game-analyzer/components/GameStage/GameStage'
import { useCurrentMarket } from '../../../markets/hooks/useCurrentMarket'
import { displaySnackBar } from '../../../snackbar/actions/displaySnackBar'
import { SnackBarMessage } from '../../../snackbar/types/SnackBarMessage'
import { useAvailabelGameSlotsCount } from '../../hooks/gameHooks'
import { Game } from '../../types/Game'
import GameCard from '../GameCard/GameCard'
import GameCloneDialog from '../GameCloneDialog/GameCloneDialog'
import ToggleGameFollowDialog from '../ToggleGameFollowDialog/ToggleGameFollowDialog'
import './GameCardList.scss'

interface Props {
  title: string
  games: { games?: Array<Game>; isLoading: boolean; error?: any }
  displayLastSeenBadge?: boolean
  showUnfollowButton?: boolean
}

const GameCardList: React.FC<Props> = ({ title, games, displayLastSeenBadge, showUnfollowButton }) => {
  const availabelGameSlotsCount = useAvailabelGameSlotsCount()

  return (
    <div className="GameCardList">
      <h3 className="text-center">{title}</h3>
      <div className="GameCardList__cards">
        {games.isLoading ? (
          <div className="text-center top-margin bottom-margin">
            <CircularProgress color="primary" />
          </div>
        ) : (
          <Games
            games={games.games}
            displayLastSeenBadge={displayLastSeenBadge}
            availabelGameSlotsCount={availabelGameSlotsCount || 0}
            showUnfollowButton={showUnfollowButton}
          />
        )}
      </div>
    </div>
  )
}

const Games = ({
  games,
  displayLastSeenBadge,
  availabelGameSlotsCount,
  showUnfollowButton,
}: {
  games?: Game[]
  displayLastSeenBadge?: boolean
  availabelGameSlotsCount: number
  showUnfollowButton?: boolean
}) => {
  const { t } = useTranslation()
  const { data: userSettings } = useGetUserSettingsQuery()
  const { currentMarketIso } = useCurrentMarket()
  const navigate = useNavigate()
  const [cloneGame, setCloneGame] = useState<Game | undefined>()
  const [gameUnfollowed, setGameUnfollowed] = useState<Game | undefined>()
  const dispatch = useAppDispatch()
  const showError = useCallback((errorPayload: SnackBarMessage) => dispatch(displaySnackBar(errorPayload)), [dispatch])

  const LAST_SEEN_GAME_VERSIONS_MAP = useMemo(() => userSettings?.lastSeenGameVersionsMap || {}, [userSettings?.lastSeenGameVersionsMap])
  const sortedGames: Game[] = useMemo(() => {
    if (!games) return []
    const changedGames = games
      .filter((game) => LAST_SEEN_GAME_VERSIONS_MAP[game.appId] !== game.version)
      .sort((a, b) => b.resolvedName.localeCompare(a.resolvedName))
      .map((game) => game.id)
    return [...games].sort((a, b) => changedGames.indexOf(b.id) - changedGames.indexOf(a.id))
  }, [LAST_SEEN_GAME_VERSIONS_MAP, games])

  const navigateToAnalyzer = (game: Game) => {
    if (game.RIBBON_TYPE !== 'featured') {
      navigate(`/game/${game.id}/updatehistory`)
      return
    }

    analyticsService.trackEvent('Clicked featured game', { data: { gameName: game.resolvedName } })
    navigate(`/game/${game.id}/overview`)
  }
  const gameCardButtonActions = (game: Game): GameCardButtons[] => {
    const defaultActions = [
      {
        text: t('overview:game_overview'),
        onClick: (game: Game) => {
          navigate(`/game/${game.id}`)
        },
        icon: <TableChartOutlined fontSize="small" />,
        hidden: !game.gpsPerMarket[currentMarketIso],
      },
      {
        text: t('game-header:clone_game'),
        onClick: (game: Game) => {
          if (availabelGameSlotsCount > 0) {
            setCloneGame(game)
          } else {
            showError({ message: t('game-header:clone_error_body_no_slot'), severity: 'warning', open: true })
          }
        },
        icon: <ContentCopy fontSize="small" />,
      },
    ]
    const unfollowAction = [
      {
        text: t('common:unfollow_game'),
        onClick: (game: Game) => {
          setGameUnfollowed(game)
        },
        icon: <RemoveCircle fontSize="small" />,
      },
    ]

    const showActions = defaultActions.filter((action) => !action.hidden)
    if (!showUnfollowButton) return showActions

    return [...showActions, ...unfollowAction]
  }

  return (
    <>
      {sortedGames && sortedGames.length > 0 ? (
        sortedGames.map((game) => {
          return (
            <Box key={game.id} sx={{ mb: 2 }}>
              <GameCard
                onCardClick={() => {
                  navigateToAnalyzer(game)
                }}
                game={game}
                showLastSeenBadge={displayLastSeenBadge && LAST_SEEN_GAME_VERSIONS_MAP[game.appId] !== game.version}
              >
                <GameCard.Actions>
                  <MenuList>
                    {gameCardButtonActions(game).map((button) => (
                      <MenuItem
                        onClick={() => button.onClick(game)}
                        sx={{
                          pl: 2,
                          pr: 2,
                        }}
                        key={button.text}
                      >
                        <ListItemIcon>{button.icon}</ListItemIcon>
                        <ListItemText>{button.text}</ListItemText>
                      </MenuItem>
                    ))}
                  </MenuList>
                </GameCard.Actions>
              </GameCard>
            </Box>
          )
        })
      ) : (
        <p className="text-center light-text-color">{t('common:no_games_available')}</p>
      )}
      {!!cloneGame && <GameCloneDialog originGame={cloneGame} open={!!cloneGame} onClose={() => setCloneGame(undefined)} />}
      {!!gameUnfollowed && <ToggleGameFollowDialog open={true} onClose={() => setGameUnfollowed(undefined)} game={gameUnfollowed} />}
    </>
  )
}

export default GameCardList
